import type { ClientBdrId, ClientBdrLevel, ProfileSummaryFlagType } from './client';
import type { Profiles, ProfileTypeKey } from './models';
import type { FxCashPerimeterKey, FxCashProductKey } from './cash';
import type { FxOrderPerimeterKey, FxOrderProductKey } from './order';

export interface FxProductProfiles {
  profileTypeKey: ProfileTypeKey;
  profiles: Profiles[];
}

export interface FxClientInheritance {
  data: FxClientInheritanceData;
}

export interface FxClientInheritanceData {
  clientBdrId: number;
  clientBdrLevel: string;
  profileSummaryFlag: ProfileSummaryFlagType;
  perimeters: FxInheritancePerimeter[];
}

export type FxInheritancePerimeter = FxOrderInheritancePerimeter | FxCashInheritancePerimeter;
export type FxInheritanceProduct = FxOrderInheritanceProduct | FxCashInheritanceProduct;

export type FxOrderInheritancePerimeter = {
  perimeterKey: FxOrderPerimeterKey;
  products: FxOrderInheritanceProduct[];
};

export type FxOrderInheritanceProduct = {
  productKey: FxOrderProductKey;
  profile: ProductInheritedProfile;
};

export type FxCashInheritancePerimeter = {
  perimeterKey: FxCashPerimeterKey;
  products: FxCashInheritanceProduct[];
};

export type FxCashInheritanceProduct = {
  productKey: FxCashProductKey;
  marginGrid?: ProductInheritedProfile & { gridDataTypeKey: 'margin-data-type' };
  tieringGrid?: ProductInheritedProfile & { gridDataTypeKey: 'tiering-data-type' };
};

export interface ProductInheritedProfile {
  profileId: number;
  profileName: string;
  profileTypeKey: string;
  isProfileInherited: boolean;
  inheritableProfile: InheritableProfile;
}

export interface InheritableProfile {
  profileId: number;
  profileName: string;
  client: InheritableProfileClient;
}

export interface InheritableProfileClient {
  clientLongName: string;
  clientBdrId: ClientBdrId;
  clientBdrLevel: ClientBdrLevel;
  isDME: boolean;
}

// ██╗   ██╗████████╗██╗██╗     ██╗████████╗██╗███████╗███████╗
// ██║   ██║╚══██╔══╝██║██║     ██║╚══██╔══╝██║██╔════╝██╔════╝
// ██║   ██║   ██║   ██║██║     ██║   ██║   ██║█████╗  ███████╗
// ██║   ██║   ██║   ██║██║     ██║   ██║   ██║██╔══╝  ╚════██║
// ╚██████╔╝   ██║   ██║███████╗██║   ██║   ██║███████╗███████║
//  ╚═════╝    ╚═╝   ╚═╝╚══════╝╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝

export const getFxOrderInheritancePerimeter = (allPerimeters: FxInheritancePerimeter[]) =>
  allPerimeters.find(isFxOrderInheritancePerimeter) as FxOrderInheritancePerimeter | undefined;

export const isFxOrderInheritancePerimeter = (
  perimeter: FxInheritancePerimeter,
): perimeter is FxOrderInheritancePerimeter => perimeter.perimeterKey === 'fx-order-perimeter';

export const isFxOrderInheritanceProduct = (
  product: FxInheritanceProduct | undefined,
): product is FxOrderInheritanceProduct =>
  product?.productKey === 'algo-order-product' ||
  product?.productKey === 'fixing-order-product' ||
  product?.productKey === 'limit-order-product';

// ------------------------------------------------------------

export const getFxCashInheritancePerimeter = (allPerimeters: FxInheritancePerimeter[]) =>
  allPerimeters.find(isFxCashInheritancePerimeter) as FxCashInheritancePerimeter | undefined;

export const isFxCashInheritancePerimeter = (
  perimeter: FxInheritancePerimeter,
): perimeter is FxCashInheritancePerimeter => perimeter.perimeterKey === 'fx-cash-rfq-perimeter';

export const isFxCashInheritanceProduct = (
  product: FxInheritanceProduct | undefined,
): product is FxCashInheritanceProduct =>
  product?.productKey === 'spot-product' ||
  product?.productKey === 'ndf-product' ||
  product?.productKey === 'swap-product';
