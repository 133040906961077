import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonGroup, Label } from 'reactstrap';
import { isString } from '@sgme/fp';
import { type Item, SgPicker } from '@components/SgPicker';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { changeCashColumnDimension, changeOrderBaseColumn } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useEffect } from 'react';

interface CurrencyPairButtonsProps {
  currencies: string[];
  groups: string[];
  setSelectedCurrencyType: (currencyType: string) => void;
  selectedCurrencyType: string;
  disabled: boolean;
  isPairEnabled: boolean;
  isSingleEnabled: boolean;
  isGroupEnabled: boolean;
  isAllEnabled: boolean;
  isOrder: boolean;
  isEditingRowMargin: boolean
}

export const CurrencyPairSelect = ({
  currencies,
  groups,
  setSelectedCurrencyType,
  selectedCurrencyType,
  disabled,
  isAllEnabled,
  isGroupEnabled,
  isPairEnabled,
  isSingleEnabled,
  isOrder,
  isEditingRowMargin
}: CurrencyPairButtonsProps): JSX.Element => {

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const currencyValue = useAppSelector(
    (state) => state.fxProfileEdition.editingRow?.columns.currencyPairDimension ?? '',
  );

  const buttonNames = [
    isAllEnabled && 'all',
    isPairEnabled && 'pair',
    isGroupEnabled && 'group',
    isSingleEnabled && 'single',
  ].filter(isString);

  // this is needed to initialize the component
  // with the right currency type when it's an edition
  // from an existing row
  useEffect(() => {
    setSelectedCurrencyType(getDefaultButtonSelected(currencies, groups, currencyValue ?? '*'));
  }, []);



  const changeBaseColumn = isOrder ? changeOrderBaseColumn : changeCashColumnDimension

  const setCurrencyValue = (item: Item | undefined) => {
    dispatch(changeBaseColumn({ key: 'currencyPairDimension', value: item?.key ?? '' }));
  };

  const setLeftCurrencyValue = (item: Item | undefined) => {
    dispatch(
      changeBaseColumn({
        key: 'currencyPairDimension',
        value: `${item?.key ?? ''}/${rightCurrencyValue ?? ''}`,
      }),
    );
  };

  const setRightCurrencyValue = (item: Item | undefined) => {
    dispatch(
      changeBaseColumn({
        key: 'currencyPairDimension',
        value: `${leftCurrencyValue ?? ''}/${item?.key ?? ''}`,
      }),
    );
  };

  const onSelected = (currencyType: string) => () => {
    // when ALL currency pair type is selected it we should set it accordingly
    if (currencyType === 'all') {
      dispatch(changeBaseColumn({ key: 'currencyPairDimension', value: '*' }));
    } else {
      // when another currency pair type is selected we should reset it to its initial state
      // allowing the user to further select it.
      dispatch(changeBaseColumn({ key: 'currencyPairDimension', value: '' }));
    }

    if (currencyType === 'pair' && isEditingRowMargin) {
      dispatch(
        changeCashColumnDimension({
          key: "applyOnInvertedPairDimension",
          value: "True",
        }),
      )
    } else {
      dispatch(
        changeCashColumnDimension({
          key: "applyOnInvertedPairDimension",
          value: "False",
        }),
      )
    }

    setSelectedCurrencyType(currencyType);
  };

  const [leftCurrencyValue, rightCurrencyValue] = currencyValue.split('/');

  return (
    <div>
      <div>
        <Label className="text-primary mb-1" for="currencyPairDimension">
          <FormattedMessage id="currencyPairDimension" />
        </Label>
      </div>

      <div>
        <ButtonGroup id="currencyPairDimension" className="w-100">
          {buttonNames.map((currencyType) => (
            <Button
              key={currencyType}
              className="btn-toggle-primary"
              color="primary"
              outline
              data-e2e={`currency-${currencyType}`}
              onClick={onSelected(currencyType)}
              active={selectedCurrencyType === currencyType}
              disabled={disabled}
            >
              <FormattedMessage id={currencyType} />
            </Button>
          ))}
        </ButtonGroup>
      </div>

      {selectedCurrencyType !== 'all' && (
        <div className={'mt-8px'}>
          {selectedCurrencyType === 'group' && (
            <div className="w-100" data-e2e={'currency-select-group'}>
              <SgPicker
                mode="filter"
                placeholder={formatMessage({ id: 'placeholder.currency-group' })}
                singleSelect
                noIcon
                items={groups}
                defaultValue={currencyValue}
                onChange={setCurrencyValue}
              />
            </div>
          )}

          {selectedCurrencyType === 'single' && (
            <div className="w-100" data-e2e={'currency-select-single'}>
              <SgPicker
                mode="filter"
                placeholder={formatMessage({ id: 'placeholder.currency' })}
                singleSelect
                noIcon
                items={currencies}
                defaultValue={currencyValue}
                onChange={setCurrencyValue}
              />
            </div>
          )}

          {selectedCurrencyType === 'pair' && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="w-100" data-e2e={'currency-select-pair-left'}>
                <SgPicker
                  mode="filter"
                  placeholder={formatMessage({ id: 'placeholder.leftCurrency' })}
                  defaultValue={leftCurrencyValue}
                  onChange={setLeftCurrencyValue}
                  singleSelect
                  noIcon
                  noClear
                  items={currencies}
                />
              </div>

              <span className="mx-2">/</span>
              <div className="w-100" data-e2e={'currency-select-pair-right'}>
                <SgPicker
                  mode="filter"
                  placeholder={formatMessage({ id: 'placeholder.rightCurrency' })}
                  defaultValue={rightCurrencyValue}
                  onChange={setRightCurrencyValue}
                  singleSelect
                  noIcon
                  noClear
                  items={currencies}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const getDefaultButtonSelected = (currencies: string[], groups: string[], value: string): string => {
  if (groups?.includes(value)) {
    return 'group';
  }

  if (currencies?.includes(value)) {
    return 'single';
  }

  if (isString(value) && isCurrencyPair(value, currencies)) {
    return 'pair';
  }

  return 'all';
};

const isCurrencyPair = (value: string, currencies: string[] | undefined) => {
  const splitCurrency = value.split('/');
  return splitCurrency.length === 2 && splitCurrency.every((currency) => currencies?.includes(currency));
};
