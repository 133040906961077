import { FormattedMessage } from 'react-intl';
import { selectClientById } from 'services/clients';

import { narrowDefined } from 'utils/asserts';
import { changeReasons } from 'utils/changeReasons';

import { Select } from '../Form/Select';
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {getChangeReasonAll, getEditedClientsDiffs} from "../../store/clientEdition/clientEditionSelectors";
import {actionCreators} from "../../store/actions";

const options: [string, string][] = changeReasons.map((x) => [x, x]);

interface SelectSingleReasonProps {
  value: string | undefined;
  msgId: string;
  msgValues?: Record<string, React.ReactNode | ((msg: React.ReactNode[]) => JSX.Element)>;
  onChange(value: string): void;
}

function SelectSingleReason({
  value,
  msgId,
  msgValues,
  onChange,
}: SelectSingleReasonProps): JSX.Element {
  return (
    <div className="form-group">
      <label className="fw-normal mb-2 text-primary">
        <FormattedMessage id={msgId} values={msgValues} />
      </label>
      <Select value={value} options={options} onChange={onChange} />
    </div>
  );
}

interface SelectClientReasonProps {
  clientIds: number[];
}

function SelectOneClientReasonMode({ clientIds }: SelectClientReasonProps): JSX.Element {
  const dispatch = useAppDispatch();

  const selectEditedClientsDiffs = useAppSelector(getEditedClientsDiffs);

  const getClientById = useAppSelector(selectClientById);

  return (
    <>
      {clientIds.map((clientId) => {
        const value = selectEditedClientsDiffs[clientId]?.changeReason;
        const clientName = narrowDefined(getClientById(clientId)).name;
        return (
          <SelectSingleReason
            key={clientId}
            value={value}
            msgId="changeReasonModal.selectReason"
            msgValues={{
              client: clientName,
              strong: (msg) => <strong className="text-secondary">{msg}</strong>,
            }}
            onChange={(changeReason: string) =>
              dispatch(
                actionCreators.clientEdition.marginProfileModified({
                  clientId,
                  diff: { changeReason },
                }),
              )
            }
          />
        );
      })}
    </>
  );
}

function SelectAllClientReasonMode({ clientIds }: SelectClientReasonProps): JSX.Element {
  const dispatch = useAppDispatch();
  const reason = useAppSelector(getChangeReasonAll);
  return (
    <SelectSingleReason
      value={reason}
      msgId="changeReasonModal.selectReasonAll"
      onChange={(changeReason: string) =>
        dispatch(
          actionCreators.clientEdition.marginProfileModifiedAll({
            clientIds,
            diff: { changeReason },
          }),
        )
      }
    />
  );
}

type SelectReasonMode = 'all-clients' | 'one-client';

export interface SelectReasonProps {
  clientIds: number[];
  mode: SelectReasonMode;
}

export function SelectReason({ clientIds, mode }: SelectReasonProps): JSX.Element {
  return mode === 'one-client' ? (
    <SelectOneClientReasonMode clientIds={clientIds} />
  ) : (
    <SelectAllClientReasonMode clientIds={clientIds} />
  );
}
