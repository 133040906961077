import type { FC } from 'react';
import { Button, HStack } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type ProfileAlreadyModifiedErrorProps = {
  reload: () => void;
  className?: string;
};

const ProfileAlreadyModifiedError: FC<ProfileAlreadyModifiedErrorProps> = ({ reload, className }) => (
  <HStack className={`bg-warning bg-opacity-10 px-3 py-16px ${className ?? ''}`} gap={'16px'}>
    <em className="icon icon-md text-warning">warning_amber</em>
    <StyledSpan>
      <FormattedMessage id={`error.ProfileAlreadyModifiedError`} />
    </StyledSpan>
    <Button purpose={'outline'} color={'warning'} onClick={reload}>
      <FormattedMessage id={`common.reload`} />
    </Button>
  </HStack>
);

export default ProfileAlreadyModifiedError;

const StyledSpan = styled.span.attrs({
  className: 'text-warning',
})`
  strong {
    color: inherit !important; // this is done as strong balise in translation file gets applied an automatic text-secondary className, therfore we need to override it
  }
`;
