import type { FxOrderProfileColumns, FxOrderProfileRowMappedForAgGrid } from '@services/fx/model/order';
import { isFxAlgoOrderProfileColumns } from '@services/fx/model/perimiters';
import type { FxProfileMarginGridRow } from '@services/fx/model/profile-margin-grid';

export function toOrderGridRow(
  selectedProfileRows: FxProfileMarginGridRow<FxOrderProfileColumns>[],
): FxOrderProfileRowMappedForAgGrid[] {
  return selectedProfileRows.map((row, index) => {
    if (isFxAlgoOrderProfileColumns(row.columns)) {
      const {
        'liquidityProviderDimension.Internal.marginValue': _InternalMarginValue,
        'liquidityProviderDimension.External.marginValue': _ExternalMarginValue,
        ...otherColumns
      } = row.columns;

      const columns = {
        ...otherColumns,
        liquidityProviderDimension: {
          Internal: row.columns['liquidityProviderDimension.Internal.marginValue'],
          External: row.columns['liquidityProviderDimension.External.marginValue'],
        },
      };

      return {
        ...row,
        ...columns,
        rowContext: { isLastRow: index === selectedProfileRows.length - 1 },
        uiRowId: row.uiRowId || index,
      };
    }

    const { columns, ...otherProperties } = row;

    return {
      ...otherProperties,
      ...columns,
      rowContext: { isLastRow: index === selectedProfileRows.length - 1 },
      uiRowId: row.uiRowId || index,
    };
  });
}
