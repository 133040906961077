import { FormattedMessage } from 'react-intl';
import { FormGroup, Input, Label } from 'reactstrap';
import type { ChangeReasonsTypeKey } from '@services/fx/model/models';
import { useGetFxProfileChangeReasonsQuery } from '@services/fx/getFxProfileChangeReasons';

interface ReasonsFormGroupProps {
  changeReasonTypeKey: ChangeReasonsTypeKey;
}

export const ReasonsFormGroup = ({ changeReasonTypeKey }: ReasonsFormGroupProps): JSX.Element => {
  const { changeReasons } = useGetFxProfileChangeReasonsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      changeReasons: data?.entities[changeReasonTypeKey]?.reasons || [],
    }),
  });

  return (
    <FormGroup className="form-group">
      <Label for="reasonSelect">
        <FormattedMessage id="common.reason" />
      </Label>
      <Input id="reasonSelect" name="reason" type="select" className="form-control form-control-lg">
        {changeReasons.map(({ reasonId, reasonText }) => (
          <option key={reasonId} value={`${reasonId}`}>
            {reasonText}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};
