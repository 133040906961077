import { useState } from 'react';

import type { IcAccount } from 'components/IcAccountPicker';
import { IcAccountPicker } from 'components/IcAccountPicker';

export function IcAccountPickerSection(): JSX.Element {
  const [accountName, setAccountName] = useState<string | null>(null);
  const onChange = (account: IcAccount) => setAccountName(account?.name ?? '');

  return (
    <section data-e2e="status-ic-account-picker">
      <h3>IC Account Picker</h3>

      <IcAccountPicker onChange={onChange} />
      <div data-e2e="status-ic-account-picker-client-name">{accountName}</div>
    </section>
  );
}
