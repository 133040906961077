import { useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Select } from 'components/Form/Select';
import {pcruMargins} from "../../../store/pcruProfiles/pcruMarginsSlice";

const options: [number, string][] = pcruMargins.map((value) => [value, `${value}bp`]);

export const SelectPcruMargin = () => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'pcruMargin',
    rules: {
      required: true,
    },
  });

  return (
    <div className="d-flex align-items-center gap-2">
      <div className="form-group col-6" data-e2e="select-pcru-margin-profile">
        <label className="fw-medium mb-2">
          <FormattedMessage id="clientForm.pcruMarginProfile" />
        </label>

        <Select
          {...field}
          className={`form-select ${error ? 'is-warning' : ''}`}
          options={options}
        />
      </div>
    </div>
  );
};
