import * as clientDuplicationSelectors from './clientDuplication/clientDuplicationSelectors';
import * as clientEditionSelectors from './clientEdition/clientEditionSelectors';
import * as errorSelectors from './error/errorSelectors';
import * as filtersSelectors from './filters/filtersSelectors';
import * as fxClientsSelectors from './fxClients/fxClientsSelector';
import * as marginProfileEditionSelectors from './marginProfilesEdition/marginProfilesEditionSelectors';
import * as uiSelectors from './ui/uiSelectors';

export const selectors = {
  ...filtersSelectors,
  ...uiSelectors,
  ...clientEditionSelectors,
  ...marginProfileEditionSelectors,
  ...errorSelectors,
  ...clientDuplicationSelectors,
  ...fxClientsSelectors,
};
export type Selectors = typeof selectors;
