import type { InstrumentName } from '@pages/fx/FxProductsPage/CashTab/model';
import type { ClientBdrLevel } from '@services/fx/model/client';
import type { ProfileId } from '@services/fx/model/models';

export const makeSelectCashProfileUrl = (
  instrumentName: InstrumentName,
  profileId: ProfileId,
  clientBdrId: number,
  clientBdrLevel: ClientBdrLevel,
  isInheritedProfile: boolean,
) => {
  const [productKey, gridDataType] = instrumentName.split('/');
  const isInheritedSearchParams = isInheritedProfile ? '&isInherited=true' : '';
  return `/fx/clients/${clientBdrId}/${clientBdrLevel}/perimeters/fx-cash-rfq-perimeter/products/${productKey}/gridDataType/${gridDataType}?profileId=${profileId}${isInheritedSearchParams}`;
};
