import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type Item, SgPicker } from '@components/SgPicker';
import { mapAndTranslateOptions } from './utils';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { HelpCenter } from '@components/HelpCenter';
import type { FxOrderProfileColumns } from '@services/fx/model/order';
import { changeOrderBaseColumn } from '@store/fxProfileEdition/fxProfileEditionSlice';

type InstrumentSelectProps = {
  instrumentDimension: Array<string>;
  disabled: boolean;
};
export const InstrumentSelect: FC<InstrumentSelectProps> = ({ instrumentDimension, disabled }) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const instrumentValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxOrderProfileColumns).instrumentDimension,
  );

  const setInstrumentValue = (item: Item | null) => {
    dispatch(changeOrderBaseColumn({ value: item?.key ?? '', key: 'instrumentDimension' }));
  };

  return (
    <div className={'form-group'} data-e2e="instrument-dimension-select">
      <div className={'d-flex flex-between'}>
        <label className="text-primary fw-medium" htmlFor="instrumentDimensionSelect">
          <FormattedMessage id="instrumentDimension" />
        </label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <SgPicker
        defaultValue={instrumentValue}
        onChange={setInstrumentValue}
        mode="filter"
        singleSelect
        noIcon
        noClear
        disabled={disabled}
        items={mapAndTranslateOptions(formatMessage, instrumentDimension)}
      />
    </div>
  );
};
