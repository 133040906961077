import type { Color, Size } from "@sgme/ui";
import { ToggleButtonContext, type ToggleButtonContextData, type ToggleHandler } from "./useContext";
import type { PropsWithChildren } from "react";
import clsx from "clsx";


export type ToggleButtonGroupsProps = {
  activeButtonIds: string[]
  color?: Color
  size?: Size
  selectionType: "single" | "multiple"
  onToggle: ToggleHandler
}

export const ToggleButtonGroup = (props: PropsWithChildren<ToggleButtonGroupsProps>) => {
  const {
    activeButtonIds,
    color: defaultColor,
    size,
    selectionType,
    onToggle,
    children
  } = props;

  const componentClassName = clsx(
    [
      "btn-group btn-group-toggle"
    ],
    {
      "btn-group-sm": size === "sm",
      "btn-group-lg": size === "lg",
      "btn-group-sxl": size === "xl",
    }
  );

  const context: ToggleButtonContextData = {
    activeButtonIds,
    defaultColor,
    selectionType,
    onToggle
  }

  return (
    <ToggleButtonContext.Provider value={context}>
      <div className={componentClassName} data-toggle="buttons" role="group" aria-label={`toggle buttons ${selectionType} select`}>
        {children}
      </div>
    </ToggleButtonContext.Provider>
  )
}
