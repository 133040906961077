import { useState } from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { isDefined } from '@sgme/fp';

import { FormMessageStatus } from 'components/Form/FormMessageStatus';
import { getLabel } from 'components/Form/getLabel';

interface MarginInputProps extends UseControllerProps {
  className?: string;
  unit: string;
  disabled?: boolean;
  hideError?: boolean;
  labelLocaleKey?: string;
  applyMask?: boolean;
}

export const MarginInput = ({
  className = '',
  name,
  unit,
  labelLocaleKey,
  disabled,
  hideError = false,
  applyMask = false,
}: MarginInputProps): JSX.Element => {
  const [
    prevApplyMask,
    setPrevApplyMask
  ] = useState(applyMask);

  const {
    formatMessage
  } = useIntl();

  const {
    setValue
  } = useFormContext();

  const placeholder = formatMessage({ id: 'placeholder.marginUnit' });

  const controllerConfiguration = {
    name,
    rules: {
      pattern: /^\d*(\.\d+)?$/,
      required: true,
    },
  };

  const {
    field,
    fieldState: { error },
  } = useController(controllerConfiguration);

  if (prevApplyMask !== applyMask && applyMask && isDefined(field.value)) {
    const defaultedValue = Number(field.value);
    setValue(field.name, Math.round((defaultedValue + Number.EPSILON) * 100));
    setPrevApplyMask(applyMask);
  }

  if (prevApplyMask !== applyMask && !applyMask && isDefined(field.value)) {
    setValue(field.name, field.value / 100);
    setPrevApplyMask(applyMask);
  }

  const labelComponent = (
    <>
      <FormattedMessage id={name} />

      <span className="text-secondary ms-1">
        <FormattedMessage id="common.unit.margin" values={{ unit }} />
      </span>
    </>
  );

  return (
    <div className={`form-group ${className ?? ""}`} data-e2e={name.replace('.', '-')}>
      <label className="text-primary fw-medium mb-2" htmlFor={name}>
        {getLabel(labelComponent)(labelLocaleKey)}
      </label>

      <input
        {...field}
        data-e2e={`${name.replace('.', '-')}-input`}
        type="text"
        autoComplete="off"
        className={`form-control ${error ? 'is-invalid' : ''}`}
        id={name}
        placeholder={placeholder}
        disabled={disabled}
      />

      {
        hideError ?? (
          <FormMessageStatus
            name={ name }
            errorType={ error?.type }
            status="danger"
            icon="error"
            show={ Boolean(error) }
          />
        )
      }
    </div>
  );
};
