import type { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, FormGroup, Label } from 'reactstrap';
import { type ChangeReasonsTypeKey, isChangeReasonsTypeKey } from '../../../services/fx/model/models';
import { useGetFxProfileChangeReasonsQuery } from '../../../services/fx/getFxProfileChangeReasons';
import type { FxCashGridDataType } from '@services/fx/model/cash';

interface ReasonTypesFormGroupProps {
  value?: ChangeReasonsTypeKey;
  onChange: (changeReasonsTypeKey: ChangeReasonsTypeKey) => void;
  gridDataType?: FxCashGridDataType;
}

export const ReasonTypesFormGroup = ({ onChange, value, gridDataType }: ReasonTypesFormGroupProps): JSX.Element => {
  const { changeReasonTypes } = useGetFxProfileChangeReasonsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      changeReasonTypes: (data?.ids as ChangeReasonsTypeKey[]) || [],
    }),
  });

  const onSelectReasonType: MouseEventHandler<HTMLButtonElement> = (event) => {
    const changeReasonsTypeKey = event.currentTarget.name;

    if (!isChangeReasonsTypeKey(changeReasonsTypeKey)) {
      return;
    }

    onChange(changeReasonsTypeKey);
  };

  return (
    <FormGroup className="form-group">
      <Label for="reasonTypes" className="mb-2">
        <FormattedMessage id={`profileChangeReasonModal.audit.purpose.${gridDataType ?? 'margin-grid-data-type'}`} />
      </Label>

      <ButtonGroup id="reasonTypes" className="btn-group btn-group-toggle">
        {changeReasonTypes.map((changeReasonType) => (
          <Button
            key={changeReasonType}
            className="btn-toggle-primary"
            outline
            name={changeReasonType}
            onClick={onSelectReasonType}
            active={value === changeReasonType}
            data-e2e={`${changeReasonType}-btn`}
          >
            <FormattedMessage id={`common.${changeReasonType}`} />
          </Button>
        ))}
      </ButtonGroup>
    </FormGroup>
  );
};
