import { FormattedMessage } from 'react-intl';

export const SearchMessage = (): JSX.Element => {
  return (
    <>
      <i className="d-flex justify-content-center icon icon-xl text-secondary mt-5 mb-4">search</i>
      <span className="d-flex justify-content-center text-secondary fs-16 fw-semibold">
        <FormattedMessage id="clientsMargins.searchMessage" />
      </span>
    </>
  );
};
