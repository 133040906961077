import { FormattedMessage } from 'react-intl';
import type { FxCashGridDataType, FxCashProductKey } from '@services/fx/model/cash';
import { createRow } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';

export type FxCashTieringEditorProps = {
  addRow: () => void;
  gridDataType: FxCashGridDataType;
  productKey: FxCashProductKey;
};

export const FxCashEditorEmpty = ({ addRow, gridDataType, productKey }: FxCashTieringEditorProps) => {
  const dispatch = useAppDispatch();

  const addRejectionRow = () => {
    dispatch(createRow({ type: 'cash-rejection', productKey }));
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="text-secondary my-4">
          <FormattedMessage id="fx.margin.edit.panel.description" />
        </div>

        <button
          data-e2e={'add-rejection'}
          className="btn btn-md btn-icon-start btn-outline-primary align-self-center mb-8px"
          onClick={addRejectionRow}
        >
          <em className="icon icon-md">add</em>
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.addRejectionRowButton`} />
        </button>

        <button
          data-e2e={'add-row'}
          className="btn btn-md btn-icon-start btn-outline-primary align-self-center"
          onClick={addRow}
        >
          <em className="icon icon-md">add</em>
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.${gridDataType}.addRowButton`} />
        </button>
      </div>
    </>
  );
};
