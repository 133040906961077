import { FormattedMessage } from 'react-intl';

export const HomeHeader = (): JSX.Element => {
  return (
    <div className="flex-column">
      <h1 className="display-3 text-primary mb-2">
        <FormattedMessage id="common.title" />
      </h1>

      <p className="text-secondary mb-4 fw-medium">
        <FormattedMessage id="common.description" />
      </p>
    </div>
  );
};
