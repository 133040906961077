import Tooltip from '@components/Tooltip';
import { FxClientBadge } from '@components/fx/badges/FxClientBadge';
import { FxFirstClientCard } from '@pages/fx/FxClientsPage/FxClients/FxClientCard/FxFirstClientCard';
import { useLazyGetFXClientQuery } from '@services/fx/getFXClient';
import type { FxClientBase, FxClientModel } from '@services/fx/model/client';
import type { FxInheritancePerimeter } from '@services/fx/model/inheritance';
import type { FxPerimeterKey } from '@services/fx/model/perimiters';
import { isDefined, isNotDefined } from '@sgme/fp';
import { FallbackMessage } from 'components/FallbackMessage';
import { LoadingMessage } from 'components/LoadingMessage';
import { type MouseEvent, type PropsWithChildren, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import { FX_PRODUCTS_ROUTE } from '../../constants';
import { Ascendant } from './Ascendant';
import { UserAccessIcon } from './UserAccessIcon';

export const FxFirstClientWithperimeterKey = withPerimeterKey(FxFirstClient);
export const FxClientWithperimeterKey = withPerimeterKey(FxClient);

interface FxSubClientsListProps {
  perimeters: FxInheritancePerimeter[];
  childrenList: FxClientBase[];
}
export function FxSubClientsList({ perimeters, childrenList }: FxSubClientsListProps) {
  return (
    <div className="mt-4" data-e2e="subclients">
      <h4 data-e2e="client-children-header">
        <FormattedMessage id="clients.children.tile" />

        <Tooltip labelId="clients.tooltip">
          <span className="icon icon-sm text-secondary ms-1 mb-3">info_outline</span>
        </Tooltip>
      </h4>

      {childrenList.map((child) => {
        return (
          <FxClientWithperimeterKey
            key={`${child.clientBdrId}/${child.clientBdrLevel}`}
            client={child}
            perimeters={perimeters}
          />
        );
      })}
    </div>
  );
}

interface FxFirstClientProps {
  client: FxClientModel;
  perimeterKey: FxPerimeterKey;
}
function FxFirstClient({ client, perimeterKey }: FxFirstClientProps) {
  const isAuthorized = client.userAccess !== 'unauthorized';

  return (
    <>
      <FxFirstClientCard client={client} isAuthorized={isAuthorized} perimeterKey={perimeterKey} />
      <Ascendant client={client} isAuthorized={isAuthorized} perimeterKey={perimeterKey} />
    </>
  );
}

interface FxClientProps {
  client: FxClientBase;
  perimeterKey: FxPerimeterKey;
  uiLevel?: number;
}
function FxClient({ client, perimeterKey, uiLevel = 0 }: FxClientProps) {
  const navigate = useNavigate();

  const { clientLongName, clientCountryCode, userAccess, clientBdrId: bdrId, clientBdrLevel: bdrLevel } = client;

  const [isChildrenVisible, setIsChildrenVisible] = useState(false);
  const [trigger, response] = useLazyGetFXClientQuery();

  const toggleOpen = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    trigger({ clientBdrId: bdrId, clientBdrLevel: bdrLevel });

    setIsChildrenVisible((value) => !value);
  };

  const goToPerimetersPage = useCallback(() => {
    if (
      isDefined(client) &&
      isDefined(perimeterKey) &&
      isDefined(bdrId) &&
      isDefined(bdrLevel)
    ) {
      navigate(
        generatePath(FX_PRODUCTS_ROUTE, {
          clientBdrId: String(bdrId),
          clientBdrLevel: bdrLevel?.toUpperCase(),
          perimeterKey,
        }),
      );
    }
  }, [bdrId, bdrLevel, client, navigate, perimeterKey]);

  const isAuthorized = userAccess !== 'unauthorized';
  const leftPadding = uiLevel * 24 + (!client.hasChildWithExplicitProfile ? 24 : 0);

  return (
    <div data-e2e="fx-client-item">
      <ul className={`${isAuthorized ? 'card-raising' : 'card'} list-group`}>
        <li
          className={`list-group-item d-flex justify-content-between align-items-center border-bottom py-3 ${isAuthorized ? 'btn btn-flat-primary' : ''
            }`}
          onClick={goToPerimetersPage}
        >
          <div className="d-flex align-items-center fs-16" style={{ paddingLeft: leftPadding }}>
            {client.hasChildWithExplicitProfile && (
              <StyledIcon
                className={`icon me-2 ${isChildrenVisible ? 'opened' : ''}`}
                onClick={toggleOpen}
                data-e2e="toggle"
              >
                chevron_right
              </StyledIcon>
            )}
            <span className="fw-semi-bold">{clientLongName}</span>

            <span className="ms-1 text-secondary">
              <FormattedMessage id="country.level" values={{ accountLevel: bdrLevel, country: clientCountryCode }} />
            </span>

            <span className="ms-1 text-secondary">
              <FormattedMessage id="client.bdr.id" values={{ clientBdrId: bdrId }} />
            </span>
          </div>

          <span className="d-flex align-items-center">
            {client.profileSummaryFlag !== '' && (
              <FxClientBadge dataE2e="profile-flag-badge" profileSummaryFlag={client.profileSummaryFlag} />
            )}

            <UserAccessIcon userAccess={userAccess} />

            {isAuthorized && <i className="icon icon-md text-secondary ms-3">arrow_forward</i>}
          </span>
        </li>
      </ul>
      {isChildrenVisible && (
        <Collapse isOpen={isChildrenVisible} data-e2e="children">
          <LoadingFxClient isLoading={response.isLoading} isError={response.isError} client={client}>
            {response.data?.[0].children.map((child) => (
              <FxClientWithperimeterKey
                key={`${child.clientBdrId}/${child.clientBdrLevel}`}
                client={child}
                perimeters={response.data?.[0].perimeters ?? []}
                uiLevel={uiLevel + 1}
              />
            ))}
          </LoadingFxClient>
        </Collapse>
      )}
    </div>
  );
}

interface LoadingFxClientProps {
  isLoading: boolean;
  isError: boolean;
  client: FxClientBase;
}
function LoadingFxClient({ isLoading, isError, client, children }: PropsWithChildren<LoadingFxClientProps>) {
  if (isNotDefined(client)) {
    return null;
  }

  return isLoading || isError ? (
    isLoading ? (
      <LoadingMessage />
    ) : (
      <FallbackMessage
        icon="domain_disabled"
        headerLocaleKey="no.data.for.client"
        descriptionElement={<DescriptionElement client={client} />}
      />
    )
  ) : (
    <>{children}</>
  );
}

interface WithLoadingClientDataProps<T extends FxClientBase> {
  client: T;
  perimeters: FxInheritancePerimeter[];
  uiLevel?: number;
}

function withPerimeterKey<T extends FxClientBase>(
  Component: (props: { client: T; perimeterKey: FxPerimeterKey }) => JSX.Element,
) {
  return ({ client, perimeters, ...props }: WithLoadingClientDataProps<T>) => {
    const perimeterKey: FxPerimeterKey = perimeters.find(
      (perimeter) => perimeter.perimeterKey === 'fx-cash-rfq-perimeter',
    )
      ? 'fx-cash-rfq-perimeter'
      : 'fx-order-perimeter';

    return <Component client={client} perimeterKey={perimeterKey} {...props} />;
  };
}

const StyledIcon = styled.i`
  cursor: pointer;

  &.opened {
    transform: rotate(90deg);
    transition: transform 300ms ease-in-out;
  }
`;

interface ClientAccountProps {
  client: FxClientBase;
}

function DescriptionElement({ client }: ClientAccountProps) {
  return (
    <div>
      {client?.clientLongName}

      <FormattedMessage
        id="country.level"
        values={{ accountLevel: client?.clientBdrLevel, country: client?.clientCountryCode }}
      />

      <FormattedMessage id="client.bdr.id" values={{ clientBdrId: client?.clientBdrId }} />
    </div>
  );
}
