import type { HTMLAttributes } from 'react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { isDefined } from '@sgme/fp';
import type { ComponentSize } from '../../../utils/size';
import type { ProfileTypeKey } from '../../../services/fx/model/models';

interface FxProfileBadgeProps extends HTMLAttributes<HTMLSpanElement> {
  profileName?: string;
  profileTypeKey?: ProfileTypeKey;
  isLoading?: boolean;
  isError?: boolean;
  size?: ComponentSize;
}

export const FxProfileBadge = (props: FxProfileBadgeProps): JSX.Element => {
  const { profileName, profileTypeKey, size = 'md', className } = props;

  const { color, icon, prefixLocaleKey } = useMemo(() => {
    if (!(isDefined(profileTypeKey) && profileTypeKey in DESIGN)) {
      return ERROR_PROFILE_DESIGN;
    }

    return DESIGN[profileTypeKey];
  }, [profileTypeKey]);

  const fontSize = size === 'sm' ? 'fs-14' : '';
  return (
    <span
      data-e2e="badge-preset"
      className={`badge badge-${size}  rounded-pill badge-discreet-${color} d-flex align-items-center ${
        isDefined(className) ? className : ''
      }`}
    >
      {isDefined(icon) && <em className={`icon ${fontSize} me-1`}>{icon}</em>}

      {isDefined(prefixLocaleKey) ? <FormattedMessage id={prefixLocaleKey} values={{ profileName }} /> : profileName}
    </span>
  );
};

interface DesignOfProfile {
  color: string;
  icon?: string;
  prefixLocaleKey?: string;
}

const ERROR_PROFILE_DESIGN: DesignOfProfile = {
  color: 'dark',
  icon: 'gpp_bad',
  prefixLocaleKey: 'label.no.profile',
} as const;

const DESIGN: Record<ProfileTypeKey, DesignOfProfile> = {
  'default-profile-type': {
    color: 'info',
    icon: 'outlined_flag',
  },
  'client-pool-profile-type': {
    color: 'royal-blue',
    icon: 'group_work',
    prefixLocaleKey: 'label.pool',
  },
  'standard-profile-type': {
    color: 'dark',
    icon: 'gpp_good',
    prefixLocaleKey: 'label.standard',
  },
  'custom-profile-type': {
    color: 'apricot',
    icon: 'account_circle',
    prefixLocaleKey: 'label.custom',
  },
} as const;
