import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';
import { fxApi } from './fxApi';
import type { FxCashGridDataType } from './model/cash';
import type { ClientBdrId, ClientBdrLevel } from './model/client';
import type { FxProductProfiles } from './model/inheritance';
import type { FxPayload } from './model/models';
import { PROFILE_TYPE_KEYS } from './model/models';

const productProfilesAdapter = createEntityAdapter<FxProductProfiles>({
  selectId: ({ profileTypeKey }) => profileTypeKey,
  sortComparer: (a, b) => PROFILE_TYPE_KEYS.indexOf(a.profileTypeKey) - PROFILE_TYPE_KEYS.indexOf(b.profileTypeKey),
});

export const availableProfileApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFXAvailableProfiles: builder.query<
      EntityState<FxProductProfiles>,
      {
        clientBdrId: ClientBdrId;
        clientBdrLevel: ClientBdrLevel;
        perimeterKey: FxPerimeterKey;
        productKey: FxProductKey;
        gridDataType?: FxCashGridDataType;
      }
    >({
      query: ({ clientBdrId, clientBdrLevel, perimeterKey, productKey, gridDataType }) => ({
        url: `perimeters/${perimeterKey}/products/${productKey}/profiles`,
        params: {
          clientBdrId,
          clientBdrLevel,
          gridDataType,
        },
      }),

      transformResponse: (response: FxPayload<FxProductProfiles[]>) =>
        productProfilesAdapter.addMany(productProfilesAdapter.getInitialState(), response.data),

      providesTags: (result, error, { perimeterKey, productKey }) => [
        { type: 'Profiles', id: `${perimeterKey}-${productKey}` },
      ],
    }),
  }),
});

export const { useGetFXAvailableProfilesQuery } = availableProfileApi;
