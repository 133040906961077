export const RESOURCE = 'SGM_MARGIN_N_TIERING' as const;
export type Resource = typeof RESOURCE;

// the order here is important as we use it to display the correct edit permission choice
// in the EditMarginOrTieringModal
export const editCashProductPermissions = [
  'FX_WRITE_CASHRFQ_SPOT_MARGIN',
  'FX_WRITE_CASHRFQ_SPOT_TIERING',
  'FX_WRITE_CASHRFQ_SWAP_MARGIN',
  'FX_WRITE_CASHRFQ_SWAP_TIERING',
  'FX_WRITE_CASHRFQ_NDF_MARGIN',
  'FX_WRITE_CASHRFQ_NDF_TIERING',
] as const;

export const editOrderProductPermissions = [
  'FX_WRITE_ORDER_ALGO_MARGIN',
  'FX_WRITE_ORDER_LIMIT_MARGIN',
  'FX_WRITE_ORDER_FIXING_MARGIN',
] as const;

export type EditPermission = EditCashPermission | EditOrderPermission;

export type EditCashPermission = (typeof editCashProductPermissions)[number];
export type EditOrderPermission = (typeof editOrderProductPermissions)[number];

export const PERMISSIONS = [
  'NAVIGATE_AS',
  'SGM_DEV',
  'LAG_READ',
  'LAG_WRITE',
  'LAG_MARGINS_WRITEPRESETS',
  'LAG_MARGINS_READPRESETS',
  'FX_READALLDESK',
  'FX_READMYDESK',
  'FX_WRITEALLDESK',
  'FX_WRITEMYDESK',
  'FX_STANDARDS_WRITEPRESETS',
  'FX_WRITE_ORDER_FIXING_MARGIN',
  'FX_WRITE_ORDER_LIMIT_MARGIN',
  'FX_AUDIT_ONBEHALF_REQUIRED',
  ...editCashProductPermissions,
  ...editOrderProductPermissions
] as const;

export type Permission = (typeof PERMISSIONS)[number];

export const NOT_AUTHORIZED = 'notAuthorized';
