import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import type { PostClientPayload } from 'services/clients';
import type { MarginProfileType } from 'services/marginProfiles';
import { isCustomProfile, selectMarginsPresets } from 'services/marginProfiles';

import { Select } from 'components/Form/Select';
import {useAppSelector} from "../../../hooks/useAppSelector";

const marginProfileTypeToField: Record<
  Exclude<MarginProfileType, 'NA'>,
  keyof Pick<PostClientPayload, 'salesMarginProfileId' | 'devLMarginProfileId'>
> = {
  Sales: 'salesMarginProfileId',
  DevL: 'devLMarginProfileId',
};

interface SelectMarginProfileProps {
  type: Exclude<MarginProfileType, 'NA'>;
}

export const SelectMarginProfile = ({ type }: SelectMarginProfileProps) => {
  const filedName = marginProfileTypeToField[type];
  const {
    field,
    fieldState: { error },
  } = useController({
    name: filedName,
    rules: {
      required: true,
    },
  });

  const isAlreadyCustomized = useAppSelector(isCustomProfile(field.value));
  const marginsPresets = useAppSelector(selectMarginsPresets);

  const options = useMemo(() => [
      ...marginsPresets
        .filter((marginsPreset) => marginsPreset.type === type)
        .map(({ id, name }) => [id, name] as [number, string]),
      ...(type === 'Sales'
        ? [[isAlreadyCustomized ? field.value : -1, 'Custom'] as [number, string]]
        : []),
    ], [isAlreadyCustomized, field.value, marginsPresets, type]);

  return (
    <div className="d-flex align-items-center gap-2 my-3">
      <div className="form-group col-6" data-e2e={`select-${type.toLowerCase()}-margin-profile`}>
        <label className="fw-medium mb-2">
          <FormattedMessage id={`clientForm.${filedName}`} />
        </label>

        <Select
          {...field}
          className={`form-select ${error ? 'is-warning' : ''}`}
          options={options}
        />
      </div>
    </div>
  );
};
