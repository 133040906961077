import { FormattedMessage } from 'react-intl';
import { isNotDefined } from '@sgme/fp';
import { useFirstFxClientFor } from '@services/fx/getFXClient';
import {
  type FxCashGridDataType,
  type FxCashPerimeterKey,
  type FxCashProductKey,
  TIERING_GRID_DATA_TYPE,
} from '@services/fx/model/cash';
import { ProductProperty, StyledPropertyContainer } from '@pages/layout/Header/FXHeader/ProductHeaderProperty';
import { CancelValidationHandler } from '@components/CancelValidationHandler';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { FX_PRODUCTS_ROUTE } from '@pages/fx/constants';
import SaveEditedProfileButton from '@pages/fx/FxEditProfilePage/FxEditProfileHeader/SaveEditedProfileButton';
import { useGetFXProfileMarginGridQuery } from '@services/fx/getFXProfileMarginGrid';
import { useGetFXCashProfileOfProductQuery } from '@services/fx/getFXCashProfileOfProduct';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { FxProductsCrumb } from '@pages/layout/Breadcrumb/FxProductsCrumb';
import { FxEditProductCrumb } from '@pages/layout/Breadcrumb/FxEditProductCrumb';
import { FxClientsCrumb } from '@pages/layout/Breadcrumb/FxClientsCrumb';
import { useGetFxProductChanges } from '@hooks/useGetFxProductChange';

export const FxEditProfileHeader = (): JSX.Element => {
  const { clientBdrLevel, clientBdrId, perimeterKey, productKey, gridDataType, profileId } =
    useFxSelectAndEditProfileUrlParams();
  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);
  const navigate = useNavigate();
  const { haveRowsChanged, hasRowInEdition } = useGetFxProductChanges();

  const goToProductPage = useCallback(() => {
    if (isNotDefined(clientBdrId) || isNotDefined(clientBdrLevel) || isNotDefined(perimeterKey)) {
      return;
    }

    navigate(generatePath(FX_PRODUCTS_ROUTE, { clientBdrId: String(clientBdrId), clientBdrLevel, perimeterKey }));
  }, [clientBdrId, clientBdrLevel, navigate, perimeterKey]);

  // this call is needed as this endpoint sends back profile information with metadata linked to the client
  // such as clientProfileAssignmentVersion which is further required to save the profile in Cash profile
  // it is not needed in order profile.
  // todo-5231 this should be merged with useGetFxOrderProfileOfProductQuery
  const { data: originalSelectedProfileWithClientInfo } = useGetFXCashProfileOfProductQuery({
    perimeterKey: perimeterKey as FxCashPerimeterKey,
    productKey: productKey as FxCashProductKey,
    clientBdrId,
    clientBdrLevel,
    gridDataType: gridDataType as FxCashGridDataType,
  });

  const { data: selectedProfile } = useGetFXProfileMarginGridQuery({
    perimeterKey,
    productKey,
    profileId,
    gridDataType,
  });

  if (isNotDefined(client)) {
    return <></>;
  }
  const { clientLongName, clientCountryCode } = client;

  return (
    <>
      <nav className="mb-1" data-e2e="breadcrumb">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <FxClientsCrumb />
          </li>
          <li className="breadcrumb-item">
            <FxProductsCrumb />
          </li>
          <li className="breadcrumb-item">
            <FxEditProductCrumb />
          </li>
        </ol>
      </nav>
      <div className="flex-between flex-wrap align-items-start mb-4">
        <div>
          <h1 className="text-primary display-3 mb-3">
            <FormattedMessage id={gridDataType === TIERING_GRID_DATA_TYPE ? 'edit.tierings' : 'edit.margins'} />
          </h1>

          <StyledPropertyContainer className="d-flex align-items-baseline gap-2 fs-18 text-secondary fw-medium">

            <div>
              <ProductProperty
                dataE2e="commercial-level"
                labelId="fx.header.property.commercialLevel"
                value={clientBdrLevel}
              />
              <ProductProperty dataE2e="region" labelId="fx.header.property.region" value={clientCountryCode} />
              <ProductProperty dataE2e="legal-bdr-id" labelId="fx.header.property.legalBdrId" value={clientBdrId} />
              <ProductProperty dataE2e="client" labelId="fx.header.property.client" value={clientLongName} />
              {client.clientMnemonic !== '' && <ProductProperty dataE2e="mnemonic" labelId="fx.header.property.mnemonic" value={client.clientMnemonic} />}

              <ProductProperty dataE2e="product" labelId="fx.header.property.product" formattedValueId={productKey} />
            </div>
          </StyledPropertyContainer>
        </div>

        <span className="d-flex">
          <CancelValidationHandler hasUserChanges={haveRowsChanged || hasRowInEdition}>
            <button
              data-e2e="fx-cancel-product-profile"
              className="btn btn-lg btn-flat-primary me-2"
              onClick={goToProductPage}
            >
              <FormattedMessage id="common.cancel" />
            </button>
          </CancelValidationHandler>
          <SaveEditedProfileButton
            profileVersion={selectedProfile?.profileVersion}
            clientProfileAssignmentVersion={originalSelectedProfileWithClientInfo?.data.clientProfileAssignmentVersion}
          />
        </span>
      </div>
    </>
  );
};
