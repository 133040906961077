import type { HTMLAttributes } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {useFullTextSearch} from "../hooks/useFullTextSearch";

export type Item = {
  id: string | number;
  level: string;
  value: string;
};

interface SearchSelectProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  items?: Array<Item>;
  noItemLocaleKey?: string;
}

export const SearchSelect = (props: SearchSelectProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { className, items = [], noItemLocaleKey = 'no.item.found' } = props;

  const [itemsSearched, onSearch] = useFullTextSearch(items, ({ id, level, value }) => `${id} ${level} ${value}`);

  return (
    <div className={className} style={{ minHeight: '30em' }}>
      <div className="input-group mb-1">
        <div className="input-icon-start">
          <em className="icon">search</em>
        </div>
        <input
          type="search"
          className="form-control"
          placeholder={formatMessage({ id: 'common.search' })}
          onChange={onSearch}
        />
      </div>
      <ul
        className="list-group list-group-flush overflow-auto border"
        style={{ maxHeight: '27em' }}
      >
        {itemsSearched.map(({ id, level, value }) => (
          <li key={id} className="list-group-item border-light p-2" title={`${id} (${level}) - ${value}`}>
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {`${id} (${level}) - ${value}`}
            </div>
          </li>
        ))}

        {itemsSearched.length === 0 && (
          <li className="list-group-item border-light p-2 border-start-0 text-light">
            <FormattedMessage id={noItemLocaleKey} />
          </li>
        )}
      </ul>
    </div>
  );
};
