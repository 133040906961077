import { makeValuesPredicate } from "@sgme/fp";

export interface FxPayload<T> {
  data: T;
}

export interface FxPayloadError<T> {
  data: {
    errorCode: T;
    message: string;
  };
}
export type InternalRowId = Array<string>;

export const USER_ACCESSES = [
  "authorized_read_only",
  "authorized_on_my_desk_clients",
  "authorized_on_my_clients",
  "authorized_on_all_clients",
  "authorized_on_my_desk_clients_read_only",
  "unauthorized",
  "default",
] as const;
export type UserAccess = (typeof USER_ACCESSES)[number];

/**
 * PROFILE
 */
export type ProfileId = number;

export const PROFILE_TYPE_KEYS = [
  "default-profile-type",
  "standard-profile-type",
  "client-pool-profile-type",
  "custom-profile-type",
] as const;

export type ProfileTypeKey = (typeof PROFILE_TYPE_KEYS)[number];

export type InternalProfileTypeKey = Exclude<
  ProfileTypeKey,
  "default-profile-type"
>;

export type FxMarginProfileTypeKey = Exclude<
  ProfileTypeKey,
  "default-profile-type"
>;

export type Profiles = {
  profileId: ProfileId;
  profileName: string;
  defaultProfile: boolean;
};

/**
 * CHANGE REASONS
 */

export interface FxProfileChangeReasonType {
  reasonTypeKey: ChangeReasonsTypeKey;
  reasons: Array<FxReason>;
}

export const CHANGE_REASONS_TYPE_KEYS = [
  "upgrade-reason-type",
  "downgrade-reason-type",
  "both-reason-type",
] as const;
export type ChangeReasonsTypeKey = (typeof CHANGE_REASONS_TYPE_KEYS)[number];
export const isChangeReasonsTypeKey = makeValuesPredicate(
  CHANGE_REASONS_TYPE_KEYS
);

export interface FxReason {
  reasonId: number;
  reasonText: string;
}

/*
 * ERRORS
 */

export type FxCashValidationErrorCode =
  | "InvalidTenorValidationError"
  | "InvalidTenorRangeValidationError"
  | "InvalidAmountValidationError"
  | "InvalidAmountRangeValidationError"
  | null;

export type UpdateProfileErrorCode =
  | "TransactionError"
  | "TechnicalError"
  | "ProfileNameExistError"
  | "ClientProfileAssignmentAlreadyModifiedError"
  | "ProfileAlreadyModifiedError";

export type UpdateProfileErrorPayload =
  FxPayloadError<UpdateProfileErrorCode | null>;

export interface FxCurrencyPairDimension {
  currencies: string[];
  groups: string[];
  isPairEnabled: boolean;
  isSingleEnabled: boolean;
  isGroupEnabled: boolean;
  isAllEnabled: boolean;
  isInvertedPairSame: boolean; //seems not present for spot margin
}
