import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PageTracker } from '@features/analytics/PageTracker';
import { Authorize, type AuthorizeProps } from '@features/authorization/Authorize';
import { NOT_AUTHORIZED } from '@features/authorization/authorization.model';
import { FX_ROUTE } from './fx/constants';
import { LAG_ROUTE } from './lag/contants';

interface NavigableCardProps {
  titleId: string;
  descriptionId: string;
  href: string;
}

function NavigableCard({ titleId, descriptionId, href }: NavigableCardProps): JSX.Element {
  return (
    <CardLink to={href}>
      <div className="card-body" data-e2e={`${titleId.replaceAll(/\./g, '-')}-card`}>
        <h3 className="card-title line-height-1">
          <FormattedMessage id={titleId} />
        </h3>

        <div className="card-text text-secondary">
          <span>
            <FormattedMessage id={descriptionId} />
          </span>

          <div className="d-flex justify-content-end mt-2">
            <i className="icon">arrow_forward</i>
          </div>
        </div>
      </div>
    </CardLink>
  );
}

const cards: (NavigableCardProps & { authorizeFor: AuthorizeProps['authorizedFor'] })[] = [
  {
    titleId: 'home.fx.title',
    descriptionId: 'home.fx.description',
    href: FX_ROUTE,
    authorizeFor: ['FX_READALLDESK', 'FX_READMYDESK'],
  },
  {
    titleId: 'home.lag.title',
    descriptionId: 'home.lag.description',
    href: LAG_ROUTE,
    authorizeFor: ['LAG_READ'],
  },
];

const redirectTo: AuthorizeProps['redirectTo'] = (permissions) => {
  if (permissions.includes('LAG_READ')) {
    return LAG_ROUTE;
  }

  if (permissions.includes('FX_READALLDESK') || permissions.includes('FX_READMYDESK')) {
    return FX_ROUTE;
  }

  return NOT_AUTHORIZED;
};

const authorizedFor: AuthorizeProps['authorizedFor'] = (permissions) =>
  permissions.includes('LAG_READ') && (permissions.includes('FX_READALLDESK') || permissions.includes('FX_READMYDESK'));

function HomePage(): JSX.Element {
  return (
    <Authorize authorizedFor={authorizedFor} redirectTo={redirectTo}>
      <PageTracker>
        <HomeSection>
          <div className="d-flex flex-row">
            <div className="col">
              <div className="d-flex flex-row flex-wrap my-4" data-e2e="cards-wrapper">
                {cards.map(({ authorizeFor, titleId, ...props }) => {
                  const id = titleId.split('.')[1];

                  return (
                    <Authorize key={`home-card-${id}`} authorizedFor={authorizeFor}>
                      <NavigableCard titleId={titleId} {...props} />
                    </Authorize>
                  );
                })}
              </div>
            </div>
          </div>
        </HomeSection>
      </PageTracker>
    </Authorize>
  );
}

const HomeSection = styled.section.attrs(() => ({
  className: 'container-fixed px-3 px-md-4',
}))`
  height: -webkit-calc(100% - 168px);
`;

const CardLink = styled(Link).attrs(() => ({
  className: 'card bg-lvl3 shadow-sm card-raising me-4',
}))`
  width: 18rem;
`;

export default HomePage;
