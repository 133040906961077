import { isDefined } from '@sgme/fp';
import { getAuthorizationHeader, getUserConnection } from '@typings/sgwt-widgets';
import { v4 } from 'uuid';

export function prepareHeaders(headers: Headers): Headers {
  const authorizationHeader = getAuthorizationHeader();

  if (isDefined(authorizationHeader)) {
    headers.set('Authorization', authorizationHeader);
  }

  const fakeUser = getFakeUserAuthentication();

  if (isDefined(fakeUser)) {
    headers.set('X-Fake-Authentication', fakeUser);
  }

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  headers.set('X-Correlation-ID', v4());

  return headers;
}

export function getFakeUserAuthentication(): string | undefined {
  const userConnection = getUserConnection();

  return (isDefined(userConnection) && isDefined(userConnection.mail) && userConnection.mail) || undefined;
}
