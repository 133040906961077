import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isDefined } from '@sgme/fp';

import { LoadingButton } from 'components/LoadingButton';
import { FormInput } from '../Form/FormInput';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import type { ProfileId, UpdateProfileErrorPayload } from '@services/fx/model/models';
import { useRenameFxProfileMutation } from '@services/fx/renameFxProfile';
import { useState } from 'react';
import ProfileAlreadyModifiedError from '@components/modals/FxProfileChangeReasonsModal/ProfileAlreadyModifiedError';
import TechnicalError from '@components/modals/TechnicalError';

interface FormValues {
  profileName: string;
  profileVersion: number;
}

export interface FxRenameProfileModalProps extends ModalProps {
  profileId?: ProfileId;
  profileName?: string;
  profileVersion: number;
}

export const FxRenameProfileModal = ({
  profileId,
  profileName,
  profileVersion,
  ...props
}: FxRenameProfileModalProps): JSX.Element => {
  const [renameFxProfile, { isLoading }] = useRenameFxProfileMutation();
  const [otherError, setOtherError] = useState<UpdateProfileErrorPayload['data']>({ errorCode: null, message: '' });

  const { perimeterKey, productKey, gridDataType } = useFxUrlParams();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      profileName,
      profileVersion,
    },
  });

  const {
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<FormValues> = async ({ profileName, profileVersion }) => {
    if (isDefined(perimeterKey) && isDefined(productKey) && isDefined(profileId)) {
      const renameResponse = await renameFxProfile({
        perimeterKey,
        productKey,
        profileId,
        profileName,
        profileVersion,
        gridDataType,
      });

      const { error } = renameResponse as { error: UpdateProfileErrorPayload }; // bad typing of rtk query
      if (isDefined(error) && isDefined(error.data)) {
        //errorCode is always defined when having error
        // Edit: spoiler non !
        const errorCode = error.data.errorCode;
        if (errorCode === 'ProfileNameExistError') {
          setError('profileName', { type: errorCode }, { shouldFocus: true });
        } else {
          setOtherError(error.data);
        }
      } else {
        if (props.onClosed) {
          props.onClosed();
        }
      }
    }
  };

  // Focus the field only when the modal is open, before input is not rendered.
  const onOpened = () => setFocus('profileName', { shouldSelect: true });

  const reload = () => {
    window.location.reload();
  };

  const { errorCode, message } = otherError;

  return (
    <Modal data-e2e="rename-profile-modal" style={{ minWidth: '600px' }} onOpened={onOpened} {...props}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader tag="h3" close={props.modalHeaderClose}>
            <FormattedMessage id="renameModal.title" />
          </ModalHeader>

          <ModalBody className="pb-0">
            <FormInput name="profileName" labelLocaleKey="renameModal.text" rules={{ required: true }} />

            {errorCode === 'ProfileAlreadyModifiedError' && (
              <ProfileAlreadyModifiedError reload={reload} className={'mt-4px'} />
            )}
            {errorCode === 'TechnicalError' && (
              <TechnicalError className={'mt-4px'} reload={reload} message={message} />
            )}
          </ModalBody>

          <ModalFooter>
            <button type="reset" className="btn btn-lg btn-flat-primary" onClick={props.onClosed}>
              <FormattedMessage id="common.cancel" />
            </button>

            <LoadingButton
              type="submit"
              icon="save"
              className="btn-lg btn-primary"
              loading={isLoading}
              dataE2e={`rename-profile-button-save`}
              disabled={Object.keys(errors).length > 0 || errorCode !== null}
            >
              <FormattedMessage id="common.save" />
            </LoadingButton>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};
