import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FilterGroup } from './FilterGroup';
import {useAppDispatch} from "../../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../../hooks/useAppSelector";
import {selectFilter} from "../../../../../store/filters/filtersSelectors";
import {actionCreators} from "../../../../../store/actions";

export const CustomOnlyFilter = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const value = useAppSelector(selectFilter('customizedMarginsOnly'));

  const onToggle = useCallback(() => {
    dispatch(actionCreators.filters.customizedMarginsOnlyFilterToggled());
  }, [dispatch]);

  return (
    <FilterGroup label={formatMessage({ id: 'filters.standardOrCustomizedMargins' })}>
      <div className="form-check">
        <input
          type="checkbox"
          id="customizedMarginsOnly"
          className="form-check-input"
          checked={Boolean(value)}
          onChange={onToggle}
          data-e2e="lag-checkbox-customized-margins-only"
        />

        <label htmlFor="customizedMarginsOnly" className="form-check-label">
          <FormattedMessage id="filters.customizedMarginsOnly" />
        </label>
      </div>
    </FilterGroup>
  );
};
