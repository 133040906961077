import { FormattedMessage } from 'react-intl';
import { isDefined, isNotDefined } from '@sgme/fp';
import { HelpCenter } from '@components/HelpCenter';
import { TIERING_GRID_DATA_TYPE } from '@services/fx/model/cash';
import { LoadingMessage } from '@components/LoadingMessage';
import { useSearchParams } from 'react-router-dom';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { useGetFxInheritableProfileReference } from '@services/fx/getFXClientsInheritance';
import { useGetFXProfileMarginGridQuery } from '@services/fx/getFXProfileMarginGrid';
import { skipToken } from '@reduxjs/toolkit/query';

const UpperCommercialLevelProfileType = () => {
  const [_searchParams, setSearchParams] = useSearchParams();

  const {
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
    profileId: selectedProfileId,
    isInherited,
  } = useFxSelectAndEditProfileUrlParams();

  const profileReference = useGetFxInheritableProfileReference({
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
  });

  const inheritableProfileId = profileReference?.inheritableProfile?.profileId;
  const inheritableProfileName = profileReference?.inheritableProfile?.profileName;
  const hasParentClient = isDefined(profileReference?.inheritableProfile.client);

  const { data: inheritedProfileMarginGrid } = useGetFXProfileMarginGridQuery(
    isDefined(inheritableProfileId)
      ? {
          perimeterKey,
          productKey,
          profileId: inheritableProfileId,
          gridDataType,
        }
      : skipToken,
  );

  const isInHeritableProfileDefault = inheritedProfileMarginGrid?.profileTypeKey === 'default-profile-type';

  const onChange = () => {
    if (isNotDefined(inheritableProfileId)) {
      return;
    }

    setSearchParams((prev) => ({
      ...prev,
      profileId: inheritableProfileId,
      isInherited: true,
    }));
  };

  // request of Richard FOK until the backend fixes the API
  const profileName = inheritableProfileName?.startsWith('Custom_') ? 'Custom' : inheritableProfileName;

  const isSelected = inheritableProfileId === selectedProfileId && isInherited;

  return (
    <div className={`mb-2`} style={{ minHeight: `7em` }} data-e2e={`fx-select-upper-commercial-level-type`}>
      <h5 className={`d-flex align-items-center text-black`}>
        <FormattedMessage id="upperCommercial.presets" />
        <HelpCenter className={`text-black ms-1`} withHandler topic="TODO" />
      </h5>

      <p className="fs-12 text-secondary fw-medium mb-2">
        <FormattedMessage
          id={
            hasParentClient
              ? gridDataType === TIERING_GRID_DATA_TYPE
                ? 'upperCommercial.presets.tiering.description'
                : 'upperCommercial.presets.margin.description'
              : 'upperCommercial.presets.notAvailable'
          }
        />
      </p>

      {hasParentClient && (
        <ul className="list-group border overflow-auto" style={{ maxHeight: `3em` }}>
          <label
            key={inheritableProfileId}
            htmlFor={`${inheritableProfileId}/up`}
            className={`list-group-item list-group-item-action list-group-item-primary d-flex align-items-center justify-content-between border-bottom p-2 border-start-0 fw-medium ${
              // TODO-5198: the default item is not display in blue: why ?
              isInHeritableProfileDefault ? 'text-info' : ''
            }`}
          >
            {isDefined(profileName) ? profileName : <LoadingMessage />}

            <input
              type="radio"
              id={`${inheritableProfileId}/up`}
              value={`${inheritableProfileId}/up`}
              name="FxProfilesPanel"
              onChange={onChange}
              checked={isSelected}
            />
          </label>
        </ul>
      )}
    </div>
  );
};

export default UpperCommercialLevelProfileType;
