// FX
export const FX_PATH = '/fx/clients';
export const FX_ROUTE = `${FX_PATH}`;

export const FX_PATH_UNAUTHORIZED = 'unauthorized';
export const FX_ROUTE_UNAUTHORIZED = `${FX_PATH}/${FX_PATH_UNAUTHORIZED}`;

export const FX_PRODUCTS_PATH = ':clientBdrId/:clientBdrLevel/perimeters/:perimeterKey/products';

export const FX_PRODUCTS_ROUTE = `${FX_ROUTE}/${FX_PRODUCTS_PATH}`;

export const FX_EDIT_PRODUCT_PATH = ':productKey';
export const FX_SELECT_CASH_PROFILE_ROUTE =
  '/fx/clients/:clientBdrId/:clientBdrLevel/perimeters/:perimeterKey/products/:productKey/gridDataType/:gridDataType';
export const FX_SELECT_ORDER_PROFILE_ROUTE =
  '/fx/clients/:clientBdrId/:clientBdrLevel/perimeters/:perimeterKey/products/:productKey';
export const FX_EDIT_CASH_PROFILE_ROUTE = `${FX_SELECT_CASH_PROFILE_ROUTE}/edit`;
export const FX_EDIT_ORDER_PROFILE_ROUTE = `${FX_SELECT_ORDER_PROFILE_ROUTE}/edit`;
export const FX_EDIT_PRODUCT_ROUTE = `${FX_PRODUCTS_ROUTE}/${FX_EDIT_PRODUCT_PATH}`;
export const FX_EDIT_PROFILE_ROUTE = `${FX_EDIT_PRODUCT_ROUTE}/edit`;
