import { FormattedMessage } from 'react-intl';
import { isDefined, isNonEmpty } from '@sgme/fp';

interface FallbackMessageProps {
  icon?: string;
  headerLocaleKey?: string;
  descriptionLocaleKey?: string;
  descriptionElement?: JSX.Element;
}

export const FallbackMessage = ({
  icon,
  headerLocaleKey,
  descriptionLocaleKey,
  descriptionElement,
}: FallbackMessageProps): JSX.Element => (
  <>
    {isNonEmpty(icon) && <i className="d-flex justify-content-center icon icon-xl text-secondary mt-5 mb-4">{icon}</i>}

    <div className="d-flex flex-column align-items-center text-secondary fs-16 fw-semibold">
      {isNonEmpty(headerLocaleKey) && (
        <h3>
          <FormattedMessage id={headerLocaleKey} />
        </h3>
      )}

      {isNonEmpty(descriptionLocaleKey) && (
        <div>
          <FormattedMessage id={descriptionLocaleKey} />
        </div>
      )}

      {isDefined(descriptionElement) && descriptionElement}
    </div>
  </>
);
