import { FxFirstClientCardBody } from '@pages/fx/FxClientsPage/FxClients/FxClientCard/FxFirstClientCardBody';
import { FxFirstClientCardHeader } from '@pages/fx/FxClientsPage/FxClients/FxClientCard/FxFirstClientHeader';
import { FX_PRODUCTS_ROUTE } from '@pages/fx/constants';
import type { FxClientModel } from '@services/fx/model/client';
import type { FxPerimeterKey } from '@services/fx/model/perimiters';
import { isDefined } from '@sgme/fp';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

type ClientCardProps = {
  client: FxClientModel;
  isAuthorized: boolean;
  perimeterKey: FxPerimeterKey;
};

export function FxFirstClientCard({ client, isAuthorized, perimeterKey }: ClientCardProps) {
  const navigate = useNavigate();
  const { clientBdrId, clientBdrLevel, perimeters } = client;

  // @TODO ABO, create Hook to return goToPerimetersPage
  const goToPerimetersPage = useCallback(() => {
    if (
      isDefined(client) &&
      isDefined(perimeterKey) &&
      isDefined(clientBdrId) &&
      isDefined(clientBdrLevel) &&
      client.userAccess !== 'unauthorized'
    ) {
      navigate(
        generatePath(FX_PRODUCTS_ROUTE, {
          clientBdrId: String(clientBdrId),
          clientBdrLevel: clientBdrLevel?.toUpperCase(),
          perimeterKey,
        }),
      );
    }
  }, [clientBdrId, clientBdrLevel, client, navigate, perimeterKey]);

  return (
    <div className={`${isAuthorized ? 'card-bordered card-raising' : 'card-bordered card'}`}>
      <FxFirstClientCardHeader client={client} goToPerimetersPage={goToPerimetersPage} />
      <FxFirstClientCardBody bdrId={clientBdrId} bdrLevel={clientBdrLevel} perimeters={perimeters} />
    </div>
  );
}
