import { isNotDefined } from '@sgme/fp';
import clsx from 'clsx';

// why props: any ?
// because for AgGrid, cellRenderer is any !
export const DefaultCell = (props: any) => {
  const { value, node, context, valueFormatted } = props;

  if (isNotDefined(value)) {
    return undefined;
  }
  const isLastChild = node.lastChild;
  const isEdited = node.data?.state !== 'untouched';

  return value === '*' ? (
    <span
      className={clsx({
        'text-primary': isLastChild,
        'text-secondary': !isEdited && !isLastChild,
        'text-info': isEdited,
      })}
    >
      {context.formatMessage({ id: '*' })}
    </span>
  ) : (
    <span
      className={clsx({
        'text-info': isEdited,
      })}
    >
      {valueFormatted ?? value}
    </span>
  );
};
