import { isDefined } from '@sgme/fp';
import { useAppSelector } from '@hooks/useAppSelector';
import { FxCashEditorEmpty } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxCashEditorEmpty';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { createRow } from '@store/fxProfileEdition/fxProfileEditionSlice';
import type { FxCashProductKey } from '@services/fx/model/cash';
import { FormattedMessage } from 'react-intl';
import { FxCashEditorForm } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxCashEditorForm';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { isFxCashProfileRejectedColumns } from '@services/fx/model/perimiters';

export const FxCashMarginEditor = () => {
  const dispatch = useAppDispatch();
  const { productKey } = useFxUrlParams();
  const editingRow = useAppSelector((state) => state.fxProfileEdition.editingRow);

  const addCashRow = () => {
    //always FxCashProductKey in this component
    dispatch(createRow({ type: 'cash-margin', productKey: productKey as FxCashProductKey }));
  };

  const editorTitleId = `fx.editProfile.fx-cash-rfq-perimeter${
    isFxCashProfileRejectedColumns(editingRow?.columns) ? '.setup.rejectionTitle' : '.margin-grid-data-type.setup.title'
  }`;

  return (
    <div className={`card h-100 p-4 bg-lvl2 col-6 col-md-4 col-xl-3 col-xxl-2 border-start`} data-e2e="fx-edit-Panel">
      <div className={`d-flex align-items-start justify-content-between`}>
        <h4 className="card-title line-height-1 w-100 mb-3">
          <FormattedMessage id={editorTitleId} />
        </h4>
      </div>

      {isDefined(editingRow) ? (
        <FxCashEditorForm editingRow={editingRow} />
      ) : (
        <FxCashEditorEmpty
          addRow={addCashRow}
          gridDataType={'margin-grid-data-type'}
          productKey={productKey as FxCashProductKey}
        />
      )}
    </div>
  );
};
