import { createSelector } from '@reduxjs/toolkit';
import { selectEntitiesMarginProfiles } from 'services/marginProfiles';

import { maximumUpperMaturityRange, minimumLowerMaturityRange } from 'utils/maturityRange';

import type { FiltersState } from './filtersSlice';
import type {AppState} from "../store";

export const getFilters = (state: AppState): FiltersState => state.filters;

export const getFilter = <K extends keyof FiltersState>(
  state: AppState,
  filter: K,
): FiltersState[K] => getFilters(state)[filter];

export const selectFilter =
  <K extends keyof FiltersState>(filter: K) =>
  (state: AppState) =>
    getFilter<K>(state, filter);

export type ActiverFilterType = 'Sales' | 'DevL' | 'Pcru' | 'Custom' | 'MaturityRange';
export interface ActiveFilterCustomized {
  type: 'Custom';
}
export interface ActiveFilterMargin {
  type: 'Sales' | 'DevL' | 'Pcru';
  label: string;
  value: number;
}
export interface ActiverFilterMaturityRange {
  type: 'MaturityRange';
  min: number;
  max: number;
}
export type ActiveFilterProps = ActiveFilterCustomized | ActiveFilterMargin | ActiverFilterMaturityRange;

export const getActiveFilters = createSelector(
  getFilters,
  selectEntitiesMarginProfiles,
  (filters, marginProfiles) =>
    [
      ...(filters.lowerMaturityRange > minimumLowerMaturityRange ||
      filters.upperMaturityRange < maximumUpperMaturityRange
        ? [
            {
              type: 'MaturityRange',
              min: filters.lowerMaturityRange,
              max: filters.upperMaturityRange,
            },
          ]
        : []),
      ...(filters.customizedMarginsOnly
        ? [
            {
              type: 'Custom',
              label: 'Custom',
            },
          ]
        : filters.salesProfiles.map((value) => ({
            type: 'Sales',
            label: marginProfiles[value]?.name,
            value,
          }))),
      ...filters.devLProfiles.map((value) => ({
        type: 'DevL',
        label: marginProfiles[value]?.name,
        value,
      })),
      ...filters.pcruMargins.map((value) => ({
        type: 'Pcru',
        label: `${value}bp`,
        value,
      })),
    ] as ActiveFilterProps[],
);
