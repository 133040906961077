import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';

import type { AppState } from '@store/store';
import type { SgConnectUserInfo } from '../sgwtConnect/sgwtConnect.model';
import type { EditCashPermission, Permission, Resource } from './authorization.model';
import { RESOURCE, editCashProductPermissions } from './authorization.model';

export interface AuthorizationState {
  permissions: Permission[];
  loading: boolean;
}

const initialState: AuthorizationState = {
  permissions: [],
  loading: true,
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setPermissions: (state, { payload }: PayloadAction<SgConnectUserInfo<Resource, Permission>>) => {
      state.loading = false;
      const authorization = payload.user_authorization.find(({ resource }) => resource === RESOURCE);

      if (isDefined(authorization)) {
        state.permissions = authorization.permissions.map(({ name }) => name);
      }

      return state;
    },
  },
});

const { actions } = authorizationSlice;

export const { setPermissions } = actions;

export const selectPermissions = (state: AppState): Permission[] => state.authorization.permissions;
export const selectAuthorizationLoading = (state: AppState): boolean => state.authorization.loading;

export const selectEditCashPermissions = createSelector(selectPermissions, (allUsersPermissions) =>
  editCashProductPermissions.filter((userPermission) =>
    allUsersPermissions.includes(userPermission as EditCashPermission),
  ),
);
