import { fxApi } from './fxApi';
import type { ProfileId } from './model/models';
import { clearUndefined } from '@sgme/fp';
import type { ClientBdrId, ClientBdrLevel } from './model/client';
import type { FxCashGridDataType } from './model/cash';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';

export const saveProductApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    selectFxProfile: builder.mutation<
      void,
      {
        clientBdrId: ClientBdrId;
        clientBdrLevel: ClientBdrLevel;
        perimeterKey: FxPerimeterKey;
        productKey: FxProductKey;
        profileId: ProfileId;
        reasonId: number;
        comment?: string;
        onBehalfOfUser?: string;
        gridDataType?: FxCashGridDataType;
        profileVersion?: number;
        clientProfileAssignmentVersion?: number;
      }
    >({
      query: ({ clientBdrId, clientBdrLevel, perimeterKey, productKey, profileId, gridDataType, ...reason }) => ({
        method: 'PUT',
        url: `perimeters/${perimeterKey}/products/${productKey}/clients/${clientBdrId}/profiles/${profileId}`,
        params: {
          clientBdrLevel,
          gridDataType,
        },
        body: clearUndefined(reason),
      }),

      invalidatesTags: (
        result,
        error,
        { perimeterKey, productKey, profileId, clientBdrId, clientBdrLevel, gridDataType },
      ) => [
        { type: 'ClientsUsingProfile', id: profileId },
        { type: 'Profile', id: `${perimeterKey}-${productKey}` },
        { type: 'Profile', id: `${perimeterKey}-${productKey}-${gridDataType}` },
        { type: 'Profile', id: `${profileId}` },
        { type: 'ClientsInheritance', id: `${clientBdrId}/${clientBdrLevel}` },
        'FXClients',
      ],
    }),
  }),
});

export const { useSelectFxProfileMutation } = saveProductApi;
