import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import type { GetRowIdParams, RowClassParams, RowGroupOpenedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import type { Dictionary } from '@reduxjs/toolkit';
import { selectFilteredClients, useGetClientsQuery } from 'services/clients';
import type { MarginProfile } from 'services/marginProfiles';
import { useGetMarginProfilesQuery } from 'services/marginProfiles';

import { LoadingMessage } from 'components/LoadingMessage';
import { partition } from 'utils/array';
import { range } from 'utils/range';

import { NoClientsFound } from './NoClientsFound';
import useColumnDef from './useColumnDef';
import useRowData from './useRowData';
import { getEditedClientsDiffs, isEditingClients } from "../../../../../store/clientEdition/clientEditionSelectors";
import { getFilters } from "../../../../../store/filters/filtersSelectors";
import { useIntl } from "react-intl";
import type { ColGroupDef, GridApi, GridReadyEvent, ProcessDataFromClipboardParams } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { lagDefaultColDef, lagGridOptions } from "../../../contants";



export const ClientsMarginsTable = (): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);

  const { formatMessage } = useIntl();

  const clientsDiffs = useSelector(getEditedClientsDiffs);
  const isEditing = useSelector(isEditingClients);

  const { lowerMaturityRange, upperMaturityRange } = useSelector(getFilters);

  const { isLoading: isClientsLoading } = useGetClientsQuery();
  const clients = useSelector(selectFilteredClients);

  const { marginProfiles, isLoading: isMarginProfilesLoading } = useGetMarginProfilesQuery(undefined, {
    selectFromResult: ({ data, isLoading, isError }) => {
      if (data?.entities === undefined) {
        return { marginProfiles: {}, isLoading, isError };
      }

      return {
        marginProfiles: data.entities as Dictionary<MarginProfile>,
        isLoading,
        isError,
      };
    },
  });

  const isLoading = isClientsLoading || isMarginProfilesLoading;

  const [containerHeight, setContainerHeight] = useState<string | undefined>(undefined);

  // update visible maturity range
  useEffect(() => {
    if (!gridRef.current?.api || !gridRef.current?.columnApi) return;
    const { api, columnApi } = gridRef.current;

    const [visible, hidden] = partition(range(1, 30), (cur) => cur >= lowerMaturityRange && cur <= upperMaturityRange);
    api.sizeColumnsToFit();
    columnApi.setColumnsVisible(visible.map(getMaturityColId), true);
    columnApi.setColumnsVisible(hidden.map(getMaturityColId), false);
  }, [lowerMaturityRange, upperMaturityRange]);

  // update margin profiles. WARNING: this is only used to refresh labels in group row which are not updated when the children data changes.
  // If we could target a specific group row it would be better.
  useEffect(() => {
    if (!gridRef.current?.api) return;
    const { api } = gridRef.current;
    api.refreshCells({ columns: EDITABLE_COLUMNS });
  }, [clients]);

  const columnDefs = useColumnDef();
  const rows = useRowData({ clients, clientsDiffs, marginProfiles });

  const getRowId = useCallback(({ data }: GetRowIdParams) => `${data.client.id}-${data.marginProfile.type}`, []);

  const getMaturityTotalRowClass = useCallback(
    (params: RowClassParams) => (params.node?.group ? 'fw-bold' : ''),
    [],
  );

  // This is used to hide the label "Customized" when the user expands the client's margins.
  const onRowGroupOpened = useCallback(({ api, node }: RowGroupOpenedEvent) => {
    api.refreshCells({
      rowNodes: [node],
      columns: EDITABLE_COLUMNS,
      force: true,
    });
  }, []);

  // AgGrid context don't refresh component cell on change.
  useEffect(() => {
    if (!gridRef.current?.api) return;

    const { api } = gridRef.current;

    api.refreshCells({ force: true, columns: EDITABLE_COLUMNS });
  }, [isEditing]);

  const processDataFromClipboard = useCallback(
    ({ data }: ProcessDataFromClipboardParams) =>
      data.filter((row) => !(row.length === 1 && !row[0])),
    [],
  );

  // Provides access to the grid API from the outside
  const maxRowDisplayed = 30;

  function onGridReady(event: GridReadyEvent) {

    const domLayout = rows.length > maxRowDisplayed ? 'normal' : 'autoHeight';
    event.api.setDomLayout(domLayout);

    setContainerHeight(calculateContainerHeight(rows.length, maxRowDisplayed, event.api))
  }

  if (isLoading) {
    return <LoadingMessage />;
  }

  if (clients.length === 0) {
    return <NoClientsFound />;
  }

  return (
    <div className="col">
      <div
        className={`d-flex ag-theme-sg-bootstrap ag-theme-lag`}
        style={{ height: containerHeight }}
      >
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          gridOptions={lagGridOptions}
          defaultColDef={lagDefaultColDef}
          columnDefs={columnDefs}
          processDataFromClipboard={processDataFromClipboard}
          modules={AG_GRID_MODULES}
          className="w-100"

          context={{ formatMessage, isEditing }}
          rowMultiSelectWithClick={isEditing}
          rowData={rows}
          localeText={{ noRowsToShow: formatMessage({ id: 'no.marge.to.show' }) }}
          onRowGroupOpened={onRowGroupOpened}
          getRowClass={getMaturityTotalRowClass}
          groupDefaultExpanded={0}
          getRowId={getRowId}
          componentWrappingElement="span"
        />
      </div>
    </div>
  );
};

const getMaturityColId = (index: number) => `maturity_${index}`;

const EDITABLE_COLUMNS = ['marginProfileName'];

const AG_GRID_MODULES = [ClientSideRowModelModule, ClipboardModule, RowGroupingModule];


const calculateContainerHeight = (rowCount: number, maxRowDisplayed: number, api: GridApi) => {

  if (rowCount <= maxRowDisplayed) {
    return undefined;
  }

  const { headerHeight = 32, rowHeight = 32 } = api.getSizesForCurrentTheme();

  const headerHaveChildren = (api.getColumnDefs() ?? []).find(
    (columnDef) => !!(columnDef as ColGroupDef).children,
  );
  const styleHeaderHeight = `(${headerHaveChildren ? '2' : '1'} * ${headerHeight}px)`;

  const styleRowsHeight = `(${Math.min(maxRowDisplayed, Math.max(rowCount, 1))} * ${rowHeight}px)`;

  return `calc(${styleHeaderHeight} + ${styleRowsHeight} + 0.5em)`

};
