import type { ColDef, ColGroupDef, HeaderValueGetterParams, ValueGetterParams } from '@ag-grid-community/core';
import { isNotDefined } from '@sgme/fp';
import { ActionsCellRenderer } from 'pages/fx/components/FxOrderProfileTable/ActionsCellRenderer';
import { InternalMarginCellRenderer } from 'pages/fx/components/FxOrderProfileTable/InternalMarginCellRenderer';
import { HelperCellHeader } from 'pages/fx/components/HelperCellHeader';
import { memo } from 'react';
import type { FxOrderProductKey, FxOrderProfileRow, OrderProfileColumns } from '../../../../services/fx/model/order';

//  █████╗  ██████╗        ██████╗ ██████╗ ██╗██████╗      ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
// ██╔══██╗██╔════╝       ██╔════╝ ██╔══██╗██║██╔══██╗    ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
// ███████║██║  ███╗█████╗██║  ███╗██████╔╝██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
// ██╔══██║██║   ██║╚════╝██║   ██║██╔══██╗██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
// ██║  ██║╚██████╔╝      ╚██████╔╝██║  ██║██║██████╔╝    ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
// ╚═╝  ╚═╝ ╚═════╝        ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝      ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝

export interface UseColumnDefsReturn {
  columnDefs: Array<ColDef | ColGroupDef>;
  defaultColDef: ColDef;
}

export const useColumnDefs = (productKey: FxOrderProductKey, editing = false): UseColumnDefsReturn => ({
  defaultColDef: getDefaultColDef(),
  columnDefs: editing ? getEditColumnDefs(productKey) : getViewColumnDefs(productKey),
});

/**
 * DEFINE ORDER OF COLUMNS BY PRODUCT
 */
export const LIMIT_ORDER_COLUMN_KEYS = [
  'mediaDimension',
  'limitOrderTypeDimension',
  'instrumentDimension',
  'currencyPairDimension',
  'marginValue',
  'marginUnit',
] as const;

export const ALGO_ORDER_COLUMN_KEYS = [
  'mediaDimension',
  'algoTypeDimension',
  'instrumentDimension',
  'currencyPairDimension',
  'liquidityProviderDimension',
  'marginUnit',
] as const;

export const FIXING_ORDER_COLUMN_KEYS = [
  'mediaDimension',
  'instrumentDimension',
  'currencyPairDimension',
  'fixingSourceDimension',
  'marginValue',
  'marginUnit',
] as const;

// ██████╗ ███████╗███████╗ █████╗ ██╗   ██╗██╗  ████████╗     ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗    ██████╗ ███████╗███████╗
// ██╔══██╗██╔════╝██╔════╝██╔══██╗██║   ██║██║  ╚══██╔══╝    ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║    ██╔══██╗██╔════╝██╔════╝
// ██║  ██║█████╗  █████╗  ███████║██║   ██║██║     ██║       ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║    ██║  ██║█████╗  █████╗
// ██║  ██║██╔══╝  ██╔══╝  ██╔══██║██║   ██║██║     ██║       ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║    ██║  ██║██╔══╝  ██╔══╝
// ██████╔╝███████╗██║     ██║  ██║╚██████╔╝███████╗██║       ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║    ██████╔╝███████╗██║
// ╚═════╝ ╚══════╝╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚═╝        ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝    ╚═════╝ ╚══════╝╚═╝

const getDefaultColDef = (): ColDef => ({
  flex: 1,
  editable: false,
  resizable: false,
  suppressMenu: true,
  sortable: false,
  filter: false,
  headerValueGetter: ({ context, column }: HeaderValueGetterParams) =>
    context.formatMessage({ id: column?.getColId() }),
  headerClass: 'text-secondary',
});

// ███████╗██████╗ ██╗████████╗     ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
// ██╔════╝██╔══██╗██║╚══██╔══╝    ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
// █████╗  ██║  ██║██║   ██║       ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
// ██╔══╝  ██║  ██║██║   ██║       ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
// ███████╗██████╔╝██║   ██║       ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
// ╚══════╝╚═════╝ ╚═╝   ╚═╝        ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝

const getEditColumnDefs = (productKey: FxOrderProductKey): ColDef<FxOrderProfileRow>[] => [
  selectionColDef,
  ...getViewColumnDefs(productKey),
  actionsColDef,
];

const selectionColDef: ColDef = {
  field: 'selections',
  headerValueGetter: '',
  headerCheckboxSelection: true,
  showDisabledCheckboxes: true,
  maxWidth: 50,
  pinned: 'left',
  checkboxSelection: true,
};

const actionsColDef: ColDef = {
  field: 'actions',
  pinned: 'right',
  width: 125,
  cellRenderer: memo(ActionsCellRenderer),
};

// ██╗   ██╗██╗███████╗██╗    ██╗     ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
// ██║   ██║██║██╔════╝██║    ██║    ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
// ██║   ██║██║█████╗  ██║ █╗ ██║    ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
// ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║    ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
//  ╚████╔╝ ██║███████╗╚███╔███╔╝    ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
//   ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝      ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝

const getViewColumnDefs = (productKey: FxOrderProductKey): ColDef<FxOrderProfileRow>[] =>
  ({
    'algo-order-product': ALGO_ORDER_COLUMN_KEYS.map(toColDefsMapper),
    'limit-order-product': LIMIT_ORDER_COLUMN_KEYS.map(toColDefsMapper),
    'fixing-order-product': FIXING_ORDER_COLUMN_KEYS.map(toColDefsMapper),
  })[productKey];

const toColDefsMapper = (field: keyof OrderProfileColumns): ColDef<FxOrderProfileRow> => ({
  field,
  ...fxColumnDefs[field](),
});

// dictionary of getters of the column def
// each getter generate the name of the column in the locale of the user

const fxColumnDefs: Record<keyof OrderProfileColumns, () => ColDef<FxOrderProfileRow>> = {
  mediaDimension: () => ({ type: ['defaultFormatValue'] }),

  algoTypeDimension: () => ({ type: ['defaultFormatValue'] }),

  limitOrderTypeDimension: () => ({ type: ['defaultFormatValue'] }),

  fixingSourceDimension: () => ({ type: ['defaultFormatValue'] }),

  instrumentDimension: () => ({ type: ['defaultFormatValue'] }),

  currencyPairDimension: () => ({
    type: ['defaultFormatValue'],
    headerComponent: HelperCellHeader,
    headerComponentParams: {
      helperLocaleKey: 'fx.message.tooltip.currency',
    },
  }),

  marginValue: () => ({
    headerClass: 'text-secondary',
    width: 220,
    pinned: 'right',
    type: ['alignRight'],
  }),

  liquidityProviderDimension: () => ({
    headerValueGetter: ({ context }: HeaderValueGetterParams) =>
      context.formatMessage({ id: 'liquidityProviderDimension' }),
    headerClass: 'text-secondary',
    children: [
      {
        field: 'liquidityProviderDimension.External',
        width: 110,
        pinned: 'right',
        type: ['alignRight', 'marginFormatValue'],
      },
      {
        field: 'liquidityProviderDimension.Internal',
        width: 110,
        pinned: 'right',
        type: ['alignRight', 'marginFormatValue'],
        cellRenderer: memo(InternalMarginCellRenderer),
      },
    ],
  }),

  marginUnit: () => ({
    pinned: 'right',
    width: 100,
    valueGetter({ data, context }: ValueGetterParams<FxOrderProfileRow>) {
      if (isNotDefined(data)) {
        return '';
      }

      // the user can swith unit only between bps & mln
      if (context.productKey !== 'algo-order-product') {
        return context.formatMessage({ id: `common.unit.${data.marginUnit}` });
      }

      switch (context.valueUnit) {
        case 'bps':
          return context.formatMessage({ id: 'common.unit.bps' });
        case 'mln':
          return context.formatMessage({ id: 'common.unit.mln' });
      }
    },
  }),
};
