import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { selectFilteredClients } from 'services/clients';

import { LoadingButton } from 'components/LoadingButton';
import { ChangeReasonsModal } from 'components/modals/ChangeReasonsModal';
import {useAppDispatch} from "../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../hooks/useAppSelector";
import {
  getEditedCells,
  isEditingClients,
  isSavingClientEdition
} from "../../../../store/clientEdition/clientEditionSelectors";
import {actionCreators} from "../../../../store/actions";
import {Authorize} from "../../../../features/authorization/Authorize";

interface EditButtonProps {
  hasClients: boolean;
  toggle: () => void;
}

function EditButton({ hasClients, toggle }: EditButtonProps): JSX.Element {
  return hasClients ? (
    <div className="d-flex flex-row-reverse align-items-center mb-3">
      <button
        className="btn btn-primary btn-icon-start"
        data-e2e="edit-clients-margins"
        onClick={toggle}
      >
        <i className="icon">edit</i>
        <FormattedMessage id="common.edit" />
      </button>
    </div>
  ) : (
    <></>
  );
}

function EditionPanel(): JSX.Element {
  const dispatch = useAppDispatch();

  const isEditing = useAppSelector(isEditingClients);
  const isSaving = useAppSelector(isSavingClientEdition);
  const editedCellsCount = useAppSelector(getEditedCells);
  const clients = useAppSelector(selectFilteredClients);
  const hasClients = clients.length > 0;

  const toggle = useCallback(() => {
    dispatch(actionCreators.clientEdition.toggleEdition());
  }, [dispatch]);

  const saveEditing = useCallback(() => {
    dispatch(actionCreators.clientEdition.saveChanges());
  }, [dispatch]);

  return isEditing ? (
    <div className="d-flex align-items-center alert alert-info px-3 py-3">
      <div className="flex-fill">
        <span data-e2e="cells-edited">
          <FormattedMessage id="common.cellsEdited" values={{ editedCellsCount }} />
        </span>
      </div>
      <div>
        <button className="btn btn-info m-1" onClick={toggle}>
          <FormattedMessage id="common.cancel" />
        </button>
        <LoadingButton
          className="btn btn-outline-white m-1"
          data-e2e="save-changes"
          icon="save"
          loading={isSaving}
          disabled={editedCellsCount === 0}
          onClick={saveEditing}
        >
          <FormattedMessage id="clientsMargins.edit.saveChanges" />
        </LoadingButton>
      </div>
    </div>
  ) : (
    <EditButton hasClients={hasClients} toggle={toggle} />
  );
}

export function EditClientsMargins(): JSX.Element {
  return (
    <Authorize authorizedFor={['LAG_WRITE']}>
      <ChangeReasonsModal />
      <EditionPanel />
    </Authorize>
  );
}
