import { type UIMatch, useMatches } from 'react-router-dom';
import { isDefined } from '@sgme/fp';

type Matches = UIMatch<
  unknown,
  {
    crumb: (active?: boolean) => JSX.Element;
  }
>[];

export const Breadcrumb = (): JSX.Element => {
  const matches = useMatches() as Matches;

  const crumbElements = matches
    .filter(({ handle }) => isDefined(handle?.crumb))
    .map(({ handle }, index, { length }) => handle?.crumb(index === length - 1));

  if (crumbElements.length <= 1) {
    return <></>;
  }

  return (
    <nav className="mb-1" data-e2e="breadcrumb">
      <ol className="breadcrumb mb-0">
        {crumbElements.map((crumbElement, index) => (
          <li key={index} className="breadcrumb-item">
            {crumbElement}
          </li>
        ))}
      </ol>
    </nav>
  );
};
