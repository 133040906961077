import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FxClientsList } from 'components/fx/FxClientsList';
import type { FxProfileMarginGrid } from '@services/fx/model/profile-margin-grid';

import type { FxProfileColumns } from '@services/fx/model/perimiters';

export interface FxClientsForProfileModalProps extends ModalProps {
  profile?: FxProfileMarginGrid<FxProfileColumns>;
}

export const FxClientsForProfileModal = ({ profile, ...props }: FxClientsForProfileModalProps): JSX.Element => (
  <Modal data-e2e="clients-for-profile-modal" {...props}>
    <ModalHeader tag="h3" close={props.modalHeaderClose}>
      <FormattedMessage id="fx.title.clients" />
    </ModalHeader>

    <ModalBody className="pb-0">
      <FxClientsList profile={profile} />
    </ModalBody>

    <ModalFooter>
      <button type="reset" className="btn btn-lg btn-flat-primary" onClick={props.onClosed}>
        <FormattedMessage id="common.cancel" />
      </button>
    </ModalFooter>
  </Modal>
);
