import clsx from 'clsx';

type LoadingButtonProps = {
  loading: boolean;
  icon?: string;
  dataE2e?: string;
  iconPosition?: 'start' | 'end';
} & JSX.IntrinsicElements['button'];

export const LoadingButton = ({
  loading,
  icon = '',
  className = '',
  dataE2e,
  children,
  iconPosition = 'start',
  ...props
}: LoadingButtonProps): JSX.Element => (
  <button
    data-e2e={dataE2e}
    className={clsx([`btn ${className ?? ''}`], {
      'btn-icon-start': iconPosition === 'start',
      'btn-icon-end': iconPosition === 'end',
      loading,
    })}
    {...props}
  >
    {iconPosition === 'start' && (
      <>
        <span className="loader" />
        <span className="icon">{icon}</span>
      </>
    )}

    {children}

    {iconPosition === 'end' && (
      // needed to align replaced icon and spinner otherwise button
      // size is changing
      <div className={clsx(loading && 'ms-2px')}>
        <span className="icon">{icon}</span>
        <span className="loader" />
      </div>
    )}
  </button>
);
