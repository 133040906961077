import { selectClientById } from 'services/clients';

import { narrowDefined } from 'utils/asserts';
import { strictEntries } from 'utils/object';

import type { ClientDiff } from './clientEditionSlice';
import type {AppThunk} from "../store";

export const modifyMarginProfile =
  (clientId: number, diff: Partial<ClientDiff>): AppThunk =>
  (dispatch, getState, { actionCreators: ac }) => {
    const state = getState();
    strictEntries(diff).forEach(([key, value]) => {
      // If the value was changed, and changed again to its original value
      if (
        key !== 'changeReason' &&
        value === narrowDefined(selectClientById(state)(clientId))[key]
      ) {
        dispatch(ac.clientEdition.marginProfileResetted({ clientId, diff: [key] }));
      } else {
        dispatch(ac.clientEdition.marginProfileModified({ clientId, diff: { [key]: value } }));
      }
    });
  };

export const saveChanges =
  (): AppThunk =>
  async (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (sl.hasClientEditionMissingChangeReasons(state)) {
      dispatch(ac.clientEdition.reasonModalToggled());
      return;
    }
  };
