import type { HTMLAttributes } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isDefined, isNotDefined } from '@sgme/fp';

import { LoadingMessage } from 'components/LoadingMessage';

import type { Permission } from './authorization.model';
import { NOT_AUTHORIZED } from './authorization.model';
import { selectAuthorizationLoading, selectPermissions } from './authorization.slice';
import { redirectToNotAuthorized } from './redirectToNotAuthorized';

type Mode = 'all' | 'atLeastOne';
type To = string | typeof NOT_AUTHORIZED;

export interface AuthorizeProps extends HTMLAttributes<HTMLDivElement> {
  authorizedFor?: Readonly<Array<Permission>> | ((permissions: Permission[]) => boolean);
  mode?: Mode;
  fallback?: JSX.Element;
  redirectTo?: To | ((permissions: Permission[]) => To);
}

export const Authorize = ({
  authorizedFor = [],
  mode = 'atLeastOne',
  fallback = <></>,
  redirectTo,
  children,
}: AuthorizeProps): JSX.Element => {
  const permissions: Permission[] = useSelector(selectPermissions);
  const loading: boolean = useSelector(selectAuthorizationLoading);
  // compute permissions
  const isAuthorized = useMemo(() => {
    if (isNotDefined(authorizedFor)) {
      return false;
    }

    if (typeof authorizedFor === 'function') {
      return authorizedFor(permissions);
    }

    switch (mode) {
      case 'all': {
        return authorizedFor.every((authorized) => permissions.includes(authorized));
      }
      case 'atLeastOne': {
        return authorizedFor.findIndex((authorized) => permissions.includes(authorized)) !== -1;
      }
    }
  }, [authorizedFor, mode, permissions]);

  // Permissions not load yet
  if (loading) {
    return <LoadingMessage />;
  }

  // User have No one permission
  if (permissions.length === 0) {
    redirectToNotAuthorized();
  }

  // User is Authorized
  if (isAuthorized) {
    return <>{children}</>;
  }

  // User is not authorized to access and it's redirect to an other page
  if (isDefined(redirectTo)) {
    const to = typeof redirectTo === 'function' ? redirectTo(permissions) : redirectTo;

    if (to === NOT_AUTHORIZED) {
      redirectToNotAuthorized();
    }

    return <Navigate to={to} replace />;
  }

  // User is not authorized to access and display fallback component
  return fallback;
};
