import { PageTracker } from '@features/analytics/PageTracker';
import { Authorize } from '../../../features/authorization/Authorize';
import ClientsMargins from './ClientsMargins';

function LagClientsPage(): JSX.Element {
  return (
    <Authorize authorizedFor={['LAG_READ']} redirectTo="/">
      <PageTracker>
        <ClientsMargins />
      </PageTracker>
    </Authorize>
  );
}

export default LagClientsPage;
