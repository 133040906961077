import { FormattedMessage } from "react-intl";
import { CommonBadge } from "./CommonBadge";
import type { ComponentSize } from "../../../utils/size";
import type { ReactNode } from "react";
import type { ProfileSummaryFlagType } from "../../../services/fx/model/client";

interface FxClientBadgeProps {
  profileSummaryFlag: Omit<ProfileSummaryFlagType, "inherited"> | "";
  size?: ComponentSize
  className?: string
  dataE2e: string
}

export const FxClientBadge = (props: FxClientBadgeProps): JSX.Element => {
  const {
    profileSummaryFlag,
    size = "md",
    dataE2e,
    className
  } = props;

  const labelId = `fx.badge.${profileSummaryFlag}.label`;

  const tooltip = getTooltip(profileSummaryFlag);

  return (
    <CommonBadge
      labelId={labelId}
      size={size}
      dataE2e={dataE2e}
      color={getProfileColor(profileSummaryFlag)}
      className={className}
      children={tooltip}
    />
  );
};

function getProfileColor(profileSummaryFlag: Omit<ProfileSummaryFlagType, "inherited"> | "") {
  switch (profileSummaryFlag) {
    case 'local_setup':
      return "warning"
    case 'partial_local_setup':
      return "danger"
    // case 'inherited':
    //   return "plantation"
    default:
      return undefined
  }
}

const getTooltip = (profileSummaryFlag: Omit<ProfileSummaryFlagType, "inherited"> | "", inheritedBdrId?: number, inheritedBdrLevel?: string, inheritedBdrName?: string): ReactNode | undefined => {
  if (profileSummaryFlag === "partial_local_setup") {
    return (
      <div>
        <p>
          <FormattedMessage id="fx.badge.partial_local_setup.tooltip"/>
        </p>
      </div>
    )
  }

  if (profileSummaryFlag === "local_setup") {
    return (
      <div>
        <p>
          <FormattedMessage id="fx.badge.local_setup.tooltip"/>
        </p>
      </div>
    )
  }

  return undefined
}
