import { isDefined } from '@sgme/fp';

import { marginApi } from './getMarginProfiles';
import { marginProfilesApi } from './marginProfilesApi';
import type { MarginByMaturity } from './models';

export type UpdateMarginsPayload = Pick<MarginByMaturity, 'id' | 'value'>[];

export const updateMarginsApi = marginProfilesApi.injectEndpoints({
  endpoints: (builder) => ({
    updateMargins: builder.mutation<void, UpdateMarginsPayload>({
      query: (margins) => ({
        url: '/margins',
        method: 'PATCH',
        body: margins,
      }),
      invalidatesTags: ['marginProfiles'],
      onQueryStarted: async (marginsToUpdate, { dispatch, queryFulfilled }) => {
        const marginUpdater = updateMargins(marginsToUpdate);

        const patchResult = dispatch(
          marginApi.util.updateQueryData('getMarginProfiles', undefined, (draft) => {
            for (const entityId in draft.entities) {
              const entity = draft.entities[entityId];
              if (isDefined(entity)) {
                const { type } = entity;

                switch (type) {
                  case 'Sales': {
                    entity.fixedMargins = marginUpdater(entity.fixedMargins);
                    break;
                  }
                  case 'DevL': {
                    entity.margins = marginUpdater(entity.margins);
                    break;
                  }
                  default: {
                    entity.adjustmentMargins = marginUpdater(entity.adjustmentMargins);
                    entity.techMarginsFixed = marginUpdater(entity.techMarginsFixed);
                    entity.techMarginsFloating = marginUpdater(entity.techMarginsFloating);
                    break;
                  }
                }
              }
            }
          }),
        );

        // in case of request is in error, undo the optimistic update
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

// update margins value
const updateMargins = (marginsToUpdate: UpdateMarginsPayload) => {
  const updatedMargins = marginsToUpdate.reduce(
    (acc, { id, value }) => ({ ...acc, [id]: value }),
    {} as Record<string, number>,
  );
  const updatedMarginIds = Object.keys(updatedMargins);

  return (margins: MarginByMaturity[]): MarginByMaturity[] =>
    margins.map((margin) => {
      const { id } = margin;
      if (updatedMarginIds.includes(id)) {
        return { ...margin, value: updatedMargins[id] };
      }
      return margin;
    });
};

export const { useUpdateMarginsMutation } = updateMarginsApi;
