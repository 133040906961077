import { FX_ROUTE_UNAUTHORIZED } from '../constants';
import { Authorize, type AuthorizeProps } from '@features/authorization/Authorize';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { useFirstFxClientFor } from '@services/fx/getFXClient';

const AUTHORIZED_ACCESSES = [
  'authorized_read_only',
  'authorized_on_my_desk_clients_read_only',
  'authorized_on_my_desk_clients',
  'authorized_on_my_clients',
  'authorized_on_all_clients',
  'default',
];

export const AuthorizeFxClient = (props: AuthorizeProps): JSX.Element => {
  const { clientBdrId, clientBdrLevel } = useFxUrlParams();

  const { isSuccess, isError, client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);

  const clientIsAuthorizedForUser = () => {
    if (client && (isSuccess || isError)) {
      return AUTHORIZED_ACCESSES.includes(client.userAccess);
    }

    return true;
  };

  return <Authorize authorizedFor={clientIsAuthorizedForUser} redirectTo={FX_ROUTE_UNAUTHORIZED} {...props} />;
};
