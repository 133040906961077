import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';

import type { MarginsTableRow } from './useRowData';
import type { SelectPresetValues } from './useSelectPresetValues';
import type {PcruMargin} from "../../../../../store/pcruProfiles/pcruMarginsSlice";
import {useAppDispatch} from "../../../../../hooks/useAppDispatch";
import {actionCreators} from "../../../../../store/actions";

type MarginOption = { id: number | PcruMargin; name: string };

type FieldType = ReturnType<typeof getField>;
const getField = (marginProfile: MarginsTableRow['marginProfile']) => {
  switch (marginProfile.type) {
    case 'Sales':
      return 'salesMarginProfileId';
    case 'DevL':
      return 'devLMarginProfileId';
    case 'pcru':
      return 'pcruMargin';
    default:
      return undefined;
  }
};

const getDefaultValue = (dataRow: MarginsTableRow) => (field: FieldType) => {
  if (!field) return 0;

  const { salesMarginProfileId, devLMarginProfileId, pcruMargin } = {
    ...dataRow.client,
    ...dataRow.clientDiff,
  };

  const client = { salesMarginProfileId, devLMarginProfileId, pcruMargin };
  return client[field];
};

type CellSelectPresetRendererProps = ICellRendererParams & { presetValues: SelectPresetValues };

const CellSelectPresetRenderer = (params: CellSelectPresetRendererProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { node, presetValues, context, value } = params;

  const { allLeafChildren } = node;

  const dataRow: MarginsTableRow = !allLeafChildren
    ? params.data
    : allLeafChildren.find((child) => child.data.marginProfile.type === 'Sales')?.data;

  const clientId = useMemo(() => dataRow.client.id, [dataRow.client.id]);

  const field = useMemo(() => getField(dataRow.marginProfile), [dataRow.marginProfile]);

  const [currentValue, setCurrentValue] = useState(getDefaultValue(dataRow)(field));

  const options = useMemo<MarginOption[]>(() => {
    if (!dataRow.marginProfile.type) return [];

    return presetValues[dataRow.marginProfile.type];
  }, [dataRow.marginProfile.type, presetValues]);

  const onChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
      if (!field) return;

      const selectedValue = Number(target.value);
      setCurrentValue(Number(target.value));

      dispatch(
        actionCreators.clientEdition.modifyMarginProfile(clientId, { [field]: selectedValue }),
      );
    },
    [clientId, dispatch, field],
  );

  useEffect(() => {
    setCurrentValue(getDefaultValue(dataRow)(field));
  }, [
    field,
    context.isEditing, // this dependency is to init or reset component inner value when cancel edition
    dataRow,
  ]);

  if (!context.isEditing || (!allLeafChildren && dataRow.marginProfile.type === 'Sales')) {
    return value;
  }

  return (
    <select
      className="form-select form-select-sm py-0 border-0"
      onChange={onChange}
      value={currentValue}
    >
      {options.map(({ id, name }) => (
        <option key={`${field}-${id}`} value={id}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default CellSelectPresetRenderer;
