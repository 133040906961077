import { isDefined } from '@sgme/fp';
import { useAppSelector } from '@hooks/useAppSelector';
import { FxCashEditorEmpty } from '../FxCashEditorEmpty';
import { createRow } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { FormattedMessage } from 'react-intl';
import { FxCashEditorForm } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxCashEditorForm';
import type { FxCashProductKey } from '@services/fx/model/cash';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { isFxCashProfileRejectedColumns } from '@services/fx/model/perimiters';

export const FxCashTieringEditor = () => {
  const { productKey } = useFxUrlParams();
  const dispatch = useAppDispatch();
  const editingRow = useAppSelector((state) => state.fxProfileEdition.editingRow);

  const addTieringRow = () => {
    dispatch(createRow({ type: 'cash-tiering', productKey: productKey as FxCashProductKey }));
  };

  const editorTitleId = `fx.editProfile.fx-cash-rfq-perimeter${
    isFxCashProfileRejectedColumns(editingRow?.columns)
      ? '.setup.rejectionTitle'
      : '.tiering-grid-data-type.setup.title'
  }`;

  return (
    <div className={`card h-100 p-4 bg-lvl2 col-6 col-md-4 col-xl-3 col-xxl-2 border-start`} data-e2e="fx-edit-Panel">
      <div className={`d-flex align-items-start justify-content-between`}>
        <h4 className="card-title line-height-1 w-100 mb-3">
          <FormattedMessage id={editorTitleId} />
        </h4>
      </div>
      {isDefined(editingRow) ? (
        <FxCashEditorForm editingRow={editingRow} />
      ) : (
        <FxCashEditorEmpty
          addRow={addTieringRow}
          gridDataType={'tiering-grid-data-type'}
          productKey={productKey as FxCashProductKey}
        />
      )}
    </div>
  );
};
