import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { isNotDefined } from '@sgme/fp';
import type { AppState } from '@store/store';
import { narrowDefined } from 'utils/asserts';
import { marginProfilesApi } from './marginProfilesApi';
import type { MarginProfile } from './models';

export const marginProfilesAdapter = createEntityAdapter<MarginProfile>({
  selectId: ({ id }) => id,
});

const initialState = marginProfilesAdapter.getInitialState();

export const marginApi = marginProfilesApi.injectEndpoints({
  endpoints: (builder) => ({
    getMarginProfiles: builder.query<EntityState<MarginProfile>, void>({
      query: () => '',
      transformResponse: (marginProfiles: MarginProfile[]) =>
        marginProfilesAdapter.addMany(initialState, marginProfiles),
      providesTags: ['marginProfiles'],
    }),
  }),
});

export const { useGetMarginProfilesQuery } = marginApi;

const marginProfilesSelectors = marginProfilesAdapter.getSelectors((state: AppState) => {
  const { data } = marginApi.endpoints.getMarginProfiles.select()(state);

  if (data === undefined) {
    return initialState;
  }

  return data;
});

export const selectEntitiesMarginProfiles = marginProfilesSelectors.selectEntities;

export const selectAllMarginProfiles = marginProfilesSelectors.selectAll;

export const selectMarginById = createSelector(
  [selectAllMarginProfiles, (_state: AppState, marginId: string) => marginId],
  (marginProfiles, marginId) => marginProfiles.flatMap(getMarginsOfProfile).find(({ id }) => id === marginId),
);

export const isCustomProfile = (marginProfileId?: number) => (state: AppState) => {
  if (isNotDefined(marginProfileId)) {
    return false;
  }
  return !marginProfilesSelectors.selectById(state, marginProfileId)?.isPreset;
};

export const selectMarginsPresets = createSelector(selectAllMarginProfiles, (marginProfiles) =>
  marginProfiles.filter((x) => narrowDefined(x).isPreset),
);

export const selectSalesProfileIds = createSelector(selectMarginsPresets, (marginProfiles) =>
  marginProfiles.filter((profile) => profile?.type === 'Sales').map((profile) => narrowDefined(profile).id),
);

export const selectDevLProfileIds = createSelector(selectMarginsPresets, (marginProfiles) =>
  marginProfiles.filter((profile) => profile?.type === 'DevL').map((profile) => narrowDefined(profile).id),
);

const getMarginsOfProfile = (marginProfile?: MarginProfile) => {
  if (isNotDefined(marginProfile)) {
    return [];
  }
  switch (marginProfile.type) {
    case 'Sales':
      return marginProfile.fixedMargins;
    case 'DevL':
      return marginProfile.margins;
    default:
      return [];
  }
};
