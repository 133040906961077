import { fxApi } from './fxApi';
import type { FxPayload } from './model/models';
import { camelizeDeepObject } from './transformFxResponse';
import type { FxCashGridDataType } from './model/cash';
import type { FxDimensionsValues, FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';

type FxDimensionValuesQueryParams = {
  productKey: FxProductKey;
  perimeterKey: FxPerimeterKey;
  gridDataType?: FxCashGridDataType;
};

export const dimensionValuesApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFxDimensionValues: builder.query<FxDimensionsValues, FxDimensionValuesQueryParams>({
      query: ({ productKey, gridDataType, perimeterKey }) => ({
        url: `perimeters/${perimeterKey}/products/${productKey}/dimension-values`,
        params: {
          gridDataType,
        },
      }),

      transformResponse: (response: FxPayload<object> /* thanks Matt for object ;-) */) =>
        camelizeDeepObject<FxDimensionsValues>(response.data),
    }),
  }),
});

export const { useGetFxDimensionValuesQuery } = dimensionValuesApi;
