import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectFilteredClients } from '../../../../../services/clients';

const ClientColumnHeader = () => {
  const { formatMessage } = useIntl();
  const clients = useSelector(selectFilteredClients);
  const clientCount = clients?.length || 0;

  return (
    <div>
      {formatMessage({ id: 'clientsMargins.table.client' })}
      <span className={`badge rounded-pill badge-discreet-info ms-1`}>{clientCount}</span>
    </div>
  );
};

export default ClientColumnHeader;
