import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { isDefined, isNotDefined } from '@sgme/fp';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetFXClientsInheritanceQuery } from '@services/fx/getFXClientsInheritance';
import type {
  FxCashGridDataType,
  FxCashProductKey,
  FxCashProfile,
  FxCashProfileMarginColumns,
  FxCashProfileRejectedColumns,
  FxCashProfileRow,
  FxCashProfileTieringColumns,
} from '@services/fx/model/cash';
import {
  type FxClientInheritance,
  getFxCashInheritancePerimeter,
  type InheritableProfileClient,
} from '@services/fx/model/inheritance';
import type { ProfileId, ProfileTypeKey } from '@services/fx/model/models';
import type { ClientBdrId, ClientBdrLevel } from '@services/fx/model/client';
import type { RowFilters } from './useRowFilters';
import type { InstrumentName } from './model';
import type { RowState } from '@services/fx/model/profile-margin-grid';
import { type FxPerimeterKey, isFxCashProfileRejectedColumns } from '@services/fx/model/perimiters';
import { useGetFXProfileMarginGridQuery } from '@services/fx/getFXProfileMarginGrid';

export const useData = () => {
  const { clientBdrId, clientBdrLevel } = useFxUrlParams();

  const clientInheritanceParams =
    isDefined(clientBdrLevel) && isDefined(clientBdrId)
      ? {
          clientBdrId: Number(clientBdrId),
          clientBdrLevel,
        }
      : skipToken;

  const { data: clientInheritance } = useGetFXClientsInheritanceQuery(clientInheritanceParams);

  const allRows = [
    ...useCashProfile('spot-product', 'fx-cash-rfq-perimeter', 'margin-grid-data-type', clientInheritance),
    ...useCashProfile('spot-product', 'fx-cash-rfq-perimeter', 'tiering-grid-data-type', clientInheritance),

    ...useCashProfile('swap-product', 'fx-cash-rfq-perimeter', 'margin-grid-data-type', clientInheritance),
    ...useCashProfile('swap-product', 'fx-cash-rfq-perimeter', 'tiering-grid-data-type', clientInheritance),

    ...useCashProfile('ndf-product', 'fx-cash-rfq-perimeter', 'margin-grid-data-type', clientInheritance),
    ...useCashProfile('ndf-product', 'fx-cash-rfq-perimeter', 'tiering-grid-data-type', clientInheritance),
  ];

  return allRows;
};

const useCashProfile = (
  productKey: FxCashProductKey,
  perimeterKey: FxPerimeterKey,
  gridDataType: FxCashGridDataType,
  clientInheritance: FxClientInheritance | undefined,
): GridCashProfileRow[] => {
  const product = isDefined(clientInheritance)
    ? getFxCashInheritancePerimeter(clientInheritance.data.perimeters)?.products.find(
        (product) => product.productKey === productKey,
      )
    : undefined;

  const profile = gridDataType === 'margin-grid-data-type' ? product?.marginGrid : product?.tieringGrid;
  const profileId = profile?.profileId;
  const inheritableClient = profile?.inheritableProfile.client;
  const profileParams = isDefined(profileId) ? { profileId, productKey, gridDataType, perimeterKey } : skipToken;

  const { data } = useGetFXProfileMarginGridQuery(profileParams);

  if (isNotDefined(data)) {
    return [];
  }

  const isProductProfileInherited = profile?.isProfileInherited ?? false;

  return (data.rows as FxCashProfileRow[]).map(
    toGridRow(productKey, gridDataType, data as FxCashProfile, isProductProfileInherited, inheritableClient),
  );
};

// todo-5198 compose this type base on editing ones.
export type GridCashProfileRow = {
  instrument: InstrumentName;

  instrumentData: {
    productKey: FxCashProductKey;
    gridDataType: FxCashGridDataType;
    isInherited: boolean;
    profileName: string;
    profileId: ProfileId;
    profileTypeKey: ProfileTypeKey;
    applyOnInvertedPairDimension: 'True' | 'False';
    inheritableClient?: {
      bdrId: ClientBdrId;
      bdrLevel: ClientBdrLevel;
      name: string;
    };
  };

  // TODO:
  // internalRowId
  // status

  venue: string;
  clientVenueLogin: string;
  currency: string;
  onshoreOffshore: string;

  amount: string;
  tenor: string;
  marginsBidAskOrTiering: MarginsBidAskOrTieringRejected | MarginsBidAsk | Tiering;
  isLastRowOfType: boolean;

  state: RowState;
  uiRowId: number;
};

type MarginsBidAskOrTieringRejected = {
  isRejected: true;
};

type MarginsBidAsk = {
  isRejected: false;
  type: 'margin';
  marginBidValue: string;
  marginAskValue: string;
  marginUnit: string;
};

type Tiering = {
  isRejected: false;
  type: 'tiering';
  tiering: string;
};

// Ok, it is not the most performing code
// but the common part is defined only one

type toGridRowReturnType = (row: FxCashProfileRow, rowIndex: number, allRows: FxCashProfileRow[]) => GridCashProfileRow;

export const toGridRow = (
  productKey: FxCashProductKey,
  gridDataType: 'margin-grid-data-type' | 'tiering-grid-data-type',
  editedProfile: FxCashProfile,
  isProductProfileInherited: boolean,
  inheritableClient: InheritableProfileClient | undefined,
): toGridRowReturnType => {
  if (gridDataType === 'margin-grid-data-type') {
    return (row: FxCashProfileRow, rowIndex: number, allRows: FxCashProfileRow[]): GridCashProfileRow => {
      const isLastRow = rowIndex === allRows.length - 1;

      return {
        ...toCommonGridRowPart(
          productKey,
          gridDataType,
          editedProfile,
          isProductProfileInherited,
          inheritableClient,
          row,
          isLastRow,
          rowIndex,
        ),
        marginsBidAskOrTiering: toMarginGridRowPart(
          row.columns as FxCashProfileMarginColumns | FxCashProfileRejectedColumns,
        ),
      };
    };
  } else {
    return (row: FxCashProfileRow, rowIndex: number, allRows: FxCashProfileRow[]): GridCashProfileRow => {
      const isLastRow = rowIndex === allRows.length - 1;

      return {
        ...toCommonGridRowPart(
          productKey,
          gridDataType,
          editedProfile,
          isProductProfileInherited,
          inheritableClient,
          row,
          isLastRow,
          rowIndex,
        ),
        marginsBidAskOrTiering: toTieringGridRowPart(
          row.columns as FxCashProfileTieringColumns | FxCashProfileRejectedColumns,
        ),
      };
    };
  }
};

const toCommonGridRowPart = (
  productKey: FxCashProductKey,
  gridDataType: 'margin-grid-data-type' | 'tiering-grid-data-type',
  data: FxCashProfile,
  isProductProfileInherited: boolean,
  inheritableClient: InheritableProfileClient | undefined,
  row: FxCashProfileRow,
  isLastRowOfType: boolean,
  rowIndex: number,
): Omit<GridCashProfileRow, 'marginsBidAskOrTiering'> => ({
  instrument: `${productKey}/${gridDataType}`,

  instrumentData: {
    productKey,
    gridDataType,
    isInherited: isProductProfileInherited,
    profileName: data.profileName,
    profileId: data.profileId,
    profileTypeKey: data.profileTypeKey,
    applyOnInvertedPairDimension: row.columns.applyOnInvertedPairDimension,
    inheritableClient: isDefined(inheritableClient)
      ? {
          bdrId: inheritableClient.clientBdrId,
          bdrLevel: inheritableClient.clientBdrLevel,
          name: inheritableClient.clientLongName,
        }
      : undefined,
  },

  venue: row.columns.mediaDimension,
  clientVenueLogin: row.columns.extClientLoginDimension,
  currency: row.columns.currencyPairDimension,
  onshoreOffshore: row.columns.onshoreOffshoreDimension,

  amount:
    row.columns.amountStartDimension === '*' && row.columns.amountEndDimension === '*'
      ? '*'
      : `]${row.columns.amountStartDimension}-${row.columns.amountEndDimension}]`,

  tenor:
    row.columns.tenorStartDimension === '*' && row.columns.tenorEndDimension === '*'
      ? '*'
      : `]${row.columns.tenorStartDimension}-${row.columns.tenorEndDimension}]`,

  // if there is a row state take it (it's the one coming from the edition)
  // otherwise initiate the row as untouched (since the user is viewing unedited data)
  // same for uiRowId
  state: row.state || 'untouched',
  uiRowId: row.uiRowId || rowIndex,
  isLastRowOfType,
});

const toTieringGridRowPart = (
  columns: FxCashProfileTieringColumns | FxCashProfileRejectedColumns,
): GridCashProfileRow['marginsBidAskOrTiering'] =>
  isFxCashProfileRejectedColumns(columns)
    ? {
        isRejected: true,
      }
    : {
        isRejected: false,
        type: 'tiering',
        tiering: columns.tieringResult,
      };

const toMarginGridRowPart = (
  columns: FxCashProfileMarginColumns | FxCashProfileRejectedColumns,
): GridCashProfileRow['marginsBidAskOrTiering'] =>
  isFxCashProfileRejectedColumns(columns)
    ? {
        isRejected: true,
      }
    : {
        isRejected: false,
        type: 'margin',
        marginBidValue: columns.marginBidValue,
        marginAskValue: columns.marginAskValue,
        marginUnit: columns.marginUnit,
      };

export const isRowVisible = (rowFilters: RowFilters) => (row: GridCashProfileRow) => {
  if (!isCashProfileVisible(row.instrumentData.productKey, row.instrumentData.gridDataType, rowFilters)) {
    return false;
  }

  // WARNING: when the user checks margins, trader tiering and rejection rules
  // it is equal to the case when he unchecks all (all filters are active / visible)
  const isRejectedRowVisible =
    rowFilters.isRejectionRulesVisible && (!rowFilters.isMarginsVisible || !rowFilters.isTraderTieringVisible)
      ? row.marginsBidAskOrTiering.isRejected
      : true;

  if (rowFilters.query === '') {
    return isRejectedRowVisible;
  }

  const lowerCaseQuery = rowFilters.query.toLowerCase();

  // TODO: how to do better ?
  // TODO: all = *
  return (
    (hasQuery(row.instrumentData.gridDataType, lowerCaseQuery) ||
      hasQuery(row.instrumentData.productKey, lowerCaseQuery) ||
      (row.instrumentData.isInherited && hasQuery('inherited', lowerCaseQuery)) || // TODO: translation
      hasQuery(row.instrumentData.profileName, lowerCaseQuery) ||
      hasQuery(row.venue, lowerCaseQuery) ||
      hasQuery(row.clientVenueLogin, lowerCaseQuery) ||
      hasQuery(row.currency, lowerCaseQuery) ||
      hasQuery(row.onshoreOffshore, lowerCaseQuery) ||
      hasQuery(row.amount, lowerCaseQuery) ||
      hasQuery(row.tenor, lowerCaseQuery) ||
      (row.marginsBidAskOrTiering.isRejected === false &&
        ((row.marginsBidAskOrTiering.type === 'margin' &&
          (hasQuery(row.marginsBidAskOrTiering.marginBidValue, lowerCaseQuery) ||
            hasQuery(row.marginsBidAskOrTiering.marginAskValue, lowerCaseQuery) ||
            hasQuery(row.marginsBidAskOrTiering.marginUnit, lowerCaseQuery))) ||
          (row.marginsBidAskOrTiering.type === 'tiering' &&
            hasQuery(row.marginsBidAskOrTiering.tiering, lowerCaseQuery)))) ||
      (row.marginsBidAskOrTiering.isRejected && hasQuery('rejected', lowerCaseQuery))) && // TODO: translation
    isRejectedRowVisible
  );
};

const isCashProfileVisible = (productKey: FxCashProductKey, gridDataType: FxCashGridDataType, rowFilters: RowFilters) =>
  ((productKey === 'spot-product' && rowFilters.isSpotVisible) ||
    (productKey === 'ndf-product' && rowFilters.isNDFVisible) ||
    (productKey === 'swap-product' && rowFilters.isSwapVisible)) &&
  ((gridDataType === 'margin-grid-data-type' && rowFilters.isMarginsVisible) ||
    (gridDataType === 'tiering-grid-data-type' && rowFilters.isTraderTieringVisible) ||
    (rowFilters.isRejectionRulesVisible && !rowFilters.isMarginsVisible && !rowFilters.isTraderTieringVisible));

const hasQuery = (value: string, lowerCaseQuery: string) => value.toLowerCase().includes(lowerCaseQuery);
