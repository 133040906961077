import { marginApi } from 'services/marginProfiles/getMarginProfiles';

import { clientsApi } from './clientsApi';
import { clientsAdapter, getClientsApi } from './getClients';
import type { LagClient, UpdateClientPayload } from './models';

export const updateClientsApi = clientsApi.injectEndpoints({
  endpoints: (builder) => ({
    updateClients: builder.mutation<LagClient, UpdateClientPayload[]>({
      query: (patch) => ({
        url: '',
        method: 'PATCH',
        body: patch,
      }),
      transformResponse: (clients: LagClient[]) => {
        const [client] = clients;
        return client;
      },
      invalidatesTags: () => ['Clients'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedClient } = await queryFulfilled;
          dispatch(
            getClientsApi.util.updateQueryData('getClients', undefined, (draft) => {
              clientsAdapter.updateOne(draft, {
                id: updatedClient.id,
                changes: updatedClient,
              });
            }),
          );
          dispatch(marginApi.util.invalidateTags(['marginProfiles']));
        } catch {
          console.warn('error occured when edit new client');
        }
      },
    }),
  }),
});

export const { useUpdateClientsMutation } = updateClientsApi;
