import { type ChangeEventHandler, type FormEvent, useState } from "react";
import { useIntl } from "react-intl";

interface InternalAccountPickerProps {
  onSubmit: (bdrId: string) => void;
}

export function FxInternalAccountPicker({ onSubmit }: InternalAccountPickerProps) {
  const { formatMessage } = useIntl();

  const [ bdrId, setBdrId ] = useState("");

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    const regex = /^[0-9]*$/;
    const isDigitOnly = regex.test(value);

    if (!isDigitOnly) {
      return;
    }

    setBdrId(value);
  };

  const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(bdrId);
  }

  const isResetVisible = bdrId.length > 0;

  const reset = () => {
    setBdrId("")
  }

  return (
    <form className="input-group input-group-lg" onSubmit={onSubmitHandler}>
      <div className="input-icon-start">
        <i className="icon text-secondary icon-md">business</i>
      </div>

      <div className="d-flex form-control align-items-center pe-0 rounded-0 me-2">
        <input
          type="text"
          className="form-control-plaintext ps-2"
          placeholder={formatMessage({ id: "clientsMargins.searchBar.placeholder.internal" })}
          onChange={onChangeHandler}
          value={bdrId}
        />

        {
          isResetVisible && (
            <div className="d-flex flex-nowrap align-items-center">
              <button type="button" className="btn btn-link btn-lg" onClick={reset}>
                <i className="icon icon-lg">cancel</i>
              </button>
            </div>
          )
        }
      </div>

      <div className="input-group-append">
        <button
          className="btn btn-primary btn-lg"
          type="submit"
        >
          {formatMessage({ id: "button.clientSearchBar.search" })}
        </button>
      </div>
    </form>
  );
}
