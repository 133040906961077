import { useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {Select} from "../../Form/Select";

const changeReasons = [
  'Client feedback',
  'Price being not competitive enough vs other banks',
  'Change of guarantees',
  'Change of status',
  'Business review',
] as const;

const options: [string, string][] = changeReasons.map((x) => [x, x]);

export const SelectChangeReason = (): JSX.Element => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'reasonForChange',
    rules: {
      required: true,
    },
  });

  return (
    <div className="form-group" data-e2e="select-margin-reason">
      <label className="fw-normal mb-2 text-primary">
        <FormattedMessage id="changeReasonModal.selectReason" />
      </label>

      <Select
        {...field}
        className={error && 'is-warning'}
        options={options}
      />
    </div>
  );
};
