import { useMemo } from 'react';
import type { Dictionary } from '@reduxjs/toolkit';
import type { LagClient } from 'services/clients/models';
import type { MarginProfile, SalesMarginProfile } from 'services/marginProfiles';
import type {ClientDiff, ClientsDiffs} from "../../../../../store/clientEdition/clientEditionSlice";

type ConstantMargin = { type: 'pcru' | 'technical'; value: number };
export type MarginsTableRow = {
  client: LagClient;
  marginProfile: MarginProfile | ConstantMargin;
  clientDiff: ClientDiff;
};

// We need something to display while creating a new custom margin
const EMPTY_CUSTOM_MARGIN: SalesMarginProfile = {
  id: -1,
  type: 'Sales',
  name: 'Custom',
  isPreset: false,
  fixedMargins: [],
};

type UseRowDataProps = {
  clients: readonly LagClient[];
  marginProfiles: Dictionary<MarginProfile>;
  clientsDiffs: ClientsDiffs;
};

const useRowData = ({
  clients,
  clientsDiffs,
  marginProfiles,
}: UseRowDataProps): MarginsTableRow[] =>
  useMemo(
    () =>
      clients.flatMap((client) => [
        {
          client,
          marginProfile:
            marginProfiles[
              clientsDiffs[client.id]?.salesMarginProfileId ?? client.salesMarginProfileId
            ] ?? EMPTY_CUSTOM_MARGIN,
          clientDiff: clientsDiffs[client.id],
        },
        {
          client,
          marginProfile:
            marginProfiles[
              clientsDiffs[client.id]?.devLMarginProfileId ?? client.devLMarginProfileId
            ] ?? EMPTY_CUSTOM_MARGIN,
          clientDiff: clientsDiffs[client.id],
        },
        {
          client,
          marginProfile: {
            type: 'pcru',
            value: clientsDiffs[client.id]?.pcruMargin ?? client.pcruMargin,
          },
          clientDiff: clientsDiffs[client.id],
        },
      ]),
    [clients, clientsDiffs, marginProfiles],
  );

export default useRowData;
