import { FxClientBadge } from '@components/fx/badges/FxClientBadge';
import type { FxClientModel } from '@services/fx/model/client';
import { FormattedMessage } from 'react-intl';

import { FX_PRODUCTS_ROUTE } from '@pages/fx/constants';
import type { FxPerimeterKey } from '@services/fx/model/perimiters';
import { isDefined } from '@sgme/fp';
import Tooltip from 'components/Tooltip';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { UserAccessIcon } from './UserAccessIcon';

type AscendantProps = {
  client: FxClientModel | undefined;
  isAuthorized: boolean;
  perimeterKey: FxPerimeterKey;
};

export function Ascendant({ client, isAuthorized, perimeterKey }: AscendantProps) {
  const navigate = useNavigate();

  if (client === undefined || !client.ascendants || client?.ascendants.length === 0) {
    return null;
  }

  const { clientBdrId, clientBdrLevel, clientLongName, userAccess, profileSummaryFlag, clientCountryCode } =
    client.ascendants[client?.ascendants.length - 1];

  const goToPerimetersPage = useCallback(() => {
    if (
      isDefined(client) &&
      isDefined(clientBdrId) &&
      isDefined(clientBdrLevel) &&
      client.userAccess !== 'unauthorized'
    ) {
      navigate(
        generatePath(FX_PRODUCTS_ROUTE, {
          clientBdrId: String(clientBdrId),
          clientBdrLevel: clientBdrLevel?.toUpperCase(),
          perimeterKey,
        }),
      );
    }
  }, [clientBdrId, clientBdrLevel, client, navigate, perimeterKey]);

  return (
    <div className="mt-5 mb-5" data-e2e="ascendant">
      <h4 data-e2e="client-ascendant-header">
        <FormattedMessage id="clients.ascendant.tile" />
        <Tooltip labelId="ascendant.tooltip">
          <span className="icon icon-sm text-secondary ms-1 mb-3">info_outline</span>
        </Tooltip>
      </h4>

      <ul className={`${isAuthorized ? 'card-raising' : 'card'} list-group`}>
        <li
          className={`list-group-item d-flex justify-content-between align-items-center border-bottom py-3 ${
            isAuthorized ? 'btn btn-flat-primary' : ''
          }`}
          onClick={goToPerimetersPage}
        >
          <div className="d-flex align-items-center fs-16" style={{ paddingLeft: 24 }}>
            <span className="fw-semi-bold">{clientLongName}</span>

            <span className="ms-1 text-secondary">
              <FormattedMessage
                id="country.level"
                values={{ accountLevel: clientBdrLevel, country: clientCountryCode }}
              />
            </span>

            <span className="ms-1 text-secondary">
              <FormattedMessage id="client.bdr.id" values={{ clientBdrId: clientBdrId }} />
            </span>
          </div>

          <span className="d-flex align-items-center">
            {profileSummaryFlag !== '' && (
              <FxClientBadge dataE2e="profile-flag-badge" profileSummaryFlag={profileSummaryFlag} />
            )}

            <UserAccessIcon userAccess={userAccess} />

            {isAuthorized && <i className="icon icon-md text-secondary ms-3">arrow_forward</i>}
          </span>
        </li>
      </ul>
    </div>
  );
}
