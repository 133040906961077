import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { maximumUpperMaturityRange, minimumLowerMaturityRange } from 'utils/maturityRange';
import { clamp } from 'utils/number';

export interface FiltersState {
  clientName: string;
  activeClientsOnly: boolean;
  lowerMaturityRange: number;
  upperMaturityRange: number;
  customizedMarginsOnly: boolean;
  salesProfiles: number[];
  devLProfiles: number[];
  pcruMargins: number[];
}

const toggleFilterReducer =
  (scope: keyof Pick<FiltersState, 'salesProfiles' | 'devLProfiles' | 'pcruMargins'>) =>
  (state: FiltersState, { payload }: PayloadAction<number>) => {
    const index = state[scope].findIndex((x) => x === payload);
    if (index === -1) {
      state[scope].push(payload);
    } else {
      state[scope].splice(index, 1);
    }
  };

export const initialState: FiltersState = {
  clientName: '',
  activeClientsOnly: true,
  lowerMaturityRange: minimumLowerMaturityRange,
  upperMaturityRange: maximumUpperMaturityRange,
  customizedMarginsOnly: false,
  salesProfiles: [],
  devLProfiles: [],
  pcruMargins: [],
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    clientNameFilterChanged: (state, { payload }: PayloadAction<string>) => {
      state.clientName = payload;
    },
    activeClientsOnlyFilterToggled: (state) => {
      state.activeClientsOnly = !state.activeClientsOnly;
    },
    lowerMaturityRangeChanged: (state, { payload }: PayloadAction<number>) => {
      state.lowerMaturityRange = clamp(
        payload,
        minimumLowerMaturityRange,
        maximumUpperMaturityRange,
      );
      state.upperMaturityRange = clamp(
        state.upperMaturityRange,
        state.lowerMaturityRange,
        maximumUpperMaturityRange,
      );
    },
    upperMaturityRangeChanged: (state, { payload }: PayloadAction<number>) => {
      state.upperMaturityRange = clamp(
        payload,
        minimumLowerMaturityRange,
        maximumUpperMaturityRange,
      );
      state.lowerMaturityRange = clamp(
        state.lowerMaturityRange,
        minimumLowerMaturityRange,
        state.upperMaturityRange,
      );
    },
    customizedMarginsOnlyFilterToggled: (state) => {
      state.customizedMarginsOnly = !state.customizedMarginsOnly;
    },
    salesProfileFilterToggled: toggleFilterReducer('salesProfiles'),
    devLProfileFilterToggled: toggleFilterReducer('devLProfiles'),
    pcruMarginFilterToggled: toggleFilterReducer('pcruMargins'),
    filtersCleared: (state) => {
      state.lowerMaturityRange = minimumLowerMaturityRange;
      state.upperMaturityRange = maximumUpperMaturityRange;
      state.customizedMarginsOnly = false;
      state.salesProfiles = [];
      state.devLProfiles = [];
      state.pcruMargins = [];
    },
  },
});
