import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import type { GetRowIdParams } from '@ag-grid-community/core';
import type { ColGroupDef, GridApi, GridReadyEvent, ProcessDataFromClipboardParams } from "@ag-grid-community/core";
import { useGetMarginProfilesQuery } from 'services/marginProfiles';

import { LoadingMessage } from 'components/LoadingMessage';

import useColumnDef from './useColumnDef';
import useRowData from './useRowData';
import { isEditingMarginProfiles } from "../../../../../store/marginProfilesEdition/marginProfilesEditionSelectors";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { useIntl } from "react-intl";
import { lagDefaultColDef, lagGridOptions } from "../../../contants";

const MarginsPresetsTable = () => {
  const { formatMessage } = useIntl();

  const isEditing = useSelector(isEditingMarginProfiles);
  const { isLoading: isMarginProfilesLoading } = useGetMarginProfilesQuery();

  const columnDefs = useColumnDef();
  const rows = useRowData();

  const [containerHeight, setContainerHeight] = useState<string | undefined>(undefined);

  const getRowId = useCallback(
    ({ data }: GetRowIdParams) => `${data.name}-${data.marginsType}`,
    [],
  );

  const processDataFromClipboard = useCallback(
    ({ data }: ProcessDataFromClipboardParams) =>
      data.filter((row) => !(row.length === 1 && !row[0])),
    [],
  );

  if (isMarginProfilesLoading) {
    return <LoadingMessage />;
  }

  const maxRowDisplayed = 40;

  function onGridReady(event: GridReadyEvent) {

    const domLayout = rows.length > maxRowDisplayed ? 'normal' : 'autoHeight';
    event.api.setDomLayout(domLayout);

    setContainerHeight(calculateContainerHeight(rows.length, maxRowDisplayed, event.api))
  }

  return (
    <div
      className={`d-flex ag-theme-sg-bootstrap ag-theme-lag`}
      style={{ height: containerHeight }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        gridOptions={lagGridOptions}
        modules={AG_GRID_MODULES}
        groupRemoveSingleChildren={true}
        groupDefaultExpanded={2}
        context={{ formatMessage, isEditing }}
        defaultColDef={lagDefaultColDef}
        columnDefs={columnDefs}
        rowData={rows}
        processDataFromClipboard={processDataFromClipboard}
        getRowId={getRowId}
        className="w-100"
      />
    </div>
  );
};

export default MarginsPresetsTable;

const AG_GRID_MODULES = [ClientSideRowModelModule, ClipboardModule, RowGroupingModule];

const calculateContainerHeight = (rowCount: number, maxRowDisplayed: number, api: GridApi) => {

  if (rowCount <= maxRowDisplayed) {
    return undefined;
  }

  const { headerHeight = 32, rowHeight = 32 } = api.getSizesForCurrentTheme();

  const headerHaveChildren = (api.getColumnDefs() ?? []).find(
    (columnDef) => !!(columnDef as ColGroupDef).children,
  );
  const styleHeaderHeight = `(${headerHaveChildren ? '2' : '1'} * ${headerHeight}px)`;

  const styleRowsHeight = `(${Math.min(maxRowDisplayed, Math.max(rowCount, 1))} * ${rowHeight}px)`;

  return `calc(${styleHeaderHeight} + ${styleRowsHeight} + 0.5em)`

};
