
import type { ClientDuplicationForm } from './clientDuplicationSlice';
import type {AppState} from "../store";

export const getClientDuplicationState = (state: AppState) => state.clientDuplication;

export const getClientDuplicationValue =
  <Key extends keyof ClientDuplicationForm>(key: Key) =>
  (state: AppState) =>
    state.clientDuplication.values[key];
