import type { HTMLAttributes } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isDefined, isNotDefined } from '@sgme/fp';
import { useFullTextSearch } from 'hooks/useFullTextSearch';
import { HelpCenter } from 'components/HelpCenter';
import CustomProfileType from './CustomProfileType';
import DefaultProfileType from './DefaultProfileType';
import type { InternalProfileTypeKey, ProfileId } from '@services/fx/model/models';
import { type FxCashGridDataType, TIERING_GRID_DATA_TYPE } from '@services/fx/model/cash';
import type { FxProductProfiles } from '@services/fx/model/inheritance';
import type { EntityState } from '@reduxjs/toolkit';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { useSearchParams } from 'react-router-dom';
import { useGetFxInheritableProfileReference } from '@services/fx/getFXClientsInheritance';
import { getEditingPermissionsForCurrentProduct } from '@features/authorization/getEditingPermissionsForCurrentProduct';

interface ProfilesSelectProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  profileTypeKey: InternalProfileTypeKey;
  profilesByType: EntityState<FxProductProfiles> | undefined;
}

export const ProfilesSelect = (props: ProfilesSelectProps): JSX.Element => {
  const { className, profileTypeKey, profilesByType } = props;

  const { formatMessage } = useIntl();
  const [_searchParams, setSearchParams] = useSearchParams();

  const {
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
    profileId: editedProfileId,
    isInherited,
  } = useFxSelectAndEditProfileUrlParams();

  const profileReference = useGetFxInheritableProfileReference({
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
  });

  // if the client has no upper commercial level
  // merge the default profiles into the standard profiles
  // (default profiles are not displayed alone)
  const allProfiles =
    isDefined(profilesByType) &&
    isNotDefined(profileReference?.inheritableProfile?.client) &&
    profileTypeKey === 'standard-profile-type'
      ? mergeStandardAndDefautlProvifes(
          profilesByType.entities['standard-profile-type'],
          profilesByType.entities['default-profile-type'],
        )
      : profilesByType?.entities[profileTypeKey]?.profiles ?? [];

  const [availableProfilesOfTypeFiltered, onSearch] = useFullTextSearch(allProfiles, ({ profileName }) => profileName);

  const onChange = (selectedProfileId: ProfileId) => {
    const selectedProfile = availableProfilesOfTypeFiltered.find((profile) => profile.profileId === selectedProfileId);

    if (isNotDefined(selectedProfile)) {
      return;
    }

    setSearchParams((prev) => ({
      ...prev,
      profileId: selectedProfileId,
    }));
  };

  if (isNotDefined(allProfiles) || !(profileTypeKey in DESIGN)) {
    return <></>;
  }

  const displaySearch = allProfiles.length > 1;

  const { color, headerLocaleKey, descriptionLocaleKey, height, topicId } = DESIGN[profileTypeKey];

  const authorizedPermissions = getEditingPermissionsForCurrentProduct(gridDataType, productKey, perimeterKey);

  return (
    <div className={className} data-e2e={`fx-select-${profileTypeKey}`}>
      <h5 className={`d-flex align-items-center text-${color}`}>
        <FormattedMessage id={headerLocaleKey} />
        <HelpCenter className={`text-${color} ms-1`} withHandler topic={topicId} />
      </h5>

      <p className="fs-12 text-secondary fw-medium mb-2">
        <FormattedMessage id={descriptionLocaleKey(gridDataType)} />
      </p>

      {displaySearch && (
        <div className="input-group mb-1">
          <div className="input-icon-start">
            <em className="icon">search</em>
          </div>

          <input
            type="search"
            className="form-control"
            placeholder={formatMessage({ id: 'common.search' })}
            onChange={onSearch}
            data-e2e={`fx-search-${profileTypeKey}`}
          />
        </div>
      )}

      {/* Specific layout for custom-profil-type */}

      {profileTypeKey === 'custom-profile-type' ? (
        <CustomProfileType
          authorizedPermissions={authorizedPermissions}
          radioGroup={RADIO_GROUP_NAME}
          editedProfileId={editedProfileId}
          onChange={onChange}
          height={height}
          availableProfilesOfTypeFiltered={availableProfilesOfTypeFiltered}
        />
      ) : (
        <DefaultProfileType
          authorizedPermissions={authorizedPermissions}
          radioGroup={RADIO_GROUP_NAME}
          editedProfileId={editedProfileId}
          isEditedProfileInherited={isInherited}
          onChange={onChange}
          height={height}
          profileTypeKey={profileTypeKey}
          availableProfilesOfTypeFiltered={availableProfilesOfTypeFiltered}
          perimeterKey={perimeterKey}
        />
      )}
    </div>
  );
};

const RADIO_GROUP_NAME = 'ProfilesSelect';

interface DesignOfProfile {
  color: string;
  headerLocaleKey: string;
  descriptionLocaleKey: (gridDataType: FxCashGridDataType | undefined) => string;
  height: number;
  topicId: string;
}

const DESIGN: Record<InternalProfileTypeKey, DesignOfProfile> = {
  'standard-profile-type': {
    color: 'black',
    headerLocaleKey: 'standard.presets',
    descriptionLocaleKey: (gridDataType: FxCashGridDataType | undefined) =>
      gridDataType === TIERING_GRID_DATA_TYPE
        ? `standard.presets.tiering.description`
        : `standard.presets.margin.description`,
    height: 13,
    topicId: 'top.y2tc2m4q',
  },
  'client-pool-profile-type': {
    color: 'royal-blue',
    headerLocaleKey: 'clients.pool.presets',
    descriptionLocaleKey: (gridDataType: FxCashGridDataType | undefined) =>
      gridDataType === TIERING_GRID_DATA_TYPE
        ? `clients.pool.presets.tiering.description`
        : `clients.pool.presets.margin.description`,
    height: 13,
    topicId: 'top.u2jtzmhl',
  },
  'custom-profile-type': {
    color: 'apricot',
    headerLocaleKey: 'custom.presets',
    descriptionLocaleKey: (gridDataType: FxCashGridDataType | undefined) =>
      gridDataType === TIERING_GRID_DATA_TYPE
        ? `custom.presets.tiering.description`
        : `custom.presets.margin.description`,
    height: 4,
    topicId: 'top.m06eh1ky',
  },
};

const mergeStandardAndDefautlProvifes = (
  standardProfiles: FxProductProfiles | undefined,
  defaultProfiles: FxProductProfiles | undefined,
) => {
  if (isNotDefined(defaultProfiles) || isNotDefined(standardProfiles)) {
    return [];
  }

  return [
    ...defaultProfiles.profiles.map((profiles) => ({ ...profiles, defaultProfile: true })),
    ...standardProfiles.profiles,
  ];
};
