import { FormattedMessage } from 'react-intl';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FxProfileBadge } from './badges/FxProfileBadge';
import type { Item } from '../SearchSelect';
import { SearchSelect } from '../SearchSelect';
import { isDefined } from '@sgme/fp';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { type FxProfileClientsQueryParams, useGetFXClientsUsingProfileQuery } from '@services/fx/getFXClientsUsingProfile';
import type { FxProfileMarginGrid } from '@services/fx/model/profile-margin-grid';

import type { FxProfileColumns } from '@services/fx/model/perimiters';

interface ClientsListProps {
  profile?: FxProfileMarginGrid<FxProfileColumns>;
}

export const FxClientsList = ({ profile }: ClientsListProps): JSX.Element => {
  const { perimeterKey, productKey } = useFxUrlParams();

  const params: FxProfileClientsQueryParams | typeof skipToken = isDefined(profile?.profileId)
    ? {
        profileId: profile!.profileId,
        perimeterKey: perimeterKey!,
        productKey: productKey!,
      }
    : skipToken;

  const { clientItems } = useGetFXClientsUsingProfileQuery(params, {
    selectFromResult: ({ data, isError, isLoading }) => {
      if (data?.entities === undefined) {
        return { clientItems: [] as Item[] };
      }

      return {
        isError,
        isLoading,
        clientItems: Object.values(data.entities).map((client) => ({
          id: client?.clientBdrId,
          level: client?.clientBdrLevel,
          value: client?.clientLongName,
        })) as Item[],
      };
    },
  });

  return (
    <>
      {profile?.clientsCount === 0 ? (
        <>
          <p className="m-0">
            <FormattedMessage id="no.client.assigned.to.margins.preset" />
          </p>
          <p className="m-0">
            <FormattedMessage id="assign.to.margins.preset" />
          </p>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-start mb-16px" style={{ flexWrap: 'wrap' }}>
            <span className="text-nowrap mb-4px">
              <FormattedMessage id="client.assigned.to.margins.preset" />:
            </span>

            <FxProfileBadge profileName={profile?.profileName} profileTypeKey={profile?.profileTypeKey} size="sm" />
          </div>
          <SearchSelect items={clientItems} noItemLocaleKey="no.client.found" />
        </>
      )}
    </>
  );
};
