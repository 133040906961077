import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';
import { isDefinedAndNonEmpty } from '@sgme/fp';

import { FormMessageStatus } from 'components/Form/FormMessageStatus';

import { MarginInput } from './MarginInput';
import {Checkbox} from "../Form/Checkbox";

interface MarginLiquidityProviderProps {
  marginUnit: string;
  showLabel?: boolean;
}

interface FormValues {
  marginsAreDifferent: boolean;
  liquidityProviderDimension: {
    External: string;
    Internal: string;
  };
}

export const MarginLiquidityProvider = ({
  marginUnit,
  showLabel = true
}: MarginLiquidityProviderProps): JSX.Element => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();

  const [marginsAreDifferent, externalMarginValue] = watch([
    'marginsAreDifferent',
    'liquidityProviderDimension.External',
  ]);

  const inError = Boolean(
    (marginsAreDifferent && errors.liquidityProviderDimension?.Internal)
    || errors.liquidityProviderDimension?.External
  );

  useEffect(() => {
    if (!marginsAreDifferent) {
      setValue('liquidityProviderDimension.Internal', externalMarginValue, {
        shouldValidate: isDefinedAndNonEmpty(externalMarginValue), // not validate on load, only when external are valued
      });
    }
  }, [externalMarginValue, marginsAreDifferent, setValue]);

  return (
    <>
      <FormGroup className="form-group" data-e2e="margin-by-liquidity">
        {showLabel &&
          <Label className="text-primary fw-medium mb-2 fs-16">
            <FormattedMessage id="liquidityProviderDimension" />
          </Label>
        }
        <div className="d-flex align-items-center gap-2">
          <MarginInput
            name="liquidityProviderDimension.External"
            className="col-md-6 mb-1"
            unit={marginUnit}
            applyMask={marginUnit === 'mln'}
            hideError
          />

          <MarginInput
            name="liquidityProviderDimension.Internal"
            className="col-md-6 mb-1"
            unit={marginUnit}
            applyMask={marginUnit === 'mln'}
            disabled={!marginsAreDifferent}
            hideError
          />
        </div>

        <FormMessageStatus
          name="marginValue"
          errorType="pattern"
          icon="error"
          status="danger"
          show={inError}
        />
      </FormGroup>

      <Checkbox name="marginsAreDifferent" labelLocaleKey="fx.edit.external.internal.different" />
    </>
  );
};
