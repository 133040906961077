export const partition = <T>(
  arr: readonly T[],
  predicate: (item: T) => boolean,
): [pass: readonly T[], fail: readonly T[]] =>
  arr.reduce(
    (partitioned, item) => {
      partitioned[predicate(item) ? 0 : 1].push(item);
      return partitioned;
    },
    [[], []] as [pass: T[], fail: T[]],
  );
