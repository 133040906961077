import { useGetStatusMonitoringQuery } from '../statusApi';

export function StatusMonitoringSection(): JSX.Element {
  const { isSuccess, isError, isLoading } = useGetStatusMonitoringQuery();

  return (
    <section data-e2e="status-monitoring">
      <h3>Status Monitoring</h3>
      {isSuccess && 'OK'}
      {isError && 'KO'}
      {isLoading && 'PENDING'}
    </section>
  );
}
