import { connect } from 'react-redux';
import { selectSalesProfileIds } from 'services/marginProfiles';
import type { MapStateToPropsHOF } from 'typings/redux-connect';
import {selectors, type Selectors} from "../../../../../../store/selectors";
import type {AppState} from "../../../../../../store/store";

export type ProfileFiltersConnectOwnProps = Record<string, never>;

export interface ProfileFiltersConnectStateProps {
  ids: number[];
  isVisible: boolean;
}

// you can whitelist selectors after implementation
export type ProfileFiltersConnectSelectors = Selectors;
/*
export type ProfileFiltersConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type ProfileFiltersConnectSelectors = Pick<Selectors, ProfileFiltersConnectSelectorsKeys>;
*/

export const mapStateToPropsProfileFilters: MapStateToPropsHOF<
  ProfileFiltersConnectStateProps,
  ProfileFiltersConnectOwnProps,
  AppState,
  ProfileFiltersConnectSelectors
> = (sl) => (state) => ({
  ids: selectSalesProfileIds(state),
  isVisible: !sl.getFilters(state).customizedMarginsOnly,
});

export const connectSalesFilters = connect(mapStateToPropsProfileFilters(selectors));
