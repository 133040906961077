import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { isNotDefined } from '@sgme/fp';
import { FX_PRODUCTS_ROUTE } from '../../fx/constants';
import { CancelValidationHandler } from '@components/CancelValidationHandler';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { useFirstFxClientFor } from '@services/fx/getFXClient';
import { useGetFxProductChanges } from '@hooks/useGetFxProductChange';

interface CrumbProps {
  active?: boolean;
}

export const FxProductsCrumb = ({ active = false }: CrumbProps): JSX.Element => {
  const { clientBdrId, clientBdrLevel, perimeterKey } = useFxUrlParams();
  const navigate = useNavigate();

  const { haveRowsChanged, hasRowInEdition, hasUserChangedSelectedProfile } = useGetFxProductChanges();

  const goToProductPage = useCallback(() => {
    if (isNotDefined(clientBdrId) || isNotDefined(clientBdrLevel) || isNotDefined(perimeterKey)) {
      return;
    }

    // clientBdrLevel has to be upper case
    navigate(generatePath(FX_PRODUCTS_ROUTE, { clientBdrId: String(clientBdrId), clientBdrLevel, perimeterKey }));
  }, [clientBdrId, clientBdrLevel, navigate, perimeterKey]);

  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);

  const { clientLongName, clientCountryCode } = client ?? {};

  if (active) {
    return (
      <>
        <span>{clientLongName}</span>
        &nbsp;{clientBdrLevel && <span className="">{`(${clientBdrLevel}) ${clientCountryCode}`}</span>}
      </>
    );
  }

  return (
    <CancelValidationHandler hasUserChanges={haveRowsChanged || hasRowInEdition || hasUserChangedSelectedProfile}>
      <a
        onClick={goToProductPage}
        style={{ cursor: 'pointer' }}
        data-e2e="fx-products-menu-item"
        className="text-secondary text-regular"
      >
        <span>{clientLongName}</span>
        {clientBdrLevel && <span className="">{` (${clientBdrLevel}) ${clientCountryCode}`}</span>}
      </a>
    </CancelValidationHandler>
  );
};
