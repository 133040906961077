import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { fxApi } from './fxApi';
import type { FxPayload, FxProfileChangeReasonType } from './model/models';
import { CHANGE_REASONS_TYPE_KEYS } from './model/models';

const changeReasonsAdapter = createEntityAdapter<FxProfileChangeReasonType>({
  selectId: ({ reasonTypeKey }) => reasonTypeKey,
  sortComparer: (a, b) =>
    CHANGE_REASONS_TYPE_KEYS.indexOf(a.reasonTypeKey) -
    CHANGE_REASONS_TYPE_KEYS.indexOf(b.reasonTypeKey),
});

export const changeReasonApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFxProfileChangeReasons: builder.query<EntityState<FxProfileChangeReasonType>, void>({
      query: () => ({
        url: `profile-change-reasons`
      }),

      transformResponse: (response: FxPayload<FxProfileChangeReasonType[]>) => {
        return changeReasonsAdapter.addMany(changeReasonsAdapter.getInitialState(), response.data);
      },
    }),
  }),
});

export const { useGetFxProfileChangeReasonsQuery } = changeReasonApi;
