import { App } from '@App';
// import { applyPolyfills, defineCustomElements } from '@sg-bootstrap/components/dist/loader';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '@sg-bootstrap/core/dist/css/sg-bootstrap-standard.css';
import '@sg-bootstrap/icons/dist/index.css';
import '@sg-bootstrap/ag-grid-theme/dist/29-standard.css';
import { setAgGridLicense } from '@utils/ag-grid';

import './assets/ag-theme-fx.css';
import './assets/ag-theme-lag.css';
import './assets/ag-common.css';
import { SgwtContainer } from '@components/SgwtContainer';
import { getAppConfig } from '@config';

setAgGridLicense();

const { matomo, sgconnect } = getAppConfig();

const sgconnectConfiguration = {
  cliendId: sgconnect.client_id,
  endpoint: sgconnect.authorization_endpoint,
  redirectUri: sgconnect.redirect_uri,
  scope: sgconnect.scope,
  acrValues: sgconnect.acr_values,
};

const analyticsConfiguration = matomo !== undefined ? { siteId: matomo.siteid, url: matomo.url } : undefined;

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <SgwtContainer infrastructure="azure" sgConnect={sgconnectConfiguration} sgAnalytics={analyticsConfiguration}>
      <App />
      <div id="root-modal" />
    </SgwtContainer>
  </StrictMode>,
);
