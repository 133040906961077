import { ToggleButton } from './ToggleButtonGroup/ToggleButton';
import { Icon, Text } from '@sgme/ui';
import { ToggleButtonGroup } from './ToggleButtonGroup';
import type { ToggleHandler } from './ToggleButtonGroup/useContext';
import { FormattedMessage } from 'react-intl';
import { isDefined } from '@sgme/fp';

export type FiltersProps = {
  labelId?: string;
  filters: FilterElement[];
  activeFilterIds: FilterId[];
  selectionType: 'single' | 'multiple';
  checkIconVisible?: boolean;
  onToggle: ToggleHandler;
};

export type FilterId = string;

export type FilterElement = {
  id: FilterId;
  labelId: string;
};

export const Filters = (props: FiltersProps) => {
  const {
    labelId,
    filters: allFilters,
    activeFilterIds: allActiveFilterIds,
    selectionType,
    checkIconVisible: isCheckIconVisible = false,
    onToggle,
  } = props;

  if (allFilters.length <= 1) {
    return null;
  }

  const hasAnyActiveFilter = allFilters.some((filter) => allActiveFilterIds.includes(filter.id));

  const allFilterStates = allFilters.map((filter) => {
    const isActive = allActiveFilterIds.includes(filter.id);

    return {
      ...filter,
      isChecked: isActive || !hasAnyActiveFilter,
      isActive,
    };
  });

  return (
    <>
      {isDefined(labelId) && (
        <Text className="ms-8px" weight="semibold">
          <FormattedMessage id={labelId} />
        </Text>
      )}

      <ToggleButtonGroup
        activeButtonIds={allActiveFilterIds}
        selectionType={selectionType}
        onToggle={onToggle}
        color="primary"
      >
        {allFilterStates.map((filter) => (
          <ToggleButton id={filter.id} key={filter.id}>
            {isCheckIconVisible &&
              (filter.isChecked ? (
                <Icon icon="visibility" size="sm" className="me-4px" />
              ) : (
                <Icon icon="visibility_off" size="sm" className="me-4px" />
              ))}
            <FormattedMessage id={filter.labelId} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};
