import type { UseControllerProps } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export interface CheckboxProps extends UseControllerProps {
  name: string;
  labelLocaleKey?: string;
  className?: string;
}

export const Checkbox = ({ labelLocaleKey, name, className }: CheckboxProps) => {
  const { field } = useController({ name });
  const { register } = useFormContext();

  return (
    <div className={ `form-check ${className ?? ""}` }>
      <input
        type="checkbox"
        id={name}
        className="form-check-input"
        defaultChecked={field.value}
        {...register(name)}
      />

      {
        labelLocaleKey && (
          <label className="form-check-label text-primary" htmlFor={ name }>
            <FormattedMessage id={ labelLocaleKey }/>
          </label>
        )
      }
    </div>
  );
};
