import { PageTracker } from '@features/analytics/PageTracker';
import { Authorize } from '../../../features/authorization/Authorize';
import { LAG_ROUTE } from '../contants';
import MarginsPresets from './MarginsPresets';

function LagPresetsPage(): JSX.Element {
  return (
    <Authorize authorizedFor={['LAG_MARGINS_READPRESETS']} redirectTo={LAG_ROUTE}>
      <PageTracker>
        <MarginsPresets />
      </PageTracker>
    </Authorize>
  );
}

export default LagPresetsPage;
