import { CashHeader } from './CashHeader';
import { CashGrid } from './CashGrid';
import { useRowFilters } from './useRowFilters';
import { useRef } from 'react';
import type { AgGridReact } from '@ag-grid-community/react';
import { isRowVisible, useData } from './useData';

function CashTab() {
  const { rowFilters, allActiveFilterIds, setQuery, toggleFilterId } = useRowFilters();

  const allRows = useData();

  const gridRef = useRef<AgGridReact>(null);

  const expandAll = () => {
    gridRef.current?.api.expandAll();
  };

  const collapseAll = () => {
    gridRef.current?.api.collapseAll();
  };

  const allVisibleRows = allRows.filter(isRowVisible(rowFilters));

  return (
    <div className="d-flex flex-column h-100">
      <CashHeader
        rowFilters={rowFilters}
        allActiveFilterIds={allActiveFilterIds}
        rows={allRows}
        onQueryChange={setQuery}
        onToggleFilterId={toggleFilterId}
        onExpandAll={expandAll}
        onCollapseAll={collapseAll}
      />

      {/*show data*/}

      <CashGrid isGroupedByInstrument ref={gridRef} rows={allVisibleRows} />
    </div>
  );
}

export default CashTab;
