import { useCallback } from 'react';

import { ActiveFilter } from './ActiveFilter';
import {useAppDispatch} from "../../../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../../../hooks/useAppSelector";
import {getActiveFilters} from "../../../../../../store/filters/filtersSelectors";
import {actionCreators} from "../../../../../../store/actions";
import { HStack } from "@sgme/ui";

export const ActiveFilters = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector(getActiveFilters);

  const clearAllFilter = useCallback(() => {
    dispatch(actionCreators.filters.filtersCleared());
  }, [dispatch]);

  if (filters.length === 0) {
    return <></>;
  }

  return (
    // <div className="border-top mx-n4 px-4 py-3">
    <HStack horizontalAlignment="start" className="border-top mx-n4 px-4 py-3">
      <span className="fw-bold me-1">Applied filters</span>

      {filters.map((filter, index) => (
        // filter order do not change, therefore it is safe to use index which is the only unique value
        <ActiveFilter key={index} {...filter} />
      ))}

      <button type="button" className="btn btn-link" onClick={clearAllFilter}>
        Clear all
      </button>
    </HStack>
    // </div>
  );
};
