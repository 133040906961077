import { useIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import type { LagClient } from 'services/clients';

const SPAN_STYLE = { maxWidth: '75%' };
const ICON_FONT_SIZE = { fontSize: '8px' };
interface ClientGroupRendererProps {
  client: LagClient;
}

const ClientGroupRenderer = ({ client }: ClientGroupRendererProps): JSX.Element => {
  const { isActive = false, name } = client;
  const tooltipTarget = name.replaceAll(' ', '-');
  const { formatMessage } = useIntl();

  if (isActive) {
    return <span title={name}>{name}</span>;
  }

  return (
    <>
      <span
        className="text-secondary ag-cell ps-0"
        id={tooltipTarget}
        title={name}
        style={SPAN_STYLE}
      >
        {name}

        <i className="icon ms-1 text-secondary" style={ICON_FONT_SIZE}>
          lens
        </i>
      </span>

      <UncontrolledTooltip placement="right" target={tooltipTarget}>
        {formatMessage({ id: 'clientsMargins.inactiveClient' })}
      </UncontrolledTooltip>
    </>
  );
};

export default ClientGroupRenderer;
