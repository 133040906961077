import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { prepareHeaders } from 'services/prepareHeaders';

import { getAppConfig } from 'config';

const appConfig = getAppConfig();

export const marginProfilesApi = createApi({
  reducerPath: 'marginProfilesApi',
  tagTypes: ['marginProfiles'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${appConfig.apiUrl}/api/marginProfiles`,
    prepareHeaders,
  }),
  endpoints: () => ({}),
});
