import { selectClientByName } from 'services/clients';
import type { AppThunk } from '../store';

export const changeNewClientName =
  (newClientName: string): AppThunk =>
  (dispatch, getState, { actionCreators: ac }) => {
    const state = getState();
    const foundClient = selectClientByName(state, newClientName);
    dispatch(
      ac.clientDuplication.clientNameChanged({
        clientName: newClientName,
        alreadyExists: foundClient !== undefined,
      }),
    );
  };
