import { isDefined } from '@sgme/fp';
import type { ComponentSize } from '@utils/size';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CommonBadge } from './CommonBadge';

import type { FxPerimeterKey } from '@services/fx/model/perimiters';

interface FxInheritedBadgeProps {
  perimeterKey: FxPerimeterKey;
  inheritedBdrId?: number;
  inheritedBdrLevel?: string;
  inheritedBdrName?: string;
  size?: ComponentSize;
  className?: string;
  dataE2e: string;
}

export const FxInheritedBadge = (props: FxInheritedBadgeProps): JSX.Element => {
  const { perimeterKey, inheritedBdrId, inheritedBdrLevel, inheritedBdrName, size = 'md', dataE2e, className } = props;

  const labelId = 'fx.badge.inherited.label';

  const tooltip = getTooltip(perimeterKey, inheritedBdrId, inheritedBdrLevel, inheritedBdrName);

  return (
    <CommonBadge
      labelId={labelId}
      icon="account_balance_wallet"
      size={size}
      dataE2e={dataE2e}
      color="plantation"
      className={className}
      children={tooltip}
    />
  );
};

const getTooltip = (
  perimeterKey: FxPerimeterKey,
  inheritedBdrId?: number,
  inheritedBdrLevel?: string,
  inheritedBdrName?: string,
): ReactNode | undefined => {
  if (isDefined(inheritedBdrId)) {
    return (
      <div>
        <p>
          <FormattedMessage id="fx.badge.inherited.tooltip.1" />
        </p>

        <p className="py-2">
          <FormattedMessage id="fx.badge.inherited.tooltip.2" />
        </p>

        <footer>
          <Link
            to={`/fx/clients/${inheritedBdrId}/${inheritedBdrLevel}/perimeters/${perimeterKey}/products`}
            target="_blank"
          >
            {inheritedBdrName} <em className="icon icon-sm">open_in_new</em>
          </Link>
        </footer>
      </div>
    );
  }

  return (
    <div>
      <p>
        <FormattedMessage id="fx.badge.inherited.tooltip.1" />
      </p>
    </div>
  );
};
