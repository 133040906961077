import type { FxPayload } from './model/models';
import { isDefined } from '@sgme/fp';
import { type ClientBdrId, ClientBdrLevel } from './model/client';
import {
  type FxOrderProfileRow,
  type FxOrderProfileRowApi,
  isOrderProfileColumnKey,
  isOrderProfileColumnProviderKey,
} from './model/order';

export const transformFxResponse = <T>(response: FxPayload<T>) => response.data;

export const kebabize = (string: string) => string.replace(/[A-Z]{1}/g, ([x]: string) => `-${x.toLowerCase()}`);

export const camelize = (string: string) => string.replace(/-./g, (x: string) => x[1].toUpperCase());

export const camelizeObject = <T>(data: object): T =>
  Object.entries(data).reduce<T>(
    (acc, [kebabKey, value]) => ({
      ...acc,
      [camelize(kebabKey)]: value,
    }),
    {} as T,
  );

export const camelizeDeepObject = <T>(data: object): T =>
  Object.entries(data).reduce<T>(
    (acc, [kebabKey, value]) => ({
      ...acc,
      // We check typeof value === object to make sure we don't camelize other properties.
      // We use __proto as non primitive values like Array have typeof Object
      [camelize(kebabKey)]: value.__proto__ === Object.prototype ? camelizeDeepObject(value) : value,
    }),
    {} as T,
  );

//@TODO Move this to the webapi ?
export const removeDeletedColumns = (row: FxOrderProfileRowApi) => {
  if (row.state === 'deleted') {
    delete row.columns;
  }
  return row;
};

//@TODO Move this to the webapi ?
export const haveSomeTouchedRow = (row: FxOrderProfileRowApi) => row.state !== 'untouched';

export const mapRowUi = ({
  state,
  uiRowId,
  internalRowId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rowContext, // remove unused prop from FxRowProfile to payload sent
  ...columns
}: FxOrderProfileRow): FxOrderProfileRowApi => ({
  state,
  uiRowId,
  internalRowId,
  // @ts-ignore
  columns: Object.entries(columns).reduce((acc, [columnKey, columnValue]) => {
    if (columnKey === 'liquidityProviderDimension' && typeof columnValue !== 'string') {
      return {
        ...acc,
        [`${kebabize('liquidityProviderDimension')}.External.${kebabize('marginValue')}`]: columnValue.External,
        [`${kebabize('liquidityProviderDimension')}.Internal.${kebabize('marginValue')}`]: columnValue.Internal,
      };
    }

    if (columnKey === 'marginUnit' && typeof columnValue === 'string' && columnValue === 'mln') {
      // To secure to not send "mln" value
      return {
        ...acc,
        [kebabize(columnKey)]: 'bps',
      };
    }

    return { ...acc, [kebabize(columnKey)]: columnValue };
  }, {} as FxOrderProfileRowApi),
});

export const mapRowApi = (
  { uiRowId, internalRowId, state = 'untouched', columns }: FxOrderProfileRowApi,
  index: number,
  { length }: Array<FxOrderProfileRowApi>,
): FxOrderProfileRow => ({
  // @ts-ignore
  ...Object.entries(columns).reduce((acc, [kebabColId, value]) => {
    const colId = camelize(kebabColId);

    if (isOrderProfileColumnKey(colId)) {
      return { ...acc, [colId]: value };
    }

    const [header, subHeader] = colId.split('.').map(camelize);

    if (header === 'liquidityProviderDimension' && isOrderProfileColumnProviderKey(subHeader)) {
      return {
        ...acc,
        [header]: {
          ...(acc[header] || {}),
          [subHeader]: value,
        },
      };
    }

    return acc;
  }, {} as FxOrderProfileRow),
  state,
  uiRowId,
  internalRowId,
  // Aggrid "isRowSelectable" function doesn't provide this information, so we add this data in Row context.
  rowContext: { isLastRow: index === length - 1 },
});

export const parseClientBdrId = (clientBdrIdParam?: string): ClientBdrId | undefined => {
  const clientBdrId = Number(clientBdrIdParam);
  return !isNaN(clientBdrId) && clientBdrId !== 0 ? clientBdrId : undefined;
};

export const parseClientBdrLevel = (value: string | undefined): ClientBdrLevel | undefined =>
  isDefined(value) && value in ClientBdrLevel ? (value.toUpperCase() as ClientBdrLevel) : undefined;
