import type { EditPermission } from '@features/authorization/authorization.model';
import type { FxCashGridDataType } from '@services/fx/model/cash';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';

export const getEditingPermissionsForCurrentProduct = (
  gridDataType: FxCashGridDataType | undefined,
  productKey: FxProductKey,
  perimeterKey: FxPerimeterKey,
): EditPermission[] => {
  switch (perimeterKey) {
    case 'fx-order-perimeter': {
      return productKey === 'algo-order-product'
        ? ['FX_WRITE_ORDER_ALGO_MARGIN']
        : productKey === 'limit-order-product'
          ? ['FX_WRITE_ORDER_LIMIT_MARGIN']
          : ['FX_WRITE_ORDER_FIXING_MARGIN'];
    }

    case 'fx-cash-rfq-perimeter': {
      if (gridDataType === 'margin-grid-data-type') {
        return productKey === 'spot-product'
          ? ['FX_WRITE_CASHRFQ_SPOT_MARGIN']
          : productKey === 'swap-product'
            ? ['FX_WRITE_CASHRFQ_SWAP_MARGIN']
            : ['FX_WRITE_CASHRFQ_NDF_MARGIN'];
      }

      return productKey === 'spot-product'
        ? ['FX_WRITE_CASHRFQ_SPOT_TIERING']
        : productKey === 'swap-product'
          ? ['FX_WRITE_CASHRFQ_SWAP_TIERING']
          : ['FX_WRITE_CASHRFQ_NDF_TIERING'];
    }

    default:
      return [];
  }
};
