import { isDefined } from '@sgme/fp';
import { FxProfileBadge } from '@components/fx/badges/FxProfileBadge';
import { FxInheritedBadge } from '@components/fx/badges/FxInheritedBadge';
import { useSelector } from 'react-redux';
import { selectEditCashPermissions } from '@features/authorization/authorization.slice';
import type { EditCashPermission } from '@features/authorization/authorization.model';
import type { FxCashGridDataType, FxCashProductKey } from '@services/fx/model/cash';
import { Link } from 'react-router-dom';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { makeSelectCashProfileUrl } from '@utils/makeSelectCashProfileUrl';
import type { InstrumentName } from '../model';
import { useFirstFxClientFor } from '@services/fx/getFXClient';

const getPermissionForCurrentInstrumentData = (
  gridDataType: FxCashGridDataType,
  productKey: FxCashProductKey,
  userEditCashPermissions: EditCashPermission[],
): { hasEditPermission: boolean; permissionName: EditCashPermission } => {
  const gridType = gridDataType.split('-')[0]; // we only want first part of "margin-grid-data-type" or "tiering-grid-data-type"
  const currentInstrument = `${gridType}-${productKey}`;
  switch (currentInstrument) {
    case 'margin-spot-product':
      return {
        hasEditPermission: userEditCashPermissions.includes('FX_WRITE_CASHRFQ_SPOT_MARGIN'),
        permissionName: 'FX_WRITE_CASHRFQ_SPOT_MARGIN',
      };
    case 'margin-swap-product':
      return {
        hasEditPermission: userEditCashPermissions.includes('FX_WRITE_CASHRFQ_SWAP_MARGIN'),
        permissionName: 'FX_WRITE_CASHRFQ_SWAP_MARGIN',
      };
    case 'margin-ndf-product':
      return {
        hasEditPermission: userEditCashPermissions.includes('FX_WRITE_CASHRFQ_NDF_MARGIN'),
        permissionName: 'FX_WRITE_CASHRFQ_NDF_MARGIN',
      };
    case 'tiering-spot-product':
      return {
        hasEditPermission: userEditCashPermissions.includes('FX_WRITE_CASHRFQ_SPOT_TIERING'),
        permissionName: 'FX_WRITE_CASHRFQ_SPOT_TIERING',
      };
    case 'tiering-swap-product':
      return {
        hasEditPermission: userEditCashPermissions.includes('FX_WRITE_CASHRFQ_SWAP_TIERING'),
        permissionName: 'FX_WRITE_CASHRFQ_SWAP_TIERING',
      };
    case 'tiering-ndf-product':
      return {
        hasEditPermission: userEditCashPermissions.includes('FX_WRITE_CASHRFQ_NDF_TIERING'),
        permissionName: 'FX_WRITE_CASHRFQ_NDF_TIERING',
      };

    default:
      throw new Error(`currentInstrument: ${currentInstrument} is unkown`);
  }
};

// why props: any ?
// because for AgGrid, cellRenderer is any !
export const InstrumentGroupCell = (props: any) => {
  const instrumentData = props.node.allLeafChildren[0].data.instrumentData;
  const userEditCashPermissions = useSelector(selectEditCashPermissions) as EditCashPermission[];
  const { clientBdrId, clientBdrLevel } = useFxUrlParams();
  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);
  const notReadOnlyAccess = client?.userAccess !== 'authorized_on_my_desk_clients_read_only';

  const name = props.context.formatMessage({
    id: `fx.CashGrid.column.instrument.${instrumentData.productKey}.${instrumentData.gridDataType}`,
  });

  const { gridDataType, productKey, profileId, isInherited } = instrumentData;
  const { hasEditPermission, permissionName } = getPermissionForCurrentInstrumentData(
    gridDataType,
    productKey,
    userEditCashPermissions,
  );

  const instrumentName = `${productKey}/${gridDataType}` as InstrumentName;

  const url = makeSelectCashProfileUrl(instrumentName, profileId, clientBdrId!, clientBdrLevel!, isInherited);

  return (
    // height = 30px because there is a vertical shift otherwise
    <div className="d-flex align-items-center gap-2" style={{ height: 30 }}>
      <span className="fw-bold">{name}</span>

      {isDefined(instrumentData.profileName) && (
        <FxProfileBadge
          className={'overflow-hidden'}
          profileName={instrumentData.profileName}
          profileTypeKey={instrumentData.profileTypeKey}
          size="sm"
        />
      )}

      {instrumentData.isInherited && (
        <FxInheritedBadge
          perimeterKey="fx-cash-rfq-perimeter"
          inheritedBdrId={instrumentData.inheritableClient?.bdrId}
          inheritedBdrLevel={instrumentData.inheritableClient?.bdrLevel}
          inheritedBdrName={instrumentData.inheritableClient?.name}
          dataE2e="inherited"
          size="sm"
        />
      )}
      {hasEditPermission && notReadOnlyAccess && (
        <div className={'mb-1'}>
          <Link
            key={permissionName}
            to={url}
            className="fs-16 text-primary fw-bold"
            data-e2e={`edit-inline-btn-${permissionName.toLowerCase()}`}
          >
            <i className="icon icon-sm text-secondary">create</i>
          </Link>
        </div>
      )}
    </div>
  );
};
