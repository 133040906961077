import type { MouseEventHandler } from 'react';
import { useCallback, useMemo } from 'react';
import { isDefined } from '@sgme/fp';
import { selectMarginsPresets } from 'services/marginProfiles';
import {useAppDispatch} from "../../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../../hooks/useAppSelector";
import {type ActiveFilterMargin, selectFilter} from "../../../../../store/filters/filtersSelectors";
import {actionCreators} from "../../../../../store/actions";
import type {FiltersState} from "../../../../../store/filters/filtersSlice";

const TYPE_FILTER: Record<
  ActiveFilterMargin['type'],
  keyof Pick<FiltersState, 'salesProfiles' | 'devLProfiles' | 'pcruMargins'>
> = {
  Pcru: 'pcruMargins',
  Sales: 'salesProfiles',
  DevL: 'devLProfiles',
} as const;

export interface FilterProps {
  type: keyof typeof TYPE_FILTER;
  value: number;
}

export const Filter = ({ type, value }: FilterProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const filter = useAppSelector(selectFilter(TYPE_FILTER[type]));
  const marginsPresets = useAppSelector(selectMarginsPresets);

  const active = useMemo(() => filter.includes(value), [filter, value]);

  const label = useMemo(() => {
    switch (type) {
      case 'Pcru':
        return `${value}bp`;
      case 'Sales':
      case 'DevL': {
        const marginPreset = marginsPresets.find((profile) => profile.id === value);

        if (isDefined(marginPreset)) {
          return marginPreset.name;
        }
      }
    }
    return '';
  }, [marginsPresets, type, value]);

  const toggle: MouseEventHandler<HTMLButtonElement> = useCallback(
    (ev) => {
      switch (type) {
        case 'Pcru':
          dispatch(actionCreators.filters.pcruMarginFilterToggled(value));
          break;
        case 'Sales':
          dispatch(actionCreators.filters.salesProfileFilterToggled(value));
          break;
        case 'DevL':
          dispatch(actionCreators.filters.devLProfileFilterToggled(value));
          break;
      }

      ev.currentTarget.blur(); // focused styled while toggling looks confusing to users
    },
    [dispatch, type, value],
  );

  return (
    <button
      type="button"
      className={`btn btn-toggle-primary ${active ? 'active' : ''} me-1`}
      onClick={toggle}
      data-e2e={`filter-${label.toLowerCase()}`}
    >
      {label}
    </button>
  );
};
