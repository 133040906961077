import { Button, ButtonGroup, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { changeCashColumnDimension } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { type ChangeEvent, useEffect, useState } from 'react';
import { HelpCenter } from '@components/HelpCenter';
import type { FxCashProfileMarginColumns } from '@services/fx/model/cash';

type ClientVenueLoginSelectProps = {
  disabled: boolean;
};

const ClientVenueLoginSelect = ({ disabled }: ClientVenueLoginSelectProps) => {
  const [clientVenueType, setClientVenueType] = useState<string | undefined>('*');
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const clientVenueLoginValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxCashProfileMarginColumns).extClientLoginDimension,
  );

  const setClientVenueLogin = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeCashColumnDimension({ key: 'extClientLoginDimension', value: event.target.value }));
  };

  const handleClickOnAllClientVenueType = () => {
    setClientVenueType('*');
    dispatch(changeCashColumnDimension({ key: 'extClientLoginDimension', value: '*' }));
  };

  const handleClickOnSpecificClientVenueType = () => {
    setClientVenueType('');
    dispatch(changeCashColumnDimension({ key: 'extClientLoginDimension', value: '' }));
  };

  // in case of existing edited row has a specific value
  // we need to select the specific venue type accordingly
  useEffect(() => {
    if (clientVenueLoginValue !== '*') {
      setClientVenueType('specific');
    }
  }, [clientVenueLoginValue]);

  return (
    <div>
      <div className={'d-flex flex-between'}>
        <Label className="text-primary" for={'clientVenueLogin'}>
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.clientVenueLogin`} />
        </Label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <ButtonGroup id={'clientVenueLogin'} className="w-100">
        <Button
          className="btn-toggle-primary w-50"
          color="primary"
          data-e2e="client-venue-login-all"
          outline
          onClick={handleClickOnAllClientVenueType}
          active={clientVenueType === '*'}
          disabled={disabled}
        >
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.clientVenueLogin.all`} />
        </Button>

        <Button
          className="btn-toggle-primary w-50"
          color="primary"
          outline
          onClick={handleClickOnSpecificClientVenueType}
          active={clientVenueType === 'specific'}
          data-e2e="client-venue-login-specific"
          disabled={disabled}
        >
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.clientVenueLogin.specific`} />
        </Button>
      </ButtonGroup>

      {clientVenueType === 'specific' && (
        <input
          data-e2e="clientVenueLoginInput"
          type="text"
          autoComplete="off"
          placeholder={formatMessage({ id: `fx.editProfile.fx-cash-rfq-perimeter.clientVenueLogin.sample` })}
          className="form-control mt-8px"
          id="clientVenueLogin"
          value={clientVenueLoginValue}
          onChange={setClientVenueLogin}
        />
      )}
    </div>
  );
};

export default ClientVenueLoginSelect;
