import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';
import { fxApi } from './fxApi';
import { type FxCashGridDataType, MARGIN_GRID_DATA_TYPE } from './model/cash';
import type { ClientBdrId, ClientBdrLevel } from './model/client';
import { type FxClientInheritance, isFxCashInheritanceProduct, isFxOrderInheritanceProduct } from './model/inheritance';

export const clientsInheritanceApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFXClientsInheritance: builder.query<
      FxClientInheritance,
      { clientBdrId: ClientBdrId; clientBdrLevel: ClientBdrLevel }
    >({
      query: ({ clientBdrId, clientBdrLevel }) => ({
        url: 'clients-inheritance',
        params: {
          clientBdrId,
          clientBdrLevel,
        },
      }),

      providesTags: (result, error, { clientBdrId, clientBdrLevel }) => [
        { type: 'ClientsInheritance', id: `${clientBdrId}/${clientBdrLevel}` },
      ],
    }),
  }),
});

export const { useGetFXClientsInheritanceQuery } = clientsInheritanceApi;

export const getProfileReferenceFromClientInheritanceData = (
  clientInheritanceData: FxClientInheritance | undefined,
  perimeterKey: FxPerimeterKey | undefined,
  productKey: FxProductKey | undefined,
  gridDataType: FxCashGridDataType | undefined,
) => {
  const defaultProfile = clientInheritanceData?.data.perimeters.find(
    (perimeter) => perimeter.perimeterKey === perimeterKey,
  );

  const savedProduct = defaultProfile?.products.find((product) => product.productKey === productKey);

  return isFxOrderInheritanceProduct(savedProduct)
    ? savedProduct.profile
    : isFxCashInheritanceProduct(savedProduct)
      ? gridDataType === MARGIN_GRID_DATA_TYPE
        ? savedProduct?.marginGrid
        : savedProduct?.tieringGrid
      : undefined;
};

type GetFxInheritableProfileReferenceParams = {
  clientBdrId: ClientBdrId;
  clientBdrLevel: ClientBdrLevel;
  perimeterKey: FxPerimeterKey;
  productKey: FxProductKey;
  gridDataType?: FxCashGridDataType;
};

export const useGetFxInheritableProfileReference = ({
  clientBdrId,
  clientBdrLevel,
  perimeterKey,
  productKey,
  gridDataType,
}: GetFxInheritableProfileReferenceParams) => {
  const { data: clientInheritance } = useGetFXClientsInheritanceQuery({ clientBdrId, clientBdrLevel });

  return getProfileReferenceFromClientInheritanceData(clientInheritance, perimeterKey, productKey, gridDataType);
};
