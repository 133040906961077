import type { Permission, Resource } from '@features/authorization/authorization.model';
import { setPermissions } from '@features/authorization/authorization.slice';
import type { SgConnectUserInfo } from '@features/sgwtConnect/sgwtConnect.model';
import { useLazyLogConnectionQuery } from '@services/user/userApi';
import { ServiceLoader } from '@sgme/ui';
import { EVENT_AUTH_EXPIRED } from '@sgwt/connect-core';
// import { EVENT_AUTH_EXPIRED } from '@sgwt/connect-core';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { actionCreators } from '@store/actions';
import { BdrProvider } from 'BdrContext';
import { router } from 'pages/router';
import { type PropsWithChildren, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { HelpCenter } from './components/HelpCenter';
import { CrashModal } from './components/modals/CrashModal';
import { TimeoutModal } from './components/modals/TimeoutModal/TimeoutModal';
import { IntlProvider } from './features/intl/IntlProvider';
import { setupStore } from './store/store';

export const App = (): JSX.Element => {
  const { sgwtWebAnalytics, sgwtConnect } = useSgwtWidgets();

  const store = useMemo(
    () => (sgwtWebAnalytics !== undefined ? setupStore(sgwtWebAnalytics.trackEvent.bind(sgwtWebAnalytics)) : undefined),
    [sgwtWebAnalytics],
  );

  useEffect(() => {
    if (store === undefined || sgwtConnect === undefined) {
      return;
    }

    sgwtConnect.sgwtConnect.fetchUserInfo((error, userInfo) => {
      // @ts-ignore: OK, we can trust SGConnect
      store.dispatch(setPermissions(userInfo as SgConnectUserInfo<Resource, Permission>));
    });

    const onAuthExpired = () => {
      store.dispatch(actionCreators.ui.authExpired());
    };

    sgwtConnect.sgwtConnect.on(EVENT_AUTH_EXPIRED, onAuthExpired);

    return () => {
      sgwtConnect.sgwtConnect.off(EVENT_AUTH_EXPIRED, onAuthExpired);
    };
  }, [store, sgwtConnect]);

  if (store === undefined) {
    return <ServiceLoader />;
  }

  return (
    <Provider store={store}>
      <ConnectionLogger>
        <IntlProvider>
          <BdrProvider>
            <ServiceLoader>
              <CrashModal />

              <TimeoutModal />

              <HelpCenter />

              <RouterProvider router={router} />
            </ServiceLoader>
          </BdrProvider>
        </IntlProvider>
      </ConnectionLogger>
    </Provider>
  );
};

/**
 * call /api/user/connect when the application is opened
 */
const ConnectionLogger = ({ children }: PropsWithChildren) => {
  const [connect] = useLazyLogConnectionQuery();

  useEffect(() => {
    connect();
  }, [connect]);

  return children;
};
