import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { fxApi } from './fxApi';
import type { FxPayload, ProfileId } from './model/models';
import type { FxClientModel } from './model/client';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';

const clientsAdapter = createEntityAdapter<FxClientModel>({
  selectId: ({ clientBdrId }) => clientBdrId,
  sortComparer: (a, b) => a.clientBdrId - b.clientBdrId,
});

const initialState = clientsAdapter.getInitialState();

export type FxProfileClientsQueryParams = {
  profileId: ProfileId;
  perimeterKey: FxPerimeterKey;
  productKey: FxProductKey;
};

export const clientsUsingProfileApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFXClientsUsingProfile: builder.query<EntityState<FxClientModel>, FxProfileClientsQueryParams>({
      query: ({ profileId, perimeterKey, productKey }) => ({
        url: `perimeters/${perimeterKey}/products/${productKey}/clients`,
        params: {
          profileId,
        },
      }),

      transformResponse: (response: FxPayload<FxClientModel[]>) => clientsAdapter.setAll(initialState, response.data),

      providesTags: (result, error, { profileId, perimeterKey, productKey }) => [
        { type: 'ClientsUsingProfile', id: `${perimeterKey}/${productKey}/${profileId}` },
      ],
    }),
  }),
});

export const { useGetFXClientsUsingProfileQuery } = clientsUsingProfileApi;
