import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { prepareHeaders } from 'services/prepareHeaders';

import { getAppConfig } from 'config';

const appConfig = getAppConfig();

export const statusApi = createApi({
  reducerPath: 'statusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${appConfig.apiUrl}/status/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getStatusJson: builder.query<undefined, void>({
      query: () => 'json',
    }),
    getStatusMonitoring: builder.query<undefined, void>({
      query: () => 'monitoring',
    }),
  }),
});

export const { useGetStatusJsonQuery, useGetStatusMonitoringQuery } = statusApi;
