import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { clientsApi } from './clientsApi';
import type { LagClient } from './models';

export const clientsAdapter = createEntityAdapter<LagClient>({
  selectId: ({ id }) => id,
});

export const initialState = clientsAdapter.getInitialState();

export const getClientsApi = clientsApi.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<EntityState<LagClient>, void>({
      query: () => '',
      providesTags: () => ['Clients'],
      transformResponse: (clients: LagClient[]) => {
        // some clients have weird spaces in their name, and thus break comparison
        return clientsAdapter.setAll(
          initialState,
          clients.map(({ name, ...client }) => ({ ...client, name: name.trim() })),
        );
      },
    }),
  }),
});

export const { useGetClientsQuery } = getClientsApi;
