import { Button, HStack, Icon, Spacer } from '@sgme/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import type { ChangeEvent } from 'react';
import type { RowFilters } from './useRowFilters';
import { type FilterElement, type FilterId, Filters } from './Filters';
import type { GridCashProfileRow } from './useData';
import { Authorize } from '@features/authorization/Authorize';
import { ModalHandler } from '@components/modals/ModalHandler';
import { editCashProductPermissions } from '@features/authorization/authorization.model';
import type { ProfileIdByInstrumentType } from './model';
import { useFirstFxClientFor } from '@services/fx/getFXClient';
import { useFxUrlParams } from '@hooks/useFxUrlParams';

type CashHeaderProps = {
  rowFilters: RowFilters;
  allActiveFilterIds: string[];

  rows: GridCashProfileRow[];

  onQueryChange: (newQuery: string) => void;
  onToggleFilterId: (filterId: string, isActive: boolean) => void;

  onExpandAll: () => void;
  onCollapseAll: () => void;
};

export const getProfileIdByInstrumentType = (rows: GridCashProfileRow[]): ProfileIdByInstrumentType =>
  rows.reduce((acc, { instrument, instrumentData: { profileId, isInherited } }) => {
    acc[instrument] = { profileId, isInherited };

    return acc;
  }, {} as ProfileIdByInstrumentType);

export function CashHeader(props: CashHeaderProps) {
  const {
    rowFilters: { query },
    allActiveFilterIds,

    rows: allRows,

    onQueryChange,
    onToggleFilterId,

    onExpandAll,
    onCollapseAll,
  } = props;

  const { formatMessage } = useIntl();
  const { clientBdrId, clientBdrLevel } = useFxUrlParams();
  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);

  const updateQuery = (event: ChangeEvent<HTMLInputElement>) => onQueryChange(event.target.value);

  const profileIdByInstrumentType = getProfileIdByInstrumentType(allRows);

  const notReadOnlyAccess = client?.userAccess !== 'authorized_on_my_desk_clients_read_only';
  return (
    <div className="flex-grow-0">
      <HStack gap="4px" horizontalAlignment="start" className="mb-8px" fullWidth>
        <Button purpose="default" iconPosition="end" onClick={onExpandAll}>
          <FormattedMessage id="fx.CashGrid.header.expandAll" />
          <Icon icon="unfold_more" />
        </Button>

        <Button purpose="default" iconPosition="end" onClick={onCollapseAll}>
          <FormattedMessage id="fx.CashGrid.header.collapseAll" />
          <Icon icon="unfold_less" />
        </Button>

        {/*display*/}

        <Filters
          labelId="fx.CashGrid.header.display"
          key="fx.CashGrid.header.display"
          filters={getAllVisibleFilters(getRowDisplayId, ALL_DISPLAY_FILTERS, allRows)}
          activeFilterIds={allActiveFilterIds}
          selectionType={'multiple'}
          onToggle={onToggleFilterId}
        />

        {/*instrument*/}

        <Filters
          labelId="fx.CashGrid.header.instrument"
          key="fx.CashGrid.header.instrument"
          filters={getAllVisibleFilters(getRowInstrumentId, ALL_INSTRUMENT_FILTERS, allRows)}
          activeFilterIds={allActiveFilterIds}
          selectionType={'multiple'}
          onToggle={onToggleFilterId}
        />

        {notReadOnlyAccess && (
          <Authorize authorizedFor={editCashProductPermissions}>
            <div className="d-flex justify-content-end flex-grow-1">
              <ModalHandler modalName="EditMarginOrTieringModal" profileIdByInstrumentType={profileIdByInstrumentType}>
                <Button purpose="primary" color="black" iconPosition="start" data-e2e={'cash-header-edit'}>
                  <Icon icon="edit" />
                  <FormattedMessage id="fx.CashGrid.header.edit" />
                </Button>
              </ModalHandler>
            </div>
          </Authorize>
        )}
      </HStack>

      <HStack>
        <div className="form-groupw w-25">
          <div className={`input-group pb-1`}>
            <div className="input-icon-start">
              <em className="icon">search</em>
            </div>

            <input
              type="search"
              className="form-control"
              id="filter-text-box"
              placeholder={formatMessage({ id: 'common.search' })}
              autoComplete="off"
              value={query}
              onChange={updateQuery}
            />
          </div>
        </div>

        <Spacer />

        <div></div>
      </HStack>
    </div>
  );
}

const getAllVisibleFilters = (
  getFilterIdForRow: (row: GridCashProfileRow) => FilterId[],
  allFilters: FilterElement[],
  allRows: GridCashProfileRow[],
) => {
  const isFilterActive = allRows.reduce(
    (activeFiltersById, row) => {
      for (const id of getFilterIdForRow(row)) {
        activeFiltersById[id] = true;
      }

      return activeFiltersById;
    },
    {} as Record<string, boolean>,
  );

  return allFilters.filter(({ id }) => isFilterActive[id] ?? false);
};

const ALL_DISPLAY_FILTERS = [
  {
    id: 'margins',
    labelId: 'fx.CashGrid.header.margins',
  },
  {
    id: 'traderTiering',
    labelId: 'fx.CashGrid.header.traderTiering',
  },
  // {
  //   id: "rejectionRules",
  //   labelId: "fx.CashGrid.header.rejectionRules"
  // }
] satisfies FilterElement[];

const getRowDisplayId = (row: GridCashProfileRow) => {
  const filterId = row.instrumentData.gridDataType === 'margin-grid-data-type' ? 'margins' : 'traderTiering';

  return [filterId];
};

const ALL_INSTRUMENT_FILTERS = [
  {
    id: 'spot-product',
    labelId: 'fx.CashGrid.header.spot',
  },
  {
    id: 'ndf-product',
    labelId: 'fx.CashGrid.header.NDF',
  },
  {
    id: 'swap-product',
    labelId: 'fx.CashGrid.header.swap',
  },
  {
    id: 'nds-product',
    labelId: 'fx.CashGrid.header.NDS',
  },
] satisfies FilterElement[];

const getRowInstrumentId = (row: GridCashProfileRow) => [row.instrumentData.productKey];
