import type { ChangeEventHandler } from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {useAppDispatch} from "../../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../../hooks/useAppSelector";
import {selectFilter} from "../../../../../store/filters/filtersSelectors";
import {actionCreators} from "../../../../../store/actions";

export const ClientSearch = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const clientName = useAppSelector(selectFilter('clientName'));

  const onClientNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      dispatch(actionCreators.filters.clientNameFilterChanged(event.currentTarget.value));
    },
    [dispatch],
  );

  return (
    <div className="input-group mb-0">
      <div className="input-icon-start">
        <em className="icon">search</em>
      </div>

      <input
        type="search"
        className="form-control"
        placeholder={formatMessage({ id: 'filters.searchClient' })}
        value={clientName}
        onChange={onClientNameChange}
        data-e2e="lag-input-search-clients"
      />
    </div>
  );
};
