import type React from 'react';
import { useIntl } from 'react-intl';

import { connectDevLFilters } from './connect/connectDevLFilters';
import { Filter } from './Filter';
import { FilterGroup } from './FilterGroup';

export interface DevLProfileFilterProps {
  ids: number[];
}

const DevLProfileFiltersRaw: React.FunctionComponent<DevLProfileFilterProps> = ({ ids }) => {
  const { formatMessage } = useIntl();
  return (
    <FilterGroup label={formatMessage({ id: 'filters.devLMargin' })}>
      {ids.map((id) => (
        <Filter key={id} type="DevL" value={id} />
      ))}
    </FilterGroup>
  );
};

export const DevLProfileFilters = connectDevLFilters(DevLProfileFiltersRaw);
