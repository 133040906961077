import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

export interface FxDuplicateRowWarningModalProps extends ModalProps {
  closeForm: () => void;
  onCancel: () => void;
}

export const FxDuplicateRowWarningModal = (props: FxDuplicateRowWarningModalProps) => {
  const innerOnCancel = () => {
    props.closeForm();
    props.onCancel();
  };

  const onClose = () => {
    props.closeForm();
  };

  return (
    <Modal {...props} centered>
      <ModalHeader tag="h3" close={props.modalHeaderClose}>
        <FormattedMessage id="duplicateRowModal.title" />
      </ModalHeader>

      <ModalBody className="pb-0">
        <div className="d-flex align-items-center justify-content-start mb-4" style={{ flexWrap: 'wrap' }}>
          <span className="me-2 text-warning d-flex align-items-center">
            <WarningIconContainer>
              <em className="icon icon-md">warning_amber</em>
            </WarningIconContainer>

            <span className="ms-2">
              <FormattedMessage id="duplicateRowModal.text" />
            </span>
          </span>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          className="btn btn-lg btn-flat-primary"
          onClick={innerOnCancel}
          data-e2e="cancel-duplication-modal-button"
        >
          <FormattedMessage id="duplicateRowModal.cancel" />
        </button>

        <button onClick={onClose} className="btn btn-lg btn-warning" data-e2e="update-duplication-modal-button">
          <FormattedMessage id="duplicateRowModal.update" />
        </button>
      </ModalFooter>
    </Modal>
  );
};

const WarningIconContainer = styled.div.attrs({
  className: 'bg-warning bg-opacity-05',
})`
  min-width: 3em;
  min-height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;
