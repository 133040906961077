import { SgwtAccountCenter } from '@sgwt/sgwt-widgets-react';
import { useContext } from 'react';
import { getAppConfig, isProductionEnvironment } from '../../../config';
import { LocaleContext, isSupportedLocale } from '../../../features/intl/IntlProvider';

// https://developer.fr.world.socgen/docs/web/widgets/structural-widgets/sgwt-account-center.html

export const AccountCenter = () => {
  const { locale, saveLocale } = useContext(LocaleContext);

  const onLanguageChanged = (language: string) => {
    if (!isSupportedLocale(language)) {
      return;
    }

    saveLocale(language);
  };

  return (
    <SgwtAccountCenter
      // @ts-ignore
      authentication="sg-connect-v2"
      available-languages="en,fr"
      environment={isProductionEnvironment() ? '' : getAppConfig().env}
      language={locale}
      mode="sg-markets"
      onLanguageChanged={onLanguageChanged}
      // navigate-as={bool(props.navigateAs)}
      // navigate-as-user={json(props.navigateAsUser)} //  string; // json extends {name: string;}
      // navigate-as-list={json(props.navigateAsList)} // ?: string; // json extends Array<{name: string;}>
      // user={json(props.user)} // ?: string; // json extends {name: string;}
      // show-notification={bool(props.showNotification)}
    />
  );
};
