import { FormattedMessage } from 'react-intl';
import { isDefined } from '@sgme/fp';
import { useAppSelector } from '@hooks/useAppSelector';
import { FxOrderEditorForm } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/FxOrderEditorForm';
import { createRow } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useDispatch } from 'react-redux';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import type { FxOrderProductKey } from '@services/fx/model/order';

export const FxOrderEditor = (): JSX.Element => {
  // const { internalRowId, onAddMode, reset } = useFxEditMode();
  const dispatch = useDispatch();
  const { productKey } = useFxSelectAndEditProfileUrlParams();

  const addOrderRow = () => {
    dispatch(createRow({ type: 'order-margin', productKey: productKey as FxOrderProductKey }));
  };

  const editingRow = useAppSelector((state) => state.fxProfileEdition.editingRow);

  return (
    <div className={`card h-100 p-4 bg-lvl2 col-6 col-md-4 col-xl-3 col-xxl-2 border-start`} data-e2e="fx-edit-Panel">
      <div className={`d-flex align-items-start justify-content-between`}>
        <h4 className="card-title line-height-1 w-100 mb-3">
          <FormattedMessage id="margin.setup" />
        </h4>
      </div>

      {isDefined(editingRow) ? (
        <FxOrderEditorForm editingRow={editingRow} />
      ) : (
        <div className="d-flex flex-column">
          <div className="text-secondary my-4">
            <FormattedMessage id="fx.margin.edit.panel.description" />
          </div>

          <button className="btn btn-md btn-icon-start btn-outline-primary align-self-center" onClick={addOrderRow}>
            <em className="icon icon-md">add</em>
            <FormattedMessage id="add.row" />
          </button>
        </div>
      )}
    </div>
  );
};
