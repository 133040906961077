import { type SgwtAnalyticsTrackEvent, analyticsMiddleware } from '@features/analytics/analyticsMiddleware';
import { authorizationSlice } from '@features/authorization/authorization.slice';
import { statusApi } from '@features/smoketest/statusApi';
import type { Action, PreloadedState, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { fxApi } from '@services/fx/fxApi';
import { userApi } from '@services/user/userApi';
import { clientsApi } from 'services/clients';
import { marginProfilesApi } from 'services/marginProfiles';
import { type ActionCreators, actionCreators } from './actions';
import { clientDuplicationSlice } from './clientDuplication/clientDuplicationSlice';
import { clientEditionSlice } from './clientEdition/clientEditionSlice';
import { catchApiMiddleware } from './error/catchApiMiddleware';
import { catchMiddleware } from './error/catchMiddleware';
import { errorSlice } from './error/errorSlice';
import { filtersSlice } from './filters/filtersSlice';
import { fxProfileEditionSlice } from './fxProfileEdition/fxProfileEditionSlice';
import { marginProfilesEditionSlice } from './marginProfilesEdition/marginProfilesEditionSlice';
import type { Selectors } from './selectors';
import { selectors } from './selectors';
import { uiSlice } from './ui/uiSlice';

const rootReducer = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [errorSlice.name]: errorSlice.reducer,
  [clientEditionSlice.name]: clientEditionSlice.reducer,
  [clientDuplicationSlice.name]: clientDuplicationSlice.reducer,
  [marginProfilesEditionSlice.name]: marginProfilesEditionSlice.reducer,
  [filtersSlice.name]: filtersSlice.reducer,
  [fxProfileEditionSlice.name]: fxProfileEditionSlice.reducer,
  [authorizationSlice.name]: authorizationSlice.reducer,

  [fxApi.reducerPath]: fxApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [marginProfilesApi.reducerPath]: marginProfilesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [statusApi.reducerPath]: statusApi.reducer,
});

export const setupStore = (trackEvent: SgwtAnalyticsTrackEvent, preloadedState?: PreloadedState<AppState>) =>
  configureStore({
    reducer: rootReducer,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            actionCreators,
            selectors,
          } as ThunkExtraArgument,
        },
      }).concat(
        fxApi.middleware,
        clientsApi.middleware,
        marginProfilesApi.middleware,
        userApi.middleware,
        statusApi.middleware,
        catchMiddleware,
        catchApiMiddleware,
        analyticsMiddleware(trackEvent),
      ),
    preloadedState,
  });

export type AppState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export type AppThunk<R = void> = ThunkAction<R, AppState, ThunkExtraArgument, Action<string>>;

export interface ThunkExtraArgument {
  actionCreators: ActionCreators;
  selectors: Selectors;
}
