import { type ClassAttributes, useEffect, useRef } from 'react';
import { useIntl } from "react-intl";
import { isDefined } from '@sgme/fp';

export interface AccountPickerProps {
  legalOnly?: boolean;
  withFunds?: boolean;
  onChange(account: IcAccount | null): void;
  clientSelected?: string
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'ic-account-single-picker': ClassAttributes<HTMLElement> & IcAccountPickerAttributes;
    }
  }
}

type IcAccountPickerLevels = 'Grp' | 'SubGrp' | 'SubGrpDiv' | 'LEGrp' | 'LE' | 'LEDiv' | 'EC';

interface IcAccountPickerAttributes {
  id?: string;
  'max-result-count'?: number;
  placeholder?: string;
  disabled?: boolean;
  outline?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  'emit-mode'?: 'always' | 'user' | 'not-empty';
  'selected-id'?: string;
  'readonly-id'?: string;
  'user-email'?: string;
  'in-error'?: string;
  'error-message'?: string;
  'legal-only'?: boolean;
  levels?: IcAccountPickerLevels[];
  withFunds?: boolean;
  displayOption?: string[];
}

export type ClientAccount = IcAccount | InternalAccount;

export interface IcAccount {
  bdrCommercialId: string;
  bdrCommercialLevel: string;
  bdrLegalId: string;
  bdrLegalLevel: string;
  icId: string;
  name: string;
  level: string;
  countryIso3: string;
  countryName: string;
}

export interface InternalAccount {
  bdrLegalId: string;
  level: string;
  name?: string;
}

type IcAccountPickerChangedEvent = CustomEvent<{
  account: IcAccount | null;
}>;

export function IcAccountPicker({
  onChange,
  legalOnly = false,
  withFunds = true,
  clientSelected
}: AccountPickerProps): JSX.Element {
  const { formatMessage } = useIntl();

  const accountPickerRef = useRef<HTMLElement & IcAccountPickerAttributes>(null);

  useEffect(() => {
    const accountPicker = accountPickerRef?.current;

    if (isDefined(accountPicker)) {
      accountPicker.withFunds = withFunds;
      accountPicker.displayOption = ['bdr-legal-id'];
    }
  }, [accountPickerRef, withFunds]);

  const changeHandler =
    // some clients have weird spaces in their name, and thus break comparison
    (ev: IcAccountPickerChangedEvent) => onChange(ev.detail?.account ?? null)

  useEffect(() => {
    window.addEventListener('ic-account-single-picker--changed', changeHandler as EventListener);

    return () => {
      window.removeEventListener(
        'ic-account-single-picker--changed',
        changeHandler as EventListener,
      );
    };
  });

  return (
    <ic-account-single-picker
      ref={accountPickerRef}
      selected-id={clientSelected}
      id="ic-account-single-picker"
      placeholder={formatMessage({ id: "clientsMargins.searchBar.placeholder.external" })}
      data-e2e="search-account"
      legal-only={legalOnly}
    ></ic-account-single-picker>
  );
}
