import { FormattedMessage } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';
import type { Permission } from '@features/authorization/authorization.model';
import { selectPermissions } from '@features/authorization/authorization.slice';
import { useSelector } from 'react-redux';
import { type ContactPickerProps, IcContactPicker } from './IcContactSinglePicker';

type ContactFormGroupProps = Pick<ContactPickerProps, 'onChange'>;

export const ContactFormGroup = ({ onChange }: ContactFormGroupProps): JSX.Element => {
  const permissions: Permission[] = useSelector(selectPermissions);
  const isMandatory = permissions.includes('FX_AUDIT_ONBEHALF_REQUIRED');

  return (
    <FormGroup className="form-group">
      <Label for="contact" className="mb-2">
        <FormattedMessage id="common.behalf.of" />

        <span className="ms-1"></span>

        <FormattedMessage id="common.optionalOrMandatory" values={{ isMandatory }} />
      </Label>

      <IcContactPicker displayOption="email" onChange={onChange} />
    </FormGroup>
  );
};
