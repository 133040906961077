import { FxOrderProduct } from './FxOrderProduct';
import { getFxOrderInheritancePerimeter } from '@services/fx/model/inheritance';
import { useGetFXClientQuery } from '@services/fx/getFXClient';
import { useFxUrlParams } from '@hooks/useFxUrlParams';

function OrderTab() {
  const { clientBdrLevel, clientBdrId } = useFxUrlParams();

  const { data: allClients } = useGetFXClientQuery({ clientBdrId: clientBdrId!, clientBdrLevel });
  const client = allClients?.find(
    (loadedClient) => loadedClient.clientBdrId === clientBdrId && loadedClient.clientBdrLevel === clientBdrLevel,
  );

  const perimeter = getFxOrderInheritancePerimeter(client?.perimeters || []);
  const products = perimeter?.products ?? [];

  return (
    <div className="col">
      {products.map(({ productKey }, index) => (
        <div key={productKey} className={`row ${index > 0 ? 'mt-4' : ''}`}>
          <FxOrderProduct
            className="row-1"
            // open the first product by default
            openByDefault
            productKey={productKey}
          />
        </div>
      ))}
    </div>
  );
}

export default OrderTab;
