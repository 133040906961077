import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ModalHandler } from 'components/modals/ModalHandler';

import { Authorize } from '@features/authorization/Authorize';
import { LAG_PRESETS_ROUTE } from '@pages/lag/contants';
import { LagTitle } from './LagTitle';

export const LagClientsHeader = () => {
  return (
    <div className="flex-between align-items-baseline mb-4">
      <LagTitle />

      <span>
        <Authorize authorizedFor={['LAG_WRITE']}>
          <ModalHandler modalName="ClientFormModal">
            <button className={'btn btn-lg btn-outline-primary'} data-e2e="add-client">
              <FormattedMessage id="clientsMargins.addClient" />
            </button>
          </ModalHandler>
        </Authorize>

        <Authorize authorizedFor={['LAG_MARGINS_READPRESETS']}>
          <Link
            className="btn btn-lg btn-text-icon btn-outline-primary ms-2"
            data-e2e="open-margin-presets"
            to={LAG_PRESETS_ROUTE}
          >
            <FormattedMessage id="clientsMargins.openMarginPresets" />

            <em className="icon">arrow_forward</em>
          </Link>
        </Authorize>
      </span>
    </div>
  );
};
