import { useCallback } from 'react';
import { assertUnreachable } from '@sgme/fp';

import { maximumUpperMaturityRange, minimumLowerMaturityRange } from 'utils/maturityRange';

import { FilterLabel } from './FilterLabel';
import {useAppDispatch} from "../../../../../../hooks/useAppDispatch";
import {actionCreators} from "../../../../../../store/actions";
import type {ActiveFilterProps} from "../../../../../../store/filters/filtersSelectors";

export const ActiveFilter = (props: ActiveFilterProps): JSX.Element => {
  const { type } = props;
  const dispatch = useAppDispatch();

  const clearFilter = useCallback(() => {
    switch (type) {
      case 'Sales':
        dispatch(actionCreators.filters.salesProfileFilterToggled(props.value));
        break;

      case 'DevL':
        dispatch(actionCreators.filters.devLProfileFilterToggled(props.value));
        break;

      case 'Pcru':
        dispatch(actionCreators.filters.pcruMarginFilterToggled(props.value));
        break;

      case 'Custom':
        dispatch(actionCreators.filters.customizedMarginsOnlyFilterToggled());
        break;

      case 'MaturityRange':
        dispatch(actionCreators.filters.lowerMaturityRangeChanged(minimumLowerMaturityRange));
        dispatch(actionCreators.filters.upperMaturityRangeChanged(maximumUpperMaturityRange));
        break;

      default:
        assertUnreachable(props, 'Unhandled filter type');
    }
  }, [dispatch, props, type]);

  return (
    <span className="badge badge-discreet-info badge-dismissible fs-12 ms-1">
      <i className="icon icon-sm" role="button" onClick={clearFilter}>
        close
      </i>

      <FilterLabel {...props} />
    </span>
  );
};
