import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import type { EditCashPermission } from '../../../../features/authorization/authorization.model';
import { useSelector } from 'react-redux';
import { selectEditCashPermissions } from '../../../../features/authorization/authorization.slice';
import { useFxUrlParams } from '../../../../hooks/useFxUrlParams';
import { Link } from 'react-router-dom';
import { HStack, Spacer, VStack } from '@sgme/ui';
import { makeSelectCashProfileUrl } from '../../../../utils/makeSelectCashProfileUrl';
import type { InstrumentName, ProfileIdByInstrumentType } from './model';

export interface EditMarginOrTieringModalProps extends ModalProps {
  profileIdByInstrumentType: ProfileIdByInstrumentType;
}

export const PERMISSION_BY_INSTRUMENT: Record<InstrumentName, EditCashPermission> = {
  'swap-product/tiering-grid-data-type': 'FX_WRITE_CASHRFQ_SWAP_TIERING',
  'spot-product/tiering-grid-data-type': 'FX_WRITE_CASHRFQ_SPOT_TIERING',
  'ndf-product/tiering-grid-data-type': 'FX_WRITE_CASHRFQ_NDF_TIERING',
  'swap-product/margin-grid-data-type': 'FX_WRITE_CASHRFQ_SWAP_MARGIN',
  'spot-product/margin-grid-data-type': 'FX_WRITE_CASHRFQ_SPOT_MARGIN',
  'ndf-product/margin-grid-data-type': 'FX_WRITE_CASHRFQ_NDF_MARGIN',
};

export function EditMarginOrTieringModal({ ...props }: EditMarginOrTieringModalProps): JSX.Element {
  const userEditCashPermissions = useSelector(selectEditCashPermissions) as EditCashPermission[];
  const { clientBdrId, clientBdrLevel } = useFxUrlParams();

  const filteredByPermissions = Object.entries(props.profileIdByInstrumentType).filter(([instrumentName]) =>
    userEditCashPermissions.includes(PERMISSION_BY_INSTRUMENT[instrumentName as InstrumentName]),
  );

  return (
    <Modal {...props} data-e2e="edit-margin-tiering-modal" contentClassName="border">
      <ModalHeader tag="h3" close={props.modalHeaderClose}>
        <FormattedMessage id="editMarginOrTieringModal.title" />
      </ModalHeader>

      <ModalBody>
        <VStack>
          {filteredByPermissions.map(([instrumentName, { profileId, isInherited }]) => {
            const url = makeSelectCashProfileUrl(
              instrumentName as InstrumentName,
              profileId,
              clientBdrId!,
              clientBdrLevel!,
              isInherited,
            );
            return (
              <HStack className="border-bottom py-2 p " key={instrumentName}>
                <Link
                  key={instrumentName}
                  to={url}
                  className="fs-16 text-primary fw-bold w-100 text-decoration-none"
                  data-e2e={`edit-modal-${instrumentName.toLowerCase().replace('/', '-')}`}
                >
                  <FormattedMessage id={`editMarginOrTieringModal.cta.${instrumentName}`} />
                </Link>
                <Spacer />
                <i className="icon icon-md text-secondary">arrow_forward</i>
              </HStack>
            );
          })}
        </VStack>
      </ModalBody>
    </Modal>
  );
}
