import type { ClientBdrId, ClientBdrLevel } from '@services/fx/model/client';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

export type BdrReference = {
  id: ClientBdrId;
  level?: ClientBdrLevel;
};

export type BdrContextValue = {
  reference: BdrReference | undefined;
  setReference: Dispatch<SetStateAction<BdrReference | undefined>>;
};

export const BdrContext = createContext<BdrContextValue>(undefined as unknown as BdrContextValue);

export function BdrProvider({ children }: { children: JSX.Element }) {
  const [bdrReference, setBdrReference] = useState<BdrReference | undefined>(undefined);

  return (
    <BdrContext.Provider value={{ reference: bdrReference, setReference: setBdrReference }}>
      {children}
    </BdrContext.Provider>
  );
}

export const useBdrReference = () => useContext(BdrContext);
