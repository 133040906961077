import { useIntl } from 'react-intl';

import { HelpCenter } from 'components/HelpCenter';

import { AlgoOrderProductMarginUnitSwitch } from '@components/fx/AlgoOrderProductMarginUnitSwitch';
import type { ChangeEvent } from 'react';

type FxOrderProfileTableHeaderProps = {
  productKey: PropertyKey;
  onFilter: (query: string) => void;
};

export const FxOrderProfileTableHeader = ({ productKey, onFilter }: FxOrderProfileTableHeaderProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => onFilter(event.target.value);

  return (
    <div className="d-flex flex-row align-items-end">
      <div className="form-groupw w-25">
        <div className={'input-group pb-1'}>
          <div className="input-icon-start">
            <em className="icon">search</em>
          </div>

          <input
            type="search"
            className="form-control"
            id="filter-text-box"
            placeholder={formatMessage({ id: 'common.search' })}
            autoComplete="off"
            onChange={onChange}
          />
        </div>
      </div>

      <div className="d-flex pb-1 ms-auto">
        {productKey === 'algo-order-product' && <AlgoOrderProductMarginUnitSwitch />}

        <HelpCenter className="ms-2" withHandler />
      </div>
    </div>
  );
};
