import { fxApi } from './fxApi';
import type { ClientBdrId, ClientBdrLevel } from './model/client';
import type { FxCashGridDataType } from '@services/fx/model/cash';
import type { FxPayload } from '@services/fx/model/models';
import type { FxPerimeterKey, FxProductKey, FxProfileColumns } from '@services/fx/model/perimiters';
import type { FxProfileMarginGrid } from '@services/fx/model/profile-margin-grid';

interface FxProfileOfProductParams {
  clientBdrId: ClientBdrId;
  clientBdrLevel: ClientBdrLevel;
  perimeterKey: FxPerimeterKey;
  productKey: FxProductKey;
  gridDataType?: FxCashGridDataType;
}
export const productApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFXCashProfileOfProduct: builder.query<
      FxPayload<FxProfileMarginGrid<FxProfileColumns>>,
      FxProfileOfProductParams
    >({
      query: ({ clientBdrId, clientBdrLevel, perimeterKey, productKey, gridDataType }) => ({
        url: `perimeters/${perimeterKey}/products/${productKey}/margin-grids`,
        params: {
          clientBdrId,
          clientBdrLevel,
          gridDataType,
        },
      }),

      providesTags: (result, error, { perimeterKey, productKey, gridDataType }) => [
        { type: 'Profile', id: `${perimeterKey}-${productKey}-${gridDataType}` },
      ],
    }),
  }),
});

export const { useGetFXCashProfileOfProductQuery } = productApi;
