import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Form, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { OverflowableModalBody } from '../OverflowableModalBody';
import { ContactFormGroup } from './ContactFormGroup';
import { ReasonsFormGroup } from './ReasonsFormGroup';
import { ReasonTypesFormGroup } from './ReasonTypesFormGroup';
import type { ChangeReasonsTypeKey, UpdateProfileErrorPayload } from '@services/fx/model/models';
import type { FxCashGridDataType } from '@services/fx/model/cash';
import ProfileAlreadyModifiedError from './ProfileAlreadyModifiedError';
import TechnicalError from '@components/modals/TechnicalError';
import { isDefined, isNotDefined } from '@sgme/fp';
import type { Permission } from '@features/authorization/authorization.model';
import { useSelector } from 'react-redux';
import { selectPermissions } from '@features/authorization/authorization.slice';
import type { IcContact } from './IcContactSinglePicker';

export interface FxSelectProfileChangeReasonsModalProps extends ModalProps {
  makeOnSubmit: (
    innerOnClosed: () => void,
    setErrorCode: (error: UpdateProfileErrorPayload['data']) => void,
    contactEmail?: string,
  ) => (event: React.FormEvent<HTMLFormElement>) => void;
  gridDataType?: FxCashGridDataType;
}

export const FxProfileChangeReasonsModal = (props: FxSelectProfileChangeReasonsModalProps): JSX.Element => {
  const { makeOnSubmit, gridDataType } = props;
  const permissions: Permission[] = useSelector(selectPermissions);

  const [selectedChangeReasonTypeKey, setSelectedChangeReasonTypeKey] = useState<ChangeReasonsTypeKey | undefined>();
  const [contactEmail, setContactEmail] = useState<string>();
  const [error, setError] = useState<UpdateProfileErrorPayload['data']>({ errorCode: null, message: '' });

  const onSelectReasonType = (changeReasonsTypeKey: ChangeReasonsTypeKey) => {
    setSelectedChangeReasonTypeKey(changeReasonsTypeKey);
  };

  const onChangeContact = (contact: IcContact) => {
    setContactEmail(contact.email);
  };

  const innerOnClosed = () => {
    if (props.onClosed) {
      props.onClosed();
    }

    setSelectedChangeReasonTypeKey(undefined);
  };

  const onSubmitCallBack = makeOnSubmit(innerOnClosed, setError, contactEmail);

  const reload = () => {
    window.location.reload();
  };
  const { errorCode, message } = error;
  const isAuditOnBehalfRequired = permissions.includes('FX_AUDIT_ONBEHALF_REQUIRED');

  const isUnkownError =
    isDefined(errorCode) &&
    errorCode !== 'ProfileAlreadyModifiedError' &&
    errorCode !== 'ClientProfileAssignmentAlreadyModifiedError';

  const isDisabled =
    selectedChangeReasonTypeKey === undefined ||
    (isNotDefined(contactEmail) && isAuditOnBehalfRequired) ||
    Boolean(errorCode);

  return (
    <Modal data-e2e="change-reason-modal" style={{ minWidth: '600px' }} {...props}>
      <ModalHeader tag="h3" close={props.modalHeaderClose}>
        <FormattedMessage id={`profileChangeReasonModal.title.${gridDataType ?? 'margin-grid-data-type'}`} />
      </ModalHeader>

      <Form onSubmit={onSubmitCallBack}>
        <OverflowableModalBody className="pb-0">
          <ReasonTypesFormGroup
            value={selectedChangeReasonTypeKey}
            onChange={onSelectReasonType}
            gridDataType={gridDataType}
          />

          {selectedChangeReasonTypeKey && (
            <>
              <ReasonsFormGroup changeReasonTypeKey={selectedChangeReasonTypeKey} />
              <ContactFormGroup onChange={onChangeContact} />
            </>
          )}

          {(errorCode === 'ProfileAlreadyModifiedError' ||
            errorCode === 'ClientProfileAssignmentAlreadyModifiedError') && (
            <ProfileAlreadyModifiedError reload={reload} />
          )}
          {(errorCode === 'TechnicalError' || isUnkownError) && <TechnicalError reload={reload} message={message} />}
        </OverflowableModalBody>

        <ModalFooter>
          <button type="reset" className="btn btn-lg btn-flat-primary me-2" onClick={innerOnClosed}>
            <FormattedMessage id="common.cancel" />
          </button>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            data-e2e="fx-confirm-profile-change"
            disabled={isDisabled}
          >
            <FormattedMessage id="common.confirm" />
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
