import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import type { IHeaderParams } from '@ag-grid-community/core';

interface HelperCellHeaderProps extends IHeaderParams {
  helperLocaleKey?: string;
}

export const HelperCellHeader = ({
  displayName,
  helperLocaleKey,
  column,
}: HelperCellHeaderProps): JSX.Element => {
  const colId = column.getColId().replaceAll('.', '-');
  const tooltipId = `${colId}-tooltip`;

  return (
    <>
      <span>
        {displayName}
        <i className="icon-sm icon pb-1 ps-1" id={tooltipId}>
          help_outline
        </i>
      </span>

      {
        helperLocaleKey && (
          <UncontrolledTooltip data-toggle="tooltip" placement="bottom" target={ tooltipId }>
            <FormattedMessage id={ helperLocaleKey }/>
          </UncontrolledTooltip>
        )
      }
    </>
  );
};
