import { connect } from 'react-redux';
import type { MapStateToPropsHOF } from 'typings/redux-connect';
import type {AppState} from "../../../store/store";
import type {Selectors} from "../../../store/selectors";
import {selectors} from "../../../store/selectors";

export type CrashModalConnectOwnProps = Record<string, never>;

export interface CrashModalConnectStateProps {
  error: string | null;
}

// you can whitelist selectors after implementation
export type CrashModalConnectSelectors = Selectors;

/*
export type CrashModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type CrashModalConnectSelectors = Pick<Selectors, CrashModalConnectSelectorsKeys>;
*/

export const mapStateToPropsCrashModal: MapStateToPropsHOF<
  CrashModalConnectStateProps,
  CrashModalConnectOwnProps,
  AppState,
  CrashModalConnectSelectors
> = (sl) => (state) => ({
  error: sl.getError(state) ?? null,
});

export const connectCrashModal = connect(mapStateToPropsCrashModal(selectors));
