import type { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link, NavLink } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isDefined, isNotDefined } from '@sgme/fp';

import { FxClientBadge } from '../../../../components/fx/badges/FxClientBadge';
import { useGetFXClientsInheritanceQuery } from '../../../../services/fx/getFXClientsInheritance';
import { ProductProperty, StyledPropertyContainer } from './ProductHeaderProperty';
import { useFxUrlParams } from '../../../../hooks/useFxUrlParams';
import type { ClientBdrId, ClientBdrLevel } from '../../../../services/fx/model/client';
import { useFirstFxClientFor } from '../../../../services/fx/getFXClient';
import {
  type FxClientInheritance,
  type FxInheritancePerimeter,
  getFxCashInheritancePerimeter,
  getFxOrderInheritancePerimeter,
  isFxOrderInheritancePerimeter,
} from '../../../../services/fx/model/inheritance';
import { FX_PRODUCTS_ROUTE } from '../../../fx/constants';
import { FxInheritedBadge } from '../../../../components/fx/badges/FxInheritedBadge';

import type { FxPerimeterKey } from '@services/fx/model/perimiters';

export const FxProductsHeader = (): JSX.Element | null => {
  const { perimeterKey, clientBdrId, clientBdrLevel } = useFxUrlParams();

  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);

  const queryClientsInheritanceParams =
    isDefined(clientBdrId) && isDefined(clientBdrLevel) ? { clientBdrId, clientBdrLevel } : skipToken;

  const { data: inheritedProfile } = useGetFXClientsInheritanceQuery(queryClientsInheritanceParams);

  if (isNotDefined(client)) {
    return null;
  }

  const { clientLongName, clientCountryCode, profileSummaryFlag } = client;

  const canOpenOrderProducts =
    isDefined(inheritedProfile) && isDefined(getFxOrderInheritancePerimeter(inheritedProfile.data.perimeters));
  const canOpenCashProducts =
    isDefined(inheritedProfile) && isDefined(getFxCashInheritancePerimeter(inheritedProfile.data.perimeters));

  return (
    <div className="flex-start flex-wrap align-items-start mb-0" data-e2e="fx-product-header">
      <h1 className="text-primary display-3">{clientLongName}</h1>

      <StyledPropertyContainer className="d-flex align-items-center gap-2 my-2 fs-14 text-secondary fw-medium">
        <div>

          <ProductProperty
            dataE2e="commercial-level"
            labelId="fx.header.property.commercialLevel"
            value={clientBdrLevel}
          />
          <ProductProperty dataE2e="region" labelId="fx.header.property.region" value={clientCountryCode} />
          <ProductProperty dataE2e="legal-bdr-id" labelId="fx.header.property.legalBdrId" value={clientBdrId} />
          {client.clientMnemonic !== '' && <ProductProperty dataE2e="mnemonic" labelId="fx.header.property.mnemonic" value={client.clientMnemonic} />}


          {
            // bad eslint => bad code
            isDefined(profileSummaryFlag) && profileSummaryFlag !== '' && profileSummaryFlag !== 'inherited' && (
              <div className="d-inline-flex align-items-center">
                <FxClientBadge dataE2e="setup-flag-badge" profileSummaryFlag={profileSummaryFlag} size="md" />
              </div>
            )
          }

          {
            // bad eslint => bad code
            isDefined(profileSummaryFlag) &&
            isDefined(perimeterKey) &&
            profileSummaryFlag !== '' &&
            profileSummaryFlag === 'inherited' && (
              <div className="d-inline-flex align-items-center">
                <FxInheritedBadge dataE2e="setup-flag-badge" perimeterKey={perimeterKey} size="md" />
              </div>
            )
          }
        </div>
      </StyledPropertyContainer>

      {client.profileSummaryFlag === 'inherited' && isDefined(client.parent) && (
        <EditUpperCommercialLevel
          name={client.parent.clientLongName}
          bdrId={client.parent.clientBdrId}
          bdrLevel={client.parent.clientBdrLevel}
          perimeterKey={perimeterKey!} // always defined when we show the local setup message
        />
      )}

      {client.profileSummaryFlag === 'partial_local_setup' &&
        isDefined(inheritedProfile) &&
        isDefined(client.parent) && (
          <RemovePartialLocalSetup
            name={client.parent.clientLongName}
            bdrId={client.parent.clientBdrId}
            perimeterKey={perimeterKey!} // always defined when we show the local setup message
            bdrLevel={client.parent.clientBdrLevel}
            localSetupCount={getLocalSetupCount(inheritedProfile)}
            productCount={getProductCount(inheritedProfile)}
          />
        )}

      <ul className="nav nav-underline">
        {canOpenCashProducts && (
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={generatePath(FX_PRODUCTS_ROUTE, {
                clientBdrId: String(clientBdrId),
                clientBdrLevel: clientBdrLevel!,
                perimeterKey: 'fx-cash-rfq-perimeter',
              })}
            >
              <FormattedMessage id="fx.title.cash" />
            </NavLink>
          </li>
        )}

        {canOpenOrderProducts && (
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={generatePath(FX_PRODUCTS_ROUTE, {
                clientBdrId: String(clientBdrId),
                clientBdrLevel: clientBdrLevel!,
                perimeterKey: 'fx-order-perimeter',
              })}
            >
              <FormattedMessage id="fx.title.order" />
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

// ███████╗██████╗ ██╗████████╗    ██╗   ██╗██████╗ ██████╗ ███████╗██████╗      ██████╗ ██████╗ ███╗   ███╗███╗   ███╗███████╗██████╗  ██████╗██╗ █████╗ ██╗     ██╗     ███████╗██╗   ██╗███████╗██╗
// ██╔════╝██╔══██╗██║╚══██╔══╝    ██║   ██║██╔══██╗██╔══██╗██╔════╝██╔══██╗    ██╔════╝██╔═══██╗████╗ ████║████╗ ████║██╔════╝██╔══██╗██╔════╝██║██╔══██╗██║     ██║     ██╔════╝██║   ██║██╔════╝██║
// █████╗  ██║  ██║██║   ██║       ██║   ██║██████╔╝██████╔╝█████╗  ██████╔╝    ██║     ██║   ██║██╔████╔██║██╔████╔██║█████╗  ██████╔╝██║     ██║███████║██║     ██║     █████╗  ██║   ██║█████╗  ██║
// ██╔══╝  ██║  ██║██║   ██║       ██║   ██║██╔═══╝ ██╔═══╝ ██╔══╝  ██╔══██╗    ██║     ██║   ██║██║╚██╔╝██║██║╚██╔╝██║██╔══╝  ██╔══██╗██║     ██║██╔══██║██║     ██║     ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║
// ███████╗██████╔╝██║   ██║       ╚██████╔╝██║     ██║     ███████╗██║  ██║    ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚═╝ ██║███████╗██║  ██║╚██████╗██║██║  ██║███████╗███████╗███████╗ ╚████╔╝ ███████╗███████╗
// ╚══════╝╚═════╝ ╚═╝   ╚═╝        ╚═════╝ ╚═╝     ╚═╝     ╚══════╝╚═╝  ╚═╝     ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝╚═╝╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝  ╚═══╝  ╚══════╝╚══════╝

type EditUpperCommercialLevelProps = {
  name: string;
  bdrId: ClientBdrId;
  bdrLevel: ClientBdrLevel;
  perimeterKey: FxPerimeterKey;
};

const EditUpperCommercialLevel = ({ name, bdrId, bdrLevel, perimeterKey }: EditUpperCommercialLevelProps) => (
  <div className="alert text-info bg-info bg-opacity-05 my-3 p-2" role="alert" data-e2e="edit-upper-commercial-level">
    <div className="d-flex">
      <div className="flex-fill">
        <em className="icon icon-md me-2">info_outline</em>

        <FormattedMessage
          id="fx.title.editUpperCommercialLevel.message"
          values={{
            bold: (content) => <strong>{content}</strong>,
            name,
          }}
        />
      </div>

      <Link
        to={`/fx/clients/${bdrId}/${bdrLevel}/perimeters/${perimeterKey}/products`}
        className="d-flex align-items-center gap-2 btn btn-outline-info btn-icon-end"
      >
        <FormattedMessage id="fx.title.editUpperCommercialLevel.button" /> <em className="icon ms-1">open_in_new</em>
      </Link>
    </div>
  </div>
);

// ██████╗ ███████╗███╗   ███╗ ██████╗ ██╗   ██╗███████╗    ██████╗  █████╗ ██████╗ ████████╗██╗ █████╗ ██╗         ██╗      ██████╗  ██████╗ █████╗ ██╗     ███████╗███████╗████████╗██╗   ██╗██████╗
// ██╔══██╗██╔════╝████╗ ████║██╔═══██╗██║   ██║██╔════╝    ██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██║██╔══██╗██║         ██║     ██╔═══██╗██╔════╝██╔══██╗██║     ██╔════╝██╔════╝╚══██╔══╝██║   ██║██╔══██╗
// ██████╔╝█████╗  ██╔████╔██║██║   ██║██║   ██║█████╗      ██████╔╝███████║██████╔╝   ██║   ██║███████║██║         ██║     ██║   ██║██║     ███████║██║     ███████╗█████╗     ██║   ██║   ██║██████╔╝
// ██╔══██╗██╔══╝  ██║╚██╔╝██║██║   ██║╚██╗ ██╔╝██╔══╝      ██╔═══╝ ██╔══██║██╔══██╗   ██║   ██║██╔══██║██║         ██║     ██║   ██║██║     ██╔══██║██║     ╚════██║██╔══╝     ██║   ██║   ██║██╔═══╝
// ██║  ██║███████╗██║ ╚═╝ ██║╚██████╔╝ ╚████╔╝ ███████╗    ██║     ██║  ██║██║  ██║   ██║   ██║██║  ██║███████╗    ███████╗╚██████╔╝╚██████╗██║  ██║███████╗███████║███████╗   ██║   ╚██████╔╝██║
// ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝    ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚═╝  ╚═╝╚══════╝    ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝

type RemovePartialLocalSetupProps = {
  name: string;
  bdrId: ClientBdrId;
  bdrLevel: ClientBdrLevel;
  localSetupCount: number;
  productCount: number;
  perimeterKey: FxPerimeterKey;
};

const RemovePartialLocalSetup = (props: RemovePartialLocalSetupProps) => {
  const { name, bdrId, bdrLevel, localSetupCount, productCount, perimeterKey } = props;

  const openLearnMore = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const widget = document.querySelector('sgwt-help-center') as any | null;

    if (isDefined(widget?.topic)) {
      widget.topic('TODO: ');
    }
  };

  return (
    <div className="alert  bg-secondary bg-opacity-05 my-3 p-2" role="alert" data-e2e="remove-partial-local-setup">
      <div className="d-flex">
        <div className="flex-fill d-flex align-items-center">
          <em className="icon icon-md me-2">info_outline</em>

          <div>
            <div data-e2e="message">
              <FormattedMessage
                id="fx.title.removeLocalSetup.message"
                values={{
                  bold: (content) => <strong>{content}</strong>,
                  localSetupCount,
                  productCount,
                }}
              />
              <Link to={`/fx/clients/${bdrId}/${bdrLevel}/perimeters/${perimeterKey}/products`} className="text-link">
                <strong>{name}</strong>
              </Link>
              . &nbsp;
              <strong>
                <FormattedMessage
                  id="fx.title.removeLocalSetup.confirm"
                  values={{
                    bold: (content) => <strong>{content}</strong>,
                    name,
                  }}
                />
              </strong>
              &nbsp;
              <a href="#" onClick={openLearnMore} data-e2e="learn-more">
                <FormattedMessage id="fx.title.removeLocalSetup.learnMore" />
              </a>
            </div>
          </div>
        </div>

        {/*TODO: enable the next button when the feature is ready*/}
        {/*<Link*/}
        {/*  to={ `/fx/clients/${bdrId}/${bdrLevel}/perimeters/fx-order-perimeter/products` }*/}
        {/*  className="d-flex align-items-center gap-2 btn btn-outline-primary"*/}
        {/*  data-e2e="remove-local-setup"*/}
        {/*>*/}
        {/*  <FormattedMessage id="fx.title.removeLocalSetup.button"/>*/}
        {/*</Link>*/}
      </div>
    </div>
  );
};

const getLocalSetupCount = (client: FxClientInheritance) =>
  getProductCountForClient(client, (perimeter) =>
    isFxOrderInheritancePerimeter(perimeter)
      ? perimeter.products.filter((product) => !product.profile.isProfileInherited).length
      : perimeter.products.reduce(
        (acc, product) =>
          acc + (product.marginGrid?.isProfileInherited ? 0 : 1) + (product.tieringGrid?.isProfileInherited ? 0 : 1),
        0,
      ),
  );

const getProductCount = (client: FxClientInheritance) =>
  getProductCountForClient(client, (perimeter) =>
    isFxOrderInheritancePerimeter(perimeter)
      ? perimeter.products.length
      : perimeter.products.reduce(
        (acc, product) => acc + (isDefined(product.marginGrid) ? 1 : 0) + (isDefined(product.tieringGrid) ? 1 : 0),
        0,
      ),
  );

const getProductCountForClient = (
  client: FxClientInheritance,
  getProductCountForPerimeter: (perimeter: FxInheritancePerimeter) => number,
) =>
  Object.values(client?.data.perimeters ?? []).reduce(
    (productCount, perimeter) => productCount + getProductCountForPerimeter(perimeter),
    0,
  );
