import { useToggle } from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { LoadingButton } from 'components/LoadingButton';

import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { connectCrashModal } from './connectCrashModal';

export interface CrashModalProps {
  error: string | null;
}

function CrashModalRaw({ error }: CrashModalProps): JSX.Element {
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const [isDetailsOpen, toggleDetails] = useToggle();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (error != null) {
      sgwtWebAnalytics?.trackEvent('Crash modal', 'Opened');
    }
  }, [error, sgwtWebAnalytics]);

  const reload = () => {
    setLoading(true);
    sgwtWebAnalytics?.trackEvent('Crash modal', 'Reload clicked');
    window.location.reload();
  };

  return error ? (
    <Modal isOpen contentClassName="border border-danger">
      <ModalHeader tag="h3" className="text-danger">
        <FormattedMessage id="crashModal.title" />
      </ModalHeader>

      <ModalBody>
        <p>
          <FormattedMessage id="crashModal.description" />
        </p>

        <div className="sgbs-collapse-separator sgbs-collapse-separator-centered">
          <div className="card bg-transparent">
            <div className="card-header p-0">
              <button
                type="button"
                className={`btn btn-flat-primary btn-icon-start btn-block${isDetailsOpen ? '' : ' collapsed'}`}
                data-toggle="collapse"
                onClick={toggleDetails}
              >
                <span>
                  <FormattedMessage id="crashModal.technicalDetails" />
                  <i className="icon icon-sm">keyboard_arrow_down</i>
                </span>
              </button>

              <Collapse isOpen={isDetailsOpen}>
                <pre>{error}</pre>
              </Collapse>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <LoadingButton className="btn btn-lg btn-danger" icon="refresh" loading={isLoading} onClick={reload}>
          <FormattedMessage id="common.reload" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  ) : (
    <></>
  );
}

export const CrashModal = connectCrashModal(CrashModalRaw);
