import { connect } from 'react-redux';
import type { MapStateToPropsHOF } from 'typings/redux-connect';
import {selectors, type Selectors} from "../../../store/selectors";
import type {AppState} from "../../../store/store";

export type TimeoutModalConnectOwnProps = Record<string, never>;

export interface TimeoutModalConnectStateProps {
  isOpen: boolean;
}

// you can whitelist selectors after implementation
export type TimeoutModalConnectSelectors = Selectors;
/*
export type TimeoutModalConnectSelectorsKeys = 'getOptionLegOptionType'; // example
export type TimeoutModalConnectSelectors = Pick<Selectors, TimeoutModalConnectSelectorsKeys>;
*/

export const mapStateToPropsTimeoutModal: MapStateToPropsHOF<
  TimeoutModalConnectStateProps,
  TimeoutModalConnectOwnProps,
  AppState,
  TimeoutModalConnectSelectors
> = (sl) => (state) => ({
  isOpen: sl.hasAuthExpired(state),
});

export const connectTimeoutModal = connect(mapStateToPropsTimeoutModal(selectors));
