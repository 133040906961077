import { fxApi } from './fxApi';
import type { ProfileId } from './model/models';
import type { FxCashGridDataType } from './model/cash';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';

export const renameProfileApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    renameFxProfile: builder.mutation<
      void,
      {
        perimeterKey: FxPerimeterKey;
        productKey: FxProductKey;
        profileId: ProfileId;
        gridDataType?: FxCashGridDataType;
        profileName: string;
        profileVersion: number;
      }
    >({
      query: ({ perimeterKey, productKey, profileId, profileName, gridDataType, profileVersion }) => ({
        url: `perimeters/${perimeterKey}/products/${productKey}/profiles/${profileId}`,
        method: 'PUT',
        params: { gridDataType },
        body: { profileName, profileVersion },
      }),

      invalidatesTags: (result, error, { perimeterKey, productKey, profileId }) => [
        { type: 'Profile', id: `${profileId}` },
        { type: 'Profile', id: `${perimeterKey}-${productKey}` },
        { type: 'Profiles', id: `${perimeterKey}-${productKey}` },
      ],
    }),
  }),
});

export const { useRenameFxProfileMutation } = renameProfileApi;
