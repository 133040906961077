import { fxApi } from './fxApi';
import type { ClientBdrId, ClientBdrLevel } from './model/client';
import type { FxOrderProfile, FxOrderProductKey } from './model/order';
import type { FxPerimeterKey } from '@services/fx/model/perimiters';
import { transformFxOrderProfileResponse } from '@services/fx/getFXProfileMarginGrid';

interface FxProfileOfProductParams {
  clientBdrId: ClientBdrId;
  clientBdrLevel: ClientBdrLevel;
  perimeterKey: FxPerimeterKey;
  productKey: FxOrderProductKey;
}

export const productApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFXOrderProfileOfProduct: builder.query<FxOrderProfile, FxProfileOfProductParams>({
      query: ({ clientBdrId, clientBdrLevel, perimeterKey, productKey }) => ({
        url: `perimeters/${perimeterKey}/products/${productKey}/margin-grids`,
        params: {
          clientBdrId,
          clientBdrLevel,
        },
      }),

      transformResponse: transformFxOrderProfileResponse,

      providesTags: (result, error, { perimeterKey, productKey }) => [
        { type: 'Profile', id: `${perimeterKey}-${productKey}` },
      ],
    }),
  }),
});

//todo-5198 useGetFXOrderProfileOfProductQuery should be merged into useGetFXProfileOfProductQuery to have common logic between cash and order
export const { useGetFXOrderProfileOfProductQuery } = productApi;
