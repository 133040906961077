import { type FxCashGridDataType, isFxCashGridDataType } from '@services/fx/model/cash';
import type { ClientBdrId, ClientBdrLevel } from '@services/fx/model/client';
import type { ProfileId } from '@services/fx/model/models';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';
import { useParams, useSearchParams } from 'react-router-dom';

interface UseUrlParamsResponse {
  clientBdrId: ClientBdrId;
  clientBdrLevel: ClientBdrLevel;
  perimeterKey: FxPerimeterKey;
  productKey: FxProductKey;
  gridDataType?: FxCashGridDataType;
  profileId: ProfileId;
  isInherited: boolean;
}

export const useFxSelectAndEditProfileUrlParams = (): UseUrlParamsResponse => {
  const urlParams = useParams();
  const [searchParams] = useSearchParams();

  const isInherited = searchParams.get('isInherited') === 'true';
  const gridDataType = parseGridDataType(urlParams.gridDataType);
  return {
    clientBdrId: Number(urlParams.clientBdrId) as ClientBdrId,
    clientBdrLevel: urlParams.clientBdrLevel as ClientBdrLevel,
    perimeterKey: urlParams.perimeterKey as FxPerimeterKey,
    productKey: urlParams.productKey as FxProductKey,
    gridDataType,
    profileId: Number(searchParams.get('profileId')),
    isInherited,
  };
};

const parseGridDataType = (gridDataType?: string): FxCashGridDataType | undefined =>
  isFxCashGridDataType(gridDataType) ? gridDataType : undefined;
