import { Button, ButtonGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { changeCashColumnDimension } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { HelpCenter } from '@components/HelpCenter';
import type { FxCashProfileMarginColumns } from '@services/fx/model/cash';

const ShoreSelect = () => {
  const dispatch = useAppDispatch();

  const shoreValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxCashProfileMarginColumns).onshoreOffshoreDimension,
  );

  const setShoreValue = (dimension: string) => () => {
    dispatch(changeCashColumnDimension({ key: 'onshoreOffshoreDimension', value: dimension }));
  };

  const frontValuesMappedToBackendValues = {
    all: '*',
    onshore: 'True',
    offshore: 'False',
  };

  return (
    <div data-e2e={'shore-button-groups'}>
      <div className={'d-flex flex-between'}>
        <Label className="text-primary" for={'shoreSelect'}>
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.shoreSelect`} />
        </Label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <ButtonGroup id={'shoreSelect'} className="w-100">
        <Button
          className="btn-toggle-primary"
          color="primary"
          outline
          data-e2e="shoreSelect-all"
          onClick={setShoreValue(frontValuesMappedToBackendValues.all)}
          active={shoreValue === frontValuesMappedToBackendValues.all}
        >
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.shoreSelect.all`} />
        </Button>

        <Button
          className="btn-toggle-primary"
          color="primary"
          data-e2e="shoreSelect-specific"
          outline
          onClick={setShoreValue(frontValuesMappedToBackendValues.onshore)}
          active={shoreValue === frontValuesMappedToBackendValues.onshore}
        >
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.shoreSelect.onshore`} />
        </Button>

        <Button
          className="btn-toggle-primary"
          color="primary"
          outline
          onClick={setShoreValue(frontValuesMappedToBackendValues.offshore)}
          active={shoreValue === frontValuesMappedToBackendValues.offshore}
        >
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.shoreSelect.offshore`} />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ShoreSelect;
