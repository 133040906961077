import { ClientsMarginsTable } from './ClientsMarginsTable/ClientsMarginsTable';
import { ClientsMarginsFilters } from './ClientsMarginsFilters';
import { EditClientsMargins } from './EditClientsMargins';

const ClientsMargins = (): JSX.Element => {
  return (
    <div className="p-4 flex-grow-1 d-flex flex-column">
      <>
        <ClientsMarginsFilters />
        <EditClientsMargins />
        <ClientsMarginsTable />
      </>
    </div>
  );
};

export default ClientsMargins;
