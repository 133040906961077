import { FormattedMessage, useIntl } from 'react-intl';
import { type ChangeEvent, useState } from 'react';

type MarginInputTextProps = {
  labelLocalKey: string;
  name?: string;
  value: string;
  unit: 'mln' | 'bps' | 'pips';
  disabled?: boolean;
  onChange: (value: string) => void;
};

export function MarginInputText({
  labelLocalKey,
  name = '',
  value,
  unit,
  disabled = false,
  onChange,
}: MarginInputTextProps) {
  const { formatMessage } = useIntl();

  const [isEmptyField, setIsEmptyField] = useState<boolean>(false);

  const authorizedCharactersRegex = /^-?[0-9]*[.,]?[0-9]*$/;
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    if (authorizedCharactersRegex.test(value)) {
      onChange(value);
    }
  }

  function handleBlur(event: ChangeEvent<HTMLInputElement>) {
    const emptyValuesList = ['', '-'];
    setIsEmptyField(emptyValuesList.includes(event.target.value));
  }

  return (
    <div>
      <label className="text-primary mb-2" htmlFor={`margin${name !== '' ? '-' + name : ''}-input`}>
        <span className="text-primary fw-medium">
          <FormattedMessage id={labelLocalKey} />
        </span>
        &nbsp;
        <span className="text-secondary">
          <FormattedMessage id="common.unit.margin" values={{ unit }} />
        </span>
      </label>
      <input
        data-e2e={`margin${name !== '' ? '-' + name : ''}-input`}
        type="text"
        id={`margin${name !== '' ? '-' + name : ''}-input`}
        autoComplete="off"
        className={`form-control ${isEmptyField ? ' border-bottom border-danger' : ''}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={formatMessage({ id: 'placeholder.marginUnit' })}
        disabled={disabled}
      />
    </div>
  );
}
