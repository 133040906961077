import { marginApi } from 'services/marginProfiles/getMarginProfiles';

import { clientsApi } from './clientsApi';
import { clientsAdapter, getClientsApi } from './getClients';
import type { LagClient, PostClientPayload } from './models';

export const postClientApi = clientsApi.injectEndpoints({
  endpoints: (builder) => ({
    postClient: builder.mutation<LagClient, PostClientPayload>({
      query: (patch) => ({
        url: '',
        method: 'POST',
        body: patch,
      }),
      invalidatesTags: () => ['Clients'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: newClient } = await queryFulfilled;
          dispatch(
            getClientsApi.util.updateQueryData('getClients', undefined, (draft) => {
              clientsAdapter.addOne(draft, newClient);
            }),
          );
          dispatch(marginApi.util.invalidateTags(['marginProfiles']));
        } catch {
          console.warn('error occured when add new client');
        }
      },
    }),
  }),
});

export const { usePostClientMutation } = postClientApi;
