import { selectClientById } from 'services/clients';

import type { ModalName } from 'components/modals/ModalHandler';
import { ModalHandler } from 'components/modals/ModalHandler';
import {useAppSelector} from "../../../../../hooks/useAppSelector";
import {Authorize} from "../../../../../features/authorization/Authorize";

type Action = {
  name: string;
  icon: string;
  modalName: ModalName;
};
const ACTIONS: Action[] = [
  { name: 'edit-client-profile-button', icon: 'edit', modalName: 'ClientFormModal' },
  { name: 'duplicate-client-button', icon: 'content_copy', modalName: 'DuplicateClientModal' },
  { name: 'delete-client-button', icon: 'delete', modalName: 'DeleteClientModal' },
];

export type ActionRendererProps = {
  clientId: number;
  hide: boolean;
};

const ActionRenderer = ({ clientId, hide }: ActionRendererProps): JSX.Element => {
  const client = useAppSelector((state) => selectClientById(state)(clientId));

  if (hide) return <></>;

  return (
    <Authorize authorizedFor={['LAG_WRITE']}>
      <span className="flex-between align-items-center" style={{ height: 'inherit' }}>
        {ACTIONS.map(({ name, icon, modalName }) => (
          <ModalHandler key={name} modalName={modalName} client={client}>
            <button className="btn btn-flat-secondary btn-sm" data-e2e={name}>
              <em className="icon">{icon}</em>
            </button>
          </ModalHandler>
        ))}
      </span>
    </Authorize>
  );
};

export default ActionRenderer;
