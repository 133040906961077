import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import type { UpdateMarginsPayload } from 'services/marginProfiles';
import { useUpdateMarginsMutation } from 'services/marginProfiles';

import { LoadingButton } from 'components/LoadingButton';
import {useAppSelector} from "../../../../hooks/useAppSelector";
import {
  getMarginProfileDiff,
  getMarginProfileEditionCellCount,
  isEditingMarginProfiles,
  isSavingMarginProfiles
} from "../../../../store/marginProfilesEdition/marginProfilesEditionSelectors";
import {useAppDispatch} from "../../../../hooks/useAppDispatch";
import {actionCreators} from "../../../../store/actions";
import {Authorize} from "../../../../features/authorization/Authorize";

const EditMarginPresets = (): JSX.Element => {
  const isEditing = useAppSelector(isEditingMarginProfiles);
  const isSaving = useAppSelector(isSavingMarginProfiles);
  const editedCellsCount = useAppSelector(getMarginProfileEditionCellCount);
  const marginsDiff = useAppSelector(getMarginProfileDiff);
  const [updateMargins] = useUpdateMarginsMutation();

  const dispatch = useAppDispatch();

  const toggleEdit = useCallback(
    () => dispatch(actionCreators.marginProfileEdition.editionToggled()),
    [dispatch],
  );

  const save = useCallback(() => {
    const payload: UpdateMarginsPayload = Object.entries(marginsDiff).flatMap(([id, value]) => ({
      id,
      value,
    }));
    updateMargins(payload);
    toggleEdit();
  }, [marginsDiff, toggleEdit, updateMargins]);

  return (
    <Authorize authorizedFor={['LAG_MARGINS_WRITEPRESETS']}>
      {
        isEditing
          ? (
            <div className="d-flex align-items-center alert alert-info px-3 py-3">
              <div className="flex-fill">
                <span>
                  <FormattedMessage id="common.cellsEdited" values={ { editedCellsCount } }/>
                </span>
              </div>

              <div>
                <button className="btn btn-info m-1" disabled={ isSaving } onClick={ toggleEdit }>
                  <FormattedMessage id="common.cancel"/>
                </button>

                <LoadingButton
                  className="btn btn-outline-white m-1"
                  icon="save"
                  loading={ isSaving }
                  disabled={ editedCellsCount === 0 || isSaving }
                  onClick={ save }
                  data-e2e="save-margins-presets"
                >
                  <FormattedMessage id="clientsMargins.edit.saveChanges"/>
                </LoadingButton>
              </div>
            </div>
          )
          : (
            <div className="d-flex flex-row-reverse align-items-center my-3">
              <button
                className="btn btn-primary btn-icon-start"
                data-e2e="edit-margins-presets"
                onClick={ toggleEdit }
              >
                <i className="icon">edit</i>
                <FormattedMessage id="common.edit"/>
              </button>
            </div>
          )
      }
    </Authorize>
  );
};

export default EditMarginPresets;
