import { isNotDefined } from '@sgme/fp';
import clsx from 'clsx';
import type { ICellRendererParams } from '@ag-grid-community/core';
import type { GridCashProfileRow } from '@pages/fx/FxProductsPage/CashTab/useData';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export function CurrencyCellRenderer({ value, node, context, data }: ICellRendererParams<GridCashProfileRow, string>) {
  if (isNotDefined(value) || isNotDefined(data)) {
    return undefined;
  }

  const isInverted = data.instrumentData.applyOnInvertedPairDimension === 'True';
  const tooltipId = `tooltip-inverted-ccy-icon-${data.instrumentData.productKey}-${data.instrumentData.gridDataType}-${data.uiRowId}`;
  const [ccy1, ccy2] = data.currency.split('/');

  const isLastChild = node.lastChild;
  const isEdited = node.data?.state !== 'untouched';

  return value === '*' ? (
    <span
      className={clsx({
        'text-primary': isLastChild,
        'text-secondary': !isEdited && !isLastChild,
        'text-info': isEdited,
      })}
    >
      {context.formatMessage({ id: '*' })}
    </span>
  ) : (
    <>
      <span
        className={clsx({
          'text-info': isEdited,
        })}
      >
        {value}
        {isInverted && (
          <>
            <i
              id={tooltipId}
              data-e2e="apply-on-inverted-pair-currency-icon"
              className="icon icon-sm ms-1 position-absolute top-0"
            >
              swap_horiz
            </i>
            <UncontrolledTooltip
              data-toggle="tooltip"
              placement="bottom"
              target={tooltipId}
              data-e2e="apply-on-inverted-pair-currency-tooltip"
            >
              <FormattedMessage id="fx.message.tooltip.invertedCurrencyPair" values={{ ccy1, ccy2 }} />
            </UncontrolledTooltip>
          </>
        )}
      </span>
    </>
  );
}
