import {
  type FxCashGridDataType,
  type FxCashProductKey,
  isFxCashGridDataType,
  isFxCashProductKey,
} from '@services/fx/model/cash';
import type { ClientBdrId, ClientBdrLevel } from '@services/fx/model/client';
import type { ProfileId } from '@services/fx/model/models';
import { type FxOrderProductKey, isFxOrderProductKey } from '@services/fx/model/order';
import { type FxPerimeterKey, isPerimeterKey } from '@services/fx/model/perimiters';
import { parseClientBdrId, parseClientBdrLevel } from '@services/fx/transformFxResponse';
import { isDefined } from '@sgme/fp';
import { useParams, useSearchParams } from 'react-router-dom';

interface UseUrlParamsResponse {
  clientBdrId?: ClientBdrId;
  clientBdrLevel?: ClientBdrLevel;
  perimeterKey?: FxPerimeterKey;
  productKey?: FxOrderProductKey | FxCashProductKey;
  gridDataType?: FxCashGridDataType;
  profileId?: ProfileId;
  isInherited: boolean;
}

// todo-5231 I think it was a bad idea to split the url hooks in several ones to better type them
// devs should not know that there are 3 different ones. They should just une ONE hook that exposes all url params possibly undefined
// and the consumer should type it adequalty. Better discvoerability and ownership for the consumer

export const useFxUrlParams = (): UseUrlParamsResponse => {
  const urlParams = useParams();
  const [searchParams] = useSearchParams();

  const clientBdrId = parseClientBdrId(urlParams.clientBdrId);
  const clientBdrLevel = parseClientBdrLevel(urlParams.clientBdrLevel);
  const perimeterKey = parsePerimeterKeyParams(urlParams.perimeterKey);
  const productKey = parseProductKeyParams(urlParams.productKey);
  const gridDataType = parseGridDataType(urlParams.gridDataType);

  const isInherited = searchParams.get('isInherited') === 'true';
  return {
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
    profileId: isDefined(searchParams.get('profileId')) ? Number(searchParams.get('profileId')) : undefined,
    isInherited,
  };
};

const parsePerimeterKeyParams = (perimeterKeyParam?: string): FxPerimeterKey | undefined =>
  isPerimeterKey(perimeterKeyParam) ? perimeterKeyParam : undefined;

const parseProductKeyParams = (productKeyParam?: string): FxOrderProductKey | FxCashProductKey | undefined =>
  isFxOrderProductKey(productKeyParam) || isFxCashProductKey(productKeyParam) ? productKeyParam : undefined;

const parseGridDataType = (gridDataType?: string): FxCashGridDataType | undefined =>
  isFxCashGridDataType(gridDataType) ? gridDataType : undefined;
