import type {
  FxCashGridDataType,
  FxCashProfile,
  FxCashProfileRow,
} from "@services/fx/model/cash";
import type {
  FxPerimeterKey,
  FxProductKey,
} from "@services/fx/model/perimiters";
import { kebabize } from "@services/fx/transformFxResponse";
import { mapRowsForFrontend } from "@services/fx/validateMarginGrid";
import { fxApi } from "./fxApi";
import type { ClientBdrLevel } from "./model/client";
import type { FxMarginProfileTypeKey, FxPayload } from "./model/models";

export type ResponseFxPreset = FxCashProfile;

type PayloadType = {
  profileName?: string;
  columns: FxCashProfileRow["columns"];
};

export const addFxCashPresetApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    addFxCashPreset: builder.mutation<
      ResponseFxPreset,
      {
        perimeterKey: FxPerimeterKey;
        productKey: FxProductKey;
        profileTypeKey: FxMarginProfileTypeKey;
        payload: PayloadType;
        queryParams?: {
          clientBdrId: number;
          clientBdrLevel: ClientBdrLevel;
          gridDataType: FxCashGridDataType;
        };
      }
    >({
      query: ({
        perimeterKey,
        productKey,
        profileTypeKey,
        payload,
        queryParams,
      }) => {
        const { profileName, columns } = payload;
        return {
          method: "POST",
          url: `perimeters/${perimeterKey}/products/${productKey}/profiles`,
          params: queryParams,
          body: {
            profileName,
            profileTypeKey,
            rows: [
              {
                internalRowId: [""],
                columns: mapColumnsForBackend(columns),
                state: "added",
              },
            ],
          },
        };
      },
      invalidatesTags: ["Profiles"],
      transformResponse: (
        response: FxPayload<ResponseFxPreset>
      ): FxCashProfile => {
        const { rows: rowsFromBackend, ...profile } = response.data;
        return {
          ...profile,
          rows: mapRowsForFrontend(rowsFromBackend) as FxCashProfileRow[],
        };
      },
    }),
  }),
});

export const { useAddFxCashPresetMutation } = addFxCashPresetApi;

const mapColumnsForBackend = (columns: FxCashProfileRow["columns"]) =>
  Object.fromEntries(
    Object.entries(columns).map(([key, value]) => [kebabize(key), value])
  );
