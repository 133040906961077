import { isNotDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import { HStack } from '@sgme/ui';
import clsx from 'clsx';

// why props: any ?
// because for AgGrid, cellRenderer is any !
export const MarginBidAskCell = (props: any) => {
  const { value, node } = props;

  if (isNotDefined(value)) {
    return undefined;
  }
  const isEdited = node.data?.state !== 'untouched';

  if (value.isRejected) {
    return (
      <span className={clsx('fw-bold', 'text-secondary', isEdited && 'text-info')}>
        <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.rejected" />
      </span>
    );
  }

  return value.type === 'margin' ? (
    <HStack gap="16px" horizontalAlignment="start">
      <span className={clsx('text-turquoise-70', 'fw-bold', isEdited && 'text-info')}>{value.marginBidValue}</span>
      <span>/</span>
      <span className={clsx('text-pink-70', 'fw-bold', isEdited && 'text-info')}>{value.marginAskValue}</span>
      <span className={clsx('text-secondary', 'fw-bold', isEdited && 'text-info')}>{value.marginUnit}</span>
    </HStack>
  ) : (
    <span className={clsx('fw-bold', isEdited && 'text-info')}>{value.tiering}</span>
  );
};
