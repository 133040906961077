import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { LagTitle } from './LagTitle';
import { LAG_ROUTE } from "../../../lag/contants";

export const LagPresetsHeader = () => (
  <div className="flex-between align-items-baseline mb-4">
    <LagTitle />

    <Link
      className="btn btn-icon-start btn-link fs-16 align-self-end"
      data-e2e="back-margins-presets"
      to={LAG_ROUTE}
    >
      <em className="icon">arrow_back</em>
      <FormattedMessage id="common.back" />
    </Link>
  </div>
);
