import { makeValuesPredicate } from '@sgme/fp';
import type { FxProfileMarginGrid } from './profile-margin-grid';
import type { FxCurrencyPairDimension } from '@services/fx/model/models';

export const FX_CASH_PRODUCT_KEYS = ['spot-product', 'swap-product', 'ndf-product'] as const;
export type FxCashProductKey = (typeof FX_CASH_PRODUCT_KEYS)[number];
export const isFxCashProductKey = makeValuesPredicate(FX_CASH_PRODUCT_KEYS);
export const FX_CASH_PERIMETER_KEY = 'fx-cash-rfq-perimeter';

export const TIERING_GRID_DATA_TYPE = 'tiering-grid-data-type';
export const MARGIN_GRID_DATA_TYPE = 'margin-grid-data-type';
const FX_CASH_GRID_DATA_TYPES = [TIERING_GRID_DATA_TYPE, MARGIN_GRID_DATA_TYPE] as const;
export type FxCashGridDataType = (typeof FX_CASH_GRID_DATA_TYPES)[number];

export const isFxCashGridDataType = makeValuesPredicate(FX_CASH_GRID_DATA_TYPES);

export type FxCashProfile = FxProfileMarginGrid<FxCashProfileColumns>;

export type FxCashProfileRow = FxCashProfile['rows'][number];

export type FxCashProfileColumns =
  | FxCashProfileMarginColumns
  | FxCashProfileTieringColumns
  | FxCashProfileRejectedColumns;

type FxCashProfileBaseColumns = {
  mediaDimension: string; // venue
  extClientLoginDimension: string; // clientVenueLogin
  onshoreOffshoreDimension: string; // onshoreOffshore

  // currency -> same as order
  currencyPairDimension: string; // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
  applyOnInvertedPairDimension: 'True' | 'False'; // False if pips and currency pair (and disabled)

  // amount
  amountStartDimension: string;
  amountEndDimension: string;
  amountCurrencyDimension: '*';

  // tenor
  tenorStartDimension: string;
  tenorEndDimension: string;
};

export type FxCashProfileMarginColumns = FxCashProfileBaseColumns & {
  // margin data (if isRejected = False)
  marginBidValue: string;
  marginAskValue: string;
  marginUnit: string; // pip / bps

  isRejected: 'False';
};

export type FxCashProfileTieringColumns = FxCashProfileBaseColumns & {
  // tiering (if isRejected = False)
  tieringResult: string;

  isRejected: 'False';
};

export type FxCashProfileRejectedColumns = FxCashProfileBaseColumns & {
  isRejected: 'True';
};

export type FxCashPerimeterKey = typeof FX_CASH_PERIMETER_KEY;

export interface FxCashDimensions {
  mediaDimension: string[];
  tenorDimension: string[];

  onshoreOffshoreDimension: string[];
  applyOnInvertedPairDimension: string[];
}

export interface FxCashMarginDimensionsValues {
  dimensions: FxCashDimensions;
  currencyPairDimension: FxCurrencyPairDimension;
  marginUnits: string[];
}

export interface FxCashTieringDimensionsValues {
  dimensions: FxCashDimensions;
  currencyPairDimension: FxCurrencyPairDimension;
  tiering: Record<string, string[]>;
}
