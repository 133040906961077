import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isDefined } from '@sgme/fp';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  getProfileReferenceFromClientInheritanceData,
  useGetFXClientsInheritanceQuery,
} from '@services/fx/getFXClientsInheritance';

export const useGetFxProductChanges = () => {
  const { perimeterKey, productKey, clientBdrId, clientBdrLevel, profileId, gridDataType } = useFxUrlParams();

  const rowByUiId = useAppSelector((state) => state.fxProfileEdition.rowByUiId);
  const editingRow = useAppSelector((state) => state.fxProfileEdition.editingRow);
  const haveRowsChanged = !Object.values(rowByUiId).every((row) => row.state === 'untouched');
  const hasRowInEdition = isDefined(editingRow);

  let hasUserChangedSelectedProfile = false;

  const queryParams = isDefined(clientBdrLevel) && isDefined(clientBdrId) ? { clientBdrId, clientBdrLevel } : skipToken;
  const { data: clientInheritance } = useGetFXClientsInheritanceQuery(queryParams);
  const savedProfile = getProfileReferenceFromClientInheritanceData(
    clientInheritance,
    perimeterKey,
    productKey,
    gridDataType,
  );

  // we are in case where a profile has been selected by the user
  if (isDefined(profileId)) {
    hasUserChangedSelectedProfile = savedProfile?.profileId !== profileId;
  }

  return {
    haveRowsChanged,
    hasRowInEdition,
    hasUserChangedSelectedProfile,
  };
};
