import { useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { isDefined } from '@sgme/fp';

import { FormMessageStatus } from 'components/Form/FormMessageStatus';
import { maximumUpperMaturityRange, minimumLowerMaturityRange } from 'utils/maturityRange';

const RULES = {
  min: minimumLowerMaturityRange,
  max: maximumUpperMaturityRange,
  required: true,
} as const;

export const Maturities = (): JSX.Element => {
  const {
    field: lowerMaturityRangeField,
    fieldState: { error: lowerMaturityRangeError },
  } = useController({
    defaultValue: minimumLowerMaturityRange,
    name: 'lowerMaturityRange',
    rules: RULES,
  });

  const {
    field: upperMaturityRangeField,
    fieldState: { error: upperMaturityRangeError },
  } = useController({
    defaultValue: maximumUpperMaturityRange,
    name: 'upperMaturityRange',
    rules: RULES,
  });

  return (
    <>
      <label className="fw-medium mt-3 mb-2">
        <FormattedMessage id="clientForm.maturities"/>
      </label>

      <div className="d-flex justify-content-between align-items-center gap-2 w-100">
        <label className="fw-medium">
          <FormattedMessage id="clientForm.maturities.from"/>
        </label>

        <input
          { ...lowerMaturityRangeField }
          className={ `flex-grow-1 form-control ${ isDefined(lowerMaturityRangeError) ? 'is-warning' : '' }` }
          type="number"
        />

        <label className="fw-medium">
          <FormattedMessage id="clientForm.maturities.to"/>
        </label>

        <input
          { ...upperMaturityRangeField }
          className={ `flex-grow-1 form-control ${ isDefined(upperMaturityRangeError) ? 'is-warning' : '' }` }
          type="number"
        />
      </div>

      <FormMessageStatus
        name="clientForm"
        errorType="maturities"
        values={ { minimumLowerMaturityRange, maximumUpperMaturityRange } }
        icon="error"
        status="warning"
        show={ isDefined(lowerMaturityRangeError) || isDefined(upperMaturityRangeError) }
      />
    </>
  );
};
