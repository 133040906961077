import type { ChangeEventHandler } from 'react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export interface SelectProps<T> {
  className?: string;
  value: T | undefined;
  options: readonly [T, string][];
  onChange(value: T): void;
}

export const Select = <T extends string | number>({
  className = '',
  value,
  options,
  onChange,
}: SelectProps<T>) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (ev) => {
      // ev.currentTarget.value is always a string, the following keeps the correct type
      const extraOptionsCount = ev.currentTarget.options.length - options.length;
      const [value] = options[ev.currentTarget.selectedIndex - extraOptionsCount];
      onChange(value);
    },
    [options, onChange],
  );
  return (
    <select className={`form-select ${className ?? ""}`} value={value ?? ''} onChange={handleChange}>
      <FormattedMessage id="common.select">
        {(msg) => (
          <option value="" hidden disabled>
            {msg}
          </option>
        )}
      </FormattedMessage>
      <FormattedMessage id="clientsMargins.table.customMarginProfile">
        {(msg) => (
          <option value="Custom" hidden>
            {msg}
          </option>
        )}
      </FormattedMessage>
      {options.map(([value, label]) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};
