import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import type { SerializedError } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';

import { MarginInput } from 'components/fx/MarginInput';
import { MarginLiquidityProvider } from 'components/fx/MarginLiquidityProvider';
import { AlgoOrderProductMarginUnitSwitch } from 'components/fx/AlgoOrderProductMarginUnitSwitch';
import { LoadingButton } from 'components/LoadingButton';
import { getFxMarginUnit } from '@store/ui/uiSelectors';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import type { FxOrderProfile, OrderProfileColumns } from '@services/fx/model/order';
import { useAddFxOrderPresetMutation } from '@services/fx/addFxOrderPreset';

interface FormValues extends OrderProfileColumns {
  'margin-unit': string;
  marginValue: string;
  liquidityProviderDimension: {
    External: string;
    Internal: string;
  };
}

export const CustomOrderFormModal = ({ ...props }: ModalProps) => {
  const { modalHeaderClose } = props;

  const { perimeterKey, productKey, clientBdrId, clientBdrLevel, gridDataType } = useFxUrlParams();

  const [addFxOrderPreset, { isLoading }] = useAddFxOrderPresetMutation();
  const fxMarginUnit = useSelector(getFxMarginUnit);

  const marginUnit = () => {
    switch (productKey) {
      case 'algo-order-product':
        return fxMarginUnit.algo;
      case 'limit-order-product':
        return fxMarginUnit.limit;
      case 'fixing-order-product':
        return fxMarginUnit.fixing;
      default:
        return fxMarginUnit.limit;
    }
  };

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      'margin-unit': marginUnit(),
      liquidityProviderDimension: {},
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = async ({
    marginValue,
    liquidityProviderDimension: { External: externalMargin, Internal: internalMargin },
  }) => {
    // TODO: clientBdrId and clientBdrLevel are required ?
    if (isDefined(perimeterKey) && isDefined(productKey) && isDefined(clientBdrId) && isDefined(clientBdrLevel)) {
      const creationResponse = await addFxOrderPreset({
        perimeterKey,
        productKey,
        profileTypeKey: 'custom-profile-type',
        addFxPresetPayload: {
          'liquidity-provider-dimension.External.margin-value': externalMargin,
          'liquidity-provider-dimension.Internal.margin-value': internalMargin,
          'margin-unit': marginUnit(),
          'margin-value': marginValue,
        },
        queryParams: { clientBdrId, clientBdrLevel, gridDataType },
      });

      const { error, data } = creationResponse as { error: SerializedError; data: FxOrderProfile }; // bad typing of rtk query

      if (isDefined(error)) {
        // @TODO handle error on press button
        // setError({ type: 'alreadyExist' }, { shouldFocus: true });
      } else {
        if (props.onClosed) {
          props.onClosed();
        }
      }

      if (isDefined(data.profileId)) {
        navigate(`./edit?profileId=${data.profileId}`);
      }
    }
  };

  return (
    <Modal data-e2e="custom-margin-form-modal" contentClassName="border" {...props}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader tag="h3" close={modalHeaderClose}>
            <FormattedMessage id="newCustomModal.title" />
          </ModalHeader>

          <ModalBody className="pb-0">
            <label className="text-primary d-block mt-1 fw-medium mb-2">
              <FormattedMessage id="newCustomModal.baseMargins.title" />
            </label>

            <span className="text-secondary">
              <FormattedMessage id="newCustomModal.baseMargins.text" />
            </span>

            {productKey === 'algo-order-product' && (
              <>
                <div className="mt-3">
                  <AlgoOrderProductMarginUnitSwitch className="btn-block" />
                </div>

                <div className="mt-3">
                  <MarginLiquidityProvider showLabel={false} marginUnit={marginUnit()} />
                </div>
              </>
            )}
            {(productKey === 'limit-order-product' || productKey === 'fixing-order-product') && (
              <div className="mt-3">
                <MarginInput name="marginValue" unit={marginUnit()} />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <button type="reset" className="btn btn-lg btn-flat-primary" onClick={props.onClosed}>
              <FormattedMessage id="common.cancel" />
            </button>

            <LoadingButton
              type="submit"
              className="btn btn-lg btn-primary btn-text-icon"
              loading={isLoading}
              dataE2e={`client-pool-preset-button-save`}
              disabled={Object.keys(errors).length > 0}
              iconPosition={'end'}
              icon={'arrow_forward'}
            >
              <FormattedMessage id="newCustomModal.add" />
            </LoadingButton>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CustomOrderFormModal;
