import type { ICellRendererParams } from '@ag-grid-community/core';

import type { FxCashProfileRow } from '@services/fx/model/cash';
import { useDispatch } from 'react-redux';
import { deleteRows, duplicateRow, editRow } from '@store/fxProfileEdition/fxProfileEditionSlice';

type Action = {
  name: string;
  icon: string;
  action: () => void;
  disabled?: boolean;
};

export const ActionsCellRenderer = ({ data, node, context }: ICellRendererParams<FxCashProfileRow>): JSX.Element => {
  const dispatch = useDispatch();

  // there will always be a row for all actions below, therefore the casting in number
  const ACTIONS: Action[] = [
    {
      name: 'edit-fx-margin-button',
      icon: 'edit',
      action: () => {
        dispatch(editRow({ uiRowId: data?.uiRowId as number }));
      },
      disabled: false,
    },
    {
      name: 'duplicate-fx-margin-button',
      icon: 'content_copy',
      action: () => {
        dispatch(duplicateRow({ uiRowId: data?.uiRowId as number }));
      },
    },
    {
      name: 'delete-fx-margin-button',
      icon: 'delete',
      action: () => {
        dispatch(deleteRows({ uiRowsId: [data?.uiRowId as number] }));
      },
      disabled: node.lastChild,
    },
  ];

  return (
    <span className="flex-between align-items-center" style={{ height: 'inherit' }}>
      {ACTIONS.map(({ name, icon, action, disabled }) => (
        <button
          key={icon}
          className="btn btn-flat-secondary btn-sm"
          data-e2e={name}
          onClick={action}
          disabled={disabled}
        >
          <em className="icon icon-sm">{icon}</em>
        </button>
      ))}
    </span>
  );
};
