import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  GroupCellRendererParams,
  ICellRendererParams,
  KeyCreatorParams,
} from '@ag-grid-community/core';
import type { LagClient } from 'services/clients';
import type { MarginByMaturity, MarginProfile, SalesMarginProfile } from 'services/marginProfiles';

import { maximumUpperMaturityRange, minimumLowerMaturityRange } from 'utils/maturityRange';
import { range } from 'utils/range';

import ActionRenderer from './ActionRenderer';
import CellSelectPresetRenderer from './CellSelectPresetRenderer';
import ClientGroupRenderer from './ClientGroupRenderer';
import { useSelectPresetValues } from './useSelectPresetValues';
import ClientColumnHeader from './ClientColumnHeader';
import type {ClientDiff} from "../../../../../store/clientEdition/clientEditionSlice";
import {
  leftGroupHeaderClass,
  maturityClass,
  maturityHeaderClass,
  rightGroupHeaderClass,
  topHeaderClasses
} from "../../../contants";

type ConstantMargin = { type: 'pcru' | 'technical'; value: number };
type MarginsTableRow = {
  client: LagClient;
  marginProfile: MarginProfile | ConstantMargin;
  diff: ClientDiff;
};

const useColumnDef = (): (ColDef | ColGroupDef)[] => {
  const { formatMessage } = useIntl();
  const presetValues = useSelectPresetValues();

  const onClickToggleExpand = useCallback(({ node }: CellClickedEvent) => {
    if (node.isExpandable()) {
      node.setExpanded(!node.expanded);
    }
  }, []);

  const isOutsideClientRange = (client: LagClient, maturityIndex: number) =>
    client.lowerMaturityRange > maturityIndex || client.upperMaturityRange < maturityIndex;

  return useMemo(
    () => [
      {
        headerName: `${formatMessage({ id: 'clientsMargins.table.client' })}`,
        headerGroupComponent: ClientColumnHeader,
        headerClass: [...topHeaderClasses, leftGroupHeaderClass],
        children: [
          {
            headerName: '',
            field: 'marginType',
            headerClass: leftGroupHeaderClass,
            showRowGroup: 'client',
            onCellClicked: onClickToggleExpand,
            valueGetter: ({ data }) =>
              formatMessage({
                id: `clientsMargins.table.marginProfileType.${data.marginProfile.type}`,
              }),
            pinned: 'left',
            minWidth: 220,
            resizable: true,
            cellClassRules: {
              'text-secondary': ({ data }: CellClassParams) => !!data,
              [leftGroupHeaderClass]: () => true,
            },
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              suppressCount: true,
              suppressPadding: true,
              suppressDoubleClickExpand: true,
            },
          },
        ],
      },

      {
        headerName: 'Client',
        children: [
          {
            headerName: '',
            field: 'client',
            rowGroup: true,
            hide: true,
            keyCreator: ({ value }: KeyCreatorParams) => `${value.name}|${value.isActive}`,
            cellRenderer: ClientGroupRenderer,
            cellRendererParams: ({ node }: GroupCellRendererParams<MarginsTableRow>) => ({
              client: node?.childrenAfterGroup?.[0]?.data?.client,
            }),
          },
        ],
      },

      {
        headerName: 'Margin Profile Type',
        children: [{ field: 'marginProfileType', hide: true }],
      },

      {
        headerClass: [...topHeaderClasses, leftGroupHeaderClass],
        headerName: formatMessage({ id: 'clientsMargins.table.marginProfile' }),
        children: [
          {
            headerName: '',
            pinned: 'left',
            headerClass: leftGroupHeaderClass,
            field: 'marginProfileName',
            minWidth: 120,
            cellClassRules: {
              'text-secondary': (params: CellClassParams) => (params.data as MarginsTableRow)?.marginProfile.type === 'technical',
              'p-0': ({ context }) => context.isEditing,
              [leftGroupHeaderClass]: () => true,
              'fw-normal': () => true,
            },
            // This component don't use ag-grid feature with Editor and Renderer. We use only the renderer and the logic for editing is on the component.
            cellRenderer: CellSelectPresetRenderer,
            cellRendererParams: {
              presetValues,
            },
            editable: false,
            valueGetter: ({ node, data }) => {
              if (node?.group) {
                const salesMargin: SalesMarginProfile = node.allLeafChildren.find(
                  ({ data }) => data.marginProfile.type === 'Sales',
                )?.data.marginProfile;

                return salesMargin.isPreset
                  ? salesMargin.name
                  : formatMessage({ id: 'clientsMargins.table.customMarginProfile' });
              }

              const tableRow: MarginsTableRow = data;

              switch (tableRow.marginProfile.type) {
                case 'DevL':
                  return tableRow.marginProfile.name;
                case 'pcru':
                  return `${tableRow.marginProfile.value}bp`;
                case 'technical':
                  return tableRow.marginProfile.value;
                default:
                  return '';
              }
            },
          },
        ],
      },

      {
        headerClass: topHeaderClasses,
        headerName: formatMessage({ id: 'clientsMargins.table.marginByMaturity' }),
        children: range(minimumLowerMaturityRange, maximumUpperMaturityRange).map(
          (maturityIndex): ColDef => ({
            headerName: `${maturityIndex}`,
            colId: `maturity_${maturityIndex}`,
            minWidth: 60,
            maxWidth: 60,
            headerClass: [
              maturityHeaderClass,
              'text-secondary',
              maturityIndex === maximumUpperMaturityRange ? 'border-0' : '',
            ],
            cellClassRules: {
              [maturityClass]: () => true,
              'text-end': () => true,
              'text-secondary': (params: CellClassParams) =>
                (params.data as MarginsTableRow)?.marginProfile.type === 'pcru' ||
                (params.data as MarginsTableRow)?.marginProfile.type === 'technical',
            },
            valueGetter: (params) => {
              const { node } = params;

              const getMaturityValue = (
                maturities: MarginByMaturity[],
              ): MarginByMaturity | undefined =>
                maturities.find((margin) => margin.maturity === maturityIndex.toString());

              if (node?.group) {
                return '';
              }

              const tableRow: MarginsTableRow = params.data;
              if (isOutsideClientRange(tableRow.client, maturityIndex)) {
                return '-';
              }
              switch (tableRow.marginProfile.type) {
                case 'Sales':
                  return getMaturityValue(tableRow.marginProfile.fixedMargins)?.value ?? '-';
                case 'DevL':
                  return getMaturityValue(tableRow.marginProfile.margins)?.value ?? '-';
                case 'pcru':
                case 'technical':
                  return tableRow.marginProfile.value;
                default:
                  return '';
              }
            },
            onCellClicked: onClickToggleExpand,
          }),
        ),
      },

      {
        headerName: formatMessage({ id: 'clientsMargins.table.action' }),
        pinned: 'right',
        headerClass: [...topHeaderClasses, rightGroupHeaderClass],
        children: [
          {
            headerName: '',
            field: 'action',
            width: 150,
            pinned: 'right',
            headerClass: rightGroupHeaderClass,
            cellClass: [rightGroupHeaderClass, 'actions-button-cell'],
            cellStyle: { left: '1px' },
            cellRenderer: ActionRenderer,
            cellRendererParams: ({ node }: ICellRendererParams) => ({
              clientId: node?.childrenAfterGroup?.[0]?.data?.client?.id,
              hide: !node.group,
            }),
          },
        ],
      },
    ],
    [formatMessage, onClickToggleExpand, presetValues],
  );
};

export default useColumnDef;
