import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { selectEntitiesClients } from 'services/clients';
import { useUpdateClientsMutation } from 'services/clients/updateClients';

import { LoadingButton } from 'components/LoadingButton';
import { narrowDefined } from 'utils/asserts';
import { strictEntries } from 'utils/object';

import { SelectReason } from './SelectReason';
import {useAppSelector} from "../../hooks/useAppSelector";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {
  getEditedClientsDiffs,
  getEditedClientsIds, hasClientEditionMissingChangeReasons,
  isClientEditionReasonModalOpen, isSavingClientEdition
} from "../../store/clientEdition/clientEditionSelectors";
import {actionCreators} from "../../store/actions";

type ButtonStatus = 'updateOneByOne' | 'updateAll';

export function ChangeReasonsModal(): JSX.Element {
  const dispatch = useAppDispatch();

  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('updateOneByOne');

  const [isUpdateOneByOneDisabled, setIsUpdateOneByOneDisabled] = useState(false);

  const [isUpdateAllDisabled, setIsUpdateAllDisabled] = useState(false);

  const [updateClients] = useUpdateClientsMutation();

  const entitiesClients = useAppSelector(selectEntitiesClients);
  const diffs = useAppSelector(getEditedClientsDiffs);
  const isOpen = useAppSelector(isClientEditionReasonModalOpen);
  const clientIds = useAppSelector(getEditedClientsIds);
  const isLoading = useAppSelector(isSavingClientEdition);
  const isNotReadyToSubmit = useAppSelector(hasClientEditionMissingChangeReasons);

  const toggle = useCallback(
    () => dispatch(actionCreators.clientEdition.reasonModalToggled()),
    [dispatch],
  );

  const submit = useCallback(async () => {
    const updatedClients = strictEntries(diffs).map(
      ([id, { changeReason: reasonForChange, ...diff }]) => ({
        id,
        name: narrowDefined(entitiesClients[id]).name,
        ...diff,
        reasonForChange,
      }),
    );

    updateClients(updatedClients);
    dispatch(actionCreators.clientEdition.reset());
  }, [diffs, dispatch, entitiesClients, updateClients]);

  useEffect(() => {
    if (isNotReadyToSubmit) {
      if (clientIds.length === 1) {
        setButtonStatus('updateOneByOne');
        setIsUpdateOneByOneDisabled(false);
        setIsUpdateAllDisabled(true);
      } else if (clientIds.length > 9) {
        setButtonStatus('updateAll');
        setIsUpdateOneByOneDisabled(true);
        setIsUpdateAllDisabled(false);
      } else if (clientIds.length > 4 && clientIds.length < 10) {
        setButtonStatus('updateAll');
        setIsUpdateOneByOneDisabled(false);
        setIsUpdateAllDisabled(false);
      } else {
        setButtonStatus('updateOneByOne');
        setIsUpdateOneByOneDisabled(false);
        setIsUpdateAllDisabled(false);
      }
    }
  }, [clientIds, setButtonStatus, isNotReadyToSubmit]);
  return (
    <Modal isOpen={isOpen} data-e2e="change-reason-modal">
      <ModalHeader tag="h3" toggle={toggle}>
        <FormattedMessage id="changeReasonModal.title" />
      </ModalHeader>

      <ModalBody>
        <label className="fw-normal text-primary fw-medium mb-2">
          <FormattedMessage id="changeReasonModal.toggle.title" />
        </label>

        <div
          className="btn-group btn-group-toggle mb-4"
          data-toggle="buttons"
          role="group"
          aria-label="toggle buttons single select info color"
        >
          <label
            className={`${isUpdateAllDisabled ? 'disabled' : ''} btn btn-toggle-primary fw-medium mb-2 ${
              buttonStatus === 'updateAll' && !isUpdateAllDisabled ? 'active' : ''
            }`}
            onClick={() => !isUpdateAllDisabled && setButtonStatus('updateAll')}
            data-e2e="update-all"
          >
            <input
              type="radio"
              name="toggle"
              id="toggle-1"
              autoComplete="off"
              disabled={isUpdateAllDisabled}
            />

            <span>
              <FormattedMessage id="changeReasonModal.toggle.updateAll" />
            </span>
          </label>

          <label
            className={`${isUpdateOneByOneDisabled ? 'disabled' : ''} btn btn-toggle-primary fw-medium mb-2 ${
              buttonStatus === 'updateAll' ? '' : 'active'
            }`}
            onClick={() => !isUpdateOneByOneDisabled && setButtonStatus('updateOneByOne')}
            data-e2e="update-one-by-one"
          >
            <input
              type="radio"
              name="toggle"
              id="toggle-2"
              autoComplete="off"
              disabled={isUpdateOneByOneDisabled}
            />

            <span>
              <FormattedMessage id="changeReasonModal.toggle.updateOneByOne" />
            </span>
          </label>
        </div>

        <SelectReason
          clientIds={clientIds}
          mode={buttonStatus === 'updateOneByOne' ? 'one-client' : 'all-clients'}
        />
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-lg btn-flat-secondary" onClick={toggle}>
          <FormattedMessage id="common.cancel" />
        </button>

        <LoadingButton
          className="btn btn-lg btn-primary"
          onClick={submit}
          loading={isLoading}
          icon="save"
          disabled={isNotReadyToSubmit || isLoading}
        >
          <FormattedMessage id="common.save" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
