import { useState } from 'react';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import useGetFXAvailableProfilesByType from '../../../../hooks/useGetFXAvailableProfilesByType';
import type { InternalProfileTypeKey } from '@services/fx/model/models';
import UpperCommercialLevelProfilType from './UpperCommercialLevelProfilType';
import { ProfilesSelect } from './ProfilesSelect';
import { CollapsableSidePanel } from '@pages/fx/FxSelectProfilePage/FxSelectableProfiles/CollapsableSidePanel';

interface FxProfilesPanelProps {
  defaultCollapsed?: boolean;
}

export const FxSelectableProfiles = ({ defaultCollapsed = false }: FxProfilesPanelProps): JSX.Element => {
  const { gridDataType } = useFxUrlParams();
  const [collapsed, setCollapse] = useState(defaultCollapsed);

  const availableProfilesByType = useGetFXAvailableProfilesByType();

  const availableProfileKeysWithoutDefaultOnes =
    (availableProfilesByType?.ids.filter((id) => id !== 'default-profile-type') as InternalProfileTypeKey[]) ?? [];

  return (
    <CollapsableSidePanel
      collapsed={collapsed}
      toggle={() => setCollapse((collapsed) => !collapsed)}
      headerLocaleKey={`${gridDataType?.startsWith('tiering') ? 'tiering' : 'margin'}.preset.selection`}
      className="border-end"
    >
      <UpperCommercialLevelProfilType />

      {availableProfileKeysWithoutDefaultOnes.map((profileKey) => (
        <ProfilesSelect
          key={profileKey}
          className="mb-2"
          profileTypeKey={profileKey}
          profilesByType={availableProfilesByType}
        />
      ))}
    </CollapsableSidePanel>
  );
};
