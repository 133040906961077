import type { FC, MouseEvent } from 'react';
import { Button, HStack, VStack } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

type TechnicalErrorProps = {
  reload: () => void;
  message: string;
  className?: string;
};

const TechnicalError: FC<TechnicalErrorProps> = ({ reload, message, className }) => {
  //this is needed to stop event propagation otherwise, it bubble up
  // to the submit form, submitting the rename or profile modification again
  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <HStack className={`bg-warning bg-opacity-10 px-3 py-16px ${className ?? ''}`} gap={'16px'}>
      <StyledSpan>
        <FormattedMessage id={'error.TechnicalError'} />
      </StyledSpan>
      <button className="btn btn-icon" id='technical-error-tooltip' type='button' onClick={onClick}>
        <em className="icon icon-md text-warning">help_outline</em>
      </button>
      <Button purpose={'outline'} color={'warning'} onClick={reload}>
        <FormattedMessage id='common.reload' />
      </Button>
      <UncontrolledPopover target={'technical-error-tooltip'} placement="bottom">
        <PopoverBody>
          <VStack gap={'16px'}>
            <span className={'fw-bold'}>
              <FormattedMessage id='error.TechnicalError.title' />
            </span>
            <span>{message}</span>
          </VStack>
        </PopoverBody>
      </UncontrolledPopover>
    </HStack>
  );
};

export default TechnicalError;

const StyledSpan = styled.span.attrs({
  className: 'text-warning',
})`
  strong {
    color: inherit !important; // this is done as strong balise in translation file gets applied an automatic text-secondary className, therfore we need to override it
  }
`;
