import type { EntityState } from '@reduxjs/toolkit';
import { makeValuesPredicate } from '@sgme/fp';
import type { FxProfileMarginGrid } from '@services/fx/model/profile-margin-grid';
import type { FxCurrencyPairDimension, InternalRowId, ProfileId, ProfileTypeKey } from '@services/fx/model/models';

export interface FxOrderProfile {
  profileId: ProfileId;
  profileName: string;
  profileVersion: number;
  profileTypeKey: ProfileTypeKey;
  clientsCount: number;
  gridDataTypeKey: null;
  header: FxOrderProductHeader[];
  rows: EntityState<FxOrderProfileRow>;
}

export interface FxOrderProductHeader {
  columnKey: string;
  subHeaderFlattenedValues?: string[] | null;
}

export const ORDER_PROFILE_COLUMN_KEYS = [
  'mediaDimension',
  'algoTypeDimension',
  'limitOrderTypeDimension',
  'fixingSourceDimension',
  'instrumentDimension',
  'currencyPairDimension',
  'marginValue',
  'marginUnit',
] as const;
export type OrderProfileColumnKey = (typeof ORDER_PROFILE_COLUMN_KEYS)[number];
export const isOrderProfileColumnKey = makeValuesPredicate(ORDER_PROFILE_COLUMN_KEYS);

export interface OrderProfileColumns extends Record<OrderProfileColumnKey, string> {
  liquidityProviderDimension: OrderProfileProviderColumns;
}

export const ORDER_PROFILE_COLUMN_PROVIDER_KEYS = ['External', 'Internal'] as const;
export type OrderProfileColumnProviderKey = (typeof ORDER_PROFILE_COLUMN_PROVIDER_KEYS)[number];
export const isOrderProfileColumnProviderKey = makeValuesPredicate(ORDER_PROFILE_COLUMN_PROVIDER_KEYS);
export type OrderProfileProviderColumns = Record<OrderProfileColumnProviderKey, string>;

export interface FxOrderProfileRow extends OrderProfileColumns {
  internalRowId: InternalRowId;
  uiRowId?: number;
  state?: EditedRowState;
  rowContext: RowContext;
  frontRowId?: string;
}

type FxOrderProfileAlgoColumnsMappedForAgGrid = Omit<
  FxOrderProfileAlgoColumns,
  'liquidityProviderDimension.External.marginValue' | 'liquidityProviderDimension.Internal.marginValue'
> & { liquidityProviderDimension: OrderProfileProviderColumns };

export type FxOrderProfileRowMappedForAgGrid = (
  | FxOrderProfileAlgoColumnsMappedForAgGrid
  | FxOrderProfileLimitColumns
  | FxOrderProfileFixingColumns
) & {
  internalRowId: InternalRowId;
  uiRowId?: number;
  state?: EditedRowState;
  rowContext: RowContext;
  frontRowId?: string;
};

export type EditedRowState = 'touched' | 'untouched' | 'deleted' | 'added';

interface RowContext {
  isLastRow: boolean;
}

export type ColumnsWithLiquidityDimension = Record<OrderProfileColumnKey, string> & {
  'liquidityProviderDimension.External.marginValue': string;
  'liquidityProviderDimension.Internal.marginValue': string;
};

export interface FxOrderProfileRowApi extends FxOrderProfileRow {
  columns?: ColumnsWithLiquidityDimension;
}

// todo-5198 clean the above model to have only one profile representation removing v2
// NEW REFAC MODEL TO CLEAN
export type FxOrderProfileColumns =
  | FxOrderProfileAlgoColumns
  | FxOrderProfileLimitColumns
  | FxOrderProfileFixingColumns;

export type FxOrderProfileBaseColumns = {
  mediaDimension: string; // venue
  instrumentDimension: string;
  currencyPairDimension: string; // single (CUR1), pair (CUR1/CUR2), group (from a list like G10) and All (*)
};

export type AlgoMarginUnit = 'mln' | 'bps';
export type LimitMarginUnit = 'pips';
export type FixingMarginUnit = 'bps';

export type FxMarginUnit = {
  algo: AlgoMarginUnit;
  limit: LimitMarginUnit;
  fixing: FixingMarginUnit;
};

export type FxOrderProfileAlgoColumns = FxOrderProfileBaseColumns & {
  algoTypeDimension: string;
  'liquidityProviderDimension.External.marginValue': string;
  'liquidityProviderDimension.Internal.marginValue': string;
  marginUnit: AlgoMarginUnit;
};

export type FxOrderProfileLimitColumns = FxOrderProfileBaseColumns & {
  limitOrderTypeDimension: string;
  marginValue: string;
  marginUnit: LimitMarginUnit;
};

export type FxOrderProfileFixingColumns = FxOrderProfileBaseColumns & {
  fixingSourceDimension: string;
  marginValue: string;
  marginUnit: FixingMarginUnit;
};

export type FxOrderProfileV2 = FxProfileMarginGrid<FxOrderProfileColumns>;

export type FxOrderProfileRowV2 = FxOrderProfileV2['rows'][number];

/**
 * PRODUCT
 */
export const FX_ORDER_PRODUCT_KEYS = ['algo-order-product', 'limit-order-product', 'fixing-order-product'] as const;
export type FxOrderProductKey = (typeof FX_ORDER_PRODUCT_KEYS)[number];
export const isFxOrderProductKey = makeValuesPredicate(FX_ORDER_PRODUCT_KEYS);

export const FX_ORDER_PERIMETER_KEY = 'fx-order-perimeter';
export type FxOrderPerimeterKey = typeof FX_ORDER_PERIMETER_KEY;

export interface FxAlgoOrderDimensions extends FxOrderCommonDimensions {
  algoTypeDimension: string[];
}

export interface FxFixingOrderDimensions extends FxOrderCommonDimensions {
  fixingSourceDimension: string[];
}

export interface FxLimitOrderDimensions extends FxOrderCommonDimensions {
  limitOrderTypeDimension: string[];
}

export type FxOrderDimensions = FxOrderCommonDimensions &
  (FxAlgoOrderDimensions | FxFixingOrderDimensions | FxLimitOrderDimensions);

export interface FxOrderDimensionsValues {
  dimensions: FxOrderDimensions;
  currencyPairDimension: FxCurrencyPairDimension;
  marginUnits: string[];
}

type FxOrderCommonDimensions = {
  mediaDimension: string[];
  instrumentDimension: string[];
};
