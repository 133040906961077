import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { prepareHeaders } from 'services/prepareHeaders';

import { getAppConfig } from 'config';

const appConfig = getAppConfig();

export const fxApi = createApi({
  reducerPath: 'fxApi',
  tagTypes: ['Profiles', 'Profile', 'ClientsUsingProfile', 'ClientsInheritance', 'FXClients'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${appConfig.apiUrl}/api/fx/`,
    prepareHeaders,
  }),
  endpoints: () => ({}),
});
