import { isDefined, isNotDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type ProductPropertyProps = {
  dataE2e: string;
  labelId: string;
  value?: string | number;
  formattedValueId?: string;
};
export const ProductProperty = (props: ProductPropertyProps) => {
  const { dataE2e, labelId, value, formattedValueId } = props;

  if ((isNotDefined(value) || value === '') && isNotDefined(formattedValueId)) {
    return null;
  }

  return (
    <span data-e2e={dataE2e}>
      <FormattedMessage id={labelId} />

      <span className="text-primary ms-1">
        {value}

        {isNotDefined(value) && isDefined(formattedValueId) && <FormattedMessage id={formattedValueId} />}
      </span>
    </span>
  );
};
export const StyledPropertyContainer = styled.div`
  * + *::before {
    content: '•';
    margin-right: 8px;
    margin-left: 8px;
    justify-content: center;
    flex-flow: row wrap;  }
`;
