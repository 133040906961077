import { skipToken } from "@reduxjs/toolkit/query";
import { isDefined, isNotDefined } from "@sgme/fp";
import { useBdrReference } from "BdrContext";

import { FallbackMessage } from "@components/FallbackMessage";
import { useGetFXClientQuery } from "@services/fx/getFXClient";
import { FxFirstClientWithperimeterKey, FxSubClientsList } from "./FxClient";
import { SearchMessage } from "./SearchMessage";

export const FxClients = (): JSX.Element => {
  const { reference: bdrReference } = useBdrReference();

  const clientBdrId = bdrReference?.id;
  const clientBdrLevel = bdrReference?.level;

  const params = isNotDefined(clientBdrId)
    ? skipToken
    : isDefined(clientBdrLevel)
      ? { clientBdrId, clientBdrLevel }
      : { clientBdrId };

  const { data: allClients } = useGetFXClientQuery(params);

  if (isNotDefined(bdrReference?.id)) {
    return <SearchMessage />;
  }

  if (allClients?.length === 0) {
    return (
      <FallbackMessage
        icon="domain_disabled"
        headerLocaleKey="no.data.for.client"
      />
    );
  }

  const singleClient = allClients?.length === 1 ? allClients?.[0] : undefined;
  const perimeters = allClients?.[0].perimeters ?? [];

  return (
    <>
      <div data-e2e="clients">
        {allClients?.map((client) => (
          <div
            key={`${client.clientBdrId}/${client.clientBdrLevel}`}
            data-e2e="fx-client-item"
          >
            <FxFirstClientWithperimeterKey
              client={client}
              perimeters={perimeters}
            />
          </div>
        ))}

        {isDefined(singleClient) && singleClient.children.length > 0 && (
          <FxSubClientsList
            perimeters={perimeters}
            childrenList={singleClient.children}
          />
        )}
      </div>
    </>
  );
};
