import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { MarginByMaturity, MarginProfileType } from 'services/marginProfiles';
import { selectAllMarginProfiles } from 'services/marginProfiles';
import type { MarginProfileDiffs } from 'store/marginProfilesEdition/marginProfilesEditionSlice';
import {getMarginProfileDiff} from "../../../../../store/marginProfilesEdition/marginProfilesEditionSelectors";

type MarginPresetsRow = {
  id: number;
  type: MarginProfileType | 'Custom' | 'Settings';
  name: string;
  marginsType: 'fixedMargins' | 'techMarginsFixed' | 'techMarginsFloating' | 'adjustmentMargins';
  margins: MarginByMaturity[];
  marginProfileDiffs: MarginProfileDiffs;
};

const useRowData = (): MarginPresetsRow[] => {
  const marginPresets = useSelector(selectAllMarginProfiles);
  const marginProfileDiffs = useSelector(getMarginProfileDiff);

  return useMemo(
    () =>
      marginPresets.flatMap<MarginPresetsRow>((profile) => {
        switch (profile.type) {
          case 'Sales':
            return [
              {
                type: profile.isPreset ? 'Sales' : 'Custom',
                id: profile.id,
                name: profile.name,
                marginsType: 'fixedMargins',
                margins: profile.fixedMargins,
                marginProfileDiffs,
              },
            ];
          case 'DevL':
            return [
              {
                type: 'Settings',
                id: profile.id,
                name: `Devl ${profile.name}`,
                marginsType: 'fixedMargins',
                margins: profile.margins,
                marginProfileDiffs,
              },
            ];

          default:
            return [
              {
                type: 'Settings',
                id: profile.id,
                name: 'Tech Margin Fixed',
                marginsType: 'techMarginsFixed',
                margins: profile.techMarginsFixed,
                marginProfileDiffs,
              },
              {
                type: 'Settings',
                id: profile.id,
                name: 'Tech Margin Floating',
                marginsType: 'techMarginsFloating',
                margins: profile.techMarginsFloating,
                marginProfileDiffs,
              },
              {
                type: 'Settings',
                id: profile.id,
                name: 'Adjustment Margin Floating',
                marginsType: 'adjustmentMargins',
                margins: profile.adjustmentMargins,
                marginProfileDiffs,
              },
            ];
        }
      }),
    [marginPresets, marginProfileDiffs],
  );
};

export default useRowData;
