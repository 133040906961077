export const strictKeys: <K extends keyof unknown>(obj: Record<K, unknown>) => K[] = Object.keys;
export const strictValues: <K extends keyof unknown, T>(obj: Record<K, T>) => T[] = Object.values;
export const strictEntries: <K extends keyof unknown, T>(obj: Record<K, T>) => [K, T][] =
  Object.entries;
export const length = <K extends keyof unknown, T>(obj: Record<K, T>) => Object.keys(obj).length;


export type SpecificationFunction = (...parameters: any[]) => any;

export type Specification = Record<string, SpecificationFunction>

export type SpecificationResult<Spec extends Specification> = {
  [Property in keyof Spec]: NonNullable<ReturnType<Spec[Property]>>
}

export const applySpecification = <Spec extends Specification> (specification: Spec) => {
  return (...parameters: Parameters<Spec[keyof Spec]>): SpecificationResult<Spec> => {
    return Object.keys(specification).reduce(
      (acc, property) => {
        const value = specification[property](...parameters)

        if (value !== null && value !== undefined) {
          acc[property as keyof Spec] = value
        }

        return acc
      },
      {} as SpecificationResult<Spec>
    )
  }
}

