import { FormattedMessage, useIntl } from 'react-intl';
import { HelpCenter } from 'components/HelpCenter';
import type { ChangeEvent } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import { createRow, deleteRows } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useDispatch } from 'react-redux';
import { type FxCashGridDataType, type FxCashProductKey, isFxCashProductKey } from '@services/fx/model/cash';
import { AlgoOrderProductMarginUnitSwitch } from '@components/fx/AlgoOrderProductMarginUnitSwitch';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';
import { isFxOrderProductKey } from '@services/fx/model/order';

type FxEditProfileTableActionHeaderProps = {
  onFilter: (query: string) => void;
  gridDataType?: FxCashGridDataType;
  perimeterKey: FxPerimeterKey;
  productKey: FxProductKey;
};

export const FxEditProfileTableActionHeader = ({
  gridDataType,
  perimeterKey,
  onFilter,
  productKey,
}: FxEditProfileTableActionHeaderProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const selectedRowIds = useAppSelector((state) => state.fxProfileEdition.selectedRowIds);
  const rowSelectedLength = selectedRowIds.length;
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(deleteRows({ uiRowsId: selectedRowIds }));
  };

  const addRow = () => {
    if (isFxCashProductKey(productKey)) {
      dispatch(
        createRow({
          type: gridDataType === 'margin-grid-data-type' ? 'cash-margin' : 'cash-tiering',
          productKey,
        }),
      );
    } else if (isFxOrderProductKey(productKey)) {
      dispatch(
        createRow({
          type: 'order-margin',
          productKey,
        }),
      );
    }
  };

  const addRejection = () => {
    //only used in cash perimeters
    dispatch(createRow({ type: 'cash-rejection', productKey: productKey as FxCashProductKey }));
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => onFilter(event.target.value);

  const commonFormattedId = `fx.editProfile.${perimeterKey}${gridDataType ? '.' + gridDataType : ''}`;

  return (
    <div className="d-flex flex-row align-items-end mb-1">
      <div className="form-group w-25">
        <div className={`input-group pb-1`}>
          <div className="input-icon-start">
            <em className="icon">search</em>
          </div>

          <input
            type="search"
            className="form-control"
            id="filter-text-box"
            placeholder={formatMessage({ id: 'common.search' })}
            autoComplete="off"
            onChange={onChange}
          />
        </div>
      </div>

      <div className="d-flex pb-1 ms-auto">
        <>
          <button
            className="btn btn-md btn-icon-start btn-discreet-primary"
            disabled={rowSelectedLength === 0}
            data-e2e="delete-rows-header"
            onClick={onDelete}
          >
            <em className="icon icon-md">delete_outline</em>
            <FormattedMessage id="delete.row" values={{ rowSelectedLength }} />
          </button>

          {perimeterKey === 'fx-cash-rfq-perimeter' && (
            <button
              className="btn btn-md btn-icon-start btn-discreet-primary mx-2"
              onClick={addRejection}
              data-e2e="add-rejection-header"
            >
              <em className="icon icon-md">add</em>
              <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.addRejectionButton`} />
            </button>
          )}

          <button
            className="btn btn-md btn-icon-start btn-discreet-primary mx-2"
            onClick={addRow}
            data-e2e="add-row-header"
          >
            <em className="icon icon-md">add</em>
            <FormattedMessage id={`${commonFormattedId}.addRowButton`} />
          </button>
        </>
        <HelpCenter className="ms-2" withHandler />
      </div>

      {productKey === 'algo-order-product' && <AlgoOrderProductMarginUnitSwitch />}
    </div>
  );
};
