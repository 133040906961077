import { useDispatch } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';
import type { AlgoMarginUnit, FxOrderProductKey } from '../services/fx/model/order';
import { DEFAULT_MARGIN_UNIT_BY_PRODUCT, uiSlice } from '../store/ui/uiSlice';

export const useMarginUnit = (productKey: FxOrderProductKey) => {
  const [marginUnit, setMarginUnitInLocalStorage] = useLocalStorage(
    `fx-unit-${productKey}`,
    DEFAULT_MARGIN_UNIT_BY_PRODUCT[productKey],
  );

  const dispatch = useDispatch();

  const setMarginUnit = (newMarginUnit: (typeof DEFAULT_MARGIN_UNIT_BY_PRODUCT)[typeof productKey]) => {
    setMarginUnitInLocalStorage(newMarginUnit);

    if (productKey === 'algo-order-product') {
      dispatch(uiSlice.actions.setFxMarginAlgoUnit(newMarginUnit as AlgoMarginUnit));
    }
  };

  return [marginUnit, setMarginUnit] as const;
};
