import { isDefined, isNotDefined } from '@sgme/fp';
import type { FxCashProductKey } from './model/cash';
import {
  type FxClientInheritance,
  type FxCashInheritancePerimeter,
  getFxOrderInheritancePerimeter,
  getFxCashInheritancePerimeter,
} from './model/inheritance';

export const convertLiquidityProviderDimension = ({ External, Internal }: { External: string; Internal: string }) => ({
  External: `${Number(External) / 100}`,
  Internal: `${Number(Internal) / 100}`,
});

const CASH_PRODUCT_ORDER = { 'spot-product': 1, 'ndf-product': 2, 'swap-product': 3, default: Number.MAX_VALUE };

export const getProductKeyListForGridData = (clientInheritance: FxClientInheritance | undefined) =>
  (
    clientInheritance?.data.perimeters?.find(
      (perimeter) => perimeter.perimeterKey === 'fx-cash-rfq-perimeter',
    ) as FxCashInheritancePerimeter
  )?.products.reduce(
    (acc, product) => {
      if (isDefined(product.marginGrid) && isDefined(product?.productKey)) {
        acc.margin = [...(acc.margin ?? []), product?.productKey].sort(
          (a, b) => (CASH_PRODUCT_ORDER[a] || CASH_PRODUCT_ORDER.default) - (CASH_PRODUCT_ORDER[b] || CASH_PRODUCT_ORDER.default),
        );
      }
      if (isDefined(product.tieringGrid) && isDefined(product.productKey)) {
        acc.tiering = [...(acc.tiering ?? []), product?.productKey].sort(
          (a, b) => (CASH_PRODUCT_ORDER[a] || CASH_PRODUCT_ORDER.default) - (CASH_PRODUCT_ORDER[b] || CASH_PRODUCT_ORDER.default),
        );
      }

      return acc;
    },
    {} as Record<string, FxCashProductKey[]>,
  );

export const findProductProfileForOrder = (clientInheritance: FxClientInheritance | undefined, productKey: string) => {
  if (isNotDefined(clientInheritance) || isNotDefined(clientInheritance.data.perimeters)) {
    return undefined;
  }

  const product = getFxOrderInheritancePerimeter(clientInheritance.data.perimeters)?.products.find(
    (product) => product.productKey === productKey,
  );
  return {
    InheritanceBadgeData: product?.profile?.isProfileInherited === true ? product?.profile : undefined,
    ProfileBadgeData: product?.profile,
  };
};

export const findProductProfileForCash = (
  clientInheritance: FxClientInheritance | undefined,
  productKey: string,
  isMargin: boolean,
) => {
  if (isNotDefined(clientInheritance) || isNotDefined(clientInheritance.data.perimeters)) {
    return undefined;
  }

  const product = getFxCashInheritancePerimeter(clientInheritance.data.perimeters)?.products.find(
    (product) => product.productKey === productKey,
  );
  if (isMargin) {
    return {
      InheritanceBadgeData: product?.marginGrid?.isProfileInherited
        ? {
            inheritableProfile: product?.marginGrid?.inheritableProfile,
            isProfileInherited: product?.marginGrid?.isProfileInherited,
          }
        : undefined,
      ProfileBadgeData: {
        profileName: product?.marginGrid?.profileName,
        profileTypeKey: product?.marginGrid?.profileTypeKey,
      },
    };
  }
  return {
    InheritanceBadgeData: product?.tieringGrid?.isProfileInherited
      ? {
          inheritableProfile: product?.tieringGrid?.inheritableProfile,
          isProfileInherited: product?.tieringGrid?.isProfileInherited,
        }
      : undefined,
    ProfileBadgeData: {
      profileName: product?.tieringGrid?.profileName,
      profileTypeKey: product?.tieringGrid?.profileTypeKey,
    },
  };
};
