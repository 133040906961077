import { FormattedMessage } from 'react-intl';
import { isNotDefined } from '@sgme/fp';
import { useFirstFxClientFor } from '@services/fx/getFXClient';
import { TIERING_GRID_DATA_TYPE } from '@services/fx/model/cash';
import { ProductProperty, StyledPropertyContainer } from '@pages/layout/Header/FXHeader/ProductHeaderProperty';
import { CancelValidationHandler } from '@components/CancelValidationHandler';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { FX_PRODUCTS_ROUTE } from '@pages/fx/constants';
import SaveNewSelectedProfileButton from '@pages/fx/FxSelectProfilePage/FxSelectProfileHeader/SaveNewSelectedProfileButton';
import { useGetFXCashProfileOfProductQuery } from '@services/fx/getFXCashProfileOfProduct';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { FxClientsCrumb } from '@pages/layout/Breadcrumb/FxClientsCrumb';
import { FxProductsCrumb } from '@pages/layout/Breadcrumb/FxProductsCrumb';
import { FxEditProductCrumb } from '@pages/layout/Breadcrumb/FxEditProductCrumb';
import { useGetFxProductChanges } from '@hooks/useGetFxProductChange';

// this is used in router.tsx
export const FxSelectProfileHeader = (): JSX.Element => {
  const { clientBdrId, clientBdrLevel, perimeterKey, productKey, gridDataType } = useFxSelectAndEditProfileUrlParams();
  const navigate = useNavigate();
  const { client } = useFirstFxClientFor(clientBdrId, clientBdrLevel);

  const { hasUserChangedSelectedProfile } = useGetFxProductChanges();

  const goToProductPage = useCallback(() => {
    if (isNotDefined(clientBdrId) || isNotDefined(clientBdrLevel) || isNotDefined(perimeterKey)) {
      return;
    }

    navigate(generatePath(FX_PRODUCTS_ROUTE, { clientBdrId: String(clientBdrId), clientBdrLevel, perimeterKey }));
  }, [clientBdrId, clientBdrLevel, navigate, perimeterKey]);

  const { data: selectedProfile } = useGetFXCashProfileOfProductQuery({
    perimeterKey,
    productKey,
    clientBdrId,
    clientBdrLevel,
    gridDataType,
  });

  if (isNotDefined(client)) {
    return <></>;
  }

  const { clientLongName, clientCountryCode } = client;
  return (
    <>
      <nav className="mb-1" data-e2e="breadcrumb">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <FxClientsCrumb />
          </li>
          <li className="breadcrumb-item">
            <FxProductsCrumb />
          </li>
          <li className="breadcrumb-item">
            <FxEditProductCrumb />
          </li>
        </ol>
      </nav>
      <div className="flex-between flex-wrap align-items-start mb-4">
        <div>
          <h1 className="text-primary display-3 mb-3">
            <FormattedMessage id={gridDataType === TIERING_GRID_DATA_TYPE ? 'edit.tierings' : 'edit.margins'} />
          </h1>

          <StyledPropertyContainer className="d-flex align-items-baseline gap-2 fs-18 text-secondary fw-medium">
            <div>

              <ProductProperty
                dataE2e="commercial-level"
                labelId="fx.header.property.commercialLevel"
                value={clientBdrLevel}
              />
              <ProductProperty dataE2e="region" labelId="fx.header.property.region" value={clientCountryCode} />
              <ProductProperty dataE2e="legal-bdr-id" labelId="fx.header.property.legalBdrId" value={clientBdrId} />
              <ProductProperty dataE2e="client" labelId="fx.header.property.client" value={clientLongName} />
              {client.clientMnemonic !== '' && <ProductProperty dataE2e="mnemonic" labelId="fx.header.property.mnemonic" value={client.clientMnemonic} />}

              <ProductProperty dataE2e="product" labelId="fx.header.property.product" formattedValueId={productKey} />
            </div>
          </StyledPropertyContainer>
        </div>

        <span className="d-flex">
          <CancelValidationHandler hasUserChanges={hasUserChangedSelectedProfile}>
            <button
              data-e2e="fx-cancel-product-profile"
              className="btn btn-lg btn-flat-primary me-2"
              onClick={goToProductPage}
            >
              <FormattedMessage id="common.cancel" />
            </button>
          </CancelValidationHandler>

          <SaveNewSelectedProfileButton
            profileVersion={selectedProfile?.data.profileVersion}
            clientProfileAssignmentVersion={selectedProfile?.data.clientProfileAssignmentVersion}
          />
        </span>
      </div>
    </>
  );
};
