import type { FxInheritancePerimeter } from './inheritance';
import type { UserAccess } from './models';

/**
 * CLIENT
 */
export type ClientBdrId = number;

export enum ClientBdrLevel {
  EC = 'EC',
  LE = 'LE',
  SUBGRP = 'SUBGRP',
  GRP = 'GRP',
}

export type ProfileSummaryFlagType = 'local_setup' | 'partial_local_setup' | 'inherited' | 'null';

export interface FxClientBase {
  clientLongName: string;
  clientBdrId: ClientBdrId;
  clientBdrLevel: ClientBdrLevel;
  clientCountryCode: string;
  profileSummaryFlag: ProfileSummaryFlagType | '';
  clientMnemonic: string;
  userAccess: UserAccess;
  hasChildWithExplicitProfile: boolean;
}

export interface FxClientResponse extends FxClientBase {
  perimeters: FxInheritancePerimeter[];
  parent: FxClientParent;
  children: FxClientBase[];
  ascendants: FxClientAscendant[];
}

export interface FxClientModel extends FxClientBase {
  perimeters: FxInheritancePerimeter[];
  profileSummaryFlag: ProfileSummaryFlagType | '';
  parent: FxClientParent;
  ascendants: FxClientAscendant[];
  children: FxClientBase[];
}

export interface FxClientParent {
  clientLongName: string;
  clientBdrId: ClientBdrId;
  clientBdrLevel: ClientBdrLevel;
  userAccess: string;
  hasChildWithExplicitProfile: boolean;
}

export interface FxClientAscendant {
  clientLongName: string;
  clientCountryCode: string;
  clientBdrId: ClientBdrId;
  profileSummaryFlag: ProfileSummaryFlagType | '';
  clientBdrLevel: ClientBdrLevel;
  userAccess: UserAccess;
  hasChildWithExplicitProfile: boolean;
}
