import { FormattedMessage } from 'react-intl';
import { isDefined, isDefinedAndNonEmpty } from '@sgme/fp';

// eslint-disable-next-line react/display-name
export const getLabel = (labelComponent?: JSX.Element) => (labelLocaleKey?: string) => {
  if (isDefined(labelComponent)) {
    return labelComponent;
  }

  if (isDefinedAndNonEmpty(labelLocaleKey)) {
    return <FormattedMessage id={labelLocaleKey} />;
  }

  return <></>;
};
