import type React from 'react';
import { useIntl } from 'react-intl';

import { connectSalesFilters } from './connect/connectSalesFilters';
import { Filter } from './Filter';
import { FilterGroup } from './FilterGroup';

export interface SalesProfileFiltersProps {
  ids: number[];
  isVisible: boolean;
}

const SalesProfileFiltersRaw: React.FunctionComponent<SalesProfileFiltersProps> = ({
  ids,
  isVisible,
}) => {
  const { formatMessage } = useIntl();
  return isVisible ? (
    <FilterGroup label={formatMessage({ id: 'filters.salesMargin' })}>
      {ids.map((id) => (
        <Filter key={id} value={id} type={'Sales'} />
      ))}
    </FilterGroup>
  ) : null;
};

export const SalesProfileFilters = connectSalesFilters(SalesProfileFiltersRaw);
