import {FormattedMessage} from 'react-intl';
import type {ActiveFilterProps} from "../../../../../../store/filters/filtersSelectors";

export const FilterLabel = (props: ActiveFilterProps): JSX.Element => {
  const {type} = props;

  switch (type) {
    case 'Sales':
    case 'DevL':
    case 'Pcru':
      return <>{ props.label }</>;

    case 'Custom':
      return <FormattedMessage id="filters.customizedMarginsOnly"/>;

    case 'MaturityRange': {
      const {min, max} = props;
      return <FormattedMessage id="filters.maturityRangeActive" values={ {min, max} }/>;
    }
  }
};
