import { useMemo } from 'react';

import { ActiveClient } from './ActiveClient';
import { ClientSearch } from './ClientSearch';
import { MaturitiesFilter } from './MaturitiesFilter';
import {CustomOnlyFilter} from "./CustomOnlyFilter";
import {SalesProfileFilters} from "./SalesProfileFilters";
import {DevLProfileFilters} from "./DevLProfileFilters";
import {PcruFilters} from "./PcruFilters";
import {ActiveFilters} from "./ActiveFilters";

export function ClientsMarginsFilters(): JSX.Element {
  const clientSearchStyle = useMemo(() => ({ maxWidth: '1200px' }), []);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="flex-grow-1" style={clientSearchStyle}>
          <ClientSearch />
        </div>

        <div className="ms-4">
          <ActiveClient />
        </div>
      </div>

      <div className="d-flex flex-wrap mb-3">
        <MaturitiesFilter />
        <CustomOnlyFilter />
        <SalesProfileFilters />
        <DevLProfileFilters />
        <PcruFilters />
      </div>

      <ActiveFilters />
    </>
  );
}
