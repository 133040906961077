import type { Color } from "@sgme/ui";
import { useToggleButtonContext } from "./useContext";
import clsx from "clsx";
import type { ChangeEvent, PropsWithChildren } from "react";


// TODO: uniformize with Button -> icon
export type ToggleButtonProps = {
  id: string
  color?: Color
  disabled?: boolean
}

export const ToggleButton = (props: PropsWithChildren<ToggleButtonProps>) => {
  const {
    id,
    color,
    disabled = false,
    children
  } = props;

  const {
    activeButtonIds: allActiveButtonIds,
    defaultColor,
    selectionType,
    onToggle
  } = useToggleButtonContext();

  const isActive = allActiveButtonIds.includes(id);
  const type = selectionType === "single" ? "radio" : "checkbox";
  const buttonColor = color ?? defaultColor ?? "primary";

  const labelClassName = clsx(
    [
      "btn",
      `btn-toggle-${buttonColor}`
    ],
    {
      "active": isActive
    }
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onToggle(id, event.target.checked);
  }

  return (
    <>
      <input className="btn-check" type={type} name={id} id={id} autoComplete="off" disabled={disabled} onChange={onChange} />
      <label className={labelClassName} htmlFor={id}>{children}</label>
    </>
  )
}
