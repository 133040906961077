import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { PageTracker } from '@features/analytics/PageTracker';
import { FX_ROUTE } from './constants';

function UnauthorizedFxClientPage(): JSX.Element {
  return (
    <PageTracker>
      <div className="container text-center text-secondary my-5">
        <div className="d-flex flex-row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-8">
            <i className="icon material-icons icon-xl d-inline-block mt-2 mb-2">not_interested</i>

            <h4>
              <FormattedMessage id="not.authorized" />
            </h4>

            <p>
              <FormattedMessage id="not.authorized.description" />
            </p>

            <Link to={FX_ROUTE} className="btn btn-secondary btn-xl mt-5">
              <FormattedMessage id="go.to.home.page" />
            </Link>
          </div>
        </div>
      </div>
    </PageTracker>
  );
}

export default UnauthorizedFxClientPage;
