import { type ClassAttributes, useCallback, useEffect, useRef } from 'react';
import { isDefined } from '@sgme/fp';

type DisplayOption = 'none' | 'email' | 'jobtitle';

export interface ContactPickerProps {
  displayOption: DisplayOption;

  onChange(contact?: IcContact): void;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'ic-contact-single-picker': ClassAttributes<HTMLElement> & IcContactPickerAttributes;
    }
  }
}

interface IcContactPickerAttributes {
  id?: string;
  'max-result-count'?: number;
  placeholder?: string;
  disabled?: boolean;
  outline?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  'create-link-mode'?: 'none' | 'redirect' | 'emit';
  'create-action-text'?: string;
  'selected-id'?: string;
  'readonly-id'?: string;
  'in-error'?: string;
  'error-message'?: string;
  'display-option'?: DisplayOption;
  'internal-only'?: boolean;
  'active-only'?: boolean;
  'emit-mode'?: 'always' | 'user' | 'not-empty';
}

type Scope = 'internal' | 'extendedInternal' | 'external' | 'all';

export interface IcContact {
  id?: string;
  jobTitle?: string;
  firstname?: string;
  lastname?: string;
  fullName?: string;
  team?: string;
  email?: string;
  scope?: Scope;
  isPepOrSpo?: boolean;
  icId: string;
  bdrCommercialId: string;
  bdrLegalId: string;
  name?: string;
  bdrCommercialLevel?: string;
  bdrLegalLevel?: string;
}

type IcContactPickerChangedEvent = CustomEvent<{
  contact: IcContact | null;
}>;

export function IcContactPicker({ displayOption, onChange }: ContactPickerProps): JSX.Element {
  const innerRef = useRef<HTMLElement & IcContactPickerAttributes>(null);

  useEffect(() => {
    const picker = innerRef?.current;

    if (isDefined(picker)) {
      picker['display-option'] = displayOption;
    }
  }, [ displayOption ]);

  const changeHandler = useCallback(
    // some clients have weird spaces in their name, and thus break comparison
    (ev: IcContactPickerChangedEvent) => {
      onChange(ev.detail?.contact ?? undefined);
    },
    [ onChange ],
  );

  useEffect(() => {
    window.addEventListener('ic-contact-single-picker--changed', changeHandler as EventListener);

    return () => {
      window.removeEventListener(
        'ic-contact-single-picker--changed',
        changeHandler as EventListener,
      );
    };
  }, [ changeHandler ]);

  return (
    <ic-contact-single-picker
      ref={ innerRef }
      id="ic-contact-single-picker"
      data-e2e="search-contact"
    />
  );
}
