import type { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import type { FxOrderProfile } from '@services/fx/model/order';

interface ClientsCountProps extends HTMLAttributes<HTMLSpanElement>, Partial<Pick<FxOrderProfile, 'clientsCount'>> {}

export const ClientsCount = ({ className = '', clientsCount = 0, ...props }: ClientsCountProps): JSX.Element => (
  <button className={`btn btn-icon-start btn-link ${className ?? ''}`} data-e2e="btn-show-clients-list" {...props}>
    <em className="icon icon-sm">visibility</em>
    <FormattedMessage id="common.clientsCount" values={{ clientsCount }} />
  </button>
);
