import type { EntityState } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetFXAvailableProfilesQuery } from '@services/fx/getFXAvailableProfiles';
import type { FxProductProfiles } from '@services/fx/model/inheritance';
import { isDefined } from '@sgme/fp';
import { useFxUrlParams } from './useFxUrlParams';

const useGetFXAvailableProfilesByType = (): EntityState<FxProductProfiles> | undefined => {
  const { clientBdrId, clientBdrLevel, perimeterKey, productKey, gridDataType } = useFxUrlParams();

  const productParams =
    isDefined(clientBdrId) && isDefined(clientBdrLevel) && isDefined(perimeterKey) && isDefined(productKey)
      ? { clientBdrId, clientBdrLevel, perimeterKey, productKey, gridDataType }
      : skipToken;

  const { data } = useGetFXAvailableProfilesQuery(productParams);

  return data;
};

export default useGetFXAvailableProfilesByType;
