import { useGetStatusJsonQuery } from '../statusApi';

export function StatusJsonSection(): JSX.Element {
  const { isSuccess, isError, isLoading } = useGetStatusJsonQuery();

  return (
    <section data-e2e="status-json">
      <h3>Status JSON</h3>
      {isSuccess && 'OK'}
      {isError && 'KO'}
      {isLoading && 'PENDING'}
    </section>
  );
}
