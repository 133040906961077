import { createContext, useContext } from "react";
import { isNotDefined } from "@sgme/fp";
import type { Color } from "@sgme/ui";

export type ToggleButtonContextData = {
  activeButtonIds: string[]
  defaultColor?: Color
  selectionType: "single" | "multiple"
  onToggle: ToggleHandler
}

export type ToggleHandler = (buttonId: string, isActive: boolean) => void;

export const ToggleButtonContext = createContext<ToggleButtonContextData | undefined>(undefined);

export const useToggleButtonContext = () => {
  const context = useContext(ToggleButtonContext);

  if (isNotDefined(context)) {
    throw new Error("ToggleButtonContext must be used inside the ToggleButtonContext.Provider")
  }

  return context;
}
