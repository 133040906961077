import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isDefined, isNotDefined } from '@sgme/fp';

import { fxApi } from './fxApi';
import type { ClientBdrId, ClientBdrLevel, FxClientModel, FxClientResponse } from './model/client';
import type { FxInheritancePerimeter } from './model/inheritance';
import type { FxPayload } from './model/models';
import { FX_ORDER_PRODUCT_KEYS } from './model/order';

export const clientApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    // WARNING: ClientBdrLevel is upper case
    getFXClient: builder.query<FxClientModel[], { clientBdrId: ClientBdrId; clientBdrLevel?: ClientBdrLevel }>({
      query: ({ clientBdrId, clientBdrLevel }) => {
        return {
          url: 'clients',
          params: isDefined(clientBdrLevel)
            ? {
                clientBdrId,
                clientBdrLevel,
              }
            : {
                clientBdrId,
              },
        };
      },

      transformResponse: (response: FxPayload<FxClientResponse[]>) => {
        const allClients = response.data;

        return allClients.map(toFxClient);
      },

      providesTags: () => ['FXClients'],
    }),
  }),
});

const toFxClient = (client: FxClientResponse): FxClientModel => {
  // we don't keep perimeters property. Only reformate it.
  const { perimeters, ...keepClientData } = client;

  const perimetersWithSortedProducts = perimeters.map((perimeter) => {
    if (perimeter.perimeterKey !== 'fx-order-perimeter') {
      return perimeter;
    }

    const { products } = perimeter;

    return {
      ...perimeter,
      products: products.sort(
        (a, b) => FX_ORDER_PRODUCT_KEYS.indexOf(a.productKey) - FX_ORDER_PRODUCT_KEYS.indexOf(b.productKey),
      ),
    } as FxInheritancePerimeter;
  });

  return { ...keepClientData, perimeters: perimetersWithSortedProducts };
};

export const { useGetFXClientQuery, useLazyGetFXClientQuery } = clientApi;

export const useFirstFxClientFor = (
  clientBdrId: ClientBdrId | undefined,
  clientBdrLevel: ClientBdrLevel | undefined,
) => {
  const params = isNotDefined(clientBdrId)
    ? skipToken
    : isDefined(clientBdrLevel)
      ? { clientBdrId, clientBdrLevel }
      : { clientBdrId };

  const { data: allClients, isLoading, isSuccess, isError } = useGetFXClientQuery(params);

  if (isNotDefined(clientBdrId) || isNotDefined(clientBdrLevel)) {
    return {
      client: undefined,
      isLoading: true,
      isSuccess: false,
      isError: false,
    };
  }

  const client = allClients?.find(
    (loadedClient) => loadedClient.clientBdrId === clientBdrId && loadedClient.clientBdrLevel === clientBdrLevel,
  );

  return {
    client,
    isLoading,
    isSuccess,
    isError,
  };
};
