import {  useState } from 'react';
import type { LagClient } from 'services/clients';
import { selectAllClients } from 'services/clients';

import type { IcAccount } from 'components/IcAccountPicker';
import { IcAccountPicker } from 'components/IcAccountPicker';
import {useAppSelector} from "../../../hooks/useAppSelector";

interface AccountPickerProps {
  onFindExistingClient: (clientFounded: LagClient) => void;
  onChangeClient: (clientName: string) => void
}
export function AccountPicker({ onFindExistingClient, onChangeClient }: AccountPickerProps): JSX.Element {
  const allClients = useAppSelector(selectAllClients);
  const[ clientSelected, setClientSelected] = useState<string>()

  const onChange =
    (client: IcAccount) => {

      onChangeClient(client.name); // set value in the form
      setClientSelected(client.icId) // persist selected client on re-render because we pass it to Ic component as the selectedId props


      const clientFounded = allClients.find(({ name }) => name === client.name);
      if (clientFounded) {
        onFindExistingClient(clientFounded);
      }
    }


  return <IcAccountPicker onChange={onChange} clientSelected={clientSelected} />;
}

