import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import type { FxOrderProfileAlgoColumns } from '@services/fx/model/order';
import { changeAlgoOrderColumn } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '@store/selectors';
import { HStack } from '@sgme/ui';
import { FormGroup, Label } from 'reactstrap';
import { MarginInputText } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/MarginInputText';
import type { Reducer } from '@reduxjs/toolkit';

interface MarginValueState {
  external: string;
  internal: string;
  previousUnit: string;
}

type MarginValueActionType = 'SET_EXTERNAL' | 'SET_INTERNAL' | 'SET_PREVIOUS_UNIT';
type MarginValueAction = {
  type: MarginValueActionType;
  value: string;
};

const MarginInputValueReducer: Reducer<MarginValueState, MarginValueAction> = (
  state = { external: '', internal: '', previousUnit: 'bps' },
  action,
): MarginValueState => {
  switch (action.type) {
    case 'SET_EXTERNAL':
      return {
        ...state,
        external: action.value,
      };
    case 'SET_INTERNAL':
      return {
        ...state,
        internal: action.value,
      };
    case 'SET_PREVIOUS_UNIT':
      return {
        ...state,
        previousUnit: action.value,
      };
  }
};

export function InternalAndExternalMarginInputs() {
  const dispatch = useAppDispatch();

  const EXTERNAL_MARGIN_KEY = 'liquidityProviderDimension.External.marginValue';
  const INTERNAL_MARGIN_KEY = 'liquidityProviderDimension.Internal.marginValue';

  const marginUnit = useSelector(selectors.getFxMarginUnit).algo;
  const marginExternalValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxOrderProfileAlgoColumns)[EXTERNAL_MARGIN_KEY],
  );
  const marginInternalValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxOrderProfileAlgoColumns)[INTERNAL_MARGIN_KEY],
  );

  const isMarginExternalValueNotANumber = isNaN(Number.parseFloat(marginExternalValue));
  const isMarginInternalValueNotANumber = isNaN(Number.parseFloat(marginInternalValue));

  const initialMarginExternalValue = isMarginExternalValueNotANumber
    ? marginExternalValue
    : marginUnit === 'bps'
      ? marginExternalValue
      : (Number(marginExternalValue) * 100).toString();
  const initialMarginInternalValue = isMarginInternalValueNotANumber
    ? marginInternalValue
    : marginUnit === 'bps'
      ? marginInternalValue
      : (Number(marginInternalValue) * 100).toString();

  const [marginsAreDifferent, setMarginsAreDifferent] = useState<boolean>(false);

  const initialMarginValue = {
    external: initialMarginExternalValue,
    internal: initialMarginInternalValue,
    previousUnit: marginUnit,
  };
  const [marginInputValue, dispatchMarginInputValue] = useReducer(MarginInputValueReducer, initialMarginValue);

  const setMarginExternalValue = (value: string) => {
    dispatchMarginInputValue({ type: 'SET_EXTERNAL', value });

    const isValueNotANumber = isNaN(Number.parseFloat(value));
    const marginValue = isValueNotANumber ? value : marginUnit === 'bps' ? value : (Number(value) / 100).toString();

    dispatch(changeAlgoOrderColumn({ value: marginValue, key: EXTERNAL_MARGIN_KEY }));
  };

  const setMarginInternalValue = (value: string) => {
    dispatchMarginInputValue({ type: 'SET_INTERNAL', value });

    const isValueNotANumber = isNaN(Number.parseFloat(value));
    const marginValue = isValueNotANumber ? value : marginUnit === 'bps' ? value : (Number(value) / 100).toString();
    dispatch(changeAlgoOrderColumn({ value: marginValue, key: INTERNAL_MARGIN_KEY }));
  };

  if (marginUnit === 'mln' && marginInputValue.previousUnit === 'bps') {
    const ExternalMarginValue = isMarginExternalValueNotANumber
      ? marginInputValue.external
      : (Number(marginInputValue.external) * 100).toString();
    const InternalMarginValue = marginsAreDifferent
      ? isMarginInternalValueNotANumber
        ? marginInputValue.internal
        : (Number(marginInputValue.internal) * 100).toString()
      : ExternalMarginValue;

    setMarginExternalValue(ExternalMarginValue);
    setMarginInternalValue(InternalMarginValue);

    dispatchMarginInputValue({ type: 'SET_PREVIOUS_UNIT', value: 'mln' });
  }

  if (marginUnit === 'bps' && marginInputValue.previousUnit === 'mln') {
    const ExternalMarginValue = isMarginExternalValueNotANumber
      ? marginInputValue.external
      : (Number(marginInputValue.external) / 100).toString();
    const InternalMarginValue = marginsAreDifferent
      ? isMarginInternalValueNotANumber
        ? marginInputValue.internal
        : (Number(marginInputValue.internal) / 100).toString()
      : ExternalMarginValue;

    setMarginExternalValue(ExternalMarginValue);
    setMarginInternalValue(InternalMarginValue);

    dispatchMarginInputValue({ type: 'SET_PREVIOUS_UNIT', value: 'bps' });
  }

  const handleExternalValue = (value: string) => {
    setMarginExternalValue(value);

    if (!marginsAreDifferent) {
      setMarginInternalValue(value);
    }
  };

  return (
    <FormGroup className="form-group" data-e2e="margin-by-liquidity">
      <Label className="text-primary fw-medium mb-2 fs-16">
        <FormattedMessage id="liquidityProviderDimension" />
      </Label>
      <HStack gap={'8px'}>
        <MarginInputText
          labelLocalKey={'liquidityProviderDimension.External'}
          name="external"
          value={marginInputValue.external}
          unit={marginUnit}
          onChange={handleExternalValue}
        />
        <MarginInputText
          labelLocalKey={'liquidityProviderDimension.Internal'}
          name="internal"
          value={marginInputValue[marginsAreDifferent ? 'internal' : 'external']}
          unit={marginUnit}
          onChange={setMarginInternalValue}
          disabled={!marginsAreDifferent}
        />
      </HStack>

      <div className={`form-check mt-4`}>
        <input
          type="checkbox"
          id={'marginsAreDifferent'}
          data-e2e="apply-on-inverted-pair-currency-checkbox"
          className="form-check-input"
          onChange={() => setMarginsAreDifferent((previous) => !previous)}
        />
        <label className="form-check-label text-primary" htmlFor={'marginsAreDifferent'}>
          <FormattedMessage id={'fx.edit.external.internal.different'} />
        </label>
      </div>
    </FormGroup>
  );
}
