import type { HTMLAttributes } from 'react';
import { useCallback, useRef } from 'react';

import { FallbackMessage } from 'components/FallbackMessage';
import { LoadingMessage } from 'components/LoadingMessage';

import type { AgGridReact } from '@ag-grid-community/react';
import type { FxOrderProductKey, FxOrderProfileRow, FxOrderProfileRowMappedForAgGrid } from '@services/fx/model/order';
import { isDefined } from '@sgme/fp';
import { FxOrderProfileTableHeader } from './FxOrderProfileTableHeader';
import OrderGrid from './OrderGrid';

interface FxOrderProfileTableProps extends HTMLAttributes<HTMLDivElement> {
  productKey: FxOrderProductKey;
  rows?: FxOrderProfileRow[];
  isLoading?: boolean;
  isError?: boolean;
  maxRowDisplayed?: number;
}

export const FxOrderProfileTable = ({
  productKey,
  rows = [],
  isLoading = true,
  isError = false,
  maxRowDisplayed = 10,
  ...props
}: FxOrderProfileTableProps) => {
  const ref = useRef<AgGridReact>(null);

  const onFilter = useCallback(
    (query: string) => {
      if (isDefined(ref.current?.api)) {
        ref.current?.api.setQuickFilter(query);
      }
    },
    [ref.current?.api],
  );

  if (isError) {
    return <FallbackMessage icon="gpp_bad" headerLocaleKey="no.data.for.product" descriptionLocaleKey={productKey} />;
  }

  if (isLoading) {
    return <LoadingMessage />;
  }

  return (
    <div className="col" {...props}>
      <FxOrderProfileTableHeader productKey={productKey} onFilter={onFilter} />
      <OrderGrid
        productKey={productKey}
        maxRowDisplayed={maxRowDisplayed}
        rows={rows as FxOrderProfileRowMappedForAgGrid[]}
        ref={ref}
      />
    </div>
  );
};
