import { FxInheritedBadge } from '@components/fx/badges/FxInheritedBadge';
import { FxProfileBadge } from '@components/fx/badges/FxProfileBadge';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetFXClientsInheritanceQuery } from '@services/fx/getFXClientsInheritance';
import type { ClientBdrLevel } from '@services/fx/model/client';
import type { FxInheritancePerimeter, FxOrderInheritancePerimeter } from '@services/fx/model/inheritance';
import type { ProfileTypeKey } from '@services/fx/model/models';
import {
  findProductProfileForCash,
  findProductProfileForOrder,
  getProductKeyListForGridData,
} from '@services/fx/utils';
import { isDefined } from '@sgme/fp';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

type FxFirstClientCardBodyProps = {
  perimeters: FxInheritancePerimeter[];
  bdrId: number | undefined;
  bdrLevel: ClientBdrLevel | undefined;
};

export function FxFirstClientCardBody({ bdrId, bdrLevel, perimeters }: FxFirstClientCardBodyProps) {
  const [isMargin, setIsMargin] = useState(true);
  const switchGridType = () => setIsMargin((previousValue) => !previousValue);

  const queryFXClientsInheritanceParams =
    isDefined(bdrId) && isDefined(bdrLevel) ? { clientBdrId: bdrId, clientBdrLevel: bdrLevel } : skipToken;
  const { data: clientInheritance } = useGetFXClientsInheritanceQuery(queryFXClientsInheritanceParams);

  const ProductKeyListForGridData = getProductKeyListForGridData(clientInheritance);

  const displayedProductKeysForCash = isMargin ? ProductKeyListForGridData?.margin : ProductKeyListForGridData?.tiering;
  const productKeyForOrder = (
    perimeters.find((perimeter) => perimeter.perimeterKey === 'fx-order-perimeter') as FxOrderInheritancePerimeter
  ).products.map((product) => product.productKey);

  return (
    <div className="card-body d-flex pt-24px">
      <ul className="nav nav-underline nav-vertical" style={{ flexBasis: '130px' }}>
        <li
          className={`btn btn-secondary nav-item nav-link text-start ${isMargin ? ' active' : ''}`}
          onClick={switchGridType}
        >
          <span>
            <FormattedMessage id="margins.ClientPage" />
          </span>
        </li>
        <li
          className={`btn btn-secondary nav-item nav-link text-start ${!isMargin ? ' active' : ''}`}
          onClick={switchGridType}
        >
          <span>
            <FormattedMessage id="tiering.ClientPage" />
          </span>
        </li>
      </ul>

      <div className="d-flex flex-fill flex-wrap gap-24px">
        {displayedProductKeysForCash && (
          <div className="flex-grow-1">
            <ul className="list-group">
              <div className="fw-bold text-secondary ps-2">
                <FormattedMessage id="cash.ClientPage" />
              </div>
              {displayedProductKeysForCash.map((productKey) => {
                const productProfileForCash = findProductProfileForCash(clientInheritance, productKey, isMargin);

                return (
                  <li className="d-flex list-group-item border-bottom p-2" key={productKey}>
                    <span className="fw-bold">
                      <FormattedMessage id={`${productKey}.clientPage`} />
                    </span>
                    <div className="ms-2">
                      <FxProfileBadge
                        profileName={productProfileForCash?.ProfileBadgeData?.profileName}
                        profileTypeKey={productProfileForCash?.ProfileBadgeData?.profileTypeKey as ProfileTypeKey}
                        size="sm"
                      />
                    </div>
                    <div className="ms-2">
                      {isDefined(productProfileForCash?.InheritanceBadgeData) &&
                        productProfileForCash?.InheritanceBadgeData.isProfileInherited && (
                          <FxInheritedBadge
                            perimeterKey="fx-order-perimeter"
                            dataE2e="setup-flag-badge"
                            inheritedBdrId={
                              productProfileForCash?.InheritanceBadgeData?.inheritableProfile?.client.clientBdrId
                            }
                            inheritedBdrLevel={
                              productProfileForCash?.InheritanceBadgeData?.inheritableProfile?.client.clientBdrLevel
                            }
                            inheritedBdrName={
                              productProfileForCash?.InheritanceBadgeData?.inheritableProfile?.client.clientLongName
                            }
                            size="sm"
                          />
                        )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {productKeyForOrder && isMargin && (
          <div className="flex-grow-1">
            <div className="fw-bold text-secondary ps-2">
              <FormattedMessage id="order.ClientPage" />
            </div>
            <ul className="list-group">
              {productKeyForOrder.map((productKey) => {
                const productProfileForOrder = findProductProfileForOrder(clientInheritance, productKey);

                return (
                  <li className="list-group-item border-bottom p-2 d-flex" key={productKey}>
                    <span className="fw-bold">
                      <FormattedMessage id={productKey} />
                    </span>
                    <div className="ms-2">
                      <FxProfileBadge
                        profileName={productProfileForOrder?.ProfileBadgeData?.profileName}
                        profileTypeKey={productProfileForOrder?.ProfileBadgeData?.profileTypeKey as ProfileTypeKey}
                        size="sm"
                      />
                    </div>
                    <div className="ms-2">
                      {isDefined(productProfileForOrder) &&
                        productProfileForOrder.ProfileBadgeData?.isProfileInherited && (
                          <FxInheritedBadge
                            perimeterKey="fx-order-perimeter"
                            dataE2e="setup-flag-badge"
                            inheritedBdrId={
                              productProfileForOrder.ProfileBadgeData?.inheritableProfile.client.clientBdrId
                            }
                            inheritedBdrLevel={
                              productProfileForOrder.ProfileBadgeData?.inheritableProfile.client.clientBdrLevel
                            }
                            inheritedBdrName={
                              productProfileForOrder.ProfileBadgeData?.inheritableProfile.client.clientLongName
                            }
                            size="sm"
                          />
                        )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
