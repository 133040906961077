import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import type { FxMarginUnit, FxOrderProductKey, FxOrderProfileLimitColumns } from '@services/fx/model/order';
import { changeMarginValue } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useSelector } from 'react-redux';
import { selectors } from '@store/selectors';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { MarginInputText } from '@pages/fx/FxEditProfilePage/FxProfileEditor/FxOrderEditor/MarginInputText';

export function MarginInput() {
  const { productKey } = useFxSelectAndEditProfileUrlParams();
  const dispatch = useAppDispatch();

  const marginValue = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxOrderProfileLimitColumns).marginValue,
  );

  const setMarginValue = (value: string) => {
    dispatch(changeMarginValue({ value }));
  };

  const fxMarginUnit = useSelector(selectors.getFxMarginUnit) as FxMarginUnit;

  const getMarginUnit = () => {
    switch (productKey as FxOrderProductKey) {
      case 'algo-order-product':
        return fxMarginUnit.algo;
      case 'limit-order-product':
        return fxMarginUnit.limit;
      case 'fixing-order-product':
        return fxMarginUnit.fixing;
    }
  };

  return (
    <MarginInputText
      labelLocalKey={'marginValue'}
      value={marginValue}
      unit={getMarginUnit()}
      onChange={setMarginValue}
    />
  );
}
