import { isDefined } from '@sgme/fp';
import type { ComponentSize } from '@utils/size';
import { type PropsWithChildren, useId } from 'react';
import { FormattedMessage } from 'react-intl';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

interface CommonBadgeProps {
  labelId: string;
  labelValues?: Record<string, string | number | boolean>;

  isLoading?: boolean;
  color?: string;
  icon?: string;
  size?: ComponentSize;
  dataE2e?: string;
  className?: string;
}

/**
 * Without tooltip
 *
 * <CommonBadge icon="icon" labelId="my.label.id" size="sm" />
 *
 *
 * With tooltip
 *
 * <CommonBadge icon="icon" labelId="my.label.id" size="sm">
 *   "tooltip" content
 * </CommonBadge>
 */

export const CommonBadge = (props: PropsWithChildren<CommonBadgeProps>) => {
  const {
    labelId,
    labelValues,
    isLoading = false,
    icon,
    color = 'primary',
    size = 'md',
    dataE2e,
    className,
    children,
  } = props;

  const id = useId();
  const popoverId = `popover-${id}`.replaceAll(':', ''); // TODO: a better way ? popover ID doesn't like ":" inside the generated ID

  const badge = (
    <span
      data-e2e={dataE2e}
      className={`d-inline-flex align-items-center badge badge-${size} badge-discreet-${color} rounded-pill ${className ?? ''} ${size === 'md' ? '' : ''}`}
      // className={ `d-inline-flex align-items-center badge rounded-pill ${className ?? ""}` }
      id={popoverId}
    >
      {isDefined(icon) && (
        <em
          data-e2e={isDefined(dataE2e) ? `${dataE2e}-icon` : undefined}
          className={`icon ${getIconClassNames(size)}`}
          style={{ fontSize: '1rem' }}
        >
          {icon}
        </em>
      )}

      {isLoading && <div className="spinner spinner-sm" />}

      <FormattedMessage id={labelId} values={labelValues} />
    </span>
  );

  return isDefined(children) ? (
    <>
      {badge}

      <UncontrolledPopover target={`${popoverId}`} trigger="hover" placement="bottom">
        <PopoverBody data-e2e={`${dataE2e}-tooltip`}>{children}</PopoverBody>
      </UncontrolledPopover>
    </>
  ) : (
    badge
  );
};

const getIconClassNames = (size: ComponentSize) => {
  switch (size) {
    case 'sm':
      return 'me-1 fs-12';

    case 'md':
      return 'me-1 fs-16';

    case 'lg':
      return 'me-1 fs-18';

    case 'xl':
      return 'me-2 fs-21';
  }
};
