import { type ChangeEventHandler, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";
import { useBdrReference } from "BdrContext";

import { type IcAccount, IcAccountPicker } from "components/IcAccountPicker";
import { FxInternalAccountPicker } from "components/fx/FxInternalAccountPicker";
import type { ClientBdrId, ClientBdrLevel } from "../../../../services/fx/model/client";


export const FxClientsHeader = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const { setReference: setBdrReference } = useBdrReference();

  const [
    clientType,
    setClientType
  ] = useState("external");


  const onChangeClientsTypeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    const clientType = event.target.name

    setClientType(clientType);
  };

  const onChangeIcAccountPickerHandler = (account: IcAccount) => {
    setBdrReference({
      id   : Number(account.bdrLegalId) as ClientBdrId,
      level: account.level.toUpperCase() as ClientBdrLevel
    })
  };

  const onSubmitInternalAccountPickerHandler = (bdrId: string) => {
    setBdrReference({
      id: Number(bdrId)
    })
  };

  return (
    <div className="flex-column offset-lg-1 col-lg-10 px-0 mb-4">
      <h1 className="display-3 mb-3 mb-md-2 text-primary">
        <FormattedMessage id="common.title"/>
      </h1>

      <div>
        <FormGroup tag="fieldset" className="d-flex mb-2 mt-4">
          <FormGroup check className="me-4">
            <Label check>
              <Input
                type="radio"
                name="external"
                checked={clientType === "external"}
                onChange={onChangeClientsTypeHandler}
              />{formatMessage({ id: "clientsMargins.radio.clientType.external" })}
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="internal"
                checked={clientType === "internal"}
                onChange={onChangeClientsTypeHandler}
              />{formatMessage({ id: "clientsMargins.radio.clientType.internal" })}
            </Label>
          </FormGroup>
        </FormGroup>
      </div>

      <div className="mt-1">
        <>
          {/* Use "display: none" css rule instead of jsx condition to avoid re-render at the change of search bar type */}
          <div className={`${clientType === "external" ? "" : "d-none"}`}>
            <IcAccountPicker onChange={onChangeIcAccountPickerHandler} withFunds/>
          </div>

          <div className={`${clientType === "internal" ? "" : "d-none"}`}>
            <FxInternalAccountPicker onSubmit={onSubmitInternalAccountPickerHandler}/>
          </div>
        </>
      </div>
    </div>
  );
};
