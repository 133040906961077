import { PageTracker } from '@features/analytics/PageTracker';
import { Authorize } from '@features/authorization/Authorize';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { AuthorizeFxClient } from 'pages/fx/components/AuthorizeFxClient';
import CashTab from './CashTab';
import OrderTab from './OrderTab';

function FxProductsPage(): JSX.Element {
  const { perimeterKey } = useFxUrlParams();

  return (
    <Authorize authorizedFor={['FX_READALLDESK', 'FX_READMYDESK']} redirectTo="/">
      <AuthorizeFxClient>
        <PageTracker>
          <div className=" h-100 w-100 px-4 py-4">
            {perimeterKey === 'fx-order-perimeter' && <OrderTab />}
            {perimeterKey === 'fx-cash-rfq-perimeter' && <CashTab />}
          </div>
        </PageTracker>
      </AuthorizeFxClient>
    </Authorize>
  );
}

export default FxProductsPage;
