import { FormattedMessage } from 'react-intl';

export const NoClientsFound = (): JSX.Element => {
  return (
    <div className="d-flex flex-column align-items-center mb-n4 mx-n4 pt-5 flex-grow-1 text-secondary bg-lvl2">
      <i className="icon icon-xl">business</i>

      <h4>
        <FormattedMessage id="clientsMargins.noClientsFound.title" />
      </h4>

      <FormattedMessage id="clientsMargins.noClientsFound.description" />
    </div>
  );
};
