import { Authorize } from '@features/authorization/Authorize';
import { isDefined, isNotDefined } from '@sgme/fp';
import sgLogo from 'assets/sg-logo.png';
import type { ReactNode } from 'react';
import { Link, type UIMatch, useMatches } from 'react-router-dom';
import { AccountCenter } from './AccountCenter';

type Matches = UIMatch<
  unknown,
  {
    IntegratedHeaderComponent: () => JSX.Element;
  }
>[];

const IntegratedHeaderComponent = (): ReactNode => {
  const matches = useMatches() as Matches;

  const [IntegratedHeaderComponent] = matches
    .filter(({ handle }) => isDefined(handle?.IntegratedHeaderComponent))
    .map((match) => match.handle.IntegratedHeaderComponent);

  if (isNotDefined(IntegratedHeaderComponent)) {
    return null;
  }

  return <IntegratedHeaderComponent />;
};

export const IntegratedHeader = (): JSX.Element => (
  <header className="bg-lvl2 border-bottom">
    <nav className="navbar navbar-lg">
      <div className="navbar-title">
        <Authorize
          authorizedFor={['FX_READALLDESK', 'LAG_READ']}
          mode="all"
          fallback={<img src={sgLogo} alt="SG logo Glyph" />}
        >
          <Link to="/" className="navbar-title-link">
            <div className="navbar-logo">
              <img src={sgLogo} alt="SG logo Glyph" />
            </div>

            <div className="navbar-title-divider" />

            <div className="navbar-service-name">Margin and Tiering</div>
          </Link>
        </Authorize>
      </div>

      <div className="navbar-toolbar pe-3">
        <AccountCenter />
      </div>
    </nav>

    <div className="px-4 bg-lvl2 pt-2">
      <IntegratedHeaderComponent />
    </div>
  </header>
);
