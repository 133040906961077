import { FormattedMessage } from 'react-intl';
import { type FxCashGridDataType, TIERING_GRID_DATA_TYPE } from '@services/fx/model/cash';
import { ModalHandler } from '@components/modals/ModalHandler';
import { FxProfileBadge } from '@components/fx/badges/FxProfileBadge';
import type { FxProfileMarginGrid } from '@services/fx/model/profile-margin-grid';

import type { FxProfileColumns } from '@services/fx/model/perimiters';
import { ClientsCount } from '@pages/fx/FxEditProfilePage/ClientsCount';

export type FxSelectProfileSubHeaderProps = {
  profile: FxProfileMarginGrid<FxProfileColumns>;
  gridDataType?: FxCashGridDataType;
};

const FxEditProfileSubHeader = (props: FxSelectProfileSubHeaderProps) => {
  const { profile, gridDataType } = props;

  const profileTypeKey = profile?.profileTypeKey;

  const showClientsCount = ['client-pool-profile-type', 'standard-profile-type'].includes(profileTypeKey ?? '');

  return (
    <span className="d-flex align-items-center mb-4 ms-0">
      <h3 className="mb-0">
        <FormattedMessage id={gridDataType === TIERING_GRID_DATA_TYPE ? 'tiering.preset' : 'margin.preset'} />
      </h3>

      {/*todo-5198 Order Version pass a status (error / isLoading) - does this make sense for a badge to react to the query result
         shouldn't it be handled higher in the page if needed ?
       */}
      <FxProfileBadge
        className="ms-4"
        size="sm"
        profileName={profile?.profileName}
        profileTypeKey={profile?.profileTypeKey}
      />

      {showClientsCount && (
        <ModalHandler modalName="FxClientsForProfileModal" profile={profile}>
          <ClientsCount className="ms-1" clientsCount={profile?.clientsCount} />
        </ModalHandler>
      )}
    </span>
  );
};

export default FxEditProfileSubHeader;
