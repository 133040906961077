import { selectMarginById } from 'services/marginProfiles';
import type {AppThunk} from "../store";

export const modifyMargin =
  (marginId: string, value: number): AppThunk =>
  (dispatch, getState, { actionCreators: ac }) => {
    const state = getState();

    const margin = selectMarginById(state, marginId);

    if (margin?.value === value) {
      dispatch(ac.marginProfileEdition.marginResetted({ marginId }));
    } else {
      dispatch(ac.marginProfileEdition.marginModified({ marginId, value }));
    }
  };
