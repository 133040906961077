import EditMarginPresets from './EditMarginsPresets';
import MarginsPresetsTable from './MarginsPresetsTable';

const MarginsPresets = (): JSX.Element => {
  return (
    <div className="p-4 flex-grow-1 d-flex flex-column">
      <EditMarginPresets />
      <MarginsPresetsTable />
    </div>
  );
};

export default MarginsPresets;
