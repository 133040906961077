import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { length } from 'utils/object';

export interface ClientEditionState {
  isEditing: boolean;
  isSaving: boolean;
  isReasonModalOpen: boolean;
  diffs: ClientsDiffs;
  changeReasonAll: string | undefined;
}

export interface ClientsDiffs {
  [clientId: number]: ClientDiff;
}

export type ClientDiff = {
  salesMarginProfileId: number | undefined;
  devLMarginProfileId: number | undefined;
  pcruMargin: number | undefined;
  changeReason: string | undefined;
};

const initialState: ClientEditionState = {
  isEditing: false,
  isSaving: false,
  isReasonModalOpen: false,
  diffs: {},
  changeReasonAll: '',
};

export const clientEditionSlice = createSlice({
  name: 'clientEdition',
  initialState,
  reducers: {
    toggleEdition: (state) => {
      state.isEditing = !state.isEditing;
      if (!state.isEditing) {
        state.diffs = {};
      }
    },
    reasonModalToggled: (state) => {
      state.isReasonModalOpen = !state.isReasonModalOpen;
    },
    marginProfileModified: (
      state,
      { payload: { clientId, diff } }: PayloadAction<MarginProfileModifiedPayload>,
    ) => {
      state.diffs[clientId] = state.diffs[clientId] ?? {};
      state.diffs[clientId] = {
        ...state.diffs[clientId],
        ...diff,
      };
      state.changeReasonAll = '';
    },
    marginProfileModifiedAll: (
      state,
      { payload: { clientIds, diff } }: PayloadAction<MarginProfileModifiedAllPayload>,
    ) => {
      clientIds.map((clientId) => {
        state.diffs[clientId] = state.diffs[clientId] ?? {};
        state.diffs[clientId] = {
          ...state.diffs[clientId],
          ...diff,
        };
        state.changeReasonAll = diff.changeReason;
      });
    },
    marginProfileResetted: (
      state,
      { payload: { clientId, diff } }: PayloadAction<MarginProfileResettedPayload>,
    ) => {
      if (state.diffs[clientId] === undefined) return;
      diff.forEach((key) => delete state.diffs[clientId][key]);
      if (length(state.diffs[clientId]) === 0) {
        delete state.diffs[clientId];
      }
    },
    reset: (_state) => initialState,
  },
});

export interface MarginProfileModifiedPayload {
  clientId: number;
  diff: Partial<ClientDiff>;
}

export interface MarginProfileModifiedAllPayload {
  clientIds: number[];
  diff: Partial<ClientDiff>;
}

export interface MarginProfileResettedPayload {
  clientId: number;
  diff: (keyof ClientDiff)[];
}

export interface ButtonStatusPayload {
  buttonStatus: string;
}
