import { isDefined } from "@sgme/fp";
import { convertLiquidityProviderDimension } from "services/fx/utils";
import { fxApi } from "./fxApi";
import type { FxMarginProfileTypeKey } from "./model/models";

import type { FxCashGridDataType } from "@services/fx/model/cash";
import type {
  FxPerimeterKey,
  FxProductKey,
} from "@services/fx/model/perimiters";
import { transformFxOrderProfileResponse } from "./getFXProfileMarginGrid";
import type { ClientBdrLevel } from "./model/client";
import type { FxOrderProfile } from "./model/order";

export type ResponseFxPreset = FxOrderProfile;

export type PayloadType = {
  profileName?: string;
  "liquidity-provider-dimension.External.margin-value"?: string;
  "liquidity-provider-dimension.Internal.margin-value"?: string;
  "margin-value"?: string;
  "margin-unit": string;
};

export const addFxOrderPresetApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    addFxOrderPreset: builder.mutation<
      ResponseFxPreset,
      {
        perimeterKey: FxPerimeterKey;
        productKey: FxProductKey;
        profileTypeKey: FxMarginProfileTypeKey;
        addFxPresetPayload: PayloadType;
        queryParams?: {
          clientBdrId: number;
          clientBdrLevel: ClientBdrLevel;
          gridDataType?: FxCashGridDataType;
        };
      }
    >({
      query: ({
        perimeterKey,
        productKey,
        profileTypeKey,
        addFxPresetPayload,
        queryParams,
      }) => {
        const { externalMarginValue, internalMarginValue } =
          getMarginValues(addFxPresetPayload);

        return {
          method: "POST",
          url: `perimeters/${perimeterKey}/products/${productKey}/profiles`,
          params: queryParams,
          body: {
            profileName:
              profileTypeKey === "custom-profile-type"
                ? null
                : addFxPresetPayload.profileName,
            profileTypeKey,
            rows: [
              {
                internalRowId: [""],
                columns: {
                  "media-dimension": "*",
                  "instrument-dimension": "*",
                  "currency-pair-dimension": "*",
                  ...(productKey === "algo-order-product"
                    ? {
                        "algo-type-dimension": "*",
                        "margin-unit": "bps",
                        "liquidity-provider-dimension.External.margin-value":
                          externalMarginValue,
                        "liquidity-provider-dimension.Internal.margin-value":
                          internalMarginValue,
                      }
                    : productKey === "limit-order-product"
                    ? {
                        "limit-order-type-dimension": "*",
                        "margin-value": addFxPresetPayload["margin-value"],
                        "margin-unit": "pips",
                      }
                    : {
                        "fixing-source-dimension": "*",
                        "margin-value": addFxPresetPayload["margin-value"],
                        "margin-unit": "bps",
                      }),
                },
                state: "added",
              },
            ],
          },
        };
      },

      transformResponse: transformFxOrderProfileResponse,

      invalidatesTags: ["Profiles"],
    }),
  }),
});

function getMarginValues(payload: PayloadType) {
  const externalMarginValue =
    payload["liquidity-provider-dimension.External.margin-value"];
  const internalMarginValue =
    payload["liquidity-provider-dimension.Internal.margin-value"];

  if (
    payload["margin-unit"] === "mln" &&
    isDefined(externalMarginValue) &&
    isDefined(internalMarginValue)
  ) {
    const { External, Internal } = convertLiquidityProviderDimension({
      External: externalMarginValue,
      Internal: internalMarginValue,
    });

    return {
      externalMarginValue: External,
      internalMarginValue: Internal,
    };
  }

  return {
    externalMarginValue,
    internalMarginValue,
  };
}

export const { useAddFxOrderPresetMutation } = addFxOrderPresetApi;
