import { FxClientBadge } from '@components/fx/badges/FxClientBadge';
import { ProductProperty } from '@pages/layout/Header/FXHeader/ProductHeaderProperty';
import type { FxClientModel } from '@services/fx/model/client';
import { FormattedMessage } from 'react-intl';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

type FxFirstClientCardHeaderProps = {
  client: FxClientModel;
  goToPerimetersPage: () => void;
};

export function FxFirstClientCardHeader({ client, goToPerimetersPage }: FxFirstClientCardHeaderProps) {
  const { clientBdrId, clientBdrLevel, clientCountryCode, clientLongName, clientMnemonic, profileSummaryFlag } = client;
  const isAuthorized = client.userAccess !== 'unauthorized';

  const clientProperties: [string, string, string | number][] = [
    ['commercial-level', 'fx.header.property.commercialLevel', clientBdrLevel],
    ['region', 'fx.header.property.region', clientCountryCode],
    ['legal-bdr-id', 'fx.header.property.legalBdrId', clientBdrId],
    ['mnemonic', 'fx.header.property.mnemonic', clientMnemonic],
  ];

  return (
    <div className="card-header border-bottom d-flex justify-content-between align-items-end">
      <div>
        <h3 className="text-primary mb-1 fw-bold">{clientLongName}</h3>
        <div className="d-flex flex-wrap gap-2 fs-14 text-secondary fw-medium">
          {getPropertiesToDisplay(clientProperties).map(([dataE2e, labelId, value]) => {
            return (
              <div key={dataE2e}>
                <ProductProperty dataE2e={dataE2e} labelId={labelId} value={value} />
                <span className="ms-8px">•</span>
              </div>
            );
          })}
          <div>
            <FormattedMessage id="marginAndTiering.clientPage" />
          </div>
          {profileSummaryFlag !== '' && (
            <FxClientBadge dataE2e="profile-flag-badge" profileSummaryFlag={profileSummaryFlag} />
          )}
        </div>
      </div>
      {isAuthorized ?
        <button type="button" data-e2e="see-client-btn" className="btn btn-lg btn-primary" onClick={goToPerimetersPage}>
          <span className="d-none d-xl-block">
            <FormattedMessage id="fx.header.seeClient.button" />
          </span>
          <i className="icon icon-md text-discreet-primary ms-xl-3">arrow_forward</i>
        </button>
        :
        <NoAccessWarning />
      }
    </div>
  );
}

function getPropertiesToDisplay(clientProperties: [string, string, string | number][]) {
  return clientProperties.filter((clientProperty) => clientProperty[2] !== '');
}



const NoAccessWarning = () => {
  return (
    <>
      <div className='alert alert-discreet-warning fw-semibold py-2' data-e2e='noAccessWarning' id='warningNoAccess'>
        <FormattedMessage id="fx.header.notAuthorized" />
      </div>

      <UncontrolledPopover target="warningNoAccess" trigger="hover" placement="bottom">
        <PopoverBody data-e2e="warningNoAccess ">
          <div className='fw-medium' style={{ color: '#ec7d1b' }}>
            <FormattedMessage id="fx.useraccess.unauthorized" />
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  )

}