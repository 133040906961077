import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isDefined, isNotDefined } from '@sgme/fp';
import { transformFxClientRows } from 'store/fxClients/fxClientsSelector';
import styled from 'styled-components';
import { FxProfileBadge } from 'components/fx/badges/FxProfileBadge';
import { useGetFXClientsInheritanceQuery } from '@services/fx/getFXClientsInheritance';
import { FxOrderProfileTable } from '../../components/FxOrderProfileTable';
import { useFxUrlParams } from '@hooks/useFxUrlParams';
import { Authorize } from '@features/authorization/Authorize';
import type { FxOrderProductKey } from '@services/fx/model/order';
import { useGetFXOrderProfileOfProductQuery } from '@services/fx/getFXOrderProfileOfProduct';
import { type FxClientInheritance, getFxOrderInheritancePerimeter } from '@services/fx/model/inheritance';
import { FxInheritedBadge } from '@components/fx/badges/FxInheritedBadge';
import { getEditingPermissionsForCurrentProduct } from '@features/authorization/getEditingPermissionsForCurrentProduct';

interface FxProductProps {
  productKey: FxOrderProductKey;
  openByDefault?: boolean;
  className?: string;
}

export const FxOrderProduct = ({ productKey, openByDefault, className = '' }: FxProductProps): JSX.Element => {

  const allowedPermissions = getEditingPermissionsForCurrentProduct(undefined, productKey, "fx-order-perimeter")
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clientBdrId, clientBdrLevel, perimeterKey } = useFxUrlParams();

  const queryProfileOfProductParams =
    isDefined(clientBdrId) && isDefined(clientBdrLevel) && isDefined(perimeterKey) && isDefined(productKey)
      ? { clientBdrId, clientBdrLevel, perimeterKey, productKey }
      : skipToken;

  const { data: profile, isError, isLoading } = useGetFXOrderProfileOfProductQuery(queryProfileOfProductParams);

  const queryClientsInheritanceParams =
    isDefined(clientBdrId) && isDefined(clientBdrLevel) ? { clientBdrId, clientBdrLevel } : skipToken;

  const { data: inheritedProfile } = useGetFXClientsInheritanceQuery(queryClientsInheritanceParams);
  const inheritedProductProfile = findInheritedProductProfile(inheritedProfile, productKey);
  const rows = transformFxClientRows(profile?.rows.entities);
  return (
    <StyledDetails className={`col ${className ?? ''}`} open={openByDefault} data-e2e={productKey}>
      <summary className="d-flex align-items-baseline mb-2 ps-2">
        <div className="d-flex align-items-center flex-grow-1 mb-2 gap-2">
          <div className="display-5 m-0 p-0">
            <FormattedMessage id={productKey} />
          </div>

          <div className="mb-0 fw-medium">
            <FormattedMessage id="margin.preset" />
          </div>

          <FxProfileBadge profileName={profile?.profileName} profileTypeKey={profile?.profileTypeKey} size="sm" />

          {isDefined(inheritedProductProfile) && inheritedProductProfile.isProfileInherited && (
            <FxInheritedBadge
              perimeterKey="fx-order-perimeter"
              dataE2e="setup-flag-badge"
              inheritedBdrId={inheritedProductProfile.inheritableProfile.client.clientBdrId}
              inheritedBdrLevel={inheritedProductProfile.inheritableProfile.client.clientBdrLevel}
              inheritedBdrName={inheritedProductProfile.inheritableProfile.client.clientLongName}
              size="sm"
            />
          )}

          <Authorize authorizedFor={allowedPermissions}>
            <button
              data-e2e={`edit-margins-${productKey}`}
              className="btn btn-discreet-primary btn-icon-start ms-auto"
              onClick={() => {
                const isInheritedSearchParams = inheritedProductProfile?.isProfileInherited ? '&isInherited=true' : '';
                const url = `${pathname}/${productKey}?profileId=${profile?.profileId}${isInheritedSearchParams}`;
                navigate(url);
              }}
            >
              <em className="icon">edit</em>
              <FormattedMessage id="edit.margins" />
            </button>
          </Authorize>
        </div>
      </summary>

      <FxOrderProfileTable
        productKey={productKey}
        rows={rows}
        maxRowDisplayed={10}
        isLoading={isLoading}
        isError={isError}
      />
    </StyledDetails>
  );
};

const StyledDetails = styled.details`
  & summary .btn.btn-discreet-primary:hover {
    background: black !important;
  }
`;

const findInheritedProductProfile = (clientInheritance: FxClientInheritance | undefined, productKey: string) => {
  if (isNotDefined(clientInheritance) || isNotDefined(clientInheritance.data.perimeters)) {
    return undefined;
  }

  const product = getFxOrderInheritancePerimeter(clientInheritance.data.perimeters)?.products.find(
    (product) => product.productKey === productKey,
  );

  return product?.profile?.isProfileInherited === true ? product?.profile : undefined;
};
