import { SmokeTestPage } from '@features/smoketest/SmokeTestPage/SmokeTestPage';
import { FxEditProfileHeader } from '@pages/fx/FxEditProfilePage/FxEditProfileHeader/FxEditProfileHeader';
import { FxSelectProfileHeader } from '@pages/fx/FxSelectProfilePage/FxSelectProfileHeader/FxSelectProfileHeader';
import { LayoutWithIntegratedHeader } from '@pages/layout/LayoutWithIntegratedHeader';
import { createBrowserRouter } from 'react-router-dom';
import HomePage from './HomePage';
import FxClientsPage from './fx/FxClientsPage';
import FxEditProfilePage from './fx/FxEditProfilePage';
import FxProductsPage from './fx/FxProductsPage';
import FxSelectProfilePage from './fx/FxSelectProfilePage';
import UnauthorizedFxClientPage from './fx/UnauthorizedFxClientPage';
import {
  FX_EDIT_CASH_PROFILE_ROUTE,
  FX_EDIT_ORDER_PROFILE_ROUTE,
  FX_PATH,
  FX_PATH_UNAUTHORIZED,
  FX_PRODUCTS_PATH,
  FX_SELECT_CASH_PROFILE_ROUTE,
  FX_SELECT_ORDER_PROFILE_ROUTE,
} from './fx/constants';
import LagClientsPage from './lag/LagClientsPage';
import LagPresetsPage from './lag/LagPresetsPage';
import { LAG_PATH, LAG_PRESETS_PATH } from './lag/contants';
import { FxClientsCrumb } from './layout/Breadcrumb/FxClientsCrumb';
import { FxEditProductCrumb } from './layout/Breadcrumb/FxEditProductCrumb';
import { FxProductsCrumb } from './layout/Breadcrumb/FxProductsCrumb';
import { Layout } from './layout/Layout';

export const router = createBrowserRouter([
  {
    path: `${FX_EDIT_CASH_PROFILE_ROUTE}`,
    element: <LayoutWithIntegratedHeader />,
    handle: { crumb: () => <FxEditProductCrumb /> },
    children: [
      {
        index: true,
        element: <FxEditProfilePage />,
        handle: { IntegratedHeaderComponent: () => <FxEditProfileHeader /> },
      },
    ],
  },

  {
    path: `${FX_EDIT_ORDER_PROFILE_ROUTE}`,
    element: <LayoutWithIntegratedHeader />,
    handle: { crumb: () => <FxEditProductCrumb /> },
    children: [
      {
        index: true,
        element: <FxEditProfilePage />,
        handle: { IntegratedHeaderComponent: () => <FxEditProfileHeader /> },
      },
    ],
  },

  {
    path: `${FX_SELECT_ORDER_PROFILE_ROUTE}`,
    element: <LayoutWithIntegratedHeader />,
    handle: { crumb: () => <FxEditProductCrumb /> },
    children: [
      {
        index: true,
        element: <FxSelectProfilePage />,
        handle: { IntegratedHeaderComponent: () => <FxSelectProfileHeader /> },
      },
    ],
  },

  {
    path: `${FX_SELECT_CASH_PROFILE_ROUTE}`,
    element: <LayoutWithIntegratedHeader />,
    handle: { crumb: () => <FxEditProductCrumb /> },
    children: [
      {
        index: true,
        element: <FxSelectProfilePage />,
        handle: { IntegratedHeaderComponent: () => <FxSelectProfileHeader /> },
      },
    ],
  },

  {
    path: '/',
    element: <Layout />,
    children: [
      // home page
      {
        index: true,
        element: <HomePage />,
      },

      // LAG
      {
        path: LAG_PATH,
        children: [
          { index: true, element: <LagClientsPage /> },
          {
            path: LAG_PRESETS_PATH,
            element: <LagPresetsPage />,
          },
        ],
      },

      // FX
      {
        path: FX_PATH,
        handle: { crumb: () => <FxClientsCrumb /> },
        children: [
          // search clients
          {
            index: true,
            element: <FxClientsPage />,
          },

          // client page
          {
            path: FX_PRODUCTS_PATH,
            handle: { crumb: (active: boolean) => <FxProductsCrumb active={active} /> },
            children: [
              {
                index: true,
                element: <FxProductsPage />,
              },
            ],
          },

          // unauthorized page
          {
            path: FX_PATH_UNAUTHORIZED,
            element: <UnauthorizedFxClientPage />,
          },
        ],
      },

      // smoke test
      {
        path: 'smoketest',
        element: <SmokeTestPage />,
      },
    ],
  },
]);
