import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FormMessageStatus } from './FormMessageStatus';
import { getLabel } from './getLabel';

interface FormInputProps extends UseControllerProps {
  className?: string;
  labelComponent?: JSX.Element;
  labelLocaleKey?: string;
  placeholderLocaleKey?: string;
  disabled?: boolean;
  hideError?: boolean;
}

export const FormInput = ({
  className = '',
  name,
  labelComponent,
  labelLocaleKey,
  placeholderLocaleKey,
  disabled,
  rules,
  hideError = false,
}: FormInputProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules,
  });

  const placeholder = placeholderLocaleKey ? formatMessage({ id: placeholderLocaleKey }) : '';

  return (
    <div className={`form-group ${className ?? ''}`} data-e2e={name.replace('.', '-')}>
      <label className="text-primary fw-medium mb-2" htmlFor={name}>
        {getLabel(labelComponent)(labelLocaleKey)}
      </label>

      <input
        {...field}
        data-e2e={`${name.replace('.', '-')}-input`}
        type="text"
        autoComplete="off"
        className={`form-control ${error ? 'is-invalid' : ''} mb-1`}
        id={name}
        placeholder={placeholder}
        disabled={disabled}
      />

      {!hideError && (
        <FormMessageStatus name={name} errorType={error?.type} status="danger" icon="error" show={Boolean(error)} />
      )}
    </div>
  );
};
