import { IcAccountPickerSection } from './IcAccountPickerSection';
import { StatusJsonSection } from './StatusJsonSection';
import { StatusMonitoringSection } from './StatusMonitoringSection';

export function SmokeTestPage(): JSX.Element {
  return (
    <div className="d-flex flex-column p-4">
      <h1 className="display-3 mb4">Margins SmokeTest</h1>
      <StatusJsonSection />
      <StatusMonitoringSection />
      <IcAccountPickerSection />
    </div>
  );
}
