import { fxApi } from './fxApi';
import type { ProfileId } from './model/models';
import { clearUndefined } from '@sgme/fp';
import type { ClientBdrId, ClientBdrLevel } from './model/client';
import type { FxCashGridDataType } from './model/cash';
import type { EditingRow } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { mapRowsForBackend } from '@services/fx/validateMarginGrid';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';

export const saveProductApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    saveEditedFxProfile: builder.mutation<
      void,
      {
        clientBdrId: ClientBdrId;
        clientBdrLevel: ClientBdrLevel;
        perimeterKey: FxPerimeterKey;
        productKey: FxProductKey;
        profileId: ProfileId;
        reasonId: number;
        comment?: string;
        onBehalfOfUser?: string;
        editedRows?: EditingRow[];
        gridDataType?: FxCashGridDataType;
        profileVersion?: number;
        clientProfileAssignmentVersion?: number;
      }
    >({
      query: ({
        clientBdrId,
        clientBdrLevel,
        perimeterKey,
        productKey,
        profileId,
        editedRows = [],
        gridDataType,
        ...reason
      }) => ({
        method: 'PUT',
        url: `perimeters/${perimeterKey}/products/${productKey}/clients/${clientBdrId}/profiles/${profileId}`,
        params: {
          clientBdrLevel,
          gridDataType,
        },
        body: clearUndefined({ ...reason, rows: mapRowsForBackend(editedRows) }),
      }),

      invalidatesTags: (
        result,
        error,
        { perimeterKey, productKey, profileId, clientBdrId, clientBdrLevel, gridDataType },
      ) => [
        { type: 'ClientsUsingProfile', id: profileId },
        { type: 'Profile', id: `${perimeterKey}-${productKey}` },
        { type: 'Profile', id: `${perimeterKey}-${productKey}-${gridDataType}` },
        { type: 'Profile', id: `${profileId}` },
        { type: 'ClientsInheritance', id: `${clientBdrId}/${clientBdrLevel}` },
        'FXClients',
      ],
    }),
  }),
});

export const { useSaveEditedFxProfileMutation } = saveProductApi;
