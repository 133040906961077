import { forwardRef, useCallback, useEffect, useState } from 'react';

export type LazyInputProps = Omit<JSX.IntrinsicElements['input'], 'value' | 'onChange'> & {
  value: string;
  onChange(value: string): void;
};

const LazyInput = forwardRef<HTMLInputElement, LazyInputProps>(({ value, onChange, ...inputProps }, ref) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setCurrentValue(event.target.value),
    [],
  );
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (_event) => onChange(currentValue),
    [onChange, currentValue],
  );

  return (
    <input
      ref={ref}
      type="text"
      className={`form-control ${inputProps.className ?? ''}`}
      value={currentValue}
      onChange={handleChange}
      onBlur={handleBlur}
      {...inputProps}
    />
  );
});

LazyInput.displayName = 'LazyInput';

export { LazyInput };
