import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { type PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function PageTracker({ children }: PropsWithChildren<object>): JSX.Element {
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const location = useLocation();
  const locationOrigin = window.location.origin;

  // Every time the location changes, we track the page view on Matomo.
  useEffect(() => {
    sgwtWebAnalytics?.trackPageView(`${locationOrigin}${location.pathname}`);
  }, [sgwtWebAnalytics, location, locationOrigin]);

  return <>{children}</>;
}
