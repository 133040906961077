import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { isDefined, isEmpty, isNotDefined } from '@sgme/fp';

type UseFullTextSearchReturn<T> = [
  listFiltered: Array<T>,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
];

export const useFullTextSearch = <T>(
  list: Array<T>,
  mapper?: (element: T) => string,
): UseFullTextSearchReturn<T> => {
  const [searchRegexp, setSearchRegexp] = useState<RegExp | undefined>(undefined);

  const listFiltered = useMemo(() => {
    if (isNotDefined(searchRegexp)) {
      return list;
    }

    return list.filter((element) => {
      if (typeof element === 'string') {
        return element.toLowerCase().match(searchRegexp);
      }

      if (isDefined(mapper)) {
        return mapper(element).toLowerCase().match(searchRegexp);
      }

      return true;
    });
  }, [searchRegexp, list, mapper]);

  const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (isEmpty(event.target.value)) {
      setSearchRegexp(undefined);
    }

    const pattern = event.target.value
      .toLowerCase()
      .split(' ')
      .map((letter) => `(?=.*${letter})`)
      .join('');

    setSearchRegexp(new RegExp(`${pattern}`, 'g'));
  }, []);

  return [listFiltered, onSearch];
};
