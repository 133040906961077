import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { Dictionary } from '@reduxjs/toolkit';
import type { MarginProfile, MarginProfileType } from 'services/marginProfiles';
import { selectEntitiesMarginProfiles } from 'services/marginProfiles';
import {type PcruMargin, pcruMargins} from "../../../../../store/pcruProfiles/pcruMarginsSlice";

export const MARGIN_PROFILE_TYPES = ['Sales', 'DevL', 'NA'] as const;

export type MarginOption = { id: number | PcruMargin; name: string };
export type SelectPresetValues = ReturnType<typeof useSelectPresetValues>;

const computeMarginOptions =
  (allMarginProfiles: Dictionary<MarginProfile>) =>
  (marginProfileType: MarginProfileType): MarginOption[] => {
    const currentMargins = Object.values(allMarginProfiles).reduce<MarginOption[]>(
      (acc, currMarginProfile) => {
        if (!currMarginProfile) return acc;

        const { id, isPreset, type, name } = currMarginProfile;
        if (!isPreset || type !== marginProfileType) return acc;

        return [...acc, { id, name }];
      },
      [],
    );

    if (marginProfileType === 'Sales') {
      const customMargin: MarginOption = { id: -1, name: 'Custom' };
      currentMargins.push(customMargin);
    }

    return currentMargins;
  };

export const useSelectPresetValues = (): { [type: string]: MarginOption[] } => {
  const allMarginProfiles = useSelector(selectEntitiesMarginProfiles);

  return useMemo(() => ({
      ...MARGIN_PROFILE_TYPES.reduce((acc, marginProfileType) => {
        if (!marginProfileType) return acc;

        return {
          ...acc,
          [marginProfileType]: computeMarginOptions(allMarginProfiles)(marginProfileType),
        };
      }, {}),

      pcru: pcruMargins.map((value) => ({
        id: value,
        name: `${value}bp`,
      })),
    }), [allMarginProfiles]);
};
