import type { HTMLAttributes } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { isDefined, isNotDefined } from '@sgme/fp';
import styled from 'styled-components';

const FadeMessage = styled.div<{ show: boolean }>`
  height: ${({ show }) => (show ? '1.3em' : '0')};
  transform: ${({ show }) => (show ? 'scaleY(1)' : 'scaleY(0)')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  transform-origin: top;

  transition: all 0.3s ease-in-out;
`;

const SUFFIX_E2E_KEY = 'form-message';

interface FormMessageStatusProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  errorType?: string;
  localeKey?: string;
  values?: Record<string, string | number>;
  icon?: string;
  status?: 'danger' | 'warning' | 'success';
  show?: boolean;
}

export const FormMessageStatus = ({
  className = '',
  icon,
  name,
  errorType,
  values,
  status = 'danger',
  show = false,
}: FormMessageStatusProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const getMessage = useCallback(
    (identifier: string[]) => {
      if (isNotDefined(identifier) || identifier.length === 0) {
        return formatMessage({ id: 'error.unknown' });
      }

      let message = formatMessage({ id: identifier.join('.') }, values);
      if (message === identifier.join('.')) {
        message = getMessage(identifier.slice(0, -1));
      }

      return message;
    },
    [formatMessage, values],
  );

  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (isDefined(errorType)) {
      setMessage(getMessage(['error', errorType, name]));
    }
  }, [errorType, getMessage, name]);

  return (
    <FadeMessage
      show={show}
      className={`d-flex align-items-center fs-14 text-${status} ${className ?? ''}`}
      data-e2e={`${name}-${errorType}-${SUFFIX_E2E_KEY}`}
    >
      {isDefined(icon) && <em className="icon me-1 fs-14">{icon}</em>}

      {message}
    </FadeMessage>
  );
};
