import { FormattedMessage } from "react-intl";
import type { ModalProps } from "reactstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

export type FxUnsavedChangesWarningModalProps = ModalProps;

export const FxUnsavedChangesWarningModal = (
  props: FxUnsavedChangesWarningModalProps,
): JSX.Element => {
  const { modalHeaderClose } = props;

  return (
    <Modal
      data-e2e="unsaved-changes-warning-modal"
      contentClassName="border border-warning"
      {...props}
    >
      <ModalHeader tag="h3" close={modalHeaderClose}>
        <FormattedMessage id="fx.unsaved.changes" />
      </ModalHeader>

      <ModalBody className="pb-0">
        <div
          className="d-flex align-items-center justify-content-start mb-4"
          style={{ flexWrap: 'wrap' }}
        >
          <span className="me-2 text-warning d-flex align-items-center">
            <WarningIconContainer>
              <em className="icon icon-md text-warning">warning_amber</em>
            </WarningIconContainer>

            <span className="ms-2">
              <FormattedMessage id="warning.pending.changes" />
            </span>
          </span>
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-lg btn-flat-primary" onClick={props.onClosed}>
          <FormattedMessage id="common.close" />
        </button>
      </ModalFooter>
    </Modal>
  );
};

const WarningIconContainer = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center me-2 bg-warning bg-opacity-10 border-radius-circle',
})`
  min-width: 3em;
  min-height: 3em;
`;
