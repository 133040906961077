import type { Dictionary } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';
import type { FxOrderProfileRow } from "../../services/fx/model/order";

export const isNotDeleted = (row: FxOrderProfileRow) => row.state !== 'deleted';

export const transformFxClientRows = (stateRows: Dictionary<FxOrderProfileRow> | undefined) =>
  Object.entries(stateRows ?? {})
    .map(([key, entity]) => ({ ...entity, frontRowId: key } as FxOrderProfileRow))
    .filter(isDefined);
