import { FormattedMessage } from "react-intl";
import { TIERING_GRID_DATA_TYPE } from "@services/fx/model/cash";
import type { Permission } from "@features/authorization/authorization.model";
import { useSelector } from "react-redux";
import { selectPermissions } from "@features/authorization/authorization.slice";
import { ModalHandler } from "@components/modals/ModalHandler";
import { FxProfileBadge } from "@components/fx/badges/FxProfileBadge";
import { FxInheritedBadge } from "@components/fx/badges/FxInheritedBadge";
import { Link } from "react-router-dom";
import type { FC } from "react";
import { useGetFxInheritableProfileReference } from "@services/fx/getFXClientsInheritance";
import { useFxSelectAndEditProfileUrlParams } from "@pages/fx/useFxSelectAndEditProfileUrlParams";
import type { FxProfileMarginGrid } from "@services/fx/model/profile-margin-grid";

import type { FxProfileColumns } from "@services/fx/model/perimiters";
import { ClientsCount } from "@pages/fx/FxEditProfilePage/ClientsCount";

type FxSelectedProfileActionsProps = {
  selectedProfile: FxProfileMarginGrid<FxProfileColumns>;
  isInheritedProfile: boolean;
};

const FxSelectedProfileActions: FC<FxSelectedProfileActionsProps> = ({
  selectedProfile,
  isInheritedProfile,
}) => {
  const permissions: Permission[] = useSelector(selectPermissions);
  const {
    gridDataType,
    clientBdrLevel,
    clientBdrId,
    perimeterKey,
    productKey,
  } = useFxSelectAndEditProfileUrlParams();

  const profileReference = useGetFxInheritableProfileReference({
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
  });

  const {
    profileTypeKey,
    profileId,
    profileVersion,
    profileName,
    clientsCount,
  } = selectedProfile;

  const showEditStandardPresetButton =
    profileTypeKey === "standard-profile-type" &&
    permissions.includes("FX_STANDARDS_WRITEPRESETS");

  const showEditPoolPresetButton =
    profileTypeKey === "client-pool-profile-type";
  const showEditCustomPresetButton = profileTypeKey === "custom-profile-type";
  const couldRenameStandardProfile =
    ["standard-profile-type"].includes(profileTypeKey ?? "") &&
    permissions.includes("FX_STANDARDS_WRITEPRESETS");
  const couldRenameCustomProfile = ["client-pool-profile-type"].includes(
    profileTypeKey ?? "",
  );
  const couldBeRenamed = couldRenameStandardProfile || couldRenameCustomProfile;
  const showClientsCount = [
    "client-pool-profile-type",
    "standard-profile-type",
  ].includes(profileTypeKey ?? "");

  return (
    <span className="d-flex align-items-center mb-4 ms-0">
      <h3 className="mb-0">
        <FormattedMessage
          id={
            gridDataType === TIERING_GRID_DATA_TYPE
              ? "tiering.preset"
              : "margin.preset"
          }
        />
      </h3>

      <FxProfileBadge
        className="ms-4"
        size="sm"
        profileName={profileName}
        profileTypeKey={profileTypeKey}
      />

      {couldBeRenamed && (
        <ModalHandler
          modalName="FxRenameProfileModal"
          profileId={profileId}
          profileName={profileName}
          profileVersion={profileVersion}
        >
          <button
            className="btn btn-icon-start btn-link ms-1"
            data-e2e="btn-rename-preset"
          >
            <em className="icon icon-sm">edit</em>
            <FormattedMessage id="common.rename" />
          </button>
        </ModalHandler>
      )}

      {showClientsCount && (
        <ModalHandler
          modalName="FxClientsForProfileModal"
          profile={selectedProfile}
        >
          <ClientsCount className="ms-1" clientsCount={clientsCount} />
        </ModalHandler>
      )}

      {isInheritedProfile && (
        <FxInheritedBadge
          perimeterKey="fx-order-perimeter" // TODO: can be cash too
          dataE2e="setup-flag-badge"
          inheritedBdrId={
            profileReference?.inheritableProfile.client.clientBdrId
          }
          inheritedBdrLevel={
            profileReference?.inheritableProfile.client.clientBdrLevel
          }
          inheritedBdrName={
            profileReference?.inheritableProfile.client.clientLongName
          }
          size="sm"
          className="ms-1"
        />
      )}

      {showEditStandardPresetButton && (
        <ModalHandler
          modalName="FxEditStandardPresetWarningModal"
          profile={selectedProfile}
        >
          <button
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            className="btn btn-discreet-primary ms-auto"
            data-e2e="edit-standard-preset-values"
          >
            <FormattedMessage
              id="fx.edit.preset.values"
              values={{ presetLabel: "standard" }}
            />
          </button>
        </ModalHandler>
      )}

      {showEditPoolPresetButton && (
        <ModalHandler
          modalName="FxEditPoolPresetWarningModal"
          profile={selectedProfile}
        >
          <button
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            className="btn btn-discreet-primary ms-auto"
            data-e2e="edit-pool-preset-values"
          >
            <FormattedMessage
              id="fx.edit.preset.values"
              values={{ presetLabel: "pool" }}
            />
          </button>
        </ModalHandler>
      )}

      {showEditCustomPresetButton && (
        <Link
          to={`./edit?profileId=${profileId}`}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className="btn btn-discreet-primary ms-auto"
          data-e2e="edit-custom-preset-values"
        >
          <FormattedMessage
            id="fx.edit.preset.values"
            values={{ presetLabel: "custom" }}
          />
        </Link>
      )}
    </span>
  );
};

export default FxSelectedProfileActions;
