import { LoadingButton } from '@components/LoadingButton';
import { FormattedMessage } from 'react-intl';
import { ModalHandler } from '@components/modals/ModalHandler';
import { isDefined } from '@sgme/fp';
import { generatePath, useNavigate } from 'react-router-dom';
import { FX_PRODUCTS_ROUTE } from '@pages/fx/constants';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { useSaveEditedFxProfileMutation } from '@services/fx/saveEditedFxProfile';
import { useAppSelector } from '@hooks/useAppSelector';
import type { UpdateProfileErrorPayload } from '@services/fx/model/models';

type SaveEditedProfileButtonProps = {
  profileVersion?: number;
  clientProfileAssignmentVersion?: number;
};

const SaveEditedProfileButton = ({ profileVersion, clientProfileAssignmentVersion }: SaveEditedProfileButtonProps) => {
  const { clientBdrId, clientBdrLevel, perimeterKey, productKey, gridDataType, profileId } =
    useFxSelectAndEditProfileUrlParams();
  const navigate = useNavigate();

  const [saveEditedFxProfile, { isLoading: isSavingEditedFxProfile }] = useSaveEditedFxProfileMutation({
    fixedCacheKey: 'fx-profile-change-reason',
  });

  const rowByUiId = useAppSelector((state) => state.fxProfileEdition.rowByUiId);
  const hasUserNotMadeAnyChangeYet = Object.values(rowByUiId).every((row) => row.state === 'untouched');

  const makeOnSubmit =
    (innerOnClosed: () => void, setError: (error: UpdateProfileErrorPayload['data']) => void, contactEmail?: string) =>
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { reason, comment } = event.currentTarget;

      const { error } = (await saveEditedFxProfile({
        clientBdrId,
        clientBdrLevel,
        perimeterKey,
        productKey,
        profileId,
        profileVersion,
        clientProfileAssignmentVersion,
        reasonId: reason?.value,
        comment: comment?.value,
        onBehalfOfUser: contactEmail,
        editedRows: Object.values(rowByUiId),
        gridDataType,
      })) as { error: UpdateProfileErrorPayload };

      if (isDefined(error)) {
        setError(error.data);
      } else {
        innerOnClosed();
        navigate(generatePath(FX_PRODUCTS_ROUTE, { clientBdrId: String(clientBdrId), clientBdrLevel, perimeterKey }));
      }
    };

  return (
    <ModalHandler modalName={'FxProfileChangeReasonsModal'} makeOnSubmit={makeOnSubmit} errorCode>
      <LoadingButton
        loading={isSavingEditedFxProfile}
        className="btn btn-lg btn-primary"
        data-e2e="fx-save-product-profile"
        disabled={hasUserNotMadeAnyChangeYet}
        icon="save"
      >
        <FormattedMessage id="common.save" />
      </LoadingButton>
    </ModalHandler>
  );
};

export default SaveEditedProfileButton;
