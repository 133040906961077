import { isNonEmpty } from '@sgme/fp';
import { SgwtHelpCenter, type SgwtHelpCenterHTMLElement } from '@sgwt/sgwt-widgets-react';
import { useCallback, useRef } from 'react';

interface HelpCenterProps {
  className?: string;
  withHandler?: boolean;
  topic?: string;
}

export function HelpCenter({ className = '', withHandler = false, topic }: HelpCenterProps): JSX.Element {
  const innerRef = useRef<SgwtHelpCenterHTMLElement>();

  const onWidgetReady = (element: SgwtHelpCenterHTMLElement) => {
    innerRef.current = element;
  };

  const onClickHandler = useCallback(() => {
    if (isNonEmpty(topic)) {
      innerRef.current?.topic?.(topic);
    } else {
      innerRef.current?.open?.();
    }
  }, [topic]);

  return (
    <>
      {withHandler && (
        <button
          className={`btn btn-flat-primary btn-icon ${className ?? ''}`}
          onClick={onClickHandler}
          onMouseUp={() => innerRef.current?.close?.()}
          type="button"
        >
          <em className="icon">help_outline</em>
        </button>
      )}

      <aside className="text-primary">
        <SgwtHelpCenter
          sg-connect-support="sg-connect-v2"
          application-id="sgmarkets-wmc"
          mail-subject="[SGWT Help Center] - [WMC] - User message"
          onWidgetReady={onWidgetReady}
        />
      </aside>
    </>
  );
}
