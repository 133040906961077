import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { changeCashColumnDimension } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { type Item, SgPicker } from '@components/SgPicker';
import { HelpCenter } from '@components/HelpCenter';
import clsx from 'clsx';
import type { FxCashValidationErrorCode } from '@services/fx/model/models';
import type { FxCashProfileColumns } from '@services/fx/model/cash';

export type TenorSelectProps = {
  dimensions: string[];
  disabled: boolean;
  validationErrorCode: FxCashValidationErrorCode;
};

const TenorSelect = (props: TenorSelectProps) => {
  const { dimensions, disabled, validationErrorCode } = props;

  const tenorItems = dimensions.map((key) => ({ key, label: key }));

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const tenorEndDimension = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxCashProfileColumns).tenorEndDimension,
  );
  const tenorStartDimension = useAppSelector(
    (state) => (state.fxProfileEdition.editingRow?.columns as FxCashProfileColumns).tenorStartDimension,
  );

  const setStartTenor = (item: Item | undefined) => {
    dispatch(changeCashColumnDimension({ key: 'tenorStartDimension', value: item?.key ?? '' }));
  };

  const setEndTenor = (item: Item | undefined) => {
    dispatch(changeCashColumnDimension({ key: 'tenorEndDimension', value: item?.key ?? '' }));
  };

  const isTenorInError =
    validationErrorCode === 'InvalidTenorValidationError' || validationErrorCode === 'InvalidTenorRangeValidationError';

  return (
    <div className="form-group" data-e2e="tenor">
      <div className={'d-flex flex-between'}>
        <label htmlFor="tenor">
          <span className="text-primary fw-medium">
            <FormattedMessage id={'fx.editProfile.fx-cash-rfq-perimeter.tenor'} />
          </span>
          &nbsp;
          <span className="text-secondary">
            <FormattedMessage id={'fx.editProfile.fx-cash-rfq-perimeter.tenor.sample'} />
          </span>
        </label>
        <div>
          <HelpCenter className={'text-secondary'} withHandler />
        </div>
      </div>

      <div>
        <span className="d-flex justify-content-between align-items-center">
          <div
            data-e2e={'tenor-start-select'}
            className={clsx(['w-100'], {
              'border-bottom': isTenorInError,
              'border-danger': isTenorInError,
            })}
          >
            <SgPicker
              mode="filter"
              placeholder={formatMessage({ id: 'fx.editProfile.fx-cash-rfq-perimeter.tenor.placeholder.left' })}
              singleSelect
              noIcon
              noClear
              items={tenorItems}
              defaultValue={tenorStartDimension}
              onChange={setStartTenor}
              disabled={disabled}
            />
          </div>

          <span className="mx-8px">-</span>

          <div
            data-e2e={'tenor-end-select'}
            className={clsx(['w-100'], {
              'border-bottom': isTenorInError,
              'border-danger': isTenorInError,
            })}
          >
            <SgPicker
              mode="filter"
              placeholder={formatMessage({ id: 'fx.editProfile.fx-cash-rfq-perimeter.tenor.placeholder.right' })}
              singleSelect
              noIcon
              noClear
              items={dimensions}
              defaultValue={tenorEndDimension}
              onChange={setEndTenor}
              disabled={disabled}
            />
          </div>
        </span>
        {isTenorInError && (
          <div className="text-danger mt-8px">
            <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.tenor.${validationErrorCode}`} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TenorSelect;
