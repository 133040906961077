import type { PropsWithChildren } from 'react';

export function FilterGroup({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <div className="d-flex flex-column pe-4 mt-1">
      <p className="text-secondary fw-bolder mb-2">{label}</p>
      <div className="btn-group btn-group-toggle flex-grow-1 d-flex align-items-center">
        {children}
      </div>
    </div>
  );
}
