import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { prepareHeaders } from 'services/prepareHeaders';

import { getAppConfig } from 'config';

const appConfig = getAppConfig();

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  tagTypes: ['Clients'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${appConfig.apiUrl}/api/clients`,
    prepareHeaders,
  }),
  endpoints: () => ({}),
});
