const serializedErrorProps = ['name', 'message', 'stack', 'code'] as const;
type SerializedErrorProps = typeof serializedErrorProps[number];
export type SerializedError = { [P in SerializedErrorProps]?: string };

export const serializeError = (error: any): any =>
  typeof error === 'object' && error !== null
    ? serializedErrorProps.reduce(
        (res, key) => ({ ...res, [key]: error[key] }),
        {} as SerializedError,
      )
    : error;
