import { FormattedMessage } from 'react-intl';
import { useFxUrlParams } from '@hooks/useFxUrlParams';

// why props: any ?
// because for AgGrid, cellRenderer is any !
export const MarginBidAskHeader = () => {
  const { gridDataType } = useFxUrlParams();

  const displayBothTieringAndMargin = gridDataType === undefined;
  const displayOnlyTieringInfo = gridDataType === 'tiering-grid-data-type';
  const displayOnlyMarginInfo = gridDataType === 'margin-grid-data-type';
  return (
    <>
      {(displayBothTieringAndMargin || displayOnlyMarginInfo) && (
        <>
          <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.margins" />
          &nbsp;
          <span className="text-turquoise-70 fw-bold">
            <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.bid" />
          </span>
          &nbsp;/&nbsp;
          <span className="text-pink-70 fw-bold">
            <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.ask" />
          </span>
        </>
      )}
      {displayBothTieringAndMargin && <> &nbsp; &amp; &nbsp;</>}
      {(displayBothTieringAndMargin || displayOnlyTieringInfo) && (
        <span className="fw-bold text-primary">
          <FormattedMessage id="fx.CashGrid.column.marginsBidAsk.tiering" />
        </span>
      )}
    </>
  );
};
