import { FormattedMessage } from 'react-intl';
import { useMarginUnit } from '../../hooks/useMarginUnit';
import type { AlgoMarginUnit } from '@services/fx/model/order';

export interface AlgoOrderProductMarginUnitSwitchProps {
  className?: string;
}

const UNIT_KEYS: AlgoMarginUnit[] = ['bps', 'mln'];

export const AlgoOrderProductMarginUnitSwitch = ({ className }: AlgoOrderProductMarginUnitSwitchProps): JSX.Element => {
  const [selectedMarginUnit, setMarginUnit] = useMarginUnit('algo-order-product');

  return (
    <div
      className={`btn-group btn-group-toggle ${className ?? ''}`}
      data-toggle="buttons"
      role="group"
      aria-label="toggle buttons single select"
    >
      {UNIT_KEYS.map((valueUnit) => (
        <SwitchUnit key={valueUnit} valueUnit={valueUnit} value={selectedMarginUnit} setValue={setMarginUnit} />
      ))}
    </div>
  );
};

interface SwitchUnitProps {
  valueUnit: string;
  value: string;
  setValue: (marginUnit: AlgoMarginUnit) => void; // PayloadAction<"mln" | "bps", "ui/setFxMarginAlgoUnit">
}

const SwitchUnit = (props: SwitchUnitProps) => {
  const { valueUnit, value, setValue } = props;

  return (
    <>
      <input key={`${valueUnit}-input`} type="radio" name="margin-unit" autoComplete="off" className="btn-check" />

      <label
        key={`${valueUnit}-label`}
        className={`btn btn-toggle-primary fw-medium ${value === valueUnit ? 'active' : ''}`}
        data-e2e={`switch-${valueUnit}`}
        onClick={() => setValue(valueUnit as AlgoMarginUnit)}
      >
        <FormattedMessage id={`common.in.${valueUnit}`} />
      </label>
    </>
  );
};
