import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';
import type { ReactElementWithOnClick } from 'components/modals/ModalHandler';
import { ModalHandler } from 'components/modals/ModalHandler';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { cancelRowEditing } from '@store/fxProfileEdition/fxProfileEditionSlice';

interface CancelValidationHandlerProps {
  children: ReactElementWithOnClick;
  hasUserChanges: boolean;
}

export const CancelValidationHandler = ({ children, hasUserChanges }: CancelValidationHandlerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const innerCancelEdition = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      dispatch(cancelRowEditing());
      children.props.onClick(event);
    },
    [children.props, dispatch],
  );

  return (
    <ModalHandler
      modalName="CancelValidationModal"
      onCancelEdition={innerCancelEdition}
      byPassHandler={!hasUserChanges}
    >
      {children}
    </ModalHandler>
  );
};
