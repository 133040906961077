import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { prepareHeaders } from 'services/prepareHeaders';

import { getAppConfig } from 'config';

const appConfig = getAppConfig();

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['User'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${appConfig.apiUrl}/api/user`,
    prepareHeaders,
  }),

  endpoints: (builder) => ({
    logConnection: builder.query<void, void>({
      query: (searchValue) => ({
        method: 'POST',
        url: 'connection',
      }),
    }),
  }),
});

export const { useLazyLogConnectionQuery } = userApi;
