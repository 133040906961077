import type { KeyboardEventHandler } from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import type { LazyInputProps } from 'components/Form/LazyInput';
import { LazyInput } from 'components/Form/LazyInput';
import { range } from 'utils/range';

import {useAppDispatch} from "../../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../../hooks/useAppSelector";
import {selectFilter} from "../../../../../store/filters/filtersSelectors";
import {actionCreators} from "../../../../../store/actions";
import {FilterGroup} from "./FilterGroup";

export function MaturitiesFilter(): JSX.Element {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const lowerBound = useAppSelector(selectFilter('lowerMaturityRange'));
  const upperBound = useAppSelector(selectFilter('upperMaturityRange'));

  const onLowerBoundChange: LazyInputProps['onChange'] = useCallback(
    (value) => {
      dispatch(actionCreators.filters.lowerMaturityRangeChanged(Number(value)));
    },
    [dispatch],
  );

  const onUpperBoundChange: LazyInputProps['onChange'] = useCallback(
    (value) => {
      dispatch(actionCreators.filters.upperMaturityRangeChanged(Number(value)));
    },
    [dispatch],
  );

  const filterInput: KeyboardEventHandler<HTMLInputElement> = useCallback((ev) => {
    const numKeys = range(0, 9).map((n) => n.toString());
    if (!numKeys.includes(ev.key)) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }, []);
  return (
    <FilterGroup label={formatMessage({ id: 'filters.maturities' })}>
      <StyledInput
        value={lowerBound.toString()}
        onChange={onLowerBoundChange}
        onKeyPress={filterInput}
      />
      <span className="mx-2">-</span>
      <StyledInput
        value={upperBound.toString()}
        onChange={onUpperBoundChange}
        onKeyPress={filterInput}
      />
    </FilterGroup>
  );
}

const StyledInput = styled(LazyInput).attrs(() => ({
  className: 'form-control text-center',
}))`
  width: 3.5rem;
`;
