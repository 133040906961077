// from the react starter
//https://sgithub.fr.world.socgen/SG-Web-Toolkit/starter-react/blob/main/src/helpers/index.tsx

export const BUS_TOPIC_GLOBALLANGUAGE = 'global.language';
export const BUS_TOPIC_SGCONNECT_ACCESSTOKEN = 'sg-connect.access-token';
export const BUS_TOPIC_SGCONNECT_USERCONNECTION = 'sg-connect.user-connection';
export const BUS_TOPIC_SGCONNECT_USERINFO = 'sg-connect.user-info';
export const BUS_TOPIC_SGCONNECT_GRANTEDSCOPE = 'sg-connect.granted-scope';

declare global {
  interface Window {
    SGWTWidgetConfiguration: {
      bus: SgWidgetsBus;
    };
  }
}

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined;
  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle<T>;
  unsubscribe<T>(handle: SubscriptionHandle<T>): void;
}

type SubscriptionHandle<T> = {
  topic: string;
  cb: BusEventCallback<T>;
};

type BusEventCallback<T> = (payload: T | undefined) => void;

export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = window.SGWTWidgetConfiguration;
  return widgetConfiguration?.bus;
}

export function getWidget<T extends HTMLElement>(tagName: string) {
  return document.querySelector(tagName) as T;
}

export function getAuthorizationHeader(): string | undefined {
  const bus = getWidgetBus();

  return bus?.dangerouslyGetCurrentValue<string>(BUS_TOPIC_SGCONNECT_ACCESSTOKEN);
}

interface UserConnection {
  connected: boolean;
  claims: Record<string, string>;
  mail: string;
}

export function getUserConnection(): UserConnection | undefined {
  const bus = getWidgetBus();

  return bus?.dangerouslyGetCurrentValue<UserConnection>(BUS_TOPIC_SGCONNECT_USERCONNECTION);
}
