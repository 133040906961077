import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

export interface TechnicalErrorWarningModalProps extends ModalProps {
  message: string;
}

export const TechnicalErrorWarningModal = (props: TechnicalErrorWarningModalProps) => {
  const reload = () => {
    window.location.reload();
  };
  return (
    <Modal {...props} centered>
      <ModalHeader tag="h3">
        <WarningIconContainer>
          <em className="icon icon-md text-warning">warning_amber</em>
        </WarningIconContainer>
        <span className="text-warning">
          <FormattedMessage id="error.technicalErrorWarningModal.title" />
        </span>
      </ModalHeader>

      <ModalBody>
        <StyledSpan>
          <FormattedMessage id="error.technicalErrorWarningModal.description" />
        </StyledSpan>
        <span className="fs-16 d-block mb-16px">
          <FormattedMessage id="error.technicalErrorWarningModal.contactSupport" />
        </span>

        <StyledDetails>
          <summary>
            <FormattedMessage id="error.technicalErrorWarningModal.logs" />
          </summary>
          <div className="fs-16 mt-16px text-primary fw-normal">{props.message}</div>
        </StyledDetails>
      </ModalBody>

      <ModalFooter>
        <button
          className="btn btn-lg btn-flat-primary"
          onClick={props.onClosed}
          data-e2e="cancel-technical-error-modal-button"
        >
          <FormattedMessage id="error.technicalErrorWarningModal.closeBtn" />
        </button>

        <button onClick={reload} className="btn btn-lg btn-warning" data-e2e="reload-technical-error-modal-button">
          <FormattedMessage id="error.technicalErrorWarningModal.reloadBtn" />
        </button>
      </ModalFooter>
    </Modal>
  );
};

const WarningIconContainer = styled.div.attrs({
  className: 'bg-warning bg-opacity-05',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
`;

const StyledSpan = styled.span.attrs({
  className: 'fs-16 d-block mb-16px text-primary',
})`
  strong {
    color: inherit !important; // this is done as strong balise in translation file gets applied an automatic text-secondary className, therfore we need to override it
  }
`;

// this whole style was needed as BS5 collapse was not smooth (perf issue)
const StyledDetails = styled.details.attrs({
  className: 'text-secondary fw-medium',
})`
  & summary {
    list-style: none;
    display: flex; /* also removes the list marker */
    align-items: center;
    margin-left: 16px;
  }

  & summary::-webkit-details-marker {
    display: none;
  }

  & summary::after {
    content: 'keyboard_arrow_down';
    font-family: 'Material Icons';
    font-size: 1.125rem;
    line-height: 1.125rem;
    height: 1.125rem;
    color: #6e777a;
    margin-left: 4px;
  }

  & summary::before {
    display: none;
  }

  &&[open] summary:after {
    content: 'keyboard_arrow_up';
    font-family: 'Material Icons';
    font-size: 1.125rem;
    line-height: 1.125rem;
    height: 1.125rem;
    color: #6e777a;
  }
`;
