import type { Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import {redirectToNotAuthorized} from "../../features/authorization/redirectToNotAuthorized";

export const catchApiMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if ([401, 403].includes(action.payload.status)) {
      redirectToNotAuthorized();
      return;
    }
  }

  return next(action);
};
