import type { PropsWithChildren } from 'react';
import { createContext, useState } from 'react';
import { IntlProvider as IntlProviderRaw } from 'react-intl';

import en from './locales/en.json';
import fr from './locales/fr.json';

export const SUPPORTED_LOCALE = ['en', 'fr'] as const;
export type SupportedLocale = (typeof SUPPORTED_LOCALE)[number];

export const isSupportedLocale = (language: string): language is SupportedLocale => {
  // @ts-ignore
  return SUPPORTED_LOCALE.includes(language);
};

export const translations: Record<SupportedLocale, Record<string, string>> = {
  en,
  fr: { ...en, ...fr },
};

export type LocaleContextValue = {
  locale: string;
  saveLocale: (newLocale: SupportedLocale) => void;
};

export const LocaleContext = createContext<LocaleContextValue>(null as unknown as LocaleContextValue);

const DEFAULT_LOCALE: SupportedLocale = 'en';
const LOCALE_KEY = 'locale';

translations;

export const IntlProvider = (props: PropsWithChildren): JSX.Element => {
  const [locale, setLocale] = useState((localStorage.getItem(LOCALE_KEY) as SupportedLocale | null) ?? DEFAULT_LOCALE);

  const saveLocale = (newLocale: SupportedLocale) => {
    localStorage.setItem(LOCALE_KEY, newLocale);
    setLocale(newLocale);
  };

  return (
    <LocaleContext.Provider value={{ locale, saveLocale }}>
      <IntlProviderRaw
        locale={locale}
        messages={translations[locale]}
        defaultRichTextElements={{
          strong: (text) => <strong className="text-secondary">{text}</strong>,
          em: (icon) => <em className="icon icon-sm">{icon}</em>,
          br: () => <br />,
        }}
        {...props}
      />
    </LocaleContext.Provider>
  );
};
