import type { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface CancelValidationModalProps extends ModalProps {
  onCancelEdition: MouseEventHandler<HTMLButtonElement>;
}

export function CancelValidationModal({
  onCancelEdition,
  ...props
}: CancelValidationModalProps): JSX.Element {
  return (
    <Modal {...props} data-e2e="cancel-validation-modal" contentClassName="border border-danger">
      <ModalHeader
        data-e2e="cancel-validation-modal-header"
        tag="h3"
        className="text-primary"
        close={props.modalHeaderClose}
      >
        <FormattedMessage id="cancelValidationModal.title" />
      </ModalHeader>

      <ModalBody>
        <FormattedMessage
          data-e2e="cancel-validation-modal-description"
          id="cancelValidationModal.description"
        />
      </ModalBody>

      <ModalFooter>
        <button
          data-e2e="continue-editing"
          className="btn btn-lg btn-flat-secondary"
          onClick={props.onClosed}
        >
          <FormattedMessage id="cancelValidationModal.continueEditing" />
        </button>

        <button data-e2e="quit-editing" className="btn btn-lg btn-danger" onClick={onCancelEdition}>
          <FormattedMessage id="cancelValidationModal.quit" />
        </button>
      </ModalFooter>
    </Modal>
  );
}
