import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { FxClientsList } from 'components/fx/FxClientsList';
import type { FxProfileMarginGrid } from '@services/fx/model/profile-margin-grid';

import type { FxProfileColumns } from '@services/fx/model/perimiters';

export interface FxEditStandardPresetWarningModalProps extends ModalProps {
  profile?: FxProfileMarginGrid<FxProfileColumns>;
}

export const FxEditStandardPresetWarningModal = ({
  profile,
  ...props
}: FxEditStandardPresetWarningModalProps): JSX.Element => {
  const [showClientsList, setShowClientsList] = useState(false);

  const innerOnClosed = () => {
    setShowClientsList(false);

    if (props.onClosed) {
      props.onClosed();
    }
  };

  return (
    <Modal data-e2e="edit-standard-preset-warning-modal" contentClassName="border border-warning" {...props}>
      <ModalHeader tag="h3" close={props.modalHeaderClose}>
        <FormattedMessage id="fx.edit.standard.preset" />
      </ModalHeader>

      <ModalBody className="pb-0">
        <div className="d-flex align-items-center justify-content-start mb-4" style={{ flexWrap: 'wrap' }}>
          <span className="me-2 text-warning d-flex align-items-center">
            <WarningIconContainer>
              <em className="icon icon-md text-warning">warning_amber</em>
            </WarningIconContainer>

            <span className="ms-2">
              <FormattedMessage
                id="fx.edit.standard.preset.description"
                values={{ clientsCount: profile?.clientsCount }}
              />
              {!showClientsList && (
                <WarningButtonLink
                  onClick={() => setShowClientsList(true)}
                  data-e2e="edit-standard-preset-warning-show-clients"
                >
                  <FormattedMessage id="fx.edit.show.clients.list" />
                </WarningButtonLink>
              )}
            </span>
          </span>
        </div>

        {showClientsList && <FxClientsList profile={profile} />}
      </ModalBody>

      <ModalFooter>
        <button
          className="btn btn-lg btn-flat-primary"
          onClick={innerOnClosed}
          data-e2e="cancel-standard-preset-button"
        >
          <FormattedMessage id="common.cancel" />
        </button>

        <Link
          to={`./edit?profileId=${profile?.profileId}`}
          onClick={innerOnClosed}
          className="btn btn-lg btn-warning"
          data-e2e="edit-standard-preset-button"
        >
          <FormattedMessage id="common.edit" />
        </Link>
      </ModalFooter>
    </Modal>
  );
};

const WarningIconContainer = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center me-2 bg-warning bg-opacity-10 border-radius-circle',
})`
  min-width: 3em;
  min-height: 3em;
`;

const WarningButtonLink = styled.a.attrs({
  className: 'text-warning ms-1',
})`
  font-weight: 400 !important;
  text-underline-position: under;
  text-decoration: underline;
  cursor: pointer;
`;
