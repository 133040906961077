import { useState } from "react";

export type RowFilters = {
  query: string

  isMarginsVisible: boolean
  isTraderTieringVisible: boolean
  isRejectionRulesVisible: boolean

  isSpotVisible: boolean
  isNDFVisible: boolean
  isSwapVisible: boolean
  isNDSVisible: boolean
}

export const useRowFilters = () => {
  const [ query, setQuery ] = useState("");

  const [ allActiveFilterIds, setAllActiveFilterIds ] = useState<string[]>([]);

  const toggleFilterId = (filterId: string, isActive: boolean) => {
    if (isActive) {
      setAllActiveFilterIds([ ...new Set([ ...allActiveFilterIds, filterId ]) ]);
    }
    else {
      setAllActiveFilterIds(allActiveFilterIds.filter(id => id !== filterId));
    }
  };

  const rowFilters: RowFilters = {
    query,

    isMarginsVisible:        isFilterActive("margins", DISPLAY_FILTER_IDS, allActiveFilterIds),
    isTraderTieringVisible:  isFilterActive("traderTiering", DISPLAY_FILTER_IDS, allActiveFilterIds),
    isRejectionRulesVisible: isFilterActive("rejectionRules", DISPLAY_FILTER_IDS, allActiveFilterIds),

    isSpotVisible: isFilterActive("spot-product", INSTRUMENT_FILTER_IDS, allActiveFilterIds),
    isNDFVisible:  isFilterActive("ndf-product", INSTRUMENT_FILTER_IDS, allActiveFilterIds),
    isSwapVisible: isFilterActive("swap-product", INSTRUMENT_FILTER_IDS, allActiveFilterIds),
    isNDSVisible:  isFilterActive("nds-product", INSTRUMENT_FILTER_IDS, allActiveFilterIds)
  };

  return {
    rowFilters,
    allActiveFilterIds,
    toggleFilterId,
    setQuery
  };
};

const DISPLAY_FILTER_IDS = [ "margins", "traderTiering", "rejectionRules" ];
const INSTRUMENT_FILTER_IDS = [ "spot-product", "ndf-product", "swap-product", "nds-product" ];

const isFilterActive = (filterId: string, allGroupFilterIds: string[], allActiveFilterIds: string[]) => {
  return (
    allActiveFilterIds.includes(filterId)
    || allGroupFilterIds.every(id => !allActiveFilterIds.includes(id))
  );
};
