import { useEffect, useRef } from "react";


export const useDocumentTitle =  (title: string) => {
  const previousTitle = useRef<string>(document.title);

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = previousTitle.current;
    }
  }, [ title ]);
};
