import { useMarginUnit } from 'hooks/useMarginUnit';
import { useIntl } from 'react-intl';
import { useColumnDefs } from './useColumnDef';
import { useGridOption } from './useGridOption';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import type { ColGroupDef, GridApi, GridReadyEvent, ProcessDataFromClipboardParams } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import type { FxOrderProductKey, FxOrderProfileRow, FxOrderProfileRowMappedForAgGrid } from '@services/fx/model/order';
import { isDefined } from '@sgme/fp';
import { selectRows } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { useDispatch } from 'react-redux';

interface OrderGridProps {
  productKey: FxOrderProductKey;
  rows: FxOrderProfileRowMappedForAgGrid[];
  maxRowDisplayed: number;
  isEditing?: boolean;
}

interface Event {
  api: { getSelectedRows: () => FxOrderProfileRow[] };
}

const OrderGrid = forwardRef<AgGridReact, OrderGridProps>((props, ref) => {
  const dispatch = useDispatch();

  const { productKey, maxRowDisplayed, rows, isEditing } = props;

  const { formatMessage } = useIntl();

  const gridApi = useRef<GridApi<FxOrderProfileRow> | null>(null);

  const [valueUnit] = useMarginUnit('algo-order-product');

  const { defaultColDef, columnDefs } = useColumnDefs(productKey, isEditing);
  const gridOptions = useGridOption();

  const onRowSelected = (event: Event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedRowsId = selectedRows?.map((row) => row.uiRowId);

    //there will always be a row selected while dispatching
    dispatch(selectRows({ uiRowsId: selectedRowsId as number[] }));
  };

  const processDataFromClipboard = useCallback(
    ({ data }: ProcessDataFromClipboardParams) => data.filter((row) => !(row.length === 1 && !row[0])),
    [],
  );

  const [containerHeight, setContainerHeight] = useState<string | undefined>(undefined);

  function onGridReady(event: GridReadyEvent) {
    gridApi.current = event.api;

    // TODO: to delete (keep only to see how previously the height is previously calculated)
    // const domLayout = "normal";
    // event.api.setDomLayout(domLayout);

    setContainerHeight(calculateContainerHeight(rows.length, maxRowDisplayed, event.api));
  }

  useEffect(() => {
    if (isDefined(gridApi.current)) {
      setContainerHeight(calculateContainerHeight(rows.length, maxRowDisplayed, gridApi.current));
    }
  }, [maxRowDisplayed, rows.length]);

  const context = useMemo(() => ({ formatMessage, valueUnit, productKey }), [formatMessage, productKey, valueUnit]);

  useEffect(() => {
    // if you don't refresh the cells, they use the previous value of the value unit
    gridApi.current?.refreshCells();
  }, [context]);

  return (
    <div
      className={`d-flex ag-theme-sg-bootstrap ag-theme-fx`}
      style={{ height: containerHeight }}
      data-e2e={productKey}
    >
      <AgGridReact
        ref={ref}
        onGridReady={onGridReady}
        context={context}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        processDataFromClipboard={processDataFromClipboard}
        modules={AG_GRID_MODULES}
        gridOptions={gridOptions}
        rowMultiSelectWithClick={isEditing}
        rowData={rows}
        localeText={{ noRowsToShow: formatMessage({ id: 'no.marge.to.show' }) }}
        onRowSelected={onRowSelected}
        className="w-100"
      />
    </div>
  );
});

OrderGrid.displayName = 'OrderGrid';

export default OrderGrid;

const AG_GRID_MODULES = [ClientSideRowModelModule, ClipboardModule, RowGroupingModule];

const calculateContainerHeight = (rowCount: number, maxRowDisplayed: number, api: GridApi) => {
  const { headerHeight = 32, rowHeight = 32 } = api.getSizesForCurrentTheme();

  const headerHaveChildren = (api.getColumnDefs() ?? []).find((columnDef) => !!(columnDef as ColGroupDef).children);
  const styleHeaderHeight = `(${headerHaveChildren ? '2' : '1'} * ${headerHeight}px)`;

  const styleRowsHeight = `(${Math.min(maxRowDisplayed, Math.max(rowCount, 1))} * ${rowHeight}px)`;

  // +3px to show all the height of the last row (maybe the returned header height
  // is not correct)
  return `calc(${styleHeaderHeight} + ${styleRowsHeight} + 3px)`;
};
