import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { isNotDefined } from '@sgme/fp';
import type { FxOrderProfileRow } from "../../../../services/fx/model/order";

export const InternalMarginCellRenderer = ({
  node,
  valueFormatted,
}: ICellRendererParams<FxOrderProfileRow>): JSX.Element => {
  const data = node.data;

  if (isNotDefined(data)) {
    return <></>;
  }

  const tooltipId = `tooltip-internal-${data.uiRowId}`;
  const { External, Internal } = data.liquidityProviderDimension;

  if (External === Internal) {
    return <p>{valueFormatted}</p>;
  }

  return (
    <>
      <p>
        <em className="icon fs-14 text-warning me-1" id={tooltipId}>
          circle
        </em>

        {valueFormatted}
      </p>

      <UncontrolledTooltip data-toggle="tooltip" placement="bottom" target={tooltipId}>
        <FormattedMessage id="fx.message.tooltip.internal" />
      </UncontrolledTooltip>
    </>
  );
};
