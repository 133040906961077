import { clientDuplicationSlice } from './clientDuplication/clientDuplicationSlice';
import * as clientDuplicationThunks from './clientDuplication/clientDuplicationThunks';
import * as clientEditionThunks from './clientEdition/clientEditionThunks';
import { marginProfilesEditionSlice } from './marginProfilesEdition/marginProfilesEditionSlice';
import * as marginProfileEditionThunks from './marginProfilesEdition/marginProfilesEditionThunks';
import {uiSlice} from "./ui/uiSlice";
import {filtersSlice} from "./filters/filtersSlice";
import {clientEditionSlice} from "./clientEdition/clientEditionSlice";

export const actionCreators = {
  [uiSlice.name]: { ...uiSlice.actions },
  [filtersSlice.name]: { ...filtersSlice.actions },
  [clientEditionSlice.name]: { ...clientEditionSlice.actions, ...clientEditionThunks },
  [clientDuplicationSlice.name]: { ...clientDuplicationSlice.actions, ...clientDuplicationThunks },
  [marginProfilesEditionSlice.name]: {
    ...marginProfilesEditionSlice.actions,
    ...marginProfileEditionThunks,
  },
} as const;

export type ActionCreators = typeof actionCreators;
