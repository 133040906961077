import { FxClients } from './FxClients';

import { PageTracker } from '@features/analytics/PageTracker';
import { Authorize } from '../../../features/authorization/Authorize';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';

function FxClientsPage(): JSX.Element {
  useDocumentTitle('Clients - Margin and Tiering - SG Markets');

  return (
    <Authorize authorizedFor={['FX_READALLDESK', 'FX_READMYDESK']} redirectTo="/">
      <PageTracker>
        <div className="flex-column p-4 col offset-lg-1 col-lg-10">
          <FxClients />
        </div>
      </PageTracker>
    </Authorize>
  );
}

export default FxClientsPage;
