import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { FX_ROUTE } from "../../fx/constants";

export const FxClientsCrumb = (): JSX.Element => {
  return (
    <NavLink to={FX_ROUTE} data-e2e="fx-clients-menu-item" className="text-secondary text-regular">
      <FormattedMessage id="fx.title.clients" />
    </NavLink>
  );
};
