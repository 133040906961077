import { noop } from '@sgme/fp';
import { useCallback, useState } from 'react';

type ToggleTuple = [boolean, () => void, () => void];

/**
 * Take an initial value and a callback and returns a stateful boolean and a function to toggle it and trigger the callback passed in entry.
 */
export const useToggle = (initial = false, callback: (value: boolean) => void = noop): ToggleTuple => {
  const [state, setState] = useState(initial);

  const toggle = useCallback(() => {
    setState((s) => {
      callback(!s);
      return !s;
    });
  }, [callback]);

  const close = useCallback(() => {
    if (state) {
      toggle();
    }
  }, [state, toggle]);

  return [state, toggle, close];
};
