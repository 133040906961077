import type { Components, SgPickerCustomEvent } from '@sg-bootstrap/components';
import { SgPicker as SgPickerComponent } from '@sg-bootstrap/components/dist/react-library/src/components';
import { isDefined } from '@sgme/fp';
import type { RefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'sg-picker': Partial<Components.SgPicker> & { ref: RefObject<HTMLSgPickerElement> };
    }
  }
}

type Items = {
  items: Item[]
}
export interface Item {
  label: string;
  key: string;
}

interface SgPickerProps {
  className?: string;
  selectFirstByDefault?: boolean;
  defaultValue?: Item['key'];
  noClear?: boolean;
  items: string[] | Item[];
  placeholder?: string;
  mode: Components.SgPicker['mode'];
  state?: Components.SgPicker['validationState'];
  singleSelect?: boolean;
  noIcon?: boolean;
  disabled?: boolean;
  onChange?: (detail: Item) => void;
}

export const SgPicker = ({
  className = '',
  selectFirstByDefault = false,
  defaultValue,
  noClear = false,
  items,
  placeholder,
  mode,
  singleSelect,
  noIcon,
  state,
  disabled = false,
  onChange,
}: SgPickerProps): JSX.Element => {
  const innerRef = useRef<HTMLSgPickerElement>(null);


  const innerOnReady = useCallback(() => {

    const formattedItems = items.map(transformItem);

    innerRef.current?.setItems(formattedItems);

    if (isDefined(defaultValue)) {
      innerRef.current?.selectItemByKey(defaultValue);
      return;
    }

    if (selectFirstByDefault) {
      const [firstItem] = formattedItems;

      innerRef.current?.selectItemByKey(firstItem.key);

      if (onChange) {
        onChange(firstItem);
      }
    }
  }, [defaultValue, items, onChange, selectFirstByDefault]);

  const onSelectItem = useCallback(
    (event: SgPickerCustomEvent<Items>) => {
      const detail = event.detail.items?.find((value) => isDefined(value)) as Item;

      if (onChange) {
        onChange(detail);
      }
    },
    [onChange],
  );

  useEffect(() => {
    const picker = innerRef.current;

    if (picker) {
      picker.addEventListener('itemsSelected', onSelectItem as EventListener);
      picker.addEventListener('itemsUnselected', onSelectItem as EventListener);
      picker.addEventListener('clearInput', onSelectItem as EventListener);

      picker.addEventListener('ready', innerOnReady);
    }

    return () => {
      if (picker) {
        picker.removeEventListener('itemsSelected', onSelectItem as EventListener);
        picker.removeEventListener('itemsUnselected', onSelectItem as EventListener);
        picker.removeEventListener('clearInput', onSelectItem as EventListener);
        picker.removeEventListener('ready', innerOnReady);
      }
    };
  }, [onSelectItem, innerOnReady]);

  return (
    <SgPickerComponent
      ref={innerRef}
      classes={className}
      showNoResultLabel
      placeholder={placeholder}
      mode={mode}
      itemSelection={singleSelect ? "single" : "multiple"}
      showIcon={noIcon}
      showClearButton={noClear}
      validationState={state}
      disabled={disabled}
    />
  );
};

const transformItem = (item: string | Item) => {
  if (typeof item === 'string') {
    return { label: item, key: item };
  }

  return item;
};
