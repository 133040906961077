import { fxApi } from './fxApi';
import type { FxCashGridDataType } from './model/cash';
import type { FxPayload, ProfileId } from './model/models';
import { camelize, mapRowApi } from './transformFxResponse';
import type { FxProfileMarginGrid } from './model/profile-margin-grid';
import { clearUndefined, isDefined } from '@sgme/fp';
import type { FxPerimeterKey, FxProductKey, FxProfileColumns } from '@services/fx/model/perimiters';
import type { FxOrderProfile, FxOrderProfileRow, FxOrderProfileRowApi } from '@services/fx/model/order';
import { createEntityAdapter, type EntityState, nanoid } from '@reduxjs/toolkit';

type FxProfileMarginGridParams = {
  perimeterKey: FxPerimeterKey;
  productKey: FxProductKey;
  profileId: ProfileId;
  gridDataType?: FxCashGridDataType;
};

export const fxMarginGridApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    getFXProfileMarginGrid: builder.query<FxProfileMarginGrid<FxProfileColumns>, FxProfileMarginGridParams>({
      async queryFn(params, _queryApi, _extraOptions, fetchWithBQ) {
        const { perimeterKey, productKey } = params;

        const query = clearUndefined({
          profileId: params.profileId,
          gridDataType: params?.gridDataType,
        });

        const result = (await fetchWithBQ({
          url: `perimeters/${perimeterKey}/products/${productKey}/margin-grids`,
          params: query,
        })) as { data: { data: FxProfileMarginGrid<FxProfileColumns> } }; // TODO-5198 first data for fetchWithBQ and the second for WebAPI response

        const {
          rows,
          profileId,
          profileTypeKey,
          profileVersion,
          profileName,
          clientsCount,
          clientProfileAssignmentVersion,
        } = result.data.data;

        return {
          data: {
            profileId,
            profileName,
            profileTypeKey,
            profileVersion,
            clientsCount,
            clientProfileAssignmentVersion,

            rows: rows.map((row) => ({
              internalRowId: row.internalRowId,
              columns: Object.fromEntries(
                Object.entries(row.columns).map(([key, value]) => [camelize(key), value]),
              ) as FxProfileColumns,
              // this is added to match the editingRow and simplify the grid logic
              state: 'untouched',
              // todo-5198 validate that there is always a value here
              uiRowId: row.uiRowId,
            })),
          },
        };
      },

      providesTags: (_result, _error, { profileId }) => [{ type: 'Profile', id: `${profileId}` }],
    }),
  }),
});

export const { useGetFXProfileMarginGridQuery } = fxMarginGridApi;

// todo-5231
// code legacy from getFxOrderProfile used in getFxProfileOfProducts and in addFxOrderClientPoolPreset
// ideally this should not be used anymore (any entity state logic)
const fxOrderProfileRowAdapter = createEntityAdapter<FxOrderProfileRow>({
  // we use nanoid when adding new fxProfileRow, because we don't have internalRowId yet.
  selectId: ({ internalRowId }) =>
    isDefined(internalRowId) && internalRowId.length > 0 ? internalRowId.join() : nanoid(),
});

export const transformFxOrderProfileResponse = (response: FxPayload<FxProfileApi>): FxOrderProfile => {
  const { rows: rowsApi, ...profile } = response.data;

  const rows: EntityState<FxOrderProfileRow> = fxOrderProfileRowAdapter.addMany(
    fxOrderProfileRowAdapter.getInitialState(),
    rowsApi.map(mapRowApi),
  );

  return {
    ...profile,
    rows,
  };
};

type FxProfileApi = Omit<FxOrderProfile, 'rows'> & {
  rows: FxOrderProfileRowApi[];
};
