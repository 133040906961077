import type { PropsWithChildren} from "react";
import { useRef } from "react";
import { useIntl } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";


export type TooltipProps = {
  labelId: string
  placement?: "top" | "bottom" | "left" | "right"
}

function Tooltip(props: PropsWithChildren<TooltipProps>): JSX.Element {
  const { labelId, placement = "top", children } = props;

  const ref = useRef<HTMLSpanElement>(null);

  const { formatMessage } = useIntl();
  const label = formatMessage({ id: labelId });

  return (
    <>
      <span ref={ref}>
        {children}
      </span>
      <UncontrolledTooltip
        target={ref}
        placement={placement}
      >
        {label}
      </UncontrolledTooltip>
    </>
  );
}

export default Tooltip;