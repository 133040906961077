import { createSelector } from '@reduxjs/toolkit';
import { isNotDefined } from '@sgme/fp';
import { selectEntitiesMarginProfiles } from 'services/marginProfiles';

import { narrowDefined } from 'utils/asserts';

import { clientsAdapter, getClientsApi, initialState } from './getClients';
import type {AppState} from "../../store/store";
import {getFilters} from "../../store/filters/filtersSelectors";

const clientsSelectors = clientsAdapter.getSelectors((state: AppState) => {
  const { data } = getClientsApi.endpoints.getClients.select()(state);
  if (data === undefined) {
    return initialState;
  }
  return data;
});

export const selectAllClients = clientsSelectors.selectAll;

export const selectClientByName = (state: AppState, name: string) =>
  selectAllClients(state).find((client) => client.name === name);

export const selectClientById = (state: AppState) => (clientId?: number) => {
  if (isNotDefined(clientId)) {
    return undefined;
  }
  return clientsSelectors.selectById(state, clientId);
};

export const selectEntitiesClients = clientsSelectors.selectEntities;

export const selectFilteredClients = createSelector(
  [selectAllClients, selectEntitiesMarginProfiles, getFilters],
  (clients, marginProfiles, filters) =>
    clients.filter(
      (client) =>
        (filters.clientName.length === 0 ||
          client.name.toLowerCase().includes(filters.clientName.toLowerCase())) &&
        (!filters.activeClientsOnly || client.isActive) &&
        (filters.customizedMarginsOnly
          ? !narrowDefined(marginProfiles[client.salesMarginProfileId]).isPreset
          : filters.salesProfiles.length === 0 ||
            filters.salesProfiles.includes(client.salesMarginProfileId)) &&
        (filters.devLProfiles.length === 0 ||
          filters.devLProfiles.includes(client.devLMarginProfileId)) &&
        (filters.pcruMargins.length === 0 || filters.pcruMargins.includes(client.pcruMargin)) &&
        client.lowerMaturityRange <= filters.upperMaturityRange &&
        client.upperMaturityRange >= filters.lowerMaturityRange,
    ),
);
