import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';
import { type EditingRow, applyValidatedRow } from '@store/fxProfileEdition/fxProfileEditionSlice';
import { fxApi } from './fxApi';
import type { FxCashGridDataType } from './model/cash';
import type { FxCashValidationErrorCode, ProfileId } from './model/models';
import { camelize, kebabize } from './transformFxResponse';

type FxUpdatedProfileMarginGridParams = {
  perimeterKey: FxPerimeterKey;
  productKey: FxProductKey;
  gridDataType?: FxCashGridDataType;
  profileId: ProfileId;
  editedRows: EditingRow[];
};

type ValidatedRowsResponse = {
  data: {
    profileId: ProfileId;
    rows: EditingRow[];
  };
};

type ValidateGridErrorCode = 'DuplicateRowError';

export interface ValidateGridErrorResponse {
  errorCode: ValidateGridErrorCode | FxCashValidationErrorCode;
  message: string;
}

export const validateMarginGridApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    validateMarginGrid: builder.mutation<ValidatedRowsResponse, FxUpdatedProfileMarginGridParams>({
      query: ({ perimeterKey, productKey, gridDataType, profileId, editedRows }) => ({
        method: 'POST',
        url: `perimeters/${perimeterKey}/products/${productKey}/validate-margin-grid`,
        params: {
          gridDataType,
        },
        body: {
          profileId,
          rows: mapRowsForBackend(editedRows),
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: validatedRowsResponse } = await queryFulfilled;
          dispatch(
            applyValidatedRow({ validatedRows: mapRowsForFrontend(validatedRowsResponse.data.rows) as EditingRow[] }),
          );
        } catch (error) {
          console.warn('error occured when merging new row', error);
        }
      },
    }),
  }),
});

export const { useValidateMarginGridMutation } = validateMarginGridApi;

export const mapRowsForBackend = (editedRows: EditingRow[]) =>
  editedRows.map((row) => ({
    ...row,
    columns: Object.fromEntries(
      Object.entries(row.columns).map(([key, value]) => {
        if (key === 'liquidityProviderDimension.External.marginValue') {
          return ['liquidity-provider-dimension.External.margin-value', value];
        }

        if (key === 'liquidityProviderDimension.Internal.marginValue') {
          return ['liquidity-provider-dimension.Internal.margin-value', value];
        }

        return [kebabize(key), value];
      }),
    ),
  }));

export const mapRowsForFrontend = (editedRows: EditingRow[]) =>
  editedRows.map((row) => ({
    ...row,
    columns: Object.fromEntries(Object.entries(row.columns).map(([key, value]) => [camelize(key), value])),
  }));
