import type React from 'react';
import { useIntl } from 'react-intl';

import { Filter } from './Filter';
import { FilterGroup } from './FilterGroup';

export const PcruFilters: React.FunctionComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <FilterGroup label={formatMessage({ id: 'filters.pcruMargin' })}>
      <Filter type="Pcru" value={5} />
      <Filter type="Pcru" value={10} />
    </FilterGroup>
  );
};
