import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { CellClassParams, ColDef, ColGroupDef } from '@ag-grid-community/core';

import type { MarginByMaturity, MarginProfileType } from 'services/marginProfiles';
import type { MarginProfileDiffs } from 'store/marginProfilesEdition/marginProfilesEditionSlice';

import { range } from 'utils/range';
import {useAppDispatch} from "../../../../../hooks/useAppDispatch";
import {actionCreators} from "../../../../../store/actions";

import { leftGroupHeaderClass, maturityClass, maturityHeaderClass, topHeaderClasses } from "../../../contants";

type MarginPresetsRow = {
  id: number;
  type: MarginProfileType | 'Custom' | 'Settings';
  name: string;
  marginsType: 'fixedMargins' | 'techMarginsFixed' | 'techMarginsFloating' | 'adjustmentMargins';
  margins: MarginByMaturity[];
  marginProfileDiffs: MarginProfileDiffs;
};

const useColumnDef = (): (ColGroupDef | ColDef)[] => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const onMarginModified = useCallback(
    (marginId: string, value: number) =>
      dispatch(actionCreators.marginProfileEdition.modifyMargin(marginId, value)),
    [dispatch],
  );

  return useMemo(
    () => [
      {
        field: 'marginProfileType',
        children: [
          {
            field: 'marginProfileType',
            rowGroup: true,
            hide: true,
            valueGetter: ({ data }) => formatMessage({
                id: `marginsPresets.table.marginProfileType.${(data as MarginPresetsRow).type}`,
              }),
          },
        ],
      },
      {
        field: 'marginProfileName',
        children: [
          {
            field: 'name',
            rowGroup: true,
            hide: true,
          },
        ],
      },
      {
        headerName: formatMessage({ id: 'marginsPresets.table.marginProfile' }),
        headerClass: [...topHeaderClasses, leftGroupHeaderClass],
        children: [
          {
            colId: 'marginName',
            headerName: '',
            pinned: 'left',
            minWidth: 240,
            showRowGroup: true,
            cellRenderer: 'agGroupCellRenderer',
            onCellClicked: ({ node }) => node.setExpanded(!node.expanded),
            cellRendererParams: {
              suppressCount: true,
            },
            headerClass: leftGroupHeaderClass,
            cellClass: leftGroupHeaderClass,
            cellClassRules: {
              'fw-bold': ({ node }: CellClassParams) => Boolean(node.group),
            },
            valueGetter: ({ data, node }) => (node?.parent?.allChildrenCount ?? 0) > 1
                ? formatMessage({
                    id: `marginPresets.table.marginType.${data.marginsType}`,
                  })
                : data.name,
          },
        ],
      },
      {
        headerName: formatMessage({ id: 'marginsPresets.table.marginByMaturity' }),
        headerClass: topHeaderClasses,
        children: range(1, 30).map((maturity) => ({
          headerName: maturity.toString(),
          field: maturity.toString(),
          colId: `maturity_${maturity}`,
          minWidth: 60,
          headerClass: [maturityHeaderClass, 'text-secondary'],
          cellClass: [maturityClass, 'text-end'],
          editable: (params) => params.context.isEditing,
          valueGetter: ({ data }) => {
            if (!data) return;

            const margin = data.margins.find(
              (margin: MarginByMaturity) => margin.maturity === maturity.toString(),
            );

            return margin?.id !== undefined
              ? data.marginProfileDiffs[margin?.id] ?? margin.value
              : '-';
          },
          valueSetter: ({ data, newValue }) => {
            const value = Number(newValue);

            if (!Number.isNaN(value)) {
              const marginId = data.margins.find(
                (margin: MarginByMaturity) => margin.maturity === maturity.toString(),
              )?.id;

              if (marginId !== undefined) {
                onMarginModified(marginId, value);
              }
            }

            return false;
          },
        })),
      },
    ],
    [formatMessage, onMarginModified],
  );
};

export default useColumnDef;
