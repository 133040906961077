import { LoadingButton } from '@components/LoadingButton';
import { FormattedMessage } from 'react-intl';
import { ModalHandler } from '@components/modals/ModalHandler';
import { isDefined } from '@sgme/fp';
import { generatePath, useNavigate } from 'react-router-dom';
import { FX_PRODUCTS_ROUTE } from '@pages/fx/constants';
import { useUnassignFxProfileForProductMutation } from '@services/fx/unassignFxProfileForProduct';
import { useSelectFxProfileMutation } from '@services/fx/selectFxProfile';
import { useFxSelectAndEditProfileUrlParams } from '@pages/fx/useFxSelectAndEditProfileUrlParams';
import { useGetFxInheritableProfileReference } from '@services/fx/getFXClientsInheritance';
import type { UpdateProfileErrorPayload } from '@services/fx/model/models';

type SaveNewSelectedProfileButtonProps = {
  profileVersion?: number;
  clientProfileAssignmentVersion?: number;
};

const SaveNewSelectedProfileButton = ({
  profileVersion,
  clientProfileAssignmentVersion,
}: SaveNewSelectedProfileButtonProps) => {
  const navigate = useNavigate();

  const {
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
    profileId,
    isInherited: isEditedProfileHerited,
  } = useFxSelectAndEditProfileUrlParams();

  const [selectFxProfile, { isLoading: isSelectingFxProfile }] = useSelectFxProfileMutation({
    fixedCacheKey: 'fx-profile-change-reason',
  });
  const [unassignFxProfileForProduct, { isLoading: isUnassigningFxProfileForProduct }] =
    useUnassignFxProfileForProductMutation();

  const savedProfile = useGetFxInheritableProfileReference({
    clientBdrId,
    clientBdrLevel,
    perimeterKey,
    productKey,
    gridDataType,
  });

  const isSelectedProfileSameAsCurrentOne = savedProfile?.profileId === profileId;

  const isLoading = isUnassigningFxProfileForProduct || isSelectingFxProfile;

  const makeOnSubmit =
    (innerOnClosed: () => void, setError: (error: UpdateProfileErrorPayload['data']) => void, contactEmail?: string) =>
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { reason, comment } = event.currentTarget;

      const { error } = isEditedProfileHerited
        ? ((await unassignFxProfileForProduct({
            clientBdrId,
            clientBdrLevel,
            perimeterKey,
            productKey,
            reasonId: reason?.value,
            comment: comment?.value,
            onBehalfOfUser: contactEmail,
            gridDataType,
          })) as { error: UpdateProfileErrorPayload })
        : ((await selectFxProfile({
            clientBdrId,
            clientBdrLevel,
            perimeterKey,
            productKey,
            profileId,
            profileVersion,
            clientProfileAssignmentVersion,
            reasonId: reason?.value,
            comment: comment?.value,
            onBehalfOfUser: contactEmail,
            gridDataType,
          })) as { error: UpdateProfileErrorPayload });

      if (isDefined(error)) {
        setError(error.data);
      } else {
        innerOnClosed();
        navigate(generatePath(FX_PRODUCTS_ROUTE, { clientBdrId: String(clientBdrId), clientBdrLevel, perimeterKey }));
      }
    };

  return (
    <ModalHandler modalName={'FxProfileChangeReasonsModal'} makeOnSubmit={makeOnSubmit} gridDataType={gridDataType}>
      <LoadingButton
        loading={isLoading}
        className="btn btn-lg btn-primary"
        data-e2e="fx-save-product-profile"
        disabled={isSelectedProfileSameAsCurrentOne}
        icon="save"
      >
        <FormattedMessage id="common.save" />
      </LoadingButton>
    </ModalHandler>
  );
};

export default SaveNewSelectedProfileButton;
