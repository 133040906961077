import type { Middleware } from '@reduxjs/toolkit';

import { serializeError } from 'utils/error';

import { errorSlice } from './errorSlice';
import type {AppState} from "../store";

type Error = Record<string, string>;

export const catchMiddleware: Middleware<Record<string, Error>, AppState> =
  (_store) => (next) => async (action) => {
    try {
      return await next(action);
    } catch (error) {
      next(errorSlice.actions.appCrashed(serializeError(error)));
    }
  };
