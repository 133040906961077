import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { isNonEmpty } from '@sgme/fp';
import type { PostClientPayload } from 'services/clients';
import { selectClientById } from 'services/clients';
import { usePostClientMutation } from 'services/clients/postClient';

import type { IcAccount } from 'components/IcAccountPicker';
import { IcAccountPicker } from 'components/IcAccountPicker';
import { LoadingButton } from 'components/LoadingButton';

import { OverflowableModalBody } from './OverflowableModalBody';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { getClientDuplicationState } from '@store/clientDuplication/clientDuplicationSelectors';
import { actionCreators } from '@store/actions';

export interface DuplicateClientModalProps extends ModalProps {
  clientId: number;
}

// TODO: not used -> delete ?
export function DuplicateClientModal({ ...props }: DuplicateClientModalProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [postClient] = usePostClientMutation();

  const sourceClient = useAppSelector(selectClientById)(props.client.id);
  const clientName = useAppSelector(selectClientById)(props.client.id)?.name;

  const { isAlreadyExisting, isReadyToSubmit, isSubmitting, values } = useAppSelector(getClientDuplicationState);

  const isAlreadyExistingAndClientNameNotEmpty = isAlreadyExisting && isNonEmpty(clientName);
  const innerOnClose = useCallback(() => {
    dispatch(actionCreators.clientDuplication.duplicationCleared());

    if (props.onClosed) {
      props.onClosed();
    }
  }, [dispatch, props]);

  const changeNewClientName = (account: IcAccount) =>
    dispatch(actionCreators.clientDuplication.changeNewClientName(account.name.trim()));

  const handleDuplicate = () => {
    const duplicatedClient = {
      ...sourceClient,
      name: values.newClientName,
    } as PostClientPayload;

    postClient(duplicatedClient);
    innerOnClose();
  };

  const goToExistingClient = useCallback(() => {
    dispatch(actionCreators.filters.clientNameFilterChanged(values.newClientName));
    innerOnClose();
  }, [dispatch, innerOnClose, values.newClientName]);

  return (
    <Modal {...props} onClosed={innerOnClose} data-e2e="duplicate-client-modal">
      <ModalHeader tag="h3" close={props.modalHeaderClose}>
        <FormattedMessage id="duplicateClientModal.title" />
      </ModalHeader>
      <OverflowableModalBody>
        <p>
          <FormattedMessage
            id="duplicateClientModal.description"
            values={{
              client: clientName,
            }}
          />
        </p>
        <div className="form-group">
          <label className="fw-medium mb-2">
            <FormattedMessage id="duplicateClientModal.newClientName" />
          </label>

          <IcAccountPicker onChange={changeNewClientName} />

          {isAlreadyExistingAndClientNameNotEmpty && (
            <p className="d-flex align-items-center justify-content-between py-3 text-info">
              <span>
                <i className="icon icon-sm me-2">info_outline</i>
                <FormattedMessage id="clientForm.clientAlreadyAdded" />
              </span>

              <button className="btn btn-outline-info" onClick={goToExistingClient}>
                <FormattedMessage id="duplicateClientModal.goToExistingClient" />
              </button>
            </p>
          )}
        </div>
      </OverflowableModalBody>

      <ModalFooter>
        <button className="btn btn-lg btn-flat-secondary" onClick={innerOnClose}>
          <FormattedMessage id="common.cancel" />
        </button>

        <LoadingButton
          className="btn btn-lg btn-primary"
          icon="add"
          disabled={!isReadyToSubmit || isSubmitting}
          loading={isSubmitting}
          onClick={handleDuplicate}
        >
          <FormattedMessage id="common.add" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
