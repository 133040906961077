import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ModalProps } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useUpdateClientsMutation } from 'services/clients/updateClients';

import { LoadingButton } from 'components/LoadingButton';
import { assertDefined } from 'utils/asserts';

export interface DeleteClientModalProps extends ModalProps {
  clientId: number;
  name: string;
}

export function DeleteClientModal({
  clientId,
  name,
  ...props
}: DeleteClientModalProps): JSX.Element {
  const [updateClient, { isLoading }] = useUpdateClientsMutation();
  const handleDelete = useCallback(async () => {
    assertDefined(props.client, `No found client for id ${clientId}`);
    updateClient([{ ...props.client, isActive: false }]);
    if (props.onClosed) {
      props.onClosed();
    }
  }, [props, clientId, updateClient]);

  return (
    <Modal {...props} data-e2e="delete-client-modal" contentClassName="border border-warning">
      <ModalHeader tag="h3" className="text-warning" close={props.modalHeaderClose}>
        <FormattedMessage id="deleteClientModal.title" />
      </ModalHeader>

      <ModalBody>
        <FormattedMessage
          id="deleteClientModal.description"
          values={{
            client: name,
          }}
        />
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-lg btn-flat-secondary" onClick={props.onClosed}>
          <FormattedMessage id="common.cancel" />
        </button>

        <LoadingButton
          className="btn btn-lg btn-warning"
          icon="delete"
          loading={isLoading}
          onClick={handleDelete}
        >
          <FormattedMessage id="common.delete" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
