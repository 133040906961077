import { Authorize } from '@features/authorization/Authorize';
import { createPortal } from 'react-dom';
import { TechnicalErrorWarningModal } from '@components/modals/TechnicalErrorWarningModal';
import { FormattedMessage } from 'react-intl';
import type { UpdateProfileErrorCode } from '@services/fx/model/models';
import type { EditPermission } from '@features/authorization/authorization.model';

type CustomCashFormButtonPropsType = {
  onClick: () => void;
  authorizedPermissions: EditPermission[];
  errorCode: UpdateProfileErrorCode | null;
  message: string;
  setError: (value: React.SetStateAction<{ errorCode: UpdateProfileErrorCode | null; message: string }>) => void;
};

export const CustomCashFormButton = ({
  onClick,
  authorizedPermissions,
  errorCode,
  message,
  setError,
}: CustomCashFormButtonPropsType) => {
  const domNode = document.querySelector(`#root-modal`);

  return (
    <Authorize authorizedFor={authorizedPermissions}>
      {errorCode === 'TechnicalError' &&
        createPortal(
          <TechnicalErrorWarningModal
            message={message}
            isOpen={errorCode === 'TechnicalError'}
            onClosed={() => setError({ errorCode: null, message: '' })}
          />,
          domNode!,
        )}
      <button
        onClick={onClick}
        className="form-control mt-1 btn btn-md btn-outline-primary align-self-center"
        data-e2e="add-new-custom-margin"
      >
        <FormattedMessage id="button.newPreset.custom" />
      </button>
    </Authorize>
  );
};
