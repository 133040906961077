import type { FC } from 'react';
import { HStack } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import type { FxCashGridDataType, FxCashProductKey } from '@services/fx/model/cash';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { changeSetupType, type SetupType } from '@store/fxProfileEdition/fxProfileEditionSlice';

interface SetupSwitchProps {
  gridDataType: FxCashGridDataType;
  isEditingRowMargin: boolean;
  isEditingRowTiering: boolean;
  productKey: FxCashProductKey;
}

const SetupSwitch: FC<SetupSwitchProps> = ({ gridDataType, productKey, isEditingRowTiering, isEditingRowMargin }) => {
  const isRejection = !isEditingRowTiering && !isEditingRowMargin;
  const dispatch = useAppDispatch();

  const setSetupType = (type: SetupType) => {
    dispatch(changeSetupType({ type, productKey }));
  };

  return (
    <div>
      <HStack horizontalAlignment="start">
        <div className={'text-primary fw-medium me-1'}>
          <FormattedMessage id="fx.editProfile.fx-cash-rfq-perimeter.setup" />
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="setup"
            id="marginOrTieringSetup"
            data-e2e={'margin-tiering-setup-radio'}
            checked={!isRejection}
            onClick={() => setSetupType(gridDataType === 'margin-grid-data-type' ? 'margin' : 'tiering')}
          />
          <label className="form-check-label" htmlFor="marginOrTieringSetup">
            <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.setup.type.${gridDataType}`} />
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="setup"
            id="rejectionSetup"
            data-e2e={'rejection-setup-radio'}
            checked={isRejection}
            onClick={() => setSetupType('rejection')}
          />
          <label className="form-check-label" htmlFor="rejectionSetup">
            <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.setup.type.rejection`} />
          </label>
        </div>
      </HStack>
      {isRejection && (
        <span className="text-info">
          <FormattedMessage id={`fx.editProfile.fx-cash-rfq-perimeter.setup.rejection-warning`} />
        </span>
      )}
    </div>
  );
};

export default SetupSwitch;
