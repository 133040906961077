import { AccountCenter } from './AccountCenter';
import { HeaderRoutes } from './HeaderRoutes';
import { Breadcrumb } from '../Breadcrumb/Breadcrumb';
import { Authorize } from '../../../features/authorization/Authorize';
import sgLogo from 'assets/sg-logo.png';
import { Link } from 'react-router-dom';

export const Header = (): JSX.Element => (
  <header className="bg-lvl2 border-bottom">
    <nav className="navbar navbar-lg">
      <div className="navbar-title">
        <Authorize
          authorizedFor={['FX_READALLDESK', 'LAG_READ']}
          mode="all"
          fallback={<img src={sgLogo} alt="SG logo Glyph" />}
        >
          <Link to="/" className="navbar-title-link">
            <div className="navbar-logo">
              <img src={sgLogo} alt="SG logo Glyph" />
            </div>

            <div className="navbar-title-divider" />

            <div className="navbar-service-name">Margin and Tiering</div>
          </Link>
        </Authorize>
      </div>

      <div className="navbar-toolbar pe-3">
        <AccountCenter />
        {/*<AppLogo/>*/}
      </div>
    </nav>

    <div className="px-4 bg-lvl2 pt-2">
      <Breadcrumb />
      <HeaderRoutes />
    </div>
  </header>
);
