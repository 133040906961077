import type {
  ColDef,
  GridOptions,
  IRowNode,
  RowClassParams,
  RowStyle,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import type { AlgoMarginUnit, FxOrderProfileRow, LimitMarginUnit } from '@services/fx/model/order';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

//  █████╗  ██████╗        ██████╗ ██████╗ ██╗██████╗      ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
// ██╔══██╗██╔════╝       ██╔════╝ ██╔══██╗██║██╔══██╗    ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
// ███████║██║  ███╗█████╗██║  ███╗██████╔╝██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
// ██╔══██║██║   ██║╚════╝██║   ██║██╔══██╗██║██║  ██║    ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
// ██║  ██║╚██████╔╝      ╚██████╔╝██║  ██║██║██████╔╝    ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
// ╚═╝  ╚═╝ ╚═════╝        ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝      ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝

export const useGridOption = (): GridOptions => {
  const { locale } = useIntl();

  const gridOptions = useMemo<GridOptions<FxOrderProfileRow>>(
    () => ({
      columnTypes: getColumnTypes(),
      headerHeight: 32,
      rowHeight: 32,
      suppressContextMenu: true,
      suppressMovableColumns: true,
      suppressCellFocus: true,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      enableRangeSelection: false,
      enableFillHandle: false,
      animateRows: true,

      // Let AgGrid compute rowId to allow multiple new row
      // getRowId: ({ data }: GetRowIdParams<FxProfileRow>) => data.internalRowId.join(''),
      getRowStyle: ({ node }: RowClassParams<FxOrderProfileRow>) => {
        const rowStyle: RowStyle = {};

        if (node.lastChild) {
          Object.assign(rowStyle, { fontWeight: 'bold', borderBottom: 'none' });
        }

        return rowStyle;
      },

      getRowClass: ({ node }: RowClassParams<FxOrderProfileRow>) =>
        clsx({
          'text-info': node.data?.state !== 'untouched',
        }),

      // Aggrid "isRowSelectable" function doesn't provide 'lastRow' information, so we add this data in Row context.
      isRowSelectable: ({ data }: IRowNode<FxOrderProfileRow>) => !data?.rowContext.isLastRow,
    }),
    [locale],
  );

  return gridOptions;
};

//  ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗    ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
// ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║       ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
// ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║       ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
// ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║       ██║      ██║   ██║     ███████╗███████║
//  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝       ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

type ColumnTypes = Record<string, ColDef<FxOrderProfileRow>>;

const getColumnTypes = (): ColumnTypes => ({
  alignRight: {
    headerClass: ['ag-right-aligned-header'],
    cellClass: ['ag-right-aligned-cell'],
  },
  marginFormatValue: {
    valueFormatter: marginTransform,
    headerClass: 'text-secondary',
  },
  defaultFormatValue: {
    valueFormatter: textValueFormatter,
    getQuickFilterText: textValueFormatter,
  },
});

const textValueFormatter = ({ value, context }: ValueFormatterParams) => {
  if ('*' === value) {
    return context.formatMessage({ id: value });
  }
  return value;
};

// ███╗   ███╗ █████╗ ██████╗  ██████╗ ██╗███╗   ██╗    ████████╗██████╗  █████╗ ███╗   ██╗███████╗███████╗ ██████╗ ██████╗ ███╗   ███╗
// ████╗ ████║██╔══██╗██╔══██╗██╔════╝ ██║████╗  ██║    ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝██╔════╝██╔═══██╗██╔══██╗████╗ ████║
// ██╔████╔██║███████║██████╔╝██║  ███╗██║██╔██╗ ██║       ██║   ██████╔╝███████║██╔██╗ ██║███████╗█████╗  ██║   ██║██████╔╝██╔████╔██║
// ██║╚██╔╝██║██╔══██║██╔══██╗██║   ██║██║██║╚██╗██║       ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
// ██║ ╚═╝ ██║██║  ██║██║  ██║╚██████╔╝██║██║ ╚████║       ██║   ██║  ██║██║  ██║██║ ╚████║███████║██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
// ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝       ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝

type MarginTransformProps = {
  value: number;
  context: { valueUnit: AlgoMarginUnit | LimitMarginUnit };
};

const marginTransform = ({ value, context }: MarginTransformProps) => {
  const defaultedValue = Number.isNaN(value) ? 0 : Number(value);

  if (context.valueUnit === 'mln') {
    // JS multiply function by 100 giving weird result like : 0.28 * 100 = 28.000000000000004, so we use this trick (cf: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary)
    return `${Math.round((defaultedValue + Number.EPSILON) * 100)}`;
  }

  return `${defaultedValue}`;
};
