import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
  AlgoMarginUnit,
  FixingMarginUnit,
  FxMarginUnit,
  FxOrderProductKey,
  LimitMarginUnit,
} from '@services/fx/model/order';
import { isDefined } from '@sgme/fp';

export interface UiState {
  activePage: Page;
  hasAuthExpired: boolean;
  isMatomoInitialized: boolean;
  marginUnit: {
    fx: FxMarginUnit;
  };
}

export const DEFAULT_MARGIN_UNIT_BY_PRODUCT: Record<FxOrderProductKey, string> = {
  'algo-order-product': 'mln' as AlgoMarginUnit,
  'limit-order-product': 'pips' as LimitMarginUnit,
  'fixing-order-product': 'bps' as FixingMarginUnit,
};

export type Page = 'ClientsMargins' | 'MarginsPresets';

// see src/hooks/useMarginUnit.ts
// can't be merged with useMarginUnit because there is a cycle dependency
const getMarginUnit = (productKey: FxOrderProductKey) => {
  const savedMarginUnit = localStorage.getItem(`fx-unit-${productKey}`);

  return isDefined(savedMarginUnit) ? JSON.parse(savedMarginUnit) : DEFAULT_MARGIN_UNIT_BY_PRODUCT[productKey];
};

const initialState: UiState = {
  activePage: 'ClientsMargins',
  hasAuthExpired: false,
  isMatomoInitialized: false,
  marginUnit: {
    fx: {
      algo: getMarginUnit('algo-order-product'),
      limit: 'pips',
      fixing: 'bps',
    },
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    authExpired: (state) => {
      state.hasAuthExpired = true;
    },
    pageChanged: (state, { payload }: PayloadAction<Page>) => {
      state.activePage = payload;
    },
    setFxMarginAlgoUnit: (state, { payload }: PayloadAction<AlgoMarginUnit>) => {
      state.marginUnit.fx.algo = payload;
    },
    matomoInitialized: (state) => {
      state.isMatomoInitialized = true;
    },
  },
});
