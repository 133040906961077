import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { LoadingButton } from 'components/LoadingButton';

import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { connectTimeoutModal } from './connectTimeoutModal';

export interface TimeoutModalProps {
  isOpen: boolean;
}

function TimeoutModalRaw({ isOpen }: TimeoutModalProps): JSX.Element {
  const { sgwtWebAnalytics } = useSgwtWidgets();
  const [isLoading, setLoading] = useState(false);

  const reload = useCallback(() => {
    setLoading(true);

    sgwtWebAnalytics?.trackEvent('Common', 'Page reloaded');

    window.location.reload();
  }, [sgwtWebAnalytics]);

  return (
    <Modal isOpen={isOpen} contentClassName="border border-warning" backdrop="static">
      <ModalHeader tag="h3" className="text-warning">
        <FormattedMessage id="timeoutModal.title" />
      </ModalHeader>

      <ModalBody>
        <FormattedMessage id="timeoutModal.description" />
      </ModalBody>

      <ModalFooter>
        <LoadingButton className="btn btn-lg btn-warning" icon="refresh" loading={isLoading} onClick={reload}>
          <FormattedMessage id="common.reload" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}

export const TimeoutModal = connectTimeoutModal(TimeoutModalRaw);
