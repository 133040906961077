import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import { getAppConfig } from 'config';

export function Footer(): JSX.Element {
  const appConfig = getAppConfig();

  if (appConfig.env === 'local') {
    return <FakeFooter />;
  }

  return (
    <SgwtMiniFooter
      id="sgwt-mini-footer"
      mode="sg-markets"
      accessibility-link={appConfig.sgwtFooter.accessibilityLink}
      accessibility-compliance={appConfig.sgwtFooter.accessibilityCompliance}
      contact-us="{'mail':'sgmarketsfx@sgcib.com'}"
      cookies-consent={appConfig.env !== 'devci'}
    />
  );
}

export function FakeFooter(): JSX.Element {
  return (
    <footer className="sgbs-footer-light border-top sgwt-compact-footer p-3">
      <span className="d-flex justify-content-between ">
        <span className="h6 mb-0 fw-normal sgwt-mini-footer-sg-label">
          ©<span className="d-none d-sm-inline-block mx-1">Groupe Société Générale</span>
        </span>
      </span>
    </footer>
  );
}
