export function assertDefined<T>(actual: T, message?: string): asserts actual is NonNullable<T> {
  if (actual === undefined || actual === null) {
    throw {
      message: message ?? 'Unexpected undefined value',
      actual,
    };
  }
}

/**
 * Asserts that `value`is defined and returns it with its type narrowed
 */
export function narrowDefined<T>(value: T) {
  assertDefined(value);
  return value;
}

export function assertUnreachable(actual: never, message: string): asserts actual is never {
  throw { message, actual };
}
