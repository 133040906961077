import { HelpCenter } from '@components/HelpCenter';
import { AlgoOrderProductMarginUnitSwitch } from '@components/fx/AlgoOrderProductMarginUnitSwitch';
import type { FxProductKey } from '@services/fx/model/perimiters';
import type { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';

type FxSelectProfileTableActionHeaderProps = {
  onFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  productKey: FxProductKey;
};

const FxSelectProfileTableActionHeader: FC<FxSelectProfileTableActionHeaderProps> = ({ onFilter, productKey }) => {
  const { formatMessage } = useIntl();

  // return (
  //   <div className={`input-group pb-1 w-25`}>
  //     <div className="input-icon-start">
  //       <em className="icon">search</em>
  //     </div>
  //
  //     <input
  //       type="search"
  //       className="form-control"
  //       id="filter-text-box"
  //       placeholder={formatMessage({ id: 'common.search' })}
  //       autoComplete="off"
  //       onChange={onFilter}
  //     />
  //
  //     {productKey === 'algo-order-product' && <AlgoOrderProductMarginUnitSwitch />}
  //   </div>
  // );

  return (
    <div className="d-flex flex-row align-items-end">
      <div className="form-groupw w-25">
        <div className={'input-group pb-1'}>
          <div className="input-icon-start">
            <em className="icon">search</em>
          </div>

          <input
            type="search"
            className="form-control"
            id="filter-text-box"
            placeholder={formatMessage({ id: 'common.search' })}
            autoComplete="off"
            onChange={onFilter}
          />
        </div>
      </div>

      <div className="d-flex pb-1 ms-auto">
        {productKey === 'algo-order-product' && <AlgoOrderProductMarginUnitSwitch />}

        <HelpCenter className="ms-2" withHandler />
      </div>
    </div>
  );
};

export default FxSelectProfileTableActionHeader;
