import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {useAppDispatch} from "../../../../../hooks/useAppDispatch";
import {useAppSelector} from "../../../../../hooks/useAppSelector";
import {selectFilter} from "../../../../../store/filters/filtersSelectors";
import {actionCreators} from "../../../../../store/actions";

export const ActiveClient = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const activeClientsOnly = useAppSelector(selectFilter('activeClientsOnly'));

  const onActiveClientsOnlyToggle = useCallback(() => {
    dispatch(actionCreators.filters.activeClientsOnlyFilterToggled());
  }, [dispatch]);

  return (
    <div className="form-check mb-0">
      <input
        type="checkbox"
        id="activeClientsOnly"
        className="form-check-input fw-medium mb-2"
        checked={ activeClientsOnly }
        onChange={ onActiveClientsOnlyToggle }
      />

      <label htmlFor="activeClientsOnly" className="form-check-label">
        <FormattedMessage id="filters.activeClientsOnly"/>
      </label>
    </div>
  );
};
