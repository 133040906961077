import { fxApi } from './fxApi';
import { haveSomeTouchedRow, mapRowUi } from './transformFxResponse';
import { clearUndefined } from '@sgme/fp';
import type { ClientBdrId, ClientBdrLevel } from './model/client';
import type { FxOrderProfileRow } from './model/order';
import type { FxCashGridDataType } from './model/cash';
import type { FxPerimeterKey, FxProductKey } from '@services/fx/model/perimiters';

export const unassignFxProfileForProductApi = fxApi.injectEndpoints({
  endpoints: (builder) => ({
    unassignFxProfileForProduct: builder.mutation<
      void,
      {
        clientBdrId: ClientBdrId;
        clientBdrLevel: ClientBdrLevel;
        perimeterKey: FxPerimeterKey;
        productKey: FxProductKey;
        reasonId: number;
        gridDataType?: FxCashGridDataType;
        comment?: string;
        onBehalfOfUser?: string;
      }
    >({
      query: ({ clientBdrId, clientBdrLevel, perimeterKey, productKey, gridDataType, ...reason }) =>
        // Find if a row has been touched
        ({
          method: 'POST',
          url: `perimeters/${perimeterKey}/products/${productKey}/clients/${clientBdrId}/unassign-profile`,
          params: {
            clientBdrLevel,
            gridDataType,
          },
          body: clearUndefined({ ...reason }), // TODO: add reason ?
        }),
      invalidatesTags: (result, error, { perimeterKey, productKey, clientBdrId, clientBdrLevel /*, profileId */ }) => [
        { type: 'Profile', id: `${perimeterKey}-${productKey}` },
        // TODO: update the previous profile ?
        // { type: 'ClientsUsingProfile', id: profileId },
        // { type: 'Profile', id: `${profileId}` },
        { type: 'ClientsInheritance', id: `${clientBdrId}/${clientBdrLevel}` },
        'FXClients',
      ],

      // TODO: update the profile ID ??
      // onQueryStarted: async (
      //   { perimeterKey, productKey, profileId },
      //   { dispatch, getState, queryFulfilled },
      // ) => {
      //   // retrieve choosing profile to update product, and assign it to product in optimistic strategy
      //   const profileQueryArg = { profileId, perimeterKey, productKey };
      //   const { data: newProfile } = selectFXProfile(profileQueryArg)(getState());
      //   const patchResult = dispatch(
      //     profileApi.util.updateQueryData(
      //       'getFXOrderProfile',
      //       { profileId, perimeterKey, productKey },
      //       (draft) => {
      //         Object.assign(draft, newProfile);
      //       },
      //     ),
      //   );
      //
      //   // in case of request is in error, undo the optimistic update
      //   queryFulfilled.catch(patchResult.undo);
      // },
    }),
  }),
});

export const { useUnassignFxProfileForProductMutation } = unassignFxProfileForProductApi;

export function findRowsChanges(profileRows: FxOrderProfileRow[]): boolean {
  return profileRows.map(mapRowUi).some(haveSomeTouchedRow);
}
