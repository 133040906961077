import type { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

const DESIGN = {
  collapsed: {
    className: '',
    style: { width: '3rem' },
    icon: 'last_page',
  },
  expanded: {
    className: 'px-4 col-6 col-md-4 col-xl-3 col-xxl-3',
    style: {},
    icon: 'first_page',
  },
  hide: {
    className: 'd-none',
    style: {},
    icon: '',
  },
};

interface CollapsableSidePanelProps extends HTMLAttributes<HTMLDivElement> {
  collapsed?: boolean;
  hide?: boolean;
  headerLocaleKey?: string;
  toggle: () => void;
}

export const CollapsableSidePanel = ({
  className = '',
  headerLocaleKey = '',
  collapsed = false,
  hide = false,
  toggle,
  children,
}: CollapsableSidePanelProps): JSX.Element => {
  const design = hide ? DESIGN.hide : DESIGN[collapsed ? 'collapsed' : 'expanded'];

  return (
    <div className={`card h-100 ${design.className} ${className ?? ''}`} style={design.style}>
      <div className={`d-flex align-items-center justify-content-around mt-4 mb-2`}>
        {!collapsed && (
          <h3 className="card-title line-height-1 w-100 m-0">
            <FormattedMessage id={headerLocaleKey} />
          </h3>
        )}

        <button className="btn btn-discreet btn-md p-0" onClick={toggle}>
          <em className="icon text-muted">{design.icon}</em>
        </button>
      </div>

      {!collapsed && <div>{children}</div>}
    </div>
  );
};
