import { Route, Routes } from 'react-router-dom';

import { HomeHeader } from './HomeHeader';
import { FX_PRODUCTS_ROUTE, FX_ROUTE } from '../../fx/constants';
import { LAG_PRESETS_ROUTE, LAG_ROUTE } from '../../lag/contants';
import { LagClientsHeader } from './LagHeader/LagClientsHeader';
import { LagPresetsHeader } from './LagHeader/LagPresetsHeader';
import { FxClientsHeader } from './FXHeader/FxClientsHeader';
import { FxProductsHeader } from './FXHeader/FxProductsHeader';

// this way of handling header decoupled from pages should not be used anymore
// please use the SpecificHeader in router.tsx to declare your header alongside
// your route. This collocation makes thing easier to maintain and thinking about.
export const HeaderRoutes = () => (
  <Routes>
    <Route path="/" element={<HomeHeader />} />

    <Route path={LAG_ROUTE} element={<LagClientsHeader />} />
    <Route path={LAG_PRESETS_ROUTE} element={<LagPresetsHeader />} />

    <Route path={FX_ROUTE} element={<FxClientsHeader />} />
    <Route path={FX_PRODUCTS_ROUTE} element={<FxProductsHeader />} />
  </Routes>
);
